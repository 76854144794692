import React, { useState } from "react";
import { Grid, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import styles from "./styles";
import Files from "../../Config/Files.js";
import authActions from "../../Redux/reducers/auth/actions.js";
import { useGoogleLogin } from "@react-oauth/google";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getAPIProgressData } from "../../Utils/APIHelper.js";
import { Setting } from "../../Utils/Setting.js";

// function CGoogle(props) {
const CGoogle = (props) => {
  const classes = styles();
  const [buttonLoader, setButtonLoader] = useState(false);
  const {
    setOpenLoginModal = false,
    setReviewModalBool = false,
    setAccVerifyModal = false,
  } = props;
  const navigate = useNavigate();

  // Set data in Redux
  const dispatch = useDispatch();
  const { setAccessToken, setUserData } = authActions;

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      const token = tokenResponse?.access_token;
      setButtonLoader(false);
      getSocialLogin(token);
    },
  });

  const getSocialLogin = async (token) => {
    setButtonLoader(true);
    let endPoints = Setting.endpoints.socialLogin;

    const params = {
      "UserSocialConnect[token]": token,
      "UserSocialConnect[provider]": "google",
    };

    try {
      const resp = await getAPIProgressData(endPoints, "POST", params);
      if (resp?.success) {
        const data = resp?.data;
        if (data?.signup_pending) {
          navigate("/new-member", { state: { data } });
          dispatch(setAccessToken(data?.user_data?.access_token));
          dispatch(setUserData(data));
          toast.success(resp?.message, {
            position: "top-right",
          });
        } else if (data?.under_review) {
          setAccVerifyModal(true);
          setReviewModalBool(true);
          setOpenLoginModal(false);
        } else {
          dispatch(setUserData(data));
          dispatch(setAccessToken(data?.access_token));
          navigate("/home");
          toast.success(resp?.message, { position: "top-right" });
        }
        setButtonLoader(false);
      } else {
        toast.error(resp?.message, {
          position: "top-right",
        });
      }
      setButtonLoader(false);
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
      });
      setButtonLoader(false);
    }
  };

  return (
    <>
      <Grid
        container
        onClick={
          buttonLoader
            ? null
            : () => {
                login();
              }
        }
      >
        {buttonLoader ? (
          <CircularProgress className={classes.loader} />
        ) : (
          <div className={classes.circleContainer}>
            <img
              src={Files.Images.google}
              alt="google"
              className={classes.socialImage}
            />
          </div>
        )}
      </Grid>
    </>
  );
};

CGoogle.propTypes = {};

CGoogle.defaultProps = {};

export default CGoogle;
