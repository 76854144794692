import { makeStyles } from "@mui/styles";
import BaseColor from "../../Config/colors";

const styles = makeStyles((theme) => ({
  loader: {
    width: 15,
    height: 15,
    color: BaseColor.primary,
  },
  circleContainer: {
    width: "52px",
    height: "52px",
    borderRadius: "50%",
    border: "1px solid #B4BDC3",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  socialImage: {
    height: "21px",
    width: "21px",
  },
}));

export default styles;
