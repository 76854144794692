import React, { useEffect, useRef, useState } from "react";
import {
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
import { isMobile, isTablet } from "react-device-detect";
import CHeader from "../../Components/Header/index.js";
import theme from "../../Config/theme.js";
import BaseColor from "../../Config/colors.js";
import styles from "./chat.styles.js";
import CIcon from "../../Components/CIcon/index.js";
import { sideBarTabs } from "../../Config/staticData.js";
import { store } from "../../Redux/store/configureStore.js";
import authActions from "../../Redux/reducers/auth/actions";
import ChatSidebar from "./ChatSidebar/ChatSidebar.js";
import ChatSection from "./ChatSection/ChatSection.js";
import { socket } from "../../Context/ContextSocket";
import { motion } from "framer-motion";

export default function Chat() {
  // ref
  const sideBarRef = useRef(null);

  // redux
  const { userData } = useSelector((state) => state.auth);
  const { selectedChatData } = useSelector((state) => state.auth);
  const { setActiveHomeTab, setCTab, setActiveMenu } = authActions;
  const {
    auth: { activeTab },
  } = store.getState();
  const dispatch = useDispatch();

  // location
  const location = useLocation();

  // media queries responsive
  const classes = styles();
  const md = useMediaQuery(theme.breakpoints.down("md"));

  // navigation
  const navigate = useNavigate();

  // chat filter tab
  const [listType, setListType] = useState("personal");

  // online users
  const [onlineUsersList, setOnlineUsersList] = useState([]);

  // page loader
  const [pageLoader, setPageLoader] = useState(false);

  // chat data
  const [chatData, setChatData] = useState({});

  // load more chat section
  const [isMoreClick, setIsMoreClick] = useState(false);

  // DND mode
  const [dnd, setDnd] = useState(false);

  // get particular user's chat
  const [particularUserChat, setParticularUserChat] = useState([]);

  // chat section name
  const [newName, setNewName] = useState("");

  // chat section loader
  const [singleChatLoader, setSingleChatLoader] = useState(false);
  const [chatSectionLoader, setChatSectionLoader] = useState(false);

  // particular user data
  const [particularUser, setParticularUser] = useState("");

  // type and zoom
  const [typingData, setTypingData] = useState(null);
  const [isZoomed, setIsZoomed] = useState(false);

  // page nation in chat
  const [chatPageNo, setChatPageNo] = useState(1);
  const [isMore, setIsMore] = useState(false);

  // open particular chat
  const [openChat, setOpenChat] = useState({ open: false, data: {} });

  useEffect(() => {
    if (location.pathname === "/chat") {
      dispatch(setActiveHomeTab("Chat"));
    }
  }, []);

  useEffect(() => {
    if (!socket.connected) {
      userConnectEmmit();
    }
  }, [socket.connected]);

  const userConnectEmmit = () => {
    if (!socket.connected) {
      socket.connect();
    }
  };

  useEffect(() => {
    getOnlineUserList();
    receiveUpdatedOnlineUsers();
  }, []);

  useEffect(() => {
    if (!isEmpty(selectedChatData)) {
      setParticularUser(selectedChatData);
      setOpenChat({ open: true, data: selectedChatData });
      handleOpenParticularchat(selectedChatData, 1, false);
    }
  }, [selectedChatData]);

  const receiveUpdatedOnlineUsers = () => {
    if (socket !== null) {
      socket.on("get_updated_online_users", (req) => {
        setOnlineUsersList(req?.data);
      });
    }
  };

  const getOnlineUserList = () => {
    if (socket !== null) {
      socket.emit("online_users", {}, (response) => {
        setOnlineUsersList(response);
      });
    }
  };

  // get single chat
  function handleOpenParticularchat(
    contact,
    page = 1,
    loader,
    type = "",
    chatLoader
  ) {
    if (loader) {
      setSingleChatLoader(true);
      setIsMoreClick(true);
    } else {
      setIsMoreClick(false);
    }

    if (chatLoader) {
      setChatSectionLoader(true);
    }

    const requestData = {
      page: page,
      user_id: userData?.id,
      c_id: contact?.conversation_id,
    };

    if (type === "send") {
      sideBarRef?.current?.callChat();
    } else if (type === "archiveList") {
      sideBarRef?.current?.archiveList();
    }

    socket.emit("get_single_chat", requestData, (anotherResponse) => {
      console.log("anotherResponse======>>>>>", anotherResponse);
      if (anotherResponse.status === false) {
        setParticularUserChat([]);
        setSingleChatLoader(false);
        setChatSectionLoader(false);
      } else {
        const messages = anotherResponse.data.messages;
        setChatData(anotherResponse?.data?.conversationData[0]);
        setNewName(anotherResponse?.data?.conversationData[0]?.conv_name);
        setDnd(anotherResponse?.data?.user_dnd_status === 1 ? true : false);
        const newData =
          page === 1
            ? messages.reverse()
            : [...messages.reverse(), ...particularUserChat];
        setParticularUserChat(newData);
        let data = {
          c_id: contact?.conversation_id,
          s_id: userData.id,
        };
        setIsMore(anotherResponse?.data?.pagination?.isMore);
        socket.emit("read_all_messages", data, (response) => {});
        setSingleChatLoader(false);
        setChatSectionLoader(false);
      }
    });
  }

  // this function for update chat list according to chat type
  function updateList(type = "") {
    if (type === "block" || type === "archive" || type === "clear") {
      sideBarRef?.current?.callChat();
    } else if (type === "unblock") {
      sideBarRef?.current?.blockList();
    } else if (type === "unarchive") {
      sideBarRef?.current?.archiveList();
    } else {
      sideBarRef?.current?.callChatList();
    }
  }

  function changeTab() {
    sideBarRef?.current?.changeTab();
  }

  return (
    <>
      <CHeader page={"chat"} />
      {md || isMobile || isTablet ? (
        <div style={{ backgroundColor: BaseColor.white }}>
          <Grid item xs={0.5} sm={0.5} md={1} lg={1} />
          <Grid
            item
            xs={11}
            sm={11}
            md={10}
            lg={10}
            style={{
              height: "calc(100vh - 70px)",
              overflowY: "auto",
            }}
          >
            {pageLoader ? (
              <Grid
                container
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                style={{
                  height:
                    md || isMobile || isTablet
                      ? "calc(100vh - 70px)"
                      : "calc(100vh - 90px)",
                }}
              >
                <CircularProgress size={40} color="primary" />
              </Grid>
            ) : (
              <Grid container>
                <Grid
                  item
                  xs={12}
                  style={{
                    overflowX: "auto",
                  }}
                >
                  <ChatSidebar
                    style={{
                      position: openChat?.open && "relative",
                      zIndex: openChat?.open && -100,
                      display: openChat?.open && "none",
                    }}
                    ref={sideBarRef}
                    mobile={true}
                    setPageLoader={(bool) => {
                      setPageLoader(bool);
                    }}
                    setOpenChat={setOpenChat}
                    onlineUsersList={onlineUsersList}
                    listType={listType}
                    setListType={setListType}
                    // chatList={chatList}
                    isTypingData={typingData}
                    setParticularUser={setParticularUser}
                    particularUser={particularUser}
                    handleOpenParticularchat={handleOpenParticularchat}
                    updateList={updateList}
                    changeTab={
                      location?.state?.changeTab === true ? true : false
                    }
                  />
                  {openChat?.open && (
                    <ChatSection
                      style={{
                        position: openChat?.open && "absolute",
                        display: !openChat?.open && "none",
                        zIndex: openChat?.open && 1000,
                        top: 80,
                        width: "100%",
                        backgroundColor: BaseColor.white,
                      }}
                      chatData={chatData}
                      listType={listType}
                      openChat={openChat}
                      onlineUsersList={onlineUsersList}
                      setOpenChat={setOpenChat}
                      setListType={setListType}
                      particularUser={particularUser}
                      particularUserChat={particularUserChat}
                      handleOpenParticularchat={handleOpenParticularchat}
                      updateList={updateList}
                      setParticularUserChat={setParticularUserChat}
                      setParticularUser={setParticularUser}
                      changePageNo={(page) => {
                        setChatPageNo(page);
                      }}
                      // isTypingData1={(data) => {
                      //   setTypingData(data);
                      // }}
                      pageNo={chatPageNo}
                      isMore={isMore}
                      singleChatLoader={singleChatLoader}
                      isMoreClick={isMoreClick}
                      chatSectionLoader={chatSectionLoader}
                      changeTab={() => changeTab()}
                      newName={newName}
                      setNewName={setNewName}
                      dnd={dnd}
                    />
                  )}
                </Grid>
              </Grid>
            )}

            {!openChat.open && (
              <Grid
                container
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  position: "fixed",
                  bottom: 0,
                  backgroundColor: BaseColor.white,
                  zIndex: 1111,
                  height: "80px",
                }}
              >
                {sideBarTabs?.map((data) => (
                  <motion.div
                    key={data.id}
                    whileTap={{ scale: data?.title === "Chat" ? 0.8 : null }}
                    whileHover={{ scale: 0.9 }}
                    initial={{ scale: data?.title === "Chat" ? 0.7 : null }}
                    animate={{
                      scale: isZoomed && data?.title === "Chat" ? 1.1 : 1,
                    }}
                  >
                    <CIcon
                      src={
                        activeTab === data?.title
                          ? data?.mobFillIcon
                          : data?.icon
                      }
                      onClick={() => {
                        dispatch(setActiveHomeTab(data?.title));
                        dispatch(setActiveMenu(""));
                        dispatch(setCTab("personal"));
                        navigate(data.path);
                      }}
                      size={35}
                    />
                    <Typography className={classes.iconText}>
                      {data?.title}
                    </Typography>
                  </motion.div>
                ))}
              </Grid>
            )}
          </Grid>
          <Grid item xs={0.5} sm={0.5} md={1} lg={1} />
        </div>
      ) : (
        <Grid container style={{ backgroundColor: BaseColor.white }}>
          <Grid item xs={0.5} sm={0.5} md={1} lg={1}>
            <Grid
              container
              style={{
                height: "calc(100vh - 90px)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              {sideBarTabs?.map((data) => (
                <>
                  <motion.div
                    className={classes.iconContainer}
                    style={{
                      backgroundColor:
                        activeTab === data?.title
                          ? BaseColor.primary
                          : "#EDF1F2",
                    }}
                    onClick={() => {
                      dispatch(setActiveHomeTab(data?.title));
                      dispatch(setActiveMenu(""));
                      dispatch(setCTab("personal"));
                      navigate(data.path);
                    }}
                    whileTap={{ scale: data?.title === "Chat" ? 0.8 : null }}
                    whileHover={{ scale: 0.9 }}
                    initial={{ scale: data?.title === "Chat" ? 0.7 : null }}
                    animate={{
                      scale: isZoomed && data?.title === "Chat" ? 1.1 : 1,
                    }}
                    transition={{ duration: 0.4 }}
                  >
                    <CIcon
                      src={
                        activeTab === data?.title ? data?.fillIcon : data?.icon
                      }
                      size={35}
                      style={{ cursor: "pointer" }}
                    />
                  </motion.div>
                  <Typography
                    className={classes.iconText}
                    style={{
                      color:
                        activeTab === data?.title
                          ? BaseColor.primary
                          : BaseColor.grey,
                      marginBottom: "20px",
                    }}
                  >
                    {data?.title}
                  </Typography>
                </>
              ))}
            </Grid>
          </Grid>
          <Grid
            items
            xs={11.5}
            sm={11.5}
            md={11}
            lg={11}
            style={{ overflowY: "auto", height: "calc(100vh - 90px)" }}
          >
            {pageLoader ? (
              <Grid
                container
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                style={{
                  height:
                    md || isMobile || isTablet
                      ? "calc(100vh - 70px)"
                      : "calc(100vh - 90px)",
                }}
              >
                <CircularProgress size={40} color="primary" />
              </Grid>
            ) : (
              <Grid container style={{ maxHeight: "calc(100vh - 90px)" }}>
                <Grid
                  item
                  xs={11.5}
                  sm={11.5}
                  md={11}
                  lg={11}
                  style={{ overflowX: "auto" }}
                  display={"flex"}
                >
                  <ChatSidebar
                    ref={sideBarRef}
                    // chatList={chatList}
                    loader={pageLoader}
                    setPageLoader={setPageLoader}
                    listType={listType}
                    isTypingData={typingData}
                    onlineUsersList={onlineUsersList}
                    setListType={setListType}
                    setParticularUser={setParticularUser}
                    particularUser={particularUser}
                    handleOpenParticularchat={handleOpenParticularchat}
                    updateList={updateList}
                    setOpenChat={setOpenChat}
                    changeTab={
                      location?.state?.changeTab === true ? true : false
                    }
                  />
                  <ChatSection
                    listType={listType}
                    setListType={setListType}
                    onlineUsersList={onlineUsersList}
                    openChat={openChat}
                    chatData={chatData}
                    setOpenChat={setOpenChat}
                    particularUser={particularUser}
                    particularUserChat={particularUserChat}
                    setParticularUser={setParticularUser}
                    handleOpenParticularchat={handleOpenParticularchat}
                    updateList={updateList}
                    setParticularUserChat={setParticularUserChat}
                    changePageNo={(page) => {
                      setChatPageNo(page);
                    }}
                    pageNo={chatPageNo}
                    isMore={isMore}
                    // isTypingData1={(data) => {
                    //   setTypingData(data);
                    // }}
                    singleChatLoader={singleChatLoader}
                    isMoreClick={isMoreClick}
                    chatSectionLoader={chatSectionLoader}
                    changeTab={() => changeTab()}
                    newName={newName}
                    setNewName={setNewName}
                    dnd={dnd}
                  />
                </Grid>
                <Grid item xs={0.5} sm={0.5} md={1} lg={1}></Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
}
