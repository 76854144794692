import { makeStyles } from "@mui/styles";
import { FontFamily } from "../../../Config/theme";
import BaseColor from "../../../Config/colors";
import { isMobile } from "react-device-detect";
const styles = makeStyles({
  emptyText: {
    fontFamily: `${FontFamily.GilbertQualifiDemo} !important`,
    fontSize: "40px !important",
    color: `${BaseColor.blackColor} !important`,
  },
  emptyText2: {
    fontFamily: `${FontFamily.Regular} !important`,
    fontSize: "20px !important",
    color: "#616161 !important",
  },
  profile_div: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#F5F5F5",
    padding: "0px 20px",
  },
  profile: {
    display: "flex",
    alignItems: "center",
    gap: "20px",
  },
  addMember: {
    fontSize: "8px",
  },
  online_dot: {
    position: "absolute",
    bottom: "2px",
    right: "100px",
    width: "12px",
    height: "12px",
    backgroundColor: "green",
    borderRadius: "50%",
    border: "2px solid white",
  },
  menuCss: {
    fontSize: "34px",
    marginLeft: "34px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  chat_container: {
    display: "flex",
    flexDirection: "column",
    padding: "20px",
    margin: "0 auto",
    height: "calc(100vh - 90px)",
    width: "100%",
    overflowY: "auto",
    position: "relative",
    alignItems: "center",
  },
  message: {
    display: "inline-block",
    margin: "5px 0",
    borderRadius: "8px",
    wordWrap: "break-word",
    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.2)",
    fontFamily: "MontserratMedium",
    lineHeight: "22px",
    fontSize: "16px",
  },
  sender: {
    alignSelf: "flex-end",
    backgroundColor: "#137450",
    color: "#FFFFFF",
  },
  receiver: {
    alignSelf: "flex-start",
    backgroundColor: "#F5F5F5",
    color: "#000000",
  },
  messageImg: {
    maxWidth: "100%",
    borderRadius: "10px",
    marginTop: "10px",
  },
  timestamp: {
    background: "transparent",
    color: "gray",
    marginTop: "5px",
    marginBottom: "10px",
  },
  mediaContainer: {
    position: "relative",
    display: "inline-block",
  },
  media: {
    filter: "blur(3px)",
    borderRadius: "8px",
  },
  downloadButton: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    cursor: "pointer",
  },
  inputMessage: {
    display: "flex",
    alignItems: "center",
    padding: "0px 10px",
    position: "fixed",
    bottom: "1vh",
  },
  modal_container: {
    padding: "40px 0px",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    justifyContent: "center",
  },
  modal_create: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
  },
  closeButton: {
    position: "absolute",
    right: 0,
    top: 0,
    color: "black",
  },
  stackContainer: {
    display: "flex",
    flexWrap: "wrap",
    width: "calc(5 * (100px + 5px))",
  },
  profileStack: {
    marginTop: "24px !important",
    width: "96px",
  },
  popOverText: {
    fontSize: "13px",
  },
  avatar_container: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
    columnGap: "36px",
    rowGap: "20px",
    margin: "2vh 0vw 8vh 0vw",
  },
  avatar_responsive_container: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    columnGap: isMobile ? "40px" : "60px",
    rowGap: "20px",
    marginTop: "2vh",
  },
  // Chat button
  chatBtn: {
    marginTop: "14px",
    fontSize: "14px",
    padding: "10px",
    width: "100%",
    border: "none",
    borderRadius: "20px",
    color: "white",
    fontWeight: "bold",
  },
  // Payment Model
  paymentLabelClass: {
    width: "100%",
    ".MuiTypography-root": {
      width: "90%",
      border: "1px solid #7B8B97",
      padding: "7px 15px",
      borderRadius: "20px",
      fontSize: "14px",
    },
    display: "flex",
  },
});

export default styles;
