import React, { useState } from "react";
import styles from "./chatSection.styles";
import Files from "../../../Config/Files";
import theme, { FontFamily } from "../../../Config/theme";
import {
  Avatar,
  AvatarGroup,
  CircularProgress,
  LinearProgress,
  useMediaQuery,
  Typography,
  Button,
  Grid,
  Tooltip,
} from "@mui/material";
import PaymentModel from "./PaymentModel";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import BaseColor from "../../../Config/colors";
import { Setting } from "../../../Utils/Setting";
import { getAPIProgressData } from "../../../Utils/APIHelper";
import { toast } from "react-toastify";
import DownloadIcon from "@mui/icons-material/Download";
import { useSelector } from "react-redux";
import moment from "moment";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { isMobile, isTablet } from "react-device-detect";
import CloseIcon from "@mui/icons-material/Close";
import { motion } from "framer-motion";
import CModal from "../../../Components/CModal";
import { IoCheckmarkOutline } from "react-icons/io5";
import CButton from "../../../Components/CButton";
import _ from "lodash";

const ChatMessage = ({
  chatData,
  index,
  message,
  isSender,
  messageType,
  mediaUrl,
  timestamp,
  messageData,
  listType,
  showMembers = () => {},
  afterRejectCall = () => {},
  editReservation = () => {},
}) => {
  console.log("messageData======>>>>>", messageData);

  // get data from redux
  const { userData } = useSelector((state) => state.auth);

  // style and media queries
  const classes = styles();
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  // download media
  const [mediaDownloaded, setMediaDownloaded] = useState(false);

  // payment modal
  const [showPaymentModel, setShowPaymentModel] = useState(false);

  const isHost = chatData?.host_user_ids?.find((e) => e === userData?.id);
  const updatedAtMoment = moment(
    messageData?.poll_data?.combinedData?.reservationDetails?.start_date
  ).format("MM-DD-YYYY");

  const updatedAtMomentTime = moment(
    messageData?.poll_data?.combinedData?.reservationDetails?.start_date +
      " " +
      messageData?.poll_data?.combinedData?.reservationDetails?.start_time,
    "YYYY-MM-DD hh:mm A" // based on the date get the time
  ).format("hh:mm A");

  const date = new Date(timestamp);
  const timeString = date.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });

  // reject and confirm btn loader
  const [rejectLoader, setRejectLoader] = useState(false);
  const [confirmLoader, setConfirmLoader] = useState(false);

  // collect payment modal
  const [collectPaymentModal, setCollectPaymentModal] = useState(false);

  // complete round
  const [completeRound, setCompleteRound] = useState(false);

  // reservation/poll member list
  const [memberList, setMemberList] = useState([]);

  // submit btn loader
  const [submitBtnLoader, setSubmitBtnLoader] = useState(false);

  // without payment btn loader
  const [withoutPayBtnLoader, setWithoutPayBtnLoader] = useState(false);

  // guest increment
  const increment = (index) => {
    setMemberList((prevList) =>
      prevList.map((member, i) =>
        i === index
          ? {
              ...member,
              current_count: Math.min(
                member.current_count + 1,
                member.guest_cnt
              ),
            }
          : member
      )
    );
  };

  // guest decrement
  const decrement = (index) => {
    setMemberList((prevList) =>
      prevList.map((member, i) =>
        i === index
          ? {
              ...member,
              current_count: Math.max(member.current_count - 1, 0),
            }
          : member
      )
    );
  };
  const revId = messageData?.poll_data?.combinedData?.reservation_id;
  const completedPaymentsCount =
    messageData?.poll_data?.combinedData?.usersDetails.filter(
      (user) => user?.payment_status == "confirm"
    )?.length;

  //reject poll
  const handleReject = async (type) => {
    if (type === "reject") {
      setRejectLoader(true);
    } else {
      setConfirmLoader(true);
    }
    let endPoints =
      type === "reject"
        ? Setting.endpoints.rejectReservation
        : Setting.endpoints.confirmReservation;
    const data = {
      "RejectReservation[reservation_id]": revId,
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", data, true);
      if (resp?.success) {
        if (type === "reject") {
          afterRejectCall();
          setRejectLoader(false);
        } else {
          afterRejectCall();
          setConfirmLoader(false);
          toast(resp?.message, {
            type: "success",
          });
        }
      } else {
        toast(resp?.message, {
          type: "error",
        });
        setConfirmLoader(false);
        setRejectLoader(false);
      }
    } catch (error) {
      toast(error.message, {
        type: "error",
      });
      setConfirmLoader(false);
      setRejectLoader(false);
    }
  };
  const handleVideoClick = () => {
    if (mediaDownloaded) {
      window.open(mediaUrl, "_blank");
    }
  };

  const specificUser = messageData?.poll_data?.combinedData?.usersDetails.find(
    (user) => user.id === userData?.id
  );

  const checkHostPayment =
    messageData?.poll_data?.combinedData?.usersDetails.filter(
      (user) => user.id !== userData?.id
    ) || [];
  const checkPaymentStatus = checkHostPayment?.every(
    (obj) => obj.payment_original_status === "Completed"
  );

  const displayValue = specificUser?.hasOwnProperty("original_amount")
    ? specificUser?.original_amount
    : specificUser?.club_fees
    ? specificUser?.club_fees
    : specificUser?.guest_fees;

  const clubName =
    messageData?.poll_data?.combinedData?.reservationDetails?.club_name || "";
  const truncatedClubName =
    clubName.length > 25 ? clubName.substring(0, 25) + "..." : clubName;
  const showMessage = () => {
    if (messageType === "text") {
      return (
        <p
          style={{
            fontFamily: FontFamily.Regular,
            fontSize: md ? "14px" : "16px",
          }}
        >
          {message}
        </p>
      );
    } else if (messageType === "image") {
      return (
        <div className={classes.mediaContainer}>
          <img
            src={mediaUrl}
            alt="media content"
            width="180px"
            className={!mediaDownloaded && classes.media}
            draggable={false}
          />
          {!mediaDownloaded && (
            <img
              alt="media-download"
              src={Files.pngIcons.downloadMedia}
              className={classes.downloadButton}
              onClick={() => setMediaDownloaded(true)}
              draggable={false}
            />
          )}
        </div>
      );
    } else if (messageType === "application/pdf") {
      return (
        <div
          className={`${classes.message} ${
            isSender ? classes.sender : classes.receiver
          }`}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "2px",
            padding: "10px 15px",
            width: "320px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <PictureAsPdfIcon />
            <span>{messageData.documentName}</span>
          </div>
          <DownloadIcon />
        </div>
      );
    } else if (messageType === "video") {
      return (
        <div className={classes.mediaContainer} onClick={handleVideoClick}>
          <video
            width="100%"
            controls={mediaDownloaded}
            style={{ borderRadius: "6px", height: md ? "150px" : "190px" }}
          >
            <source src={mediaUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          {!mediaDownloaded && (
            <img
              alt="media-download"
              src={Files.pngIcons.downloadMedia}
              className={classes.downloadButton}
              onClick={() => setMediaDownloaded(true)}
              draggable={false}
            />
          )}
        </div>
      );
    } else if (messageType === "poll") {
      return (
        <>
          {completeRound ? (
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 50 }}
              transition={{ duration: 0.4 }}
              style={{ position: "relative" }}
            >
              <CloseIcon
                onClick={() => {
                  setCompleteRound(false);
                }}
                style={{
                  position: "absolute",
                  top: -40,
                  right: -10,
                  fontSize: 25,
                  cursor: "pointer",
                  color: BaseColor.whiteColor,
                  zIndex: 1,
                }}
              />
              <Button
                variant="outlinedSecondary"
                sx={{
                  marginTop: "40px",
                  fontSize: "14px",
                  fontFamily: FontFamily.Medium,
                  textAlign: "center",
                  color: messageData?.reject ? "#FE2A2A" : "white",
                  backgroundColor: "transparent",
                  width: "100%",
                  borderColor: "white",
                  "& :hover": {
                    backgroundColor: "red",
                  },
                }}
                onClick={() => {
                  const filterData =
                    messageData?.poll_data?.combinedData?.usersDetails?.filter(
                      (data) => data.id !== userData?.id
                    );
                  setMemberList(filterData);
                  setCollectPaymentModal(true);

                  filterData.map((member, i) => {
                    if (member.guest_cnt) {
                      member.current_count = member.guest_cnt;
                    }
                  });
                }}
              >
                Collect payment
              </Button>
              <Button
                variant="outlinedSecondary"
                sx={{
                  marginTop: "20px",
                  fontSize: "14px",
                  fontFamily: FontFamily.Medium,
                  textAlign: "center",
                  color: messageData?.reject ? "#FE2A2A" : "white",
                  backgroundColor: "transparent",
                  width: "100%",
                  borderColor: "white",
                  "& :hover": {
                    backgroundColor: "red",
                  },
                  marginBottom: "10px",
                }}
                onClick={(e) => {
                  collectPaymentApiCall(false);
                }}
                loading
              >
                {withoutPayBtnLoader ? (
                  <CircularProgress size={18} color="secondary" />
                ) : (
                  "Without payment"
                )}
              </Button>
            </motion.div>
          ) : (
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Tooltip
                  enterTouchDelay={0}
                  placement="top-end"
                  componentsProps={{
                    tooltip: {
                      sx: {
                        fontSize: 17,
                        backgroundColor: BaseColor.white,
                        color: BaseColor.red,
                        fontFamily: FontFamily.Medium,
                        boxShadow: " 0 0 10px 5px rgb(0 0 0 / 10%)",
                      },
                    },
                  }}
                  title={
                    <Typography
                      style={{
                        fontFamily: FontFamily.SemiBold,
                        fontSize: "14px",
                        color: "black",
                      }}
                    >
                      {
                        messageData?.poll_data?.combinedData?.reservationDetails
                          ?.club_name
                      }
                    </Typography>
                  }
                >
                  <Typography
                    style={{
                      fontFamily: FontFamily.SemiBold,
                      fontSize: "14px",
                      color:
                        messageData?.sender_id == userData?.id
                          ? "white"
                          : "black",
                    }}
                  >
                    Club name - ({truncatedClubName})
                  </Typography>
                </Tooltip>{" "}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <p
                  style={{ fontFamily: FontFamily.SemiBold, fontSize: "14px" }}
                >
                  Reservation Request
                </p>
                {messageData?.sender_id == userData?.id &&
                  !checkPaymentStatus && (
                    <EditNoteIcon
                      onClick={() => {
                        editReservation(messageData?.poll_data?.combinedData);
                      }}
                      style={{
                        paddingLeft: 10,
                        verticalAlign: "center",
                        fontFamily: FontFamily.Bold,
                        cursor: !isMobile && !isTablet && "pointer",
                      }}
                    />
                  )}
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "10px",
                  fontSize: "14px",
                  fontFamily: FontFamily.Regular,
                }}
              >
                <span style={{}}>{updatedAtMoment}</span>
                <span style={{}}>{updatedAtMomentTime}</span>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "15px 0px 5px 0px",
                  fontSize: "14px",
                  gap: "15px",
                }}
              >
                <span>
                  Confirmed | {completedPaymentsCount + 1}/
                  {messageData?.poll_data?.combinedData?.usersDetails?.length ||
                    ""}
                </span>
                <AvatarGroup
                  max={3}
                  onClick={() => {
                    showMembers(
                      messageData?.poll_data?.combinedData?.usersDetails
                    );
                  }}
                  sx={{
                    "& .css-19i9a2-MuiAvatar-root": {
                      width: 24,
                      height: 24,
                      fontSize: "15px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      zIndex: 111,
                    },
                    "& .css-1w0ju44 .MuiAvatar-root": {
                      width: 24,
                      height: 24,
                      fontSize: "15px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      zIndex: 111,
                    },
                    "& .css-2mfhby": {
                      width: 24,
                      height: 24,
                      fontSize: "15px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      zIndex: 111,
                    },
                    cursor: !isMobile && !isTablet && "pointer",
                  }}
                >
                  {messageData?.poll_data?.combinedData?.usersDetails.map(
                    (member) => (
                      <Avatar
                        alt={member}
                        key={member}
                        sx={{
                          width: 24,
                          height: 24,
                        }}
                        src={member?.profile_pic || Files.Images.roundLogo}
                      />
                    )
                  )}
                </AvatarGroup>
              </div>
              <div>
                <LinearProgress
                  style={{ borderRadius: 10 }}
                  color="inherit"
                  variant="determinate"
                  value={
                    (Number(completedPaymentsCount + 1) /
                      messageData?.poll_data?.combinedData.usersDetails
                        .length) *
                    100
                  }
                />
              </div>

              <button
                className={classes.chatBtn}
                style={{
                  backgroundColor: isSender
                    ? "rgba(255, 255, 255, 0.25)"
                    : "#37373740",
                }}
                type="submit"
              >
                ${displayValue}
              </button>

              {messageData?.sender_id == userData?.id ? (
                <>
                  {checkPaymentStatus ? (
                    <p
                      style={{
                        marginTop: "20px",
                        fontSize: "14px",
                        fontFamily: FontFamily.Black,
                        textAlign: "center",
                        color: "white",
                      }}
                    >
                      {"Paid"}
                    </p>
                  ) : (
                    <Button
                      variant="outlinedSecondary"
                      sx={{
                        marginTop: "20px",
                        fontSize: "14px",
                        fontFamily: FontFamily.Medium,
                        textAlign: "center",
                        color: messageData?.reject ? "#FE2A2A" : "white",
                        backgroundColor: "transparent",
                        width: "100%",
                        borderColor: "white",
                        "& :hover": {
                          backgroundColor: "red",
                        },
                      }}
                      onClick={(e) => {
                        setCompleteRound(true);
                      }}
                    >
                      Complete round
                    </Button>
                  )}
                </>
              ) : (
                <>
                  {specificUser?.payment_status !== "pending" ? (
                    <p
                      style={{
                        marginTop: "20px",
                        fontSize: "14px",
                        fontFamily: FontFamily.Black,
                        textAlign: "center",
                        color:
                          specificUser?.payment_status === "reject"
                            ? "#FE2A2A"
                            : BaseColor.primary,
                      }}
                    >
                      {
                        <>
                          {specificUser?.payment_status === "confirm"
                            ? "Confirmed"
                            : "Rejected"}
                        </>
                      }
                    </p>
                  ) : (
                    <div style={{ display: "flex", gap: "5px" }}>
                      {specificUser?.payment_original_status !==
                        "Completed" && (
                        <>
                          <button
                            className={classes.chatBtn}
                            style={{
                              backgroundColor: "transparent",
                              border: isSender
                                ? "1px solid white"
                                : "1px solid red",
                              color: isSender ? "white" : "red",
                              cursor: "pointer",
                            }}
                            size="small"
                            onClick={() => handleReject("reject")}
                          >
                            {rejectLoader ? (
                              <CircularProgress size={15} color="primary" />
                            ) : (
                              "Reject"
                            )}
                          </button>

                          <button
                            className={classes.chatBtn}
                            style={{
                              backgroundColor: isSender ? "white" : "#137450",
                              color: isSender ? "black" : "white",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              handleReject("confirm");
                            }}
                          >
                            {confirmLoader ? (
                              <CircularProgress size={15} color="secondary" />
                            ) : (
                              "Confirm"
                            )}
                          </button>
                        </>
                      )}
                    </div>
                  )}

                  {specificUser?.payment_needed === "yes" &&
                  specificUser?.payment_original_status !== "Completed" &&
                  specificUser?.payment_original_status !== "Rejected" ? (
                    <Button
                      variant="outlinedPrimary"
                      sx={{
                        fontSize: "14px",
                        fontFamily: FontFamily.Medium,
                        textAlign: "center",
                        width: "100%",
                        background: BaseColor.primary,
                        color: "white",
                        height: "40px",
                        marginTop: "15px",
                      }}
                      onClick={(e) => {
                        if (specificUser?.payment_link) {
                          window.location.href = specificUser?.payment_link;
                        }
                      }}
                    >
                      Initiate payment
                    </Button>
                  ) : (
                    <>
                      {specificUser?.payment_original_status ===
                        "Completed" && (
                        <p
                          style={{
                            marginTop: "20px",
                            fontSize: "14px",
                            fontFamily: FontFamily.Black,
                            textAlign: "center",
                            color: BaseColor.primary,
                          }}
                        >
                          {"Tee Time Completed"}
                        </p>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          )}
        </>
      );
    } else {
      return (
        <div style={{ backgroundColor: "red" }}>Unsupported message type</div>
      );
    }
  };

  // collect member api
  const collectPaymentApiCall = async (submitPay) => {
    if (submitPay) {
      setSubmitBtnLoader(true);
    } else {
      setWithoutPayBtnLoader(true);
    }
    let endPoints = Setting.endpoints.collectPayment;

    let arr = [];
    memberList?.map((data) => {
      if (data?.isSelected && data?.payment_status !== "reject") {
        arr.push({
          member_id: data?.id,
          guest_cnt: data.guest_cnt === 0 ? 0 : data?.current_count,
        });
      }
    });

    let data = {};
    if (submitPay) {
      data = {
        "ReservationInitiatePayment[reservation_id]":
          messageData?.poll_data?.combinedData?.reservation_id,
        "ReservationInitiatePayment[member_id]": JSON.stringify(arr),
      };
    } else {
      data = {
        "ReservationInitiatePayment[reservation_id]":
          messageData?.poll_data?.combinedData?.reservation_id,
      };
    }

    try {
      const resp = await getAPIProgressData(endPoints, "POST", data, true);
      if (resp?.success) {
        toast(resp?.message, {
          type: "success",
        });
        if (submitPay) {
          setSubmitBtnLoader(false);
          memberList?.map((data) => {
            if (data?.isSelected) {
              data.isSelected = false;
            }
          });
          afterRejectCall();
          setMemberList([]);
          setCompleteRound(false);
          setCollectPaymentModal(false);
        }
        setWithoutPayBtnLoader(false);
      } else {
        toast(resp?.message, {
          type: "error",
        });
        setWithoutPayBtnLoader(false);
        setSubmitBtnLoader(false);
      }
    } catch (error) {
      toast(error.message, {
        type: "error",
      });
      setWithoutPayBtnLoader(false);
      setSubmitBtnLoader(false);
    } finally {
      afterRejectCall();
      setSubmitBtnLoader(false);
      setWithoutPayBtnLoader(false);
    }
  };

  return (
    <>
      <div
        className={`${classes.message} ${
          isSender ? classes.sender : classes.receiver
        }`}
        style={{
          backgroundColor:
            messageType === "text" || messageType === "poll"
              ? ""
              : "transparent",
          boxShadow:
            messageType === "text" || messageType === "poll" ? "" : "none",
          padding:
            messageType === "text" || messageType === "poll" ? "10px 15px" : "",
          maxWidth: md ? "80%" : "40%",
          marginTop: index === 0 && userData?.id === isHost && "4vh",
          minWidth: messageType === "poll" && 280,
        }}
      >
        {!isSender && listType === "groups" && (
          <p
            style={{
              fontFamily: FontFamily.SemiBold,
              fontSize: md ? "14px" : "16px",
            }}
          >
            {messageData.sender}
          </p>
        )}
        {showMessage()}
      </div>
      <div
        className={`${classes.timestamp} ${
          isSender ? classes.sender : classes.receiver
        }`}
      >
        {timeString}

        {isSender && (
          <span>
            {messageData.status === "2" ? (
              <img
                src={Files.pngIcons.greenDoubleTickIcon}
                draggable={false}
                alt="Golf"
              />
            ) : messageData.status === "1" ? (
              <img
                src={Files.pngIcons.doubleTickIcon}
                draggable={false}
                alt="Golf"
              />
            ) : messageData.status === "0" ? (
              <img
                src={Files.pngIcons.singleTickIcon}
                draggable={false}
                alt="Golf"
              />
            ) : (
              ""
            )}
          </span>
        )}
      </div>
      <PaymentModel
        showPaymentModel={showPaymentModel}
        setShowPaymentModel={setShowPaymentModel}
        messageData={messageData}
      />
      <CModal
        visible={collectPaymentModal}
        onClose={() => {
          setCollectPaymentModal(false);
          memberList?.map((data) => {
            if (data?.isSelected) {
              data.isSelected = false;
            }
          });
          setMemberList([]);
        }}
        children={
          <Grid
            container
            padding={isMobile ? "20px" : "40px"}
            style={{
              position: "relative",
              width: sm ? "90vw" : "500px",
              maxHeight: "80vh",
              overflowY: "auto",
            }}
            className={classes.scrollBar}
          >
            <CloseIcon
              onClick={() => {
                setCollectPaymentModal(false);
                memberList?.map((data) => {
                  if (data?.isSelected) {
                    data.isSelected = false;
                  }
                });
                setMemberList([]);
              }}
              style={{
                position: "absolute",
                top: 10,
                right: 15,
                fontSize: 30,
                cursor: !isMobile && !isTablet && "pointer",
                color: BaseColor.primary,
                zIndex: 1,
              }}
            />

            <Grid
              item
              xs={12}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Typography
                style={{
                  fontFamily: FontFamily.GilbertQualifiDemo,
                  fontSize: isMobile ? "20px" : "24px",
                  color: BaseColor.blackColor,
                  textAlign: "center",
                }}
              >
                Collect payment
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              alignItems={"flex-start"}
              justifyContent={"flex-start"}
              flexWrap={"wrap"}
              mt={"20px"}
              maxHeight={"500px"}
              style={{
                overflowY: "auto",
                display: "flex",
              }}
            >
              {memberList?.map((data, index) => {
                if (
                  data?.payment_needed !== "yes" &&
                  data?.payment_status !== "reject" &&
                  data?.payment_original_status !== "Completed"
                ) {
                  return (
                    <div
                      draggable={false}
                      key={index}
                      style={{
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        cursor: !isMobile && !isTablet && "pointer",
                        marginRight: "20px",
                        marginBottom: "10px",
                      }}
                    >
                      <div
                        onClick={() => {
                          if (!data?.isSelected) {
                            data.isSelected = true;
                          } else {
                            data.isSelected = false;
                          }
                          setMemberList([...memberList]);
                        }}
                        style={{ minHeight: "140px" }}
                      >
                        {data?.isSelected && (
                          <div
                            style={{
                              background: "#042B4860",
                              height: "82px",
                              width: "82px",
                              borderRadius: "50%",
                              position: "absolute",
                              justifyContent: "center",
                              alignItems: "center",
                              display: "flex",
                              bottom:
                                data?.username?.length >= 15
                                  ? "47px"
                                  : data.guest_cnt !== 0
                                  ? "85px"
                                  : "56px",
                            }}
                          >
                            <IoCheckmarkOutline
                              color={BaseColor.white}
                              size={40}
                            />
                          </div>
                        )}
                        <img
                          src={
                            data?.profile_pic ||
                            "https://i.ibb.co/D4kB907/Goat-01-1.png"
                          }
                          draggable={false}
                          alt="member"
                          style={{
                            width: "80px",
                            height: "80px",
                            borderRadius: "50%",
                            border: `2px solid ${BaseColor.primary}`,
                          }}
                        />
                        <Typography
                          style={{
                            maxWidth: "80px",
                            textAlign: "center",
                            wordBreak:
                              data?.username?.length > 10 && "break-word",
                          }}
                        >
                          {data?.username}
                        </Typography>
                      </div>

                      {data?.guest_cnt !== 0 && (
                        <Grid
                          container
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginTop: "5px",
                          }}
                        >
                          <Grid
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            onClick={() => decrement(index)}
                          >
                            <span
                              style={{
                                fontSize: "14px",
                                fontFamily: FontFamily.SemiBold,
                                color: BaseColor.textColor,
                                cursor: !isMobile && !isTablet && "pointer",
                                height: "20px",
                                width: "20px",
                                borderRadius: "5px",
                                border: "1px solid #222",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                userSelect: "none",
                              }}
                            >
                              -
                            </span>
                          </Grid>
                          <span
                            style={{
                              fontSize: "14px",
                              fontFamily: FontFamily.SemiBold,
                              color: BaseColor.textColor,
                              margin: "0px 2px",
                            }}
                          >
                            {data.current_count}
                          </span>
                          <Grid
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            onClick={() => {
                              // if (data?.current_cnt < data?.guest_cnt) {
                              increment(index);
                              console.log("indeiugiugugx", data);
                              // }
                            }}
                          >
                            <span
                              style={{
                                fontSize: "14px",
                                fontFamily: FontFamily.SemiBold,
                                color: BaseColor.textColor,
                                cursor: !isMobile && !isTablet && "pointer",
                                height: "20px",
                                width: "20px",
                                borderRadius: "5px",
                                border: "1px solid #222",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                userSelect: "none",
                              }}
                            >
                              +
                            </span>
                          </Grid>
                        </Grid>
                      )}
                    </div>
                  );
                } else {
                  return null;
                }
              })}
            </Grid>

            <Grid
              item
              xs={12}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <CButton
                variant="contained"
                color="primary"
                style={{ marginTop: "20px" }}
                onClick={() => {
                  collectPaymentApiCall(true);
                }}
                disabled={_.isEmpty(
                  memberList.filter((data) => data.isSelected === true)
                )}
                loading={submitBtnLoader}
              >
                Submit
              </CButton>
            </Grid>
          </Grid>
        }
      />
    </>
  );
};

export default ChatMessage;
