import React, { useState } from "react";
import { Grid, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import _ from "lodash";
import styles from "./styles";
import Files from "../../Config/Files.js";
import authActions from "../../Redux/reducers/auth/actions.js";
import "react-toastify/dist/ReactToastify.css";
import { isMobile, isTablet } from "react-device-detect";
import AppleLogin from "react-apple-login";
import { Setting } from "../../Utils/Setting.js";
import { getAPIProgressData } from "../../Utils/APIHelper.js";
import { toast } from "react-toastify";

const CApple = (props) => {
  const classes = styles();
  const [buttonLoader, setButtonLoader] = useState(false);
  const navigate = useNavigate();
  const {
    setOpenLoginModal = false,
    setReviewModalBool = false,
    setAccVerifyModal = false,
  } = props;
  // Set data in Redux
  const dispatch = useDispatch();
  const { setAccessToken, setUserData } = authActions;

  const appleResponse = (response) => {
    const token = response?.authorization?.id_token;
    getSocialLogin(token);
  };

  const getSocialLogin = async (token) => {
    setButtonLoader(true);
    let endPoints = Setting.endpoints.socialLogin;

    const params = {
      "UserSocialConnect[token]": token,
      "UserSocialConnect[provider]": "apple",
    };

    try {
      const resp = await getAPIProgressData(endPoints, "POST", params);
      if (resp?.success) {
        const data = resp?.data;
        if (data?.signup_pending) {
          navigate("/new-member", { state: { data } });
          dispatch(setAccessToken(data?.user_data?.access_token));
          dispatch(setUserData(data));
          toast.success(resp?.message, {
            position: "top-right",
          });
        } else if (data?.under_review) {
          setAccVerifyModal(true);
          setReviewModalBool(true);
          setOpenLoginModal(false);
        } else {
          dispatch(setUserData(data));
          dispatch(setAccessToken(data?.access_token));
          navigate("/home");
          toast.success(resp?.message, { position: "top-right" });
        }
        setButtonLoader(false);
      } else {
        toast.error(resp?.message, {
          position: "top-right",
        });
        setButtonLoader(false);
      }
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
      });
      setButtonLoader(false);
    }
  };

  return (
    <AppleLogin
      clientId="com.goatt.golf"
      redirectURI="https://app-golf.teamgroovy.com/"
      usePopup={true}
      callback={appleResponse}
      scope="email name"
      responseMode="query"
      render={(renderProps) => (
        <Grid
          container
          onClick={() => {
            if (!buttonLoader) {
              renderProps.onClick();
            }
          }}
        >
          {buttonLoader ? (
            <CircularProgress className={classes.loader} />
          ) : (
            <div
              className={classes.circleContainer}
              style={{
                marginLeft: 10,
                cursor: !isMobile && !isTablet && "pointer",
              }}
            >
              <img
                src={Files.Images.apple}
                alt="apple"
                className={classes.socialImage}
                draggable={false}
              />
            </div>
          )}
        </Grid>
      )}
    />
  );
};

export default CApple;
