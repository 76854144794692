import PropTypes from "prop-types";

const CIcon = (props) => {
  const { src, size = 20, className, onClick = () => {}, style } = props;
  return (
    <div
      onClick={onClick}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        className={className}
        src={src}
        alt="Icon"
        draggable={false}
        style={{
          height: size,
          width: size,
          objectFit: "contain",
          cursor: "default",
          ...style,
        }}
      />
    </div>
  );
};

CIcon.propTypes = {
  src: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default CIcon;
