import React from "react";
import Checkbox from "@mui/material/Checkbox";
import styles from "./styles";
import _ from "lodash";
import theme, { FontFamily } from "../../Config/theme";
import { useMediaQuery } from "@mui/material";
import PropTypes from "prop-types";
import BaseColor from "../../Config/colors";

const CCheckBox = (props) => {
  const {
    checked = false,
    onChange = () => {},
    label = "",
    errorMsg = "",
    disabled = false,
  } = props;

  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const classes = styles();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Checkbox
          {...props}
          disabled={disabled}
          checked={checked}
          onChange={(e) => {
            const val = e.target.checked;
            onChange(val);
          }}
          id={"terms"}
          style={{ marginRight: 5 }}
        />

        <label
          for={"terms"}
          style={{
            fontFamily: FontFamily.Medium,
            color: disabled ? BaseColor.grey : BaseColor.blackColor,
            cursor: "pointer",
            fontSize: sm ? 14 : 16,
            marginLeft: "5px",
          }}
        >
          {label}
        </label>
      </div>
      {!_.isEmpty(errorMsg) && (
        <div className={classes.errorMsgContainer}>
          <span className={classes.errorMsgText}>{errorMsg}</span>
        </div>
      )}
    </div>
  );
};

CCheckBox.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.string,
  errorMsg: PropTypes.string,
};

export default CCheckBox;
