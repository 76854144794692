import React, { useState } from "react";
import { Typography, Rating } from "@mui/material";
import { Grid, useMediaQuery } from "@mui/material";
import { isMobile } from "react-device-detect";
import theme, { FontFamily } from "../../Config/theme.js";
import CButton from "../../Components/CButton/index.js";
import CModal from "../../Components/CModal/index.js";
import CInput from "../../Components/CInput/index.js";
import { Setting } from "../../Utils/Setting.js";
import { toast } from "react-toastify";
import { getAPIProgressData } from "../../Utils/APIHelper.js";
import BaseColor from "../../Config/colors.js";

const FeedbackModal = (props) => {
  // props
  const { review, reviewData, reviewModal, onClose } = props;

  // media queries
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  //rating
  const [rating, setRating] = useState(0);

  // feedback data
  const [feedback, setFeedback] = useState("");
  const [feebackErrorText, setFeedbackErrorText] = useState("");
  const [ratingErrorText, setRatingErrorText] = useState("");

  // btn loader
  const [cancelBtnLoader, setCancelBtnLoader] = useState(false);
  const [saveBtnLoader, setSaveBtnLoader] = useState(false);

  const handleRatingChange = (event, newValue) => {
    setRating(newValue);
    setRatingErrorText("");
  };

  // To create/give user feedback
  const handleFeedbackSubmit = async (pageLoad) => {
    setSaveBtnLoader(true);
    let endPoints = Setting.endpoints.submitFeedback;
    const params = {
      "UserFeedback[reservation_id]": review.reservation_id,
      "UserFeedback[feedback_receiver_id]": review.review_user_id,
      "UserFeedback[rating]": rating,
      "UserFeedback[comment]": feedback,
      "UserFeedback[isHost]": review.is_host,
    };

    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
      if (resp?.success) {
        setSaveBtnLoader(false);
        toast(resp?.message, {
          type: "success",
        });
        onClose();
      } else {
        setSaveBtnLoader(false);
        toast(resp?.message, {
          type: "error",
        });
      }
    } catch (error) {
      toast(error.message, { type: "error" });
      setSaveBtnLoader(false);
    }
    // setRating(0.5);
    setFeedback("");
  };

  //End

  //To decline user feedback
  const handleCancelFeedback = async (pageLoad) => {
    setCancelBtnLoader(true);
    let endPoints = Setting.endpoints.cancelFeedback;
    const params = {
      "UserFeedback[reservation_id]": review.reservation_id,
      "UserFeedback[feedback_receiver_id]": review.review_user_id,
      "UserFeedback[isHost]": review.is_host,
    };

    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
      if (resp?.success) {
        setCancelBtnLoader(false);
        toast(resp?.message, {
          type: "success",
        });
      }
      onClose();
    } catch (error) {
      toast(error.message, { type: "error" });
      setCancelBtnLoader(false);
    }
  };

  //End

  const nextData = () => {
    const indexData = reviewData.findIndex((data) => data.open);
    const getNextData = reviewData[indexData];
    getNextData.open = false;
    if (indexData + 1 < reviewData.length) {
      reviewData[indexData + 1].open = true;
    }
  };

  //validation
  const validation = () => {
    let isValid = true;
    if (rating === 0) {
      setRatingErrorText("Please add rating");
      isValid = false;
    }
    if (feedback === "") {
      setFeedbackErrorText("Feedback is required");
      isValid = false;
    }
    if (isValid) {
      allErrorFalse();
      handleFeedbackSubmit();
      nextData();
    }
  };

  // all error false
  const allErrorFalse = () => {
    setFeedbackErrorText("");
  };

  return (
    <>
      <CModal
        visible={reviewModal}
        aria-labelledby="modal-modal-title"
        children={
          <Grid
            container
            padding={isMobile ? "20px" : "40px"}
            style={{
              position: "relative",
              width: sm ? "90vw" : "450px",
              maxHeight: "80vh",
              overflowY: "auto",
            }}
          >
            <Grid
              container
              xs={12}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Grid
                container
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",

                  maxHeight: "80vh",

                  gap: "20px",
                }}
              >
                <Typography
                  style={{ fontSize: "28px" }}
                  fontFamily={"Gilbert Qualifi Demo"}
                  fontWeight={"bold"}
                >
                  Feedback!
                </Typography>
                <div style={{ textAlign: "center" }}>
                  <Typography style={{ fontSize: "16px" }}>
                    How was Your Experience with
                  </Typography>
                  <Typography
                    style={{ fontSize: "16px" }}
                    fontFamily={"Montserrat"}
                  >
                    {review.user_name}
                  </Typography>
                </div>
                <Rating
                  name="star-rating"
                  value={rating}
                  onChange={handleRatingChange}
                  precision={0.5}
                  sx={{ fontSize: "40px", color: "#137450" }}
                />
                <Typography variant="body1">{rating} Rating</Typography>
              </Grid>
              <Typography
                style={{
                  fontSize: 14,
                  color: BaseColor.errorRed,
                  fontFamily: FontFamily.Medium,
                }}
              >
                {ratingErrorText}
              </Typography>
            </Grid>
            <Grid style={{ width: "100%" }} item>
              <CInput
                endIcon
                label={"Provide Feedback"}
                value={feedback}
                onChange={(e) => {
                  setFeedback(e);
                  setFeedbackErrorText("");
                }}
                placeholder={"Type your feedback"}
                multiline
                rows={4}
                maxLength={155}
                fullWidth
                errorMsg={feebackErrorText}
              />
            </Grid>
            <Grid
              item
              xs={12}
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              mt={"40px"}
              // padding={"20px"}
              gap={"10px"}
            >
              {" "}
              <CButton
                variant={"outlined"}
                btnStyle={{
                  width: "50%",

                  // marginTop: sm && "20px",
                }}
                loading={cancelBtnLoader}
                onClick={() => {
                  nextData();
                  handleCancelFeedback();
                  setRating(0);
                  setFeedback("");
                }}
              >
                Cancel
              </CButton>
              <CButton
                variant="contained"
                btnStyle={{
                  width: "50%",
                  marginLeft: !sm && "40px",
                  // marginTop: sm && "20px",
                }}
                loading={saveBtnLoader}
                onClick={() => {
                  validation();
                }}
              >
                Submit
              </CButton>{" "}
            </Grid>
          </Grid>
        }
      />
    </>
  );
};

export default FeedbackModal;
