import React from "react";
import { Button } from "@mui/material";
import styles from "./styles";
import BaseColor from "../../Config/colors";
import PropTypes from "prop-types";
import Lottie from "lottie-react";
import Files from "../../Config/Files";
import CIcon from "../CIcon";

const CButton = (props) => {
  const classes = styles();
  const {
    children,
    variant = "contained",
    color = "primary",
    loading = false,
    disabled = false,
    onClick,
    style,
    btnStyle,
    cameraIcon = false,
  } = props;

  return cameraIcon ? (
    <div className={classes.cameraIconBtnContainer} onClick={onClick}>
      <div className={classes.cameraIcon}>
        <CIcon
          src={Files.svgIcons.cameraIcon}
          size={18}
          style={{ cursor: "pointer" }}
        />
      </div>
    </div>
  ) : (
    <div className={classes.root} style={btnStyle}>
      <Button
        {...props}
        sx={[
          {
            "&.Mui-disabled": {
              background: `${BaseColor.primary}80`, // Set the desired disabled color here
            },
          },
          style,
        ]}
        variant={variant}
        color={color}
        disabled={loading ? loading : disabled}
        onClick={onClick}
        className={classes.button}
      >
        {loading ? (
          <div
            style={{
              position: "absolute",
              height: 40,
              width: 200,
              top: "-60%",
              zIndex: 10,
            }}
          >
            <Lottie
              animationData={Files.lottie.loader}
              loop={true}
              height={50}
              width="100%"
            />
          </div>
        ) : (
          children
        )}
      </Button>
    </div>
  );
};

CButton.propTypes = {
  color: PropTypes.string,
  variant: PropTypes.string,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default CButton;
