import { makeStyles } from "@mui/styles";
import { FontFamily } from "../../../Config/theme";
import BaseColor from "../../../Config/colors";
import { isMobile, isTablet } from "react-device-detect";

const styles = makeStyles({
  title: {
    fontFamily: `${FontFamily.GilbertQualifiDemo} !important`,
    fontSize: "22px !important",
  },
  switchDiv: {
    display: "flex",
    borderRadius: "20px",
    backgroundColor: BaseColor.offWhiteBg,
    "@media (max-width:900px)": {
      width: "100%",
    },
  },
  switchTab: {
    padding: "12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "20px",
    width: "50%",
    cursor: !isMobile && !isTablet && "pointer",
  },
  searchAndFilter: {
    display: "flex",
    alignItems: "center",
  },
  filterIcon: {
    backgroundColor: BaseColor.green,
    padding: "15px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "10px",
    marginTop: "4px",
    cursor: !isMobile && !isTablet && "pointer",
    marginRight: "5px",
  },

  errorMsgContainer: {
    marginTop: 5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  errorMsgText: {
    fontSize: 15,
    color: BaseColor.errorRed,
    fontFamily: FontFamily.Medium,
  },

  scrollBar: {
    // overflowX: "scroll",
    "&::-webkit-scrollbar": {
      width: 3,
      height: 3,
    },

    /* Track */
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 5px transperent",
      borderRadius: 10,
    },

    /* Handle */
    "&::-webkit-scrollbar-thumb": {
      background: BaseColor.primary,
      borderRadius: 10,
    },

    /* Handle on hover */
    "&::-webkit-scrollbar-thumb:hover": {
      boxShadow: "inset 0 0 5px grey",
    },
  },
  mainScrollBar: {
    "&::-webkit-scrollbar": {
      width: 0,
      height: 5,
    },
  },
});

export default styles;
