import { makeStyles } from "@mui/styles";
import { FontFamily } from "../../Config/theme";
import BaseColor from "../../Config/colors";
import { isMobile, isTablet } from "react-device-detect";

const styles = makeStyles({
  switchDiv: {
    display: "flex",
    borderRadius: "20px",
    backgroundColor: BaseColor.offWhiteBg,
    "@media (max-width:900px)": {
      width: "100%",
    },
  },
  switchTab: {
    padding: "12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "20px",
    width: "50%",
    cursor: !isMobile && !isTablet && "pointer",
  },
  closeContainer: {
    position: "absolute",
    top: 20,
    right: 20,
    fontSize: 30,
    cursor: !isMobile && !isTablet && "pointer",
    color: BaseColor.textColor,
    zIndex: 1,
  },
  divider: {
    width: "100%",
    borderTop: "1px solid #B4BDC3",
    marginBottom: 16,
    marginTop: 10,
  },
  calendarText: {
    fontFamily: FontFamily.Medium,
    color: BaseColor.grey,
    fontSize: "16px",
  },
  calendarWithoutBorder: {
    border: "none",
  },
  customNavigationLabel: {
    color: BaseColor.grey,
    fontSize: "16px",
    fontFamily: FontFamily.Bold,
  },
  customNavigationLabelText: {
    fontSize: "24px",
    fontFamily: FontFamily.GilbertQualifiDemo,
    color: BaseColor.textColor,
  },
  customSelectedDate: {
    border: `2px solid ${BaseColor.primary} !important`,
    background: `${BaseColor.white} !important`,
    borderRadius: 8,
    color: BaseColor.blackColor,
    "&.react-calendar__tile--active:enabled:hover": {
      backgroundColor: BaseColor.primary,
    },
    "&.react-calendar__month-view__days__day--weekend:enabled": {
      color: `${BaseColor.blackColor}!important`,
    },
  },
  customWeekendDay: {
    fontSize: "14px",
  },
  customTile: {
    borderBottom: "2px solid #137450 !important",
    borderRadius: 8,
  },
  todayDate: {
    border: "2px solid #042B48 !important",
    background: BaseColor.white,
    borderRadius: 8,
  },
  scrollBar: {
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  mainScrollBar: {
    "&::-webkit-scrollbar": {
      width: 0,
      height: 5,
    },
  },
  memberContainer: {
    height: "100px",
    width: "75px",
  },
  circleContainer: {
    height: "65px",
    width: "65px",
    borderRadius: "50%",
    border: "1px solid #222",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    marginTop: "3px",
  },
  plusContainer: {
    height: "20px",
    width: "20px",
    borderRadius: "5px",
    border: "1px solid #222",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",

    /* Track */
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 5px transperent",
      borderRadius: 10,
    },

    /* Handle */
    "&::-webkit-scrollbar-thumb": {
      background: BaseColor.primary,
      borderRadius: 10,
    },

    /* Handle on hover */
    "&::-webkit-scrollbar-thumb:hover": {
      boxShadow: "inset 0 0 5px grey",
    },
  },
  // scrollBar: {
  //   "&::-webkit-scrollbar": {
  //     width: 0,
  //   },
  // },

  scrollBarMain: {
    "&::-webkit-scrollbar": {
      width: 0,
      height: 3,
    },
  },
});

export default styles;
