import { makeStyles } from "@mui/styles";
import { FontFamily } from "../../../../Config/theme";
import BaseColor from "../../../../Config/colors";

const styles = makeStyles({
  inputTile: {
    color: "black !important",
    fontFamily: `${FontFamily.SemiBold} !important`,
    fontSize: "16px !important",
  },
  inputValue: {
    color: `${BaseColor.grey} !important`,
    fontFamily: `${FontFamily.Medium} !important`,
    fontSize: "14px !important",
    marginTop: "5px !important",
  },
});

export default styles;
