import types from "./actions";

const initialState = {
  userData: {},
  accessToken: "",
  signUpData: {},
  activeTab: "Home",
  activeDrawerTab: "",
  walkthrough: true,
  isUnread: false,
  latLng: {},
  selectedChatData: {},
  cTab: "personal",
  activeMenu: "",
  isAdmin: false,
  chatMessage: {
    singleChat: "",
    groupChat: "",
  },
  isNotifiy: false,
  notiData: {},
  useruuid: "",
  isTokenSent: false,
  notificationCount: false,
  isFocused: false,
  backLatLng: {},
  backState: {},
  backSearch: "",
  backLocationLatLng: {},
  homeBackLatLng: {},
  expireDays: "",
  isUserFirstTime: true,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_USER_DATA:
      localStorage.setItem("userData", JSON.stringify(action.userData));
      return {
        ...state,
        userData: action.userData,
      };

    case types.SET_SIGNUP_DATA:
      localStorage.setItem("signUpData", JSON.stringify(action.signUpData));
      return {
        ...state,
        signUpData: action.signUpData,
      };

    case types.SET_WALKTHROUGH:
      return {
        ...state,
        walkthrough: action.walkthrough,
      };

    case types.SET_SELECTED_CHAT_DATA:
      return {
        ...state,
        selectedChatData: action.selectedChatData,
      };

    case types.SET_ACCESSTOKEN:
      return {
        ...state,
        accessToken: action.accessToken,
      };

    case types.CLEAR_ALL_STORAGE_DATA:
      localStorage.removeItem("userData");
      return {
        ...state,
        userData: {},
      };

    case types.SET_ACTIVE_HOME_TAB:
      localStorage.setItem("activeTab", JSON.stringify(action.userData));
      return {
        ...state,
        activeTab: action.activeTab,
      };

    case types.SET_DRAWER_TAB:
      localStorage.setItem("drawerTab", JSON.stringify(action.activeDrawerTab));
      return {
        ...state,
        activeDrawerTab: action.activeDrawerTab,
      };

    case types.SET_LAT_LNG:
      localStorage.setItem("latLng", JSON.stringify(action.latLng));
      return {
        ...state,
        latLng: action.latLng,
      };

    case types.SET_ISUNREAD:
      localStorage.setItem("isUnread", JSON.stringify(action.isUnread));
      return {
        ...state,
        isUnread: action.isUnread,
      };

    case types.SET_CTAB:
      localStorage.setItem("cTab", JSON.stringify(action.cTab));
      return {
        ...state,
        cTab: action.cTab,
      };

    case types.SET_ACTIVE_MENU:
      return {
        ...state,
        activeMenu: action.activeMenu,
      };

    case types.SET_IS_ADMIN:
      return {
        ...state,
        isAdmin: action.isAdmin,
      };

    case types.SET_CHAT_MESSAGE:
      return {
        ...state,
        chatMessage: action.chatMessage,
      };

    case types.SET_DISPLAY_NOTIFICATION_POP_UP:
      return {
        ...state,
        isNotifiy: action.isNotifiy,
      };

    case types.SET_NOTI_DATA:
      return {
        ...state,
        notiData: action.notiData,
      };
    case types.SET_USER_UUID:
      return {
        ...state,
        useruuid: action.useruuid,
      };
    case types.SET_IS_TOKEN_SENT:
      return {
        ...state,
        isTokenSent: action.isTokenSent,
      };

    case types.SET_NOTIFICATION_COUNT:
      return {
        ...state,
        notificationCount: action.notificationCount,
      };

    case types.SET_FOCUS:
      return {
        ...state,
        isFocused: action.isFocused,
      };

    case types.SET_BACK_LATLNG:
      return {
        ...state,
        backLatLng: action.backLatLng,
      };

    case types.SET_BACK_STATE:
      return {
        ...state,
        backState: action.backState,
      };

    case types.SET_BACK_SEARCH:
      return {
        ...state,
        backSearch: action.backSearch,
      };

    case types.SET_BACK_LOCATION_LATLNG:
      return {
        ...state,
        backLocationLatLng: action.backLocationLatLng,
      };

    case types.SET_HOME_BACK_LATLNG:
      return {
        ...state,
        homeBackLatLng: action.homeBackLatLng,
      };

    case types.SET_PLAN_EXPIRE_DAYS:
      return {
        ...state,
        expireDays: action.expireDays,
      };

    case types.SET_IS_USER_FIRST_TIME:
      return {
        ...state,
        isUserFirstTime: action.isUserFirstTime,
      };

    default:
      return state;
  }
}
