import { makeStyles } from "@mui/styles";
import { FontFamily } from "../../../Config/theme";
import BaseColor from "../../../Config/colors";
import { isMobile } from "react-device-detect";

const styles = makeStyles({
  title: {
    fontFamily: `${FontFamily.GilbertQualifiDemo} !important`,
    fontSize: "22px !important",
  },
  webSwitchDiv: {
    display: "flex",
    margin: "20px",
    borderRadius: "25px",
    backgroundColor: BaseColor.white,
    width: "100%",
  },
  mobileSwitchDiv: {
    display: "flex",
    margin: "20px",
    borderRadius: "25px",
    backgroundColor: BaseColor.offWhiteBg,
    width: "100%",
  },
  switchTab: {
    padding: "12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "25px",
    width: "50%",
    cursor: "pointer",
  },
  searchAndFilter: {
    display: "flex",
    alignItems: "center",
  },
  filterIcon: {
    backgroundColor: BaseColor.green,
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "10px",
    cursor: "pointer",
  },
  scrollBar: {
    // overflowX: "scroll",
    "&::-webkit-scrollbar": {
      width: 3,
      height: 3,
    },

    /* Track */
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 5px transperent",
      borderRadius: 10,
    },

    /* Handle */
    "&::-webkit-scrollbar-thumb": {
      background: BaseColor.primary,
      borderRadius: 10,
    },

    /* Handle on hover */
    "&::-webkit-scrollbar-thumb:hover": {
      boxShadow: "inset 0 0 5px grey",
    },
  },
  mainConatiner: {
    width: "29vw",
    height: "calc(100vh - 90px)",
    backgroundColor: "#F5F5F5",
  },
  menuCss: {
    fontSize: "30px",
    padding: "23px 0 0 10px",
  },
  emptyText: {
    fontFamily: `${FontFamily.GilbertQualifiDemo} !important`,
    fontSize: isMobile ? "30px !important" : "40px !important",
    color: `${BaseColor.blackColor} !important`,
  },
  emptyText2: {
    fontFamily: `${FontFamily.Regular} !important`,
    fontSize: isMobile ? "15px" : "20px !important",
    color: "#616161 !important",
  },
  ContactClubName: {
    display: "flex",
    fontSize: "14px",
    gap: "3px",
    fontFamily: FontFamily.Light,
    paddingBottom: "5px",
  },
  ContactArrowBtn: {
    backgroundColor: "white",
    border: "none",
    boxShadow: "1px 1px #8080805e",
    padding: "4px 4px 1px 4px",
    marginLeft: "10px",
  },
  ContactNameContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  ContactChatContainer: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    paddingRight: "10px",
  },
  ContactChatText: {
    textWrap: "nowrap",
    overflow: "hidden",
    width: "100%",
    textOverflow: "ellipsis",
    paddingRight: "25px",
    gap: "4px",
  },
  ContactChatData: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
});

export default styles;
