import { makeStyles } from "@mui/styles";
import BaseColor from "../../Config/colors";
import { FontFamily } from "../../Config/theme";

const styles = makeStyles({
  scrollBar: {
    // overflowX: "scroll",
    "&::-webkit-scrollbar": {
      width: 3,
      height: 5,
    },

    /* Track */
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 5px transperent",
      borderRadius: 10,
    },
    errorMsgTxt: {
      fontSize: 14,
      color: BaseColor.errorRed,
      fontFamily: FontFamily.Medium,
    },
    /* Handle */
    "&::-webkit-scrollbar-thumb": {
      background: BaseColor.primary,
      borderRadius: 10,
    },

    /* Handle on hover */
    "&::-webkit-scrollbar-thumb:hover": {
      boxShadow: "inset 0 0 5px grey",
    },
  },
  errorMsgText: {
    fontSize: 14,
    color: BaseColor.errorRed,
    fontFamily: FontFamily.Medium,
  },
});

export default styles;
