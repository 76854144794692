import React from "react";
import styles from "./styles";
import { Typography } from "@mui/material";
import { FontFamily } from "../../Config/theme";
import BaseColor from "../../Config/colors";
import Files from "../../Config/Files";
import _ from "lodash";
import { motion } from "framer-motion";

const CClubCard = (props) => {
  const {
    style = {},
    onClick = () => {},
    image = "",
    address = "",
    name = "",
    distance = "",
  } = props;

  const classes = styles();

  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 50 }}
      transition={{ duration: 0.4 }}
      style={{ userSelect: "none", ...style }}
      className={classes.main}
      onClick={() => {
        onClick();
      }}
    >
      <div
        style={{
          height: 300,
          overflow: "hidden",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          borderBottomLeftRadius: 10,
          borderBottomRightRadius: 10,
        }}
      >
        <div
          style={{
            height: 300,
            width: "100%",
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10,
          }}
        >
          <img
            src={
              !_.isEmpty(image) && !_.isNull(image)
                ? image
                : Files.Images.clubsImage
            }
            className={classes.img}
            style={{ userSelect: "none", pointerEvents: "none" }}
            alt={name}
            title={name}
            draggable={false}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            width: "100%",
            flexDirection: "column",
            marginTop: "10px",
            paddingLeft: "15px",
          }}
        >
          <Typography
            style={{
              fontFamily: FontFamily.GilbertQualifiDemo,
              fontSize: 18,
              color: BaseColor.primary,
              textOverflow: "ellipsis",
              WebkitLineClamp: 1,
              overflow: "hidden",
              WebkitBoxOrient: "vertical",
              display: "-webkit-box",
              userSelect: "none",
            }}
          >
            {name}
          </Typography>
          <Typography
            style={{
              fontFamily: FontFamily.Medium,
              fontSize: 14,
              color: BaseColor.grey,
              paddingRight: "10px",
              textOverflow: "ellipsis",
              WebkitLineClamp: 1,
              overflow: "hidden",
              WebkitBoxOrient: "vertical",
              display: "-webkit-box",
              userSelect: "none",
            }}
          >
            {address}
          </Typography>
        </div>
      </div>
    </motion.div>
  );
};

export default CClubCard;
