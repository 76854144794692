import { makeStyles } from "@mui/styles";
import { isMobile } from "react-device-detect";
import theme, { FontFamily } from "../../../../Config/theme";
import BaseColor from "../../../../Config/colors";

const styles = makeStyles({
  createProfileContainer: {
    height: isMobile ? 130 : 100,
    width: isMobile ? 130 : 100,
    borderRadius: "100%",
    // display:'flex',
    // border: `1px solid ${BaseColor.primary}`,
    [theme.breakpoints.down("md")]: {
      height: 100,
      width: 100,
    },
  },
  createProfileImageContainer: {
    height: "100%",
    width: "100%",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  createProfileImage: {
    objectFit: "fill",
    height: "100%",
    width: "100%",
    borderRadius: "50%",
  },
  createProfilePlaceholderImg: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignSelf: "center",
    borderRadius: "50%",
  },

  inputTile: {
    color: "black !important",
    fontFamily: `${FontFamily.SemiBold} !important`,
    fontSize: "16px !important",
  },
  inputValue: {
    color: `${BaseColor.grey} !important`,
    fontFamily: `${FontFamily.Medium} !important`,
    fontSize: "14px !important",
    marginTop: "5px !important",
  },
  scrollBar: {
    // overflowX: "scroll",
    "&::-webkit-scrollbar": {
      width: 3,
      height: 5,
    },

    /* Track */
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 5px transperent",
      borderRadius: 10,
    },

    /* Handle */
    "&::-webkit-scrollbar-thumb": {
      background: BaseColor.primary,
      borderRadius: 10,
    },

    /* Handle on hover */
    "&::-webkit-scrollbar-thumb:hover": {
      boxShadow: "inset 0 0 5px grey",
    },
  },
});

export default styles;
