import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  CircularProgress,
  Drawer,
  Grid,
  ListItemButton,
  Popover,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  loginDrawerMenu,
  loginHeaderArray,
  loginHeaderArrayWeb,
} from "../../Config/staticData";
import Files from "../../Config/Files.js";
import useStyles from "./styles.js";
import theme, { FontFamily } from "../../Config/theme";
import BaseColor from "../../Config/colors.js";
import CloseIcon from "@mui/icons-material/Close";
import { isMobile, isTablet } from "react-device-detect";
import { FaAngleDown } from "react-icons/fa6";
import { BsBell } from "react-icons/bs";
import CIcon from "../CIcon/index.js";
import { NotificationsNoneSharp } from "@mui/icons-material";
import CModal from "../CModal/index.js";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { toast } from "react-toastify";
import authActions from "../../Redux/reducers/auth/actions.js";
import { Setting } from "../../Utils/Setting.js";
import { getAPIProgressData, getApiData } from "../../Utils/APIHelper.js";
import { store } from "../../Redux/store/configureStore.js";
import CButton from "../CButton/index.js";
import { motion } from "framer-motion";

function CHeader(props) {
  // media queries responsive design
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const xs = useMediaQuery(theme.breakpoints.down("xs"));
  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const xl = useMediaQuery(theme.breakpoints.down("xl"));

  const classes = useStyles();

  // get data from redux
  const userData = useSelector((state) => state.auth.userData);
  const activeTab = useSelector((state) => state.auth.activeDrawerTab);
  const useruuid = useSelector((state) => state.auth.useruuid);
  const notificationCount = useSelector(
    (state) => state.auth.notificationCount
  );
  const { isFocused, chatMessage } = useSelector((state) => state.auth);

  const navigate = useNavigate();

  const {
    title,
    backButtonClick = () => {},
    edit = false,
    onEditClick = () => {},
    style = {},
  } = props;

  const location = useLocation();

  // redux
  const dispatch = useDispatch();
  const {
    setNotificationCount,
    setChatMessages,
    setBackLatLng,
    setBackSearch,
    setBackState,
    setActiveMenu,
    setCTab,
    setBackLocationLatLng,
    setHomeBackLatLng,
  } = authActions;
  const isLogin =
    !_.isEmpty(userData) && !userData?.signup_pending ? true : false;

  // menu
  const [menuPopOver, setMenuPopOver] = useState(null);
  const popMP = Boolean(menuPopOver);
  const idMP = popMP ? "simple-popover" : undefined;

  // drawer menu
  const [openDrawer, setOpenDrawer] = useState(false);

  // active page
  const [showNotification, setShowNotification] = useState(false);

  // logout modal
  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const [logoutBtnLoader, setLogoutBtnLoader] = useState(false);

  //loader
  const [loader, setLoader] = useState(false);
  const [removeLoader, setRemoveLoader] = useState(false);

  // data
  const [notificationData, setNotificationData] = useState([]);

  // initial payment modal
  const [openInitialPaymentModal, setOpenInitialPaymentModal] = useState(false);

  //payment btn loader
  const [paymentBtnLoader, setPaymentBtnLoader] = useState(false);

  // initial plan detail
  const [initialPlanDetail, setInitialPlanDetail] = useState({});

  // payment modal loader
  const [paymentModalLoader, setPaymentModalLoader] = useState(false);

  // subscription modal
  const [openSubscriptionModal, setOpenSubscriptionModal] = useState(false);

  // store link
  const [storeLink, setStoreLink] = useState("");

  useEffect(() => {
    if (!_.isEmpty(userData?.access_token) && isFocused) {
      getNotificationList(false);
    }
  }, [isFocused]);

  useEffect(() => {
    getChatMessageData();
    if (isLogin) {
      getCommonData();
      getInitialPaymentDetails();
    }
  }, []);

  // useEffect(() => {
  //   if (!_.isEmpty(userData)) {
  //     if (
  //       userData?.subscription_status === null ||
  //       userData?.subscription_status === "inactive"
  //     ) {
  //       setOpenInitialPaymentModal(true);
  //     } else {
  //       setOpenInitialPaymentModal(false);
  //     }
  //   }
  // }, [userData]);

  // common data user data
  const getCommonData = async () => {
    let endPoints = Setting.endpoints.commonData;

    const params = {
      email: userData?.email,
    };

    try {
      const resp = await getAPIProgressData(endPoints, "POST", params);
      if (resp?.success) {
        if (!_.isEmpty(resp?.data)) {
          if (location.pathname !== "/subscription-plans") {
            if (
              (resp?.data?.subscription_status === null ||
                resp?.data?.subscription_status === "inactive") &&
              resp?.data?.subscription_detail?.subscription_expire_days !==
                "Expired" &&
              resp?.data?.unrestricted_user != 1
            ) {
              setOpenInitialPaymentModal(true);
            } else if (
              resp?.data?.subscription_detail?.subscription_expire_days ===
                "Expired" &&
              resp?.data?.unrestricted_user != 1
            ) {
              setOpenSubscriptionModal(true);
            } else {
              setOpenInitialPaymentModal(false);
            }
          }

          store.dispatch(authActions.setUserData(resp?.data));
        }
      } else {
        toast(resp?.message, {
          type: "error",
        });
      }
    } catch (error) {
      toast(error.message, {
        type: "error",
      });
    }
  };

  // logout api
  const logoutApiCall = async () => {
    setLogoutBtnLoader(true);
    let endPoints = Setting.endpoints.logOutApi;
    const data = {
      notification_token: useruuid,
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", data, true);
      if (resp?.success) {
        toast(resp?.message, {
          type: "success",
        });
        store.dispatch(authActions.setAccessToken(""));
        store.dispatch(authActions.setUserData({}));
        store.dispatch(authActions.setLatLng({}));
        store.dispatch(authActions.setSelectedChatData({}));
        store.dispatch(authActions.setIsAdmin(false));
        store.dispatch(authActions.setIsTokenSent(false));
        store.dispatch(authActions.setUserUUID(""));
        store.dispatch(authActions.setBackLatLng({}));
        store.dispatch(authActions.setBackSearch(""));
        store.dispatch(authActions.setBackState({}));
        store.dispatch(authActions.setBackLocationLatLng({}));
        store.dispatch(authActions.setHomeBackLatLng({}));
        setLogoutBtnLoader(false);

        if (isMobile) {
          navigate("/login");
        } else {
          navigate("/");
        }
      } else {
        toast(resp?.message, {
          type: "error",
        });
        setLogoutBtnLoader(false);
      }
    } catch (error) {
      toast(error.message, {
        type: "error",
      });
      setLogoutBtnLoader(false);
    }
  };

  // notification list
  const getNotificationList = async (isModalOpen) => {
    setLoader(true);
    let endPoints = Setting.endpoints.notificationList;

    const data = {
      is_modal: isModalOpen ? 1 : 0,
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", data, true);
      if (resp?.success) {
        if (!_.isEmpty(resp?.data)) {
          setNotificationData(resp?.data);
        }

        setLoader(false);

        if (!_.isEmpty(resp?.data)) {
          let hasUnreadNotifications = false;

          resp.data.forEach((entry) => {
            if (
              entry.notification_list.some(
                (notification) => notification.is_read == 0
              )
            ) {
              hasUnreadNotifications = true;
              return;
            }
          });

          dispatch(setNotificationCount(hasUnreadNotifications));
        }
      } else {
        toast(resp?.message, {
          type: "error",
        });
        setLoader(false);
        setNotificationData([]);
      }
    } catch (error) {
      toast(error.message, {
        type: "error",
      });
      setLoader(false);
    }
  };

  // remove notification
  const getRemoveNotificationList = async () => {
    setRemoveLoader(true);
    let endPoints = Setting.endpoints.removeNotificationList;

    try {
      const resp = await getAPIProgressData(endPoints, "POST", {}, true);
      if (resp?.success) {
        setNotificationData([]);
        setShowNotification(false);
        setRemoveLoader(false);
      } else {
        toast(resp?.message, {
          type: "error",
        });
        setRemoveLoader(false);
      }
    } catch (error) {
      toast(error.message, {
        type: "error",
      });
      setRemoveLoader(false);
    }
  };

  //get chat message
  const getChatMessageData = async () => {
    let endPoints = Setting.endpoints.chatMessage;
    try {
      const resp = await getApiData(endPoints, "GET", {}, true);
      if (resp?.success) {
        if (!_.isEmpty(resp?.data)) {
          const singleChatData = resp?.data?.find(
            (data) => data?.meta_type === "single_chat"
          );
          const groupChatData = resp?.data?.find(
            (data) => data?.meta_type === "group_chat"
          );

          const storeLink = resp?.data?.find(
            (data) => data?.meta_type === "store_link"
          );
          setStoreLink(storeLink?.meta_name);

          if (
            chatMessage?.singleChat !== singleChatData?.meta_name ||
            chatMessage?.singleChat !== groupChatData?.meta_name
          ) {
            dispatch(
              setChatMessages({
                singleChat: singleChatData?.meta_name,
                groupChat: groupChatData?.meta_name,
              })
            );
          }
        }
      } else {
      }
    } catch (error) {
      toast(error.message, {
        type: "error",
      });
    }
  };

  // handle initial payment
  const handleInitialPayment = async (id) => {
    setPaymentBtnLoader(true);
    let endPoints = Setting.endpoints.initialPaymentLink;

    const data = {
      plan_id: id,
    };

    try {
      const resp = await getAPIProgressData(endPoints, "POST", data, true);
      if (resp?.success) {
        if (resp && resp.data && resp.data.url) {
          window.location.replace(resp.data.url);
        }
      } else {
        toast(resp?.message, {
          type: "error",
        });
        setPaymentBtnLoader(false);
      }
    } catch (error) {
      toast(error.message, { type: "error" });
      setPaymentBtnLoader(false);
    }
  };

  // get initial payment details
  const getInitialPaymentDetails = async () => {
    setPaymentModalLoader(true);
    let endPoints = Setting.endpoints.initialPaymentDetails;

    try {
      const resp = await getAPIProgressData(endPoints, "POST", {}, true);
      if (resp?.success) {
        if (!_.isEmpty(resp?.data)) {
          setInitialPlanDetail(resp?.data);
        }
        setPaymentModalLoader(false);
      } else {
        toast(resp?.message, {
          type: "error",
        });
        setPaymentModalLoader(false);
      }
    } catch (error) {
      toast(error.message, {
        type: "error",
      });
      setPaymentModalLoader(false);
    }
  };

  const renderHeaderMenu = () => {
    return (
      <div className={classes.headerMenu}>
        {loginHeaderArrayWeb.map((obj, index) => (
          <Typography
            key={index}
            className={classes.menuLink}
            onClick={() => {
              if (location?.pathname === "/map") {
                dispatch(setBackLatLng({}));
                dispatch(setBackSearch(""));
                dispatch(setBackState({}));
                dispatch(setBackLocationLatLng({}));
              }
              if (location?.pathname === "/home") {
                dispatch(setHomeBackLatLng({}));
              }
              if (location?.pathname === "/chat") {
                dispatch(setActiveMenu(""));
                dispatch(setCTab("personal"));
              }
              if (obj?.id === 2) {
                toast("Coming soon", {
                  type: "info",
                });
              } else if (obj?.id === 3) {
                window.open(`${storeLink}`, "_blank");
              } else {
                navigate(`${obj?.link}`);
              }
            }}
          >
            <motion.div
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              transition={{ duration: 0.4 }}
            >
              <Typography
                variant="title"
                sx={{
                  fontSize: md ? 10 : lg ? 12 : xl ? 14 : 16,
                  marginLeft: lg ? "0px" : xl ? "0px" : "18px",
                  textWrap: "nowrap",
                  userSelect: "none",
                }}
              >
                {obj?.title}
              </Typography>
            </motion.div>
          </Typography>
        ))}
        {isLogin && userData?.is_do_not_disturb === 1 && (
          <motion.div
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            transition={{ duration: 0.4 }}
          >
            <Typography
              onClick={() => {
                if (location?.pathname === "/profile") {
                } else {
                  navigate("/profile");
                }
              }}
              sx={{
                cursor: "pointer",
                fontFamily: FontFamily.Bold,
                color: BaseColor.red,
                fontSize: md ? 10 : lg ? 12 : xl ? 14 : 16,
                marginLeft: lg ? "0px" : xl ? "0px" : "18px",
                marginRight: 2,
                textWrap: "nowrap",
                userSelect: "none",
              }}
            >
              DND Active
            </Typography>
          </motion.div>
        )}
      </div>
    );
  };

  const drawerMenuItems = () => {
    return (
      <div className={classes.drawerMenu}>
        {isLogin
          ? loginDrawerMenu.map((obj, index) => (
              <ListItemButton
                style={{
                  fontFamily: FontFamily.SemiBold,
                  marginTop: "15px",
                  paddingLeft: "20px",
                  width: "100%",
                  fontSize: 17,
                  position: "relative",
                  zIndex: 1,
                  color:
                    activeTab === index ? BaseColor.white : BaseColor.primary,
                  backgroundColor: activeTab === index && BaseColor.primary,
                }}
                onClick={() => {
                  if (location?.pathname === "/map") {
                    dispatch(setBackLatLng({}));
                    dispatch(setBackSearch(""));
                    dispatch(setBackState({}));
                    dispatch(setBackLocationLatLng({}));
                  }
                  if (location?.pathname === "/home") {
                    dispatch(setHomeBackLatLng({}));
                  }
                  if (obj?.id === 10) {
                    setOpenLogoutModal(true);
                  } else {
                    navigate(obj?.link);
                  }
                }}
              >
                {obj?.title}
              </ListItemButton>
            ))
          : loginHeaderArray.map((obj, index) => (
              <ListItemButton
                style={{
                  fontFamily: FontFamily.SemiBold,
                  marginTop: "15px",
                  paddingLeft: "20px",
                  width: "100%",
                  fontSize: 17,
                  position: "relative",
                  zIndex: 1,
                  color:
                    activeTab === index ? BaseColor.white : BaseColor.primary,
                  backgroundColor:
                    activeTab === index ? BaseColor.primary : "transparent",
                }}
                onClick={() => {
                  if (location?.pathname === "/map") {
                    dispatch(setBackLatLng({}));
                    dispatch(setBackSearch(""));
                    dispatch(setBackState({}));
                    dispatch(setBackLocationLatLng({}));
                  }
                  if (location?.pathname === "/home") {
                    dispatch(setHomeBackLatLng({}));
                  }
                  if (location?.pathname === "/chat") {
                    dispatch(setActiveMenu(""));
                    dispatch(setCTab("personal"));
                  }
                  if (obj?.id === 2) {
                    toast("Coming soon", {
                      type: "info",
                    });
                  } else if (obj?.id === 3) {
                    window.open(`${storeLink}`, "_blank");
                  } else {
                    navigate(obj?.link);
                  }
                }}
              >
                {obj?.title}
              </ListItemButton>
            ))}
      </div>
    );
  };

  const getYesterday = () => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    return yesterday.toISOString().slice(0, 10);
  };

  const today = new Date().toISOString().slice(0, 10);

  const groupedNotifications = notificationData.reduce(
    (acc, notification) => {
      const { date, day, notification_list } = notification;

      // Determine if the notification date is today, yesterday, or in the past
      if (date === today) {
        acc.today.push({ day, notification_list });
      } else if (date === getYesterday()) {
        acc.yesterday.push({ day, notification_list });
      } else {
        acc.past.push({ day, notification_list, date });
      }

      return acc;
    },
    { today: [], yesterday: [], past: [] }
  );

  return (
    <>
      <Grid
        container
        className={classes.headerMainCon}
        alignItems="center"
        style={{
          boxShadow: !md && !sm && !xs && "0 0 10px 10px rgb(0 0 0 / 10%)",
          height: md ? "70px" : "90px",
          overflowY: "hidden",
          ...style,
        }}
      >
        {md || isMobile || isTablet ? (
          <Grid container style={{ height: "100%" }}>
            <Grid item xs={0.5} sm={0.5} md={1} lg={1}></Grid>
            <Grid item xs={11} sm={11} md={10} lg={10}>
              {location.pathname === "/contact-us" ||
              location.pathname === "/faqs" ||
              location.pathname === "/terms-and-conditions" ||
              location.pathname === "/privacy-policy" ||
              location.pathname === "/profile-personal-details" ||
              location.pathname === "/profile-golf-club" ||
              location.pathname === "/review-details" ||
              location.pathname === "/profile-golf-other-details" ||
              location.pathname === "/profile" ||
              location?.pathname?.split("/")[1] === "event-details" ||
              location.pathname === "/about-us" ||
              location?.pathname === "/my-events" ||
              location?.pathname === "/add-bank" ||
              location?.pathname?.split("/")[1] === "club-details" ||
              location?.pathname === "/subscription-plans" ? (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <div style={{ width: "4%" }}>
                    <CIcon
                      src={Files.svgIcons.BackArrowIcon}
                      size={sm ? 25 : 30}
                      style={{
                        cursor: !isMobile && !isTablet && "pointer",
                        marginLeft: "5px",
                      }}
                      onClick={() => {
                        backButtonClick();
                      }}
                    />
                  </div>
                  <div
                    style={{
                      width: "90%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        color: BaseColor.primary,
                        fontFamily: FontFamily.GilbertQualifiDemo,
                        fontSize: 24,
                        marginLeft: "10px",
                      }}
                    >
                      {title}
                    </Typography>
                  </div>
                  {edit && (
                    <CIcon
                      src={Files.svgIcons.editGreen}
                      size={25}
                      onClick={() => {
                        onEditClick();
                      }}
                      style={{ cursor: "pointer" }}
                    />
                  )}
                </div>
              ) : (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    height: "100%",
                    borderBottom: `1px solid ${BaseColor.cursorColor}`,
                  }}
                >
                  {isLogin ? (
                    <>
                      {props.page === "chat" ? (
                        <div
                          style={{
                            width: "100%",
                            textAlign: "center",
                            paddingLeft: "40px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontFamily: FontFamily.GilbertQualifiDemo,
                              color: BaseColor.primary,
                              fontSize: 22,
                            }}
                          >
                            Chat
                          </Typography>
                        </div>
                      ) : (
                        <div>
                          <Typography
                            sx={{
                              fontFamily: FontFamily.Regular,
                              color: BaseColor.blackColor,
                              fontSize: 18,
                              marginTop: "10px",
                            }}
                          >
                            Hello
                          </Typography>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                fontFamily: FontFamily.GilbertQualifiDemo,
                                color: BaseColor.primary,
                                fontSize: 22,
                              }}
                            >
                              {userData?.firstname} {userData?.lastname}
                            </Typography>
                            {isLogin && userData?.is_do_not_disturb === 1 && (
                              <Typography
                                onClick={() => {
                                  if (location?.pathname === "/profile") {
                                  } else {
                                    navigate("/profile");
                                  }
                                }}
                                sx={{
                                  fontFamily: FontFamily.Bold,
                                  color: BaseColor.red,
                                  textWrap: "nowrap",
                                  marginLeft: "5px ",
                                  fontSize: isMobile && "12px",
                                  marginTop: "3px",
                                }}
                              >
                                (DND Active)
                              </Typography>
                            )}
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    <img
                      src={Files.svgIcons.newLogo}
                      className={classes.logoStyle}
                      style={{ width: "100px" }}
                      alt="Logo"
                      draggable={false}
                    />
                  )}
                  <div
                    style={{
                      display: "flex",
                      justifyContet: "center",
                      alignItems: "center",
                    }}
                  >
                    {isLogin && (
                      <div
                        style={{
                          backgroundColor: "#EDF1F2",
                          borderRadius: "50%",
                          padding: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: "5px",
                          position: "relative",
                        }}
                      >
                        {notificationCount && (
                          <div
                            style={{
                              position: "absolute",
                              top: 3,
                              right: 3,
                              backgroundColor: BaseColor.errorRed,
                              width: 7.5,
                              height: 7.5,
                              borderRadius: "50%",
                            }}
                          />
                        )}
                        <BsBell
                          onClick={() => {
                            if (sm) {
                              if (location?.pathname === "/map") {
                                dispatch(setBackLatLng({}));
                                dispatch(setBackSearch(""));
                                dispatch(setBackState({}));
                                dispatch(setBackLocationLatLng({}));
                              }
                              if (location?.pathname === "/home") {
                                dispatch(setHomeBackLatLng({}));
                              }
                              navigate("/notification");
                            } else {
                              setShowNotification(true);
                              getNotificationList();
                            }
                          }}
                          style={{
                            color: BaseColor.primary,
                            fontSize: lg ? 16 : 20,
                            cursor: !isMobile && !isTablet && "pointer",
                          }}
                        />
                      </div>
                    )}

                    <div
                      style={{
                        backgroundColor: "#EDF1F2",
                        borderRadius: "50%",
                        width: "35px",
                        height: "35px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: "5px",
                      }}
                    >
                      <img
                        onClick={() => {
                          setOpenDrawer(!openDrawer);
                        }}
                        src={Files.Images.Menu}
                        style={{
                          width: "30px",
                          height: "30px",
                          backgroundColor: "#EDF1F2",
                          borderRadius: "50%",
                          padding: "3px",
                        }}
                        alt="Logo"
                        draggable={false}
                      />
                    </div>
                  </div>
                </div>
              )}

              <Drawer
                anchor="left"
                onClose={() => {
                  setOpenDrawer(false);
                }}
                open={openDrawer}
                PaperProps={{
                  sx: {
                    width: isMobile ? "85%" : sm ? "55%" : md ? "40%" : "30%",
                    borderTopRightRadius: "20px",
                    borderBottomRightRadius: "20px",
                  },
                }}
              >
                <div>
                  <CloseIcon
                    onClick={() => {
                      setOpenDrawer(false);
                    }}
                    style={{
                      position: "absolute",
                      top: 15,
                      right: 15,
                      fontSize: 25,
                      cursor: !isMobile && !isTablet && "pointer",
                      color: BaseColor.primary,
                      zIndex: 1,
                    }}
                  />
                </div>

                <div className={classes.logoContainer}>
                  <img
                    src={Files.svgIcons.goatt}
                    alt="Golf-Goatt"
                    style={{ objectFit: "contain", width: "100%" }}
                    draggable={false}
                  ></img>
                </div>
                <div style={{ marginTop: "20px" }}>{drawerMenuItems()}</div>
                <span
                  style={{
                    color: BaseColor.primary,
                    fontFamily: FontFamily.Medium,
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: 20,
                  }}
                >
                  v1.4
                </span>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    position: "fixed",
                    bottom: 10,
                    alignSelf: "center",
                  }}
                >
                  <img
                    src={Files.svgIcons.drawerBg}
                    alt="Goatt"
                    style={{
                      objectFit: "contain",
                      position: "relative",
                      zIndex: -1,
                      width: "85%",
                    }}
                    draggable={false}
                  />
                </div>
              </Drawer>
            </Grid>
            <Grid item xs={0.5} sm={0.5} md={1} lg={1}></Grid>
          </Grid>
        ) : (
          <Grid
            container
            display={"flex"}
            flexWrap={"nowrap"}
            alignItems={"center"}
          >
            <Grid item xs={0.5} sm={0.5} md={1} lg={1}></Grid>
            <Grid
              item
              xs={12}
              sm={2}
              md={1}
              lg={1}
              className={classes.headerSubCon}
            >
              <img
                src={Files.svgIcons.newLogo}
                className={classes.logoStyle}
                style={{
                  width: "100%",
                  cursor: !isMobile && !isTablet && "pointer",
                }}
                draggable={false}
                alt="Logo"
                onClick={() => {
                  if (md || isMobile || isTablet) {
                    if (location?.pathname === "/map") {
                      dispatch(setBackLatLng({}));
                      dispatch(setBackSearch(""));
                      dispatch(setBackState({}));
                      dispatch(setBackLocationLatLng({}));
                    }
                    if (location?.pathname === "/home") {
                      dispatch(setHomeBackLatLng({}));
                    }
                    navigate("/login");
                  } else {
                    if (location?.pathname === "/map") {
                      dispatch(setBackLatLng({}));
                      dispatch(setBackSearch(""));
                      dispatch(setBackState({}));
                      dispatch(setBackLocationLatLng({}));
                    }
                    if (location?.pathname === "/home") {
                      dispatch(setHomeBackLatLng({}));
                    }
                    if (
                      !_.isEmpty(userData?.user_data) &&
                      userData?.user_data?.access_token &&
                      userData?.signup_pending
                    ) {
                      navigate("/new-member");
                    } else {
                      navigate("/");
                    }
                  }
                }}
              />
            </Grid>
            <span
              style={{
                color: BaseColor.primary,
                fontFamily: FontFamily.Medium,
              }}
            >
              v1.4
            </span>
            <Grid
              item
              xs={12}
              sm={10}
              md={9}
              lg={9}
              display={md && "flex"}
              flexWrap={"nowrap"}
              alignItems={"center"}
              justifyContent={md ? "flex-end" : "center"}
            >
              <Grid
                container
                flexWrap={"nowrap"}
                display={"flex"}
                justifyContent={"flex-end"}
                alignItems={"center"}
              >
                <Grid
                  item
                  xs={12}
                  display={"flex"}
                  justifyContent={"flex-end"}
                  alignItems={"center"}
                >
                  {renderHeaderMenu()}
                </Grid>
                {isLogin && (
                  <Grid item xs={2}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: lg ? "100px" : "160px",
                          backgroundColor: "#EDF1F2",
                          borderRadius: "20px",
                          padding: "10px",
                          cursor: !isMobile && !isTablet && "pointer",
                        }}
                        onClick={(e) => {
                          setMenuPopOver(e.currentTarget);
                        }}
                      >
                        <Typography
                          noWrap
                          sx={{
                            fontFamily: FontFamily.GilbertQualifiDemo,
                            fontSize: lg && 14,
                            userSelect: "none",
                          }}
                        >
                          {userData?.firstname} {userData?.lastname}
                        </Typography>

                        <FaAngleDown
                          style={{
                            color: BaseColor.blackColor,
                            marginLeft: !lg ? "5px" : "10px",
                          }}
                        />
                      </div>
                      <div
                        style={{
                          backgroundColor: "#EDF1F2",
                          borderRadius: "50%",
                          padding: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: "5px",
                          cursor: !isMobile && !isTablet && "pointer",
                          position: "relative",
                        }}
                        onClick={() => {
                          setShowNotification(true);
                          getNotificationList(true);
                        }}
                      >
                        {notificationCount && (
                          <div
                            style={{
                              position: "absolute",
                              top: 0,
                              right: 0,
                              backgroundColor: BaseColor.errorRed,
                              width: 12,
                              height: 12,
                              borderRadius: "50%",
                            }}
                          />
                        )}

                        <BsBell
                          style={{
                            color: BaseColor.primary,
                            fontSize: lg ? 16 : 20,
                          }}
                        />
                      </div>
                    </div>
                  </Grid>
                )}

                <Popover
                  onClose={() => setMenuPopOver(null)}
                  anchorEl={menuPopOver}
                  id={idMP}
                  open={popMP}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  className={classes.createPostPopoover}
                  style={{ marginTop: "1%" }}
                >
                  <Grid
                    style={{
                      backgroundColor: BaseColor.white,
                      padding: 8,
                      borderRadius: 10,
                    }}
                  >
                    <ListItemButton
                      style={{
                        marginLeft: -8,
                        fontSize: 14,
                        fontFamily: FontFamily.Medium,
                        color: BaseColor.blackColor,
                      }}
                      onClick={() => {
                        if (location?.pathname === "/map") {
                          dispatch(setBackLatLng({}));
                          dispatch(setBackSearch(""));
                          dispatch(setBackState({}));
                          dispatch(setBackLocationLatLng({}));
                        }
                        if (location?.pathname === "/home") {
                          dispatch(setHomeBackLatLng({}));
                        }
                        if (location?.pathname === "/chat") {
                          dispatch(setActiveMenu(""));
                          dispatch(setCTab("personal"));
                        }
                        setMenuPopOver(null);
                        navigate("/profile");
                      }}
                    >
                      Profile
                    </ListItemButton>
                    <div
                      style={{
                        borderBottom: `1px solid ${BaseColor.popOverSeperate}`,
                      }}
                    />

                    <ListItemButton
                      style={{
                        marginLeft: -8,
                        fontSize: 14,
                        fontFamily: FontFamily.Medium,
                        color: BaseColor.blackColor,
                      }}
                      onClick={() => {
                        if (location?.pathname === "/map") {
                          dispatch(setBackLatLng({}));
                          dispatch(setBackSearch(""));
                          dispatch(setBackState({}));
                          dispatch(setBackLocationLatLng({}));
                        }
                        if (location?.pathname === "/home") {
                          dispatch(setHomeBackLatLng({}));
                        }
                        if (location?.pathname === "/chat") {
                          dispatch(setActiveMenu(""));
                          dispatch(setCTab("personal"));
                        }
                        setMenuPopOver(null);
                        navigate("/payment");
                      }}
                    >
                      My Wallet
                    </ListItemButton>
                    <div
                      style={{
                        borderBottom: `1px solid ${BaseColor.popOverSeperate}`,
                      }}
                    />
                    <ListItemButton
                      style={{
                        marginLeft: -8,
                        fontSize: 14,
                        fontFamily: FontFamily.Medium,
                        color: BaseColor.blackColor,
                      }}
                      onClick={() => {
                        if (location?.pathname === "/map") {
                          dispatch(setBackLatLng({}));
                          dispatch(setBackSearch(""));
                          dispatch(setBackState({}));
                          dispatch(setBackLocationLatLng({}));
                        }
                        if (location?.pathname === "/home") {
                          dispatch(setHomeBackLatLng({}));
                        }
                        if (location?.pathname === "/chat") {
                          dispatch(setActiveMenu(""));
                          dispatch(setCTab("personal"));
                        }
                        setMenuPopOver(null);
                        navigate("/reservation");
                      }}
                    >
                      My Tee Times
                    </ListItemButton>
                    <div
                      style={{
                        borderBottom: `1px solid ${BaseColor.popOverSeperate}`,
                      }}
                    />
                    <ListItemButton
                      style={{
                        marginLeft: -8,
                        fontSize: 14,
                        fontFamily: FontFamily.Medium,
                        color: BaseColor.blackColor,
                      }}
                      onClick={() => {
                        if (location?.pathname === "/map") {
                          dispatch(setBackLatLng({}));
                          dispatch(setBackSearch(""));
                          dispatch(setBackState({}));
                          dispatch(setBackLocationLatLng({}));
                        }
                        if (location?.pathname === "/home") {
                          dispatch(setHomeBackLatLng({}));
                        }
                        if (location?.pathname === "/chat") {
                          dispatch(setActiveMenu(""));
                          dispatch(setCTab("personal"));
                        }
                        setMenuPopOver(null);
                        navigate("/my-events");
                      }}
                    >
                      My Events
                    </ListItemButton>
                    <ListItemButton
                      style={{
                        marginLeft: -8,
                        fontSize: 14,
                        fontFamily: FontFamily.Medium,
                        color: BaseColor.blackColor,
                      }}
                      onClick={() => {
                        setOpenLogoutModal(true);
                      }}
                    >
                      Logout
                    </ListItemButton>
                  </Grid>
                </Popover>
              </Grid>
            </Grid>
            <Grid item xs={0.5} sm={0.5} md={1} lg={1}></Grid>
          </Grid>
        )}

        <CModal
          visible={showNotification}
          onClose={() => {
            setShowNotification(false);
          }}
          children={
            <div>
              <Grid
                px={1}
                py={1}
                style={{
                  borderRadius: 15,
                  position: "absolute",
                  top: "10%",
                  right: "5%",
                  border: "none",
                  backgroundColor: "white",
                  outline: "noun",
                  width: "510px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingLeft: "15px",
                  }}
                >
                  <Typography
                    style={{
                      fontSize: "22px",
                      fontFamily: FontFamily.GilbertQualifiDemo,
                    }}
                  >
                    Notifications
                  </Typography>
                  {!_.isEmpty(notificationData) && (
                    <Button
                      onClick={() => {
                        getRemoveNotificationList();
                      }}
                    >
                      {removeLoader ? (
                        <CircularProgress size={20} color="primary" />
                      ) : (
                        <Typography
                          style={{
                            fontSize: "16px",
                            color: BaseColor.red,
                          }}
                        >
                          {"Clear All"}
                        </Typography>
                      )}
                    </Button>
                  )}
                </div>
                <Grid
                  style={{
                    maxHeight: "50vh",
                    minHeight: "25vh",
                    overflow: "auto",
                    paddingRight: "10px",
                    paddingLeft: "10px",
                    alignItems: "center",
                    justifyContent: "center",
                    display:
                      _.isEmpty(notificationData) || loader ? "flex" : "",
                  }}
                  className={classes.scrollBar}
                >
                  {loader ? (
                    <CircularProgress size={40} color="primary" />
                  ) : (
                    <>
                      {_.isEmpty(notificationData) ? (
                        <Typography
                          style={{
                            fontSize: 16,
                            color: BaseColor.textColor,
                            marginTop: 10,
                            marginBottom: 10,
                          }}
                        >
                          No notifications found.
                        </Typography>
                      ) : (
                        <motion.div
                          initial={{ opacity: 0, y: 50 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: 50 }}
                          transition={{ duration: 0.4 }}
                        >
                          {groupedNotifications.today.length > 0 && (
                            <div>
                              <Typography
                                style={{
                                  fontSize: 18,
                                  fontFamily: FontFamily.Bold,
                                  color: BaseColor.textColor,
                                  marginTop: 10,
                                  marginBottom: 10,
                                }}
                              >
                                Today
                              </Typography>
                              {groupedNotifications.today.map(
                                ({ day, notification_list }) => (
                                  <>
                                    {notification_list.map((notification) => {
                                      const unixTimestamp =
                                        notification.created_at;
                                      const date = new Date(
                                        unixTimestamp * 1000
                                      );

                                      const hours = date.getHours();
                                      const minutes = date
                                        .getMinutes()
                                        .toString()
                                        .padStart(2, "0");

                                      // Determine AM or PM
                                      const amPM = hours >= 12 ? "PM" : "AM";

                                      // Convert hours from 24-hour to 12-hour format
                                      const twelveHourFormat = hours % 12 || 12; // 0 should be converted to 12

                                      const time12 = `${twelveHourFormat}:${minutes} ${amPM}`;

                                      return (
                                        <Grid
                                          key={notification.id}
                                          onClick={() => {
                                            if (location?.pathname === "/map") {
                                              dispatch(setBackLatLng({}));
                                              dispatch(setBackSearch(""));
                                              dispatch(setBackState({}));
                                              dispatch(
                                                setBackLocationLatLng({})
                                              );
                                            }
                                            if (
                                              location?.pathname === "/home"
                                            ) {
                                              dispatch(setHomeBackLatLng({}));
                                            }
                                            const { type } = notification || "";
                                            const isChatPage =
                                              location?.pathname === "/chat";
                                            const isRevPage =
                                              location?.pathname ===
                                              "/reservation";

                                            if (
                                              isRevPage &&
                                              type === "new_reservation_created"
                                            ) {
                                              window.location.replace(
                                                "/reservation"
                                              );
                                            } else if (
                                              type === "new_reservation_created"
                                            ) {
                                              navigate("/reservation", {
                                                state: {
                                                  notificationData:
                                                    notification,
                                                },
                                              });
                                            } else if (
                                              isChatPage &&
                                              (type ===
                                                "user_reservation_rejected" ||
                                                type ===
                                                  "user_reservation_accepted" ||
                                                type === "chat_notification")
                                            ) {
                                              setShowNotification(false);
                                            } else if (
                                              type ===
                                                "user_reservation_rejected" ||
                                              type ===
                                                "user_reservation_accepted" ||
                                              type === "chat_notification"
                                            ) {
                                              navigate("/chat");
                                            }
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              padding: 5,
                                              cursor:
                                                !isMobile &&
                                                !isTablet &&
                                                "pointer",
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                              }}
                                            >
                                              {/* {notification.type ? (
                                                <Circle
                                                  style={{
                                                    position: "relative",
                                                    fontSize: 13,
                                                    color: BaseColor.green,
                                                    right: "30px",
                                                    left: 13,
                                                    bottom: -12,
                                                    borderRadius: "50px",
                                                  }}
                                                />
                                              ) : null} */}
                                              <NotificationsNoneSharp
                                                style={{
                                                  color: BaseColor.primary,
                                                  fontSize: 30,
                                                  cursor:
                                                    !isMobile &&
                                                    !isTablet &&
                                                    "pointer",
                                                  backgroundColor: "#EAF3FA",
                                                  borderRadius: "50%",
                                                  padding: 5,
                                                  border: `1px solid ${BaseColor.primary}`,
                                                }}
                                              />
                                            </div>
                                            <div style={{ marginLeft: 10 }}>
                                              <div
                                                style={{
                                                  width: "400px",
                                                  textAlign: "center",
                                                  display: "flex",
                                                  justifyContent:
                                                    "space-between",
                                                }}
                                              >
                                                <Typography
                                                  style={{
                                                    color: BaseColor.blackColor,
                                                    fontSize: 16,
                                                    fontFamily:
                                                      FontFamily.SemiBold,
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    whiteSpace: "nowrap",
                                                    maxWidth: "310px",
                                                  }}
                                                >
                                                  {notification.title}
                                                </Typography>

                                                <Typography
                                                  style={{
                                                    color: BaseColor.blackColor,
                                                    fontFamily: FontFamily.Bold,
                                                  }}
                                                >
                                                  {time12}
                                                </Typography>
                                              </div>

                                              <Tooltip
                                                enterTouchDelay={0}
                                                placement="top-start"
                                                componentsProps={{
                                                  tooltip: {
                                                    sx: {
                                                      fontSize: 17,
                                                      backgroundColor:
                                                        BaseColor.white,
                                                      fontFamily:
                                                        FontFamily.Medium,
                                                      boxShadow:
                                                        " 0 0 10px 5px rgb(0 0 0 / 10%)",
                                                    },
                                                  },
                                                }}
                                                title={
                                                  <Typography
                                                    style={{
                                                      color: BaseColor.grey,
                                                      maxWidth: "350px",
                                                    }}
                                                  >
                                                    {notification.message}
                                                  </Typography>
                                                }
                                              >
                                                <Typography
                                                  style={{
                                                    color: BaseColor.grey,
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    wrap: "wrap",
                                                    whiteSpace: "nowrap",
                                                    maxWidth: "350px",
                                                  }}
                                                >
                                                  {notification.message}
                                                </Typography>
                                              </Tooltip>
                                            </div>
                                          </div>
                                        </Grid>
                                      );
                                    })}
                                  </>
                                )
                              )}
                            </div>
                          )}

                          {groupedNotifications.yesterday.length > 0 && (
                            <div>
                              <Typography
                                style={{
                                  fontSize: 18,
                                  fontFamily: FontFamily.Bold,
                                  color: BaseColor.textColor,
                                  marginTop: 10,
                                  marginBottom: 10,
                                }}
                              >
                                Yesterday
                              </Typography>
                              {groupedNotifications.yesterday.map(
                                ({ day, notification_list }) => (
                                  <>
                                    {notification_list.map((notification) => {
                                      const unixTimestamp =
                                        notification.created_at;
                                      const date = new Date(
                                        unixTimestamp * 1000
                                      );

                                      const hours = date.getHours();
                                      const minutes = date
                                        .getMinutes()
                                        .toString()
                                        .padStart(2, "0");

                                      // Determine AM or PM
                                      const amPM = hours >= 12 ? "PM" : "AM";

                                      // Convert hours from 24-hour to 12-hour format
                                      const twelveHourFormat = hours % 12 || 12; // 0 should be converted to 12

                                      const time12 = `${twelveHourFormat}:${minutes} ${amPM}`;

                                      return (
                                        <Grid
                                          key={notification.id}
                                          onClick={() => {
                                            if (location?.pathname === "/map") {
                                              dispatch(setBackLatLng({}));
                                              dispatch(setBackSearch(""));
                                              dispatch(setBackState({}));
                                              dispatch(
                                                setBackLocationLatLng({})
                                              );
                                            }
                                            if (
                                              location?.pathname === "/home"
                                            ) {
                                              dispatch(setHomeBackLatLng({}));
                                            }
                                            const { type } = notification || "";
                                            const isChatPage =
                                              location?.pathname === "/chat";
                                            const isRevPage =
                                              location?.pathname ===
                                              "/reservation";

                                            if (
                                              isRevPage &&
                                              type === "new_reservation_created"
                                            ) {
                                              window.location.replace(
                                                "/reservation"
                                              );
                                            } else if (
                                              type === "new_reservation_created"
                                            ) {
                                              navigate("/reservation", {
                                                state: {
                                                  notificationData:
                                                    notification,
                                                },
                                              });
                                            } else if (
                                              isChatPage &&
                                              (type ===
                                                "user_reservation_rejected" ||
                                                type ===
                                                  "user_reservation_accepted" ||
                                                type === "chat_notification")
                                            ) {
                                              setShowNotification(false);
                                            } else if (
                                              type ===
                                                "user_reservation_rejected" ||
                                              type ===
                                                "user_reservation_accepted" ||
                                              type === "chat_notification"
                                            ) {
                                              navigate("/chat");
                                            }
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              padding: 5,
                                              cursor:
                                                !isMobile &&
                                                !isTablet &&
                                                "pointer",
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                              }}
                                            >
                                              {/* {notification.type ? (
                                                <Circle
                                                  style={{
                                                    position: "relative",
                                                    fontSize: 13,
                                                    color: BaseColor.green,
                                                    right: "30px",
                                                    left: 13,
                                                    bottom: -12,
                                                    borderRadius: "50px",
                                                  }}
                                                />
                                              ) : null} */}
                                              <NotificationsNoneSharp
                                                style={{
                                                  color: BaseColor.primary,
                                                  fontSize: 30,
                                                  cursor:
                                                    !isMobile &&
                                                    !isTablet &&
                                                    "pointer",
                                                  backgroundColor: "#EAF3FA",
                                                  borderRadius: "50%",
                                                  padding: 5,
                                                  border: `1px solid ${BaseColor.primary}`,
                                                }}
                                              />
                                            </div>
                                            <div style={{ marginLeft: 10 }}>
                                              <div
                                                style={{
                                                  width: "400px",
                                                  textAlign: "center",
                                                  display: "flex",
                                                  justifyContent:
                                                    "space-between",
                                                }}
                                              >
                                                <Typography
                                                  style={{
                                                    color: BaseColor.black,
                                                    fontSize: 16,
                                                    fontFamily:
                                                      FontFamily.SemiBold,
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    whiteSpace: "nowrap",
                                                    maxWidth: "315px",
                                                  }}
                                                >
                                                  {notification.title}
                                                </Typography>

                                                <Typography
                                                  style={{
                                                    color: BaseColor.black,
                                                    fontFamily: FontFamily.Bold,
                                                  }}
                                                >
                                                  {time12}
                                                </Typography>
                                              </div>

                                              <Tooltip
                                                enterTouchDelay={0}
                                                placement="top-start"
                                                componentsProps={{
                                                  tooltip: {
                                                    sx: {
                                                      fontSize: 17,
                                                      backgroundColor:
                                                        BaseColor.white,
                                                      fontFamily:
                                                        FontFamily.Medium,
                                                      boxShadow:
                                                        " 0 0 10px 5px rgb(0 0 0 / 10%)",
                                                    },
                                                  },
                                                }}
                                                title={
                                                  <Typography
                                                    style={{
                                                      color: BaseColor.grey,
                                                      maxWidth: "350px",
                                                    }}
                                                  >
                                                    {notification.message}
                                                  </Typography>
                                                }
                                              >
                                                <Typography
                                                  style={{
                                                    color: BaseColor.grey,
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    whiteSpace: "nowrap",
                                                    maxWidth: "350px",
                                                  }}
                                                >
                                                  {notification.message}
                                                </Typography>
                                              </Tooltip>
                                            </div>
                                          </div>
                                        </Grid>
                                      );
                                    })}
                                  </>
                                )
                              )}
                            </div>
                          )}

                          {groupedNotifications.past.length > 0 && (
                            <div>
                              <Typography
                                style={{
                                  fontSize: 20,
                                  fontFamily: FontFamily.Bold,
                                  color: BaseColor.textColor,
                                  marginTop: 10,
                                  marginBottom: 10,
                                }}
                              >
                                Past Days
                              </Typography>
                              {groupedNotifications.past.map(
                                ({ day, notification_list, date }) => {
                                  // Parse the ISO date string into a Date object
                                  const parsedDate = new Date(date);

                                  // Extract day, month, and year from the parsed date
                                  const dayOfMonth = parsedDate
                                    .getDate()
                                    .toString()
                                    .padStart(2, "0");
                                  const monthOfYear = (
                                    parsedDate.getMonth() + 1
                                  )
                                    .toString()
                                    .padStart(2, "0"); // getMonth() returns 0-based index, hence +1
                                  const year = parsedDate.getFullYear();

                                  // Construct the formatted date in "dd-mm-yyyy" format
                                  const formattedDate = `${monthOfYear}-${dayOfMonth}-${year}`;

                                  return (
                                    <div key={day}>
                                      <Typography
                                        style={{
                                          fontSize: 18,
                                          fontFamily: FontFamily.Bold,
                                          color: BaseColor.textColor,
                                          marginTop: 10,
                                          marginBottom: 10,
                                        }}
                                      >
                                        {day} ({formattedDate})
                                      </Typography>

                                      <ul>
                                        {notification_list.map(
                                          (notification) => {
                                            const unixTimestamp =
                                              notification.created_at;
                                            const notificationDate = new Date(
                                              unixTimestamp * 1000
                                            );

                                            const hours =
                                              notificationDate.getHours();
                                            const minutes = notificationDate
                                              .getMinutes()
                                              .toString()
                                              .padStart(2, "0");

                                            // Determine AM or PM
                                            const amPM =
                                              hours >= 12 ? "PM" : "AM";

                                            // Convert hours from 24-hour to 12-hour format
                                            const twelveHourFormat =
                                              hours % 12 || 12; // 0 should be converted to 12

                                            const time12 = `${twelveHourFormat}:${minutes} ${amPM}`;

                                            return (
                                              <li
                                                key={notification.id}
                                                style={{
                                                  cursor:
                                                    !isTablet &&
                                                    !isMobile &&
                                                    "pointer",
                                                }}
                                              >
                                                <Grid
                                                  onClick={() => {
                                                    const { type } =
                                                      notification || "";
                                                    const isChatPage =
                                                      location?.pathname ===
                                                      "/chat";

                                                    const isRevPage =
                                                      location?.pathname ===
                                                      "/reservation";

                                                    if (
                                                      isRevPage &&
                                                      type ===
                                                        "new_reservation_created"
                                                    ) {
                                                      window.location.replace(
                                                        "/reservation"
                                                      );
                                                    } else if (
                                                      type ===
                                                      "new_reservation_created"
                                                    ) {
                                                      navigate("/reservation", {
                                                        state: {
                                                          notificationData:
                                                            notification,
                                                        },
                                                      });
                                                    } else if (
                                                      isChatPage &&
                                                      (type ===
                                                        "user_reservation_rejected" ||
                                                        type ===
                                                          "user_reservation_accepted" ||
                                                        type ===
                                                          "chat_notification")
                                                    ) {
                                                      setShowNotification(
                                                        false
                                                      );
                                                    } else if (
                                                      type ===
                                                        "user_reservation_rejected" ||
                                                      type ===
                                                        "user_reservation_accepted" ||
                                                      type ===
                                                        "chat_notification"
                                                    ) {
                                                      navigate("/chat");
                                                    }
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      padding: 5,
                                                    }}
                                                  >
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        alignItems: "center",
                                                      }}
                                                    >
                                                      {/* {notification.type ? (
                                                        <Circle
                                                          style={{
                                                            position:
                                                              "relative",
                                                            fontSize: 13,
                                                            color:
                                                              BaseColor.green,
                                                            right: "30px",
                                                            left: 13,
                                                            bottom: -12,
                                                            borderRadius:
                                                              "50px",
                                                          }}
                                                        />
                                                      ) : null} */}
                                                      {/* I assume you have a custom icon component, replace with your actual component */}
                                                      <NotificationsNoneSharp
                                                        style={{
                                                          color:
                                                            BaseColor.primary,
                                                          fontSize: 30,
                                                          cursor:
                                                            !isMobile &&
                                                            !isTablet &&
                                                            "pointer",
                                                          backgroundColor:
                                                            "#EAF3FA",
                                                          borderRadius: "50%",
                                                          padding: 5,
                                                          border: `1px solid ${BaseColor.primary}`,
                                                        }}
                                                      />
                                                    </div>
                                                    <div
                                                      style={{
                                                        marginLeft: 10,
                                                        width: "400px",
                                                      }}
                                                    >
                                                      <div
                                                        style={{
                                                          textAlign: "center",
                                                          display: "flex",
                                                          justifyContent:
                                                            "space-between",
                                                        }}
                                                      >
                                                        <Typography
                                                          style={{
                                                            color:
                                                              BaseColor.black,
                                                            fontSize: 16,
                                                            fontFamily:
                                                              FontFamily.SemiBold,
                                                            overflow: "hidden",
                                                            textOverflow:
                                                              "ellipsis",
                                                            whiteSpace:
                                                              "nowrap",
                                                            maxWidth: "315px",
                                                          }}
                                                        >
                                                          {notification.title}
                                                        </Typography>

                                                        <Typography
                                                          style={{
                                                            color:
                                                              BaseColor.black,
                                                            fontFamily:
                                                              FontFamily.Bold,
                                                            textAlign: "end",
                                                          }}
                                                        >
                                                          {time12}
                                                        </Typography>
                                                      </div>

                                                      <Tooltip
                                                        enterTouchDelay={0}
                                                        placement="top-start"
                                                        componentsProps={{
                                                          tooltip: {
                                                            sx: {
                                                              fontSize: 17,
                                                              backgroundColor:
                                                                BaseColor.white,
                                                              fontFamily:
                                                                FontFamily.Medium,
                                                              boxShadow:
                                                                " 0 0 10px 5px rgb(0 0 0 / 10%)",
                                                            },
                                                          },
                                                        }}
                                                        title={
                                                          <Typography
                                                            style={{
                                                              color:
                                                                BaseColor.grey,
                                                              maxWidth: "350px",
                                                            }}
                                                          >
                                                            {
                                                              notification.message
                                                            }
                                                          </Typography>
                                                        }
                                                      >
                                                        <Typography
                                                          style={{
                                                            color:
                                                              BaseColor.grey,
                                                            overflow: "hidden",
                                                            textOverflow:
                                                              "ellipsis",
                                                            whiteSpace:
                                                              "nowrap",
                                                            maxWidth: "350px",
                                                          }}
                                                        >
                                                          {notification.message}
                                                        </Typography>
                                                      </Tooltip>
                                                    </div>
                                                  </div>
                                                </Grid>
                                              </li>
                                            );
                                          }
                                        )}
                                      </ul>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          )}
                        </motion.div>
                      )}
                    </>
                  )}
                </Grid>
              </Grid>
            </div>
          }
        />
        <CModal
          visible={openLogoutModal}
          onClose={() => {
            setOpenLogoutModal(false);
          }}
          children={
            <Grid
              container
              padding={isMobile ? "20px" : "40px"}
              style={{
                position: "relative",
                width: sm ? "90vw" : "400px",
                maxHeight: "80vh",
                overflowY: "auto",
              }}
              className={classes.scrollBar}
            >
              <CloseIcon
                onClick={() => {
                  setOpenLogoutModal(false);
                }}
                style={{
                  position: "absolute",
                  top: 10,
                  right: 15,
                  fontSize: 30,
                  cursor: !isMobile && !isTablet && "pointer",
                  color: BaseColor.primary,
                  zIndex: 1,
                }}
              />

              <Grid
                item
                xs={12}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Typography
                  style={{
                    fontFamily: FontFamily.GilbertQualifiDemo,
                    fontSize: isMobile ? "20px" : "24px",
                    color: BaseColor.blackColor,
                  }}
                >
                  Logout
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                mt={isMobile ? "20px" : "30px"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Typography
                  style={{
                    color: BaseColor.blackColor,
                    fontFamily: FontFamily.Medium,
                    fontSize: 16,
                    textAlign: "center",
                  }}
                >
                  Are you sure you want to logout?
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                mt={"40px"}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <CButton
                  btnStyle={{ width: isMobile ? "45%" : "45%" }}
                  variant={"outlined"}
                  onClick={() => {
                    setOpenLogoutModal(false);
                  }}
                >
                  Cancel
                </CButton>
                <CButton
                  btnStyle={{ width: isMobile ? "45%" : "45%" }}
                  loading={logoutBtnLoader}
                  onClick={() => {
                    logoutApiCall();
                  }}
                >
                  Logout
                </CButton>
              </Grid>
            </Grid>
          }
        />
        <CModal
          visible={openInitialPaymentModal}
          children={
            <Grid
              container
              padding={isMobile ? "20px" : "40px"}
              style={{
                position: "relative",
                width: sm ? "90vw" : "400px",
                maxHeight: "80vh",
                overflowY: "auto",
              }}
              // className={classes.scrollBar}
            >
              {paymentModalLoader ? (
                <Grid
                  container
                  width={"100%"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  height={"30vh"}
                >
                  <CircularProgress color="primary" size={40} />
                </Grid>
              ) : (
                <>
                  <Grid
                    item
                    xs={12}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Typography
                      style={{
                        fontFamily: FontFamily.GilbertQualifiDemo,
                        fontSize: isMobile ? "20px" : "24px",
                        color: BaseColor.blackColor,
                      }}
                    >
                      Pay Initiation Fee
                    </Typography>
                  </Grid>
                  {/* <Grid
                    item
                    xs={12} 
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Lottie
                      animationData={Files.lottie.subscriptionLottie}
                      loop={true}
                      style={{
                        height: isMobile ? "100px" : "120px",
                      }}
                    />
                  </Grid> */}

                  <Grid
                    item
                    xs={12}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    mt={"20px"}
                  >
                    <Typography
                      sx={{
                        fontFamily: `${FontFamily.Bold} !important`,
                        fontSize: "25px !important",
                        color: `${BaseColor.blackColor} !important`,
                      }}
                    >
                      ${initialPlanDetail?.plan_price}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    mt={isMobile ? "20px" : "10px"}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Typography
                      style={{
                        color: BaseColor.blackColor,
                        fontFamily: FontFamily.SemiBold,
                        fontSize: 16,
                        textAlign: "center",
                      }}
                    >
                      To finalize your registration and gain full access to our
                      services, please ensure you pay the required initiation
                      fee promptly.
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    mt={"20px"}
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <CButton
                      btnStyle={{ width: "100%" }}
                      loading={paymentBtnLoader}
                      onClick={() => {
                        handleInitialPayment(initialPlanDetail?.id);
                      }}
                    >
                      Pay ${initialPlanDetail?.plan_price}
                    </CButton>
                  </Grid>
                </>
              )}
            </Grid>
          }
        />
        <CModal
          visible={openSubscriptionModal}
          children={
            <Grid
              container
              padding={isMobile ? "20px" : "40px"}
              style={{
                position: "relative",
                width: sm ? "90vw" : "400px",
                maxHeight: "80vh",
                overflowY: "auto",
              }}
            >
              <Grid
                item
                xs={12}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Typography
                  style={{
                    fontFamily: FontFamily.GilbertQualifiDemo,
                    fontSize: isMobile ? "20px" : "24px",
                    color: BaseColor.blackColor,
                  }}
                >
                  Current plan has expired
                </Typography>
              </Grid>
              {/* <Grid
                item
                xs={12}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Lottie
                  animationData={Files.lottie.subscriptionLottie}
                  loop={true}
                  style={{
                    height: isMobile ? "100px" : "120px",
                  }}
                />
              </Grid> */}
              <Grid
                item
                xs={12}
                mt={isMobile ? "20px" : "10px"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Typography
                  style={{
                    color: BaseColor.blackColor,
                    fontFamily: FontFamily.Medium,
                    fontSize: 16,
                    textAlign: "center",
                  }}
                >
                  Your current plan is expired.
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                mt={isMobile ? "20px" : "10px"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Typography
                  style={{
                    color: BaseColor.blackColor,
                    fontFamily: FontFamily.Medium,
                    fontSize: 16,
                    textAlign: "center",
                  }}
                >
                  Please renew your plan to continue your experience.
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                mt={"20px"}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <CButton
                  btnStyle={{ width: "100%" }}
                  loading={paymentBtnLoader}
                  onClick={() => {
                    navigate("/subscription-plans");
                  }}
                >
                  Renew Now
                </CButton>
              </Grid>
            </Grid>
          }
        />
      </Grid>
    </>
  );
}

export default CHeader;
