import { makeStyles } from "@mui/styles";
import BaseColor from "../../Config/colors";
import { isMobile, isTablet } from "react-device-detect";

const styles = makeStyles({
  closeContainer: {
    position: "absolute",
    top: 20,
    right: 20,
    fontSize: 30,
    cursor: !isMobile && !isTablet && "pointer",
    color: BaseColor.textColor,
    zIndex: 1,
  },
  memberContainer: {
    height: "100px",
    width: "66px",
  },
  scrollBar: {
    "&::-webkit-scrollbar": {
      width: 0,
    },
  },
});

export default styles;
