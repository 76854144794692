const actions = {
  SET_USER_DATA: "auth/SET_USER_DATA",
  SET_ACCESSTOKEN: "auth/SET_ACCESSTOKEN",
  CLEAR_ALL_STORAGE_DATA: "auth/CLEAR_ALL_STORAGE_DATA",
  SET_SIGNUP_DATA: "auth/SET_SIGNUP_DATA",
  SET_ACTIVE_HOME_TAB: "auth/SET_ACTIVE_HOME_TAB",
  SET_DRAWER_TAB: "auth/SET_DRAWER_TAB",
  SET_WALKTHROUGH: "auth/SET_WALKTHROUGH",
  SET_LAT_LNG: "auth/SET_LAT_LNG",
  SET_ISUNREAD: "auth/SET_ISUNREAD",
  SET_SELECTED_CHAT_DATA: "auth/SET_SELECTED_CHAT_DATA",
  SET_CTAB: "auth/SET_CTAB",
  SET_ACTIVE_MENU: "auth/SET_ACTIVE_MENU",
  SET_IS_ADMIN: "auth/SET_IS_ADMIN",
  SET_CHAT_MESSAGE: "auth/SET_CHAT_MESSAGE",
  SET_USER_UUID: "auth/SET_USER_UUID",
  SET_NOTI_DATA: "auth/SET_NOTI_DATA",
  SET_DISPLAY_NOTIFICATION_POP_UP: "auth/SET_DISPLAY_NOTIFICATION_POP_UP",
  SET_IS_TOKEN_SENT: "auth/SET_IS_TOKEN_SENT",
  SET_NOTIFICATION_COUNT: "auth/SET_NOTIFICATION_COUNT",
  SET_FOCUS: "auth/SET_FOCUS",
  SET_BACK_LATLNG: "auth/SET_BACK_LATLNG",
  SET_BACK_STATE: "auth/SET_BACK_STATE",
  SET_BACK_SEARCH: "auth/SET_BACK_SEARCH",
  SET_BACK_LOCATION_LATLNG: "auth/SET_BACK_LOCATION_LATLNG",
  SET_HOME_BACK_LATLNG: "auth/SET_HOME_BACK_LATLNG",
  SET_PLAN_EXPIRE_DAYS: "auth/SET_PLAN_EXPIRE_DAYS",
  SET_IS_USER_FIRST_TIME: "auth/SET_IS_USER_FIRST_TIME",

  setUserData: (userData) => (dispatch) =>
    dispatch({
      type: actions.SET_USER_DATA,
      userData,
    }),

  setWalkthrough: (walkthrough) => (dispatch) =>
    dispatch({
      type: actions.SET_WALKTHROUGH,
      walkthrough,
    }),

  setSelectedChatData: (selectedChatData) => (dispatch) =>
    dispatch({
      type: actions.SET_SELECTED_CHAT_DATA,
      selectedChatData,
    }),

  setSignupData: (signUpData) => (dispatch) =>
    dispatch({
      type: actions.SET_SIGNUP_DATA,
      signUpData,
    }),

  setAccessToken: (accessToken) => (dispatch) =>
    dispatch({
      type: actions.SET_ACCESSTOKEN,
      accessToken,
    }),

  clearAllData: () => (dispatch) =>
    dispatch({
      type: actions.CLEAR_ALL_STORAGE_DATA,
    }),

  setActiveHomeTab: (activeTab) => (dispatch) =>
    dispatch({
      type: actions.SET_ACTIVE_HOME_TAB,
      activeTab,
    }),

  setDrawerTab: (activeDrawerTab) => (dispatch) =>
    dispatch({
      type: actions.SET_DRAWER_TAB,
      activeDrawerTab,
    }),

  setLatLng: (latLng) => (dispatch) =>
    dispatch({
      type: actions.SET_LAT_LNG,
      latLng,
    }),

  setIsUnread: (isUnread) => (dispatch) =>
    dispatch({
      type: actions.SET_ISUNREAD,
      isUnread,
    }),

  setCTab: (cTab) => (dispatch) =>
    dispatch({
      type: actions.SET_CTAB,
      cTab,
    }),

  setActiveMenu: (activeMenu) => (dispatch) =>
    dispatch({
      type: actions.SET_ACTIVE_MENU,
      activeMenu,
    }),

  setIsAdmin: (isAdmin) => (dispatch) =>
    dispatch({
      type: actions.SET_IS_ADMIN,
      isAdmin,
    }),

  setChatMessages: (chatMessage) => (dispatch) =>
    dispatch({
      type: actions.SET_CHAT_MESSAGE,
      chatMessage,
    }),

  setUserUUID: (useruuid) => (dispatch) =>
    dispatch({
      type: actions.SET_USER_UUID,
      useruuid,
    }),

  setNotiData: (notiData) => (dispatch) =>
    dispatch({
      type: actions.SET_NOTI_DATA,
      notiData,
    }),

  displayNotificationPopUp: (isNotifiy) => (dispatch) =>
    dispatch({
      type: actions.SET_DISPLAY_NOTIFICATION_POP_UP,
      isNotifiy,
    }),

  setIsTokenSent: (isTokenSent) => (dispatch) =>
    dispatch({
      type: actions.SET_IS_TOKEN_SENT,
      isTokenSent,
    }),

  setNotificationCount: (notificationCount) => (dispatch) =>
    dispatch({
      type: actions.SET_NOTIFICATION_COUNT,
      notificationCount,
    }),

  setFocus: (isFocused) => (dispatch) =>
    dispatch({
      type: actions.SET_FOCUS,
      isFocused,
    }),

  setBackLatLng: (backLatLng) => (dispatch) =>
    dispatch({
      type: actions.SET_BACK_LATLNG,
      backLatLng,
    }),

  setBackState: (backState) => (dispatch) =>
    dispatch({
      type: actions.SET_BACK_STATE,
      backState,
    }),

  setBackSearch: (backSearch) => (dispatch) =>
    dispatch({
      type: actions.SET_BACK_SEARCH,
      backSearch,
    }),

  setBackLocationLatLng: (backLocationLatLng) => (dispatch) =>
    dispatch({
      type: actions.SET_BACK_LOCATION_LATLNG,
      backLocationLatLng,
    }),

  setHomeBackLatLng: (homeBackLatLng) => (dispatch) =>
    dispatch({
      type: actions.SET_HOME_BACK_LATLNG,
      homeBackLatLng,
    }),

  setPlanExpireDays: (expireDays) => (dispatch) =>
    dispatch({
      type: actions.SET_PLAN_EXPIRE_DAYS,
      expireDays,
    }),

  setIsUserFirstTime: (isUserFirstTime) => (dispatch) =>
    dispatch({
      type: actions.SET_IS_USER_FIRST_TIME,
      isUserFirstTime,
    }),
};

export default actions;
