import { Typography } from "@mui/material";
import Files from "./Files";
import BaseColor from "./colors";
import { FontFamily } from "./theme";

export const loginDrawerMenu = [
  {
    id: 1,
    title: "Profile",
    link: "/profile",
  },
  {
    id: 2,
    title: "My Wallet",
    link: "/payment",
  },
  {
    id: 3,
    title: "My Tee Times",
    link: "/reservation",
  },
  {
    id: 4,
    title: "My Events",
    link: "/my-events",
  },
  // {
  //   id: 5,
  //   title: "Terms & Conditions",
  //   link: "/terms-and-conditions",
  // },
  // {
  //   id: 6,
  //   title: "Privacy Policy",
  //   link: "/privacy-policy",
  // },
  {
    id: 7,
    title: "FAQ",
    link: "/faqs",
  },
  {
    id: 8,
    title: "About Us",
    link: "/about-us",
  },
  {
    id: 9,
    title: "Contact Us",
    link: "/contact-us",
  },
  {
    id: 10,
    title: "Logout",
    link: "",
  },
];

export const loginHeaderArray = [
  {
    id: 1,
    title: "About Us",
    link: "/about-us",
  },
  {
    id: 7,
    title: "FAQ",
    link: "/faqs",
  },
  // {
  //   id: 5,
  //   title: "Terms & Conditions",
  //   link: "/terms-and-conditions",
  // },
  // {
  //   id: 6,
  //   title: "Privacy Policy",
  //   link: "/privacy-policy",
  // },
  {
    id: 4,
    title: "Contact Us",
    link: "/contact-us",
  },
  {
    id: 2,
    title: "GOATT Foundation",
    link: "",
  },
  {
    id: 3,
    title: "Store",
    link: "https://freafamily.com/",
  },
];

export const loginHeaderArrayWeb = [
  {
    id: 1,
    title: "About Us",
    link: "/about-us",
  },
  {
    id: 7,
    title: "FAQ",
    link: "/faqs",
  },
  // {
  //   id: 5,
  //   title: "Terms & Conditions",
  //   link: "/terms-and-conditions",
  // },
  // {
  //   id: 6,
  //   title: "Privacy Policy",
  //   link: "/privacy-policy",
  // },
  {
    id: 4,
    title: "Contact Us",
    link: "/contact-us",
  },
  {
    id: 2,
    title: "GOATT Foundation",
    link: "",
  },
  {
    id: 3,
    title: "Store",
    link: "https://freafamily.com/",
  },
];

export const introScreenData = [
  {
    image: Files.Images.intro1,
    title: (
      <Typography
        style={{
          marginBottom: "16px",
          marginTop: "10px",
          color: BaseColor.blackColor,
          fontFamily: FontFamily.GilbertQualifiDemo,
          fontSize: 18,
        }}
        textAlign={"center"}
      >
        Welcome to the <span style={{ color: BaseColor.green }}>G</span>reatest
        <span style={{ color: BaseColor.green }}> O</span>f{" "}
        <span style={{ color: BaseColor.green }}>A</span>ll{" "}
        <span style={{ color: BaseColor.green }}> T</span>ee{" "}
        <span style={{ color: BaseColor.green }}>T</span>imes GOATT Golf Club
      </Typography>
    ),
  },
  {
    image: Files.Images.intro2,
    title: (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Typography
          style={{
            marginTop: "10px",
            color: BaseColor.blackColor,
            fontFamily: FontFamily.GilbertQualifiDemo,
            fontSize: 18,
          }}
          textAlign={"center"}
        >
          GOATT Golf Club
        </Typography>
        <Typography
          style={{
            color: BaseColor.blackColor,
            fontFamily: FontFamily.GilbertQualifiDemo,
            fontSize: 18,
          }}
          textAlign={"center"}
        >
          Building a member network nationwide
        </Typography>
      </div>
    ),
  },
  {
    image: Files.Images.intro3,
    title:
      "Communicate directly with members of other clubs to book tee times and build lasting relationships.",
  },
];

export const reservationData = [
  {
    id: 1,
    name: "Crystal lake green",
    time: "4:00 PM - Sunday, Mar 19",
    host: " John walker",
  },
  {
    id: 1,
    name: "Crystal lake green",
    time: "3:00 PM - Sunday, Apr 9",
    host: " John walker",
  },
  {
    id: 1,
    name: "Crystal lake green",
    time: "4:00 PM - Sunday, May 19",
    host: " John walker",
  },
];

export const eventsData = [
  {
    id: 1,
    name: "Salwan Golf Cup 2024",
    address: "870 Meadvaila, CA 98546",
    distance: "5 miles",
    image: Files.Images.eventImage,
  },
  {
    id: 2,
    name: "Salwan Golf Cup 2024",
    address: "870 Meadvaila, CA 98546",
    distance: "5 miles",
    image: Files.Images.eventImage,
  },
  {
    id: 3,
    name: "Salwan Golf Cup 2024",
    address: "870 Meadvaila, CA 98546",
    distance: "5 miles",
    image: Files.Images.eventImage,
  },
  {
    id: 3,
    name: "Salwan Golf Cup 2024",
    address: "870 Meadvaila, CA 98546",
    distance: "5 miles",
    image: Files.Images.eventImage,
  },
];

export const clubsData = [
  {
    id: 1,
    name: "Pan State Golf Club",
    address: "870 Meadvaila, CA 98546",
    image: Files.Images.clubsImage,
  },
  {
    id: 1,
    name: "Salwan Golf Cup 2024",
    address: "870 Meadvaila, CA 98546",
    image: Files.Images.clubsImage,
  },
  {
    id: 1,
    name: "Salwan Golf Cup 2024",
    address: "870 Meadvaila, CA 98546",
    image: Files.Images.clubsImage,
  },
];

export const profileMenuData = [
  {
    id: 1,
    title: "Personal",
  },
  {
    id: 2,
    title: "Golf Clubs",
  },
  {
    id: 3,
    title: "Golf Other Details",
  },
  {
    id: 8,
    title: "Subscription",
  },
  {
    id: 7,
    title: "Reviews",
  },
  {
    id: 4,
    title: "Change Password",
  },
  {
    id: 5,
    title: "Delete Account",
  },
  {
    id: 6,
    title: "Logout",
  },
];

export const clubDetails = [
  {
    id: 1,
    title: "Home club",
    type: "private",
    name: "Green velly Golf Club",
    address: "1901 Thornridge Cir. Shiloh, Hawaii 81063",
    holes: "15",
    courses: "Red Bridge Golf Courses",
    state: "Alska",
    guest: "$500",
    about:
      "Woods�are long-distance clubs, meant to drive the ball a great distance down the fairway towards the hole. They generally have a large head and a long shaft for maximum club speed. Historically, woods were made from wood,",
    unaccompainedPlay: "Yes",
  },
  {
    id: 1,
    title: "Secondary club",
    type: "private",
    name: "Green velly Golf Club",
    address: "1901 Thornridge Cir. Shiloh, Hawaii 81063",
    holes: "15",
    courses: "Red Bridge Golf Courses",
    state: "Alska",
    guest: "$500",
    about:
      "Woods�are long-distance clubs, meant to drive the ball a great distance down the fairway towards the hole. They generally have a large head and a long shaft for maximum club speed. Historically, woods were made from wood,",
    unaccompainedPlay: "Yes",
  },
  {
    id: 1,
    title: "Additional club 1",
    type: "private",
    name: "Green velly Golf Club",
    address: "1901 Thornridge Cir. Shiloh, Hawaii 81063",
    holes: "15",
    courses: "Red Bridge Golf Courses",
    state: "Alska",
    guest: "$500",
    about:
      "Woods�are long-distance clubs, meant to drive the ball a great distance down the fairway towards the hole. They generally have a large head and a long shaft for maximum club speed. Historically, woods were made from wood,",
    unaccompainedPlay: "Yes",
  },
];
export const Data = [
  {
    id: 1,
    name: "New Event created",
    title:
      "Crowdsource community intelligence without compromising individual privacy",
    time: "2.00",
    type: "true",
    icon2: "Notifications",
  },
  {
    id: 2,
    name: "Your Payment successful",
    title:
      "Crowdsource community intelligence without compromising individual privacy",
    time: "3.00",
  },
  {
    id: 3,
    name: "Reservation successful",
    title:
      "Crowdsource community intelligence without compromising individual privacy",
    time: "4:45",

    icon2: "Notifications",
  },
  {
    id: 4,
    name: "Charity Golf Tournament Start",
    title:
      "Crowdsource community intelligence without compromising individual privacy",
    time: "8:45",
  },

  {
    id: 5,
    name: "Charity Golf Tournament Start",
    title:
      "Crowdsource community intelligence without compromising individual privacy",
    time: "8:45",
    type: "true",
  },
  {
    id: 6,
    name: "Charity Golf Tournament Start",
    title:
      "Crowdsource community intelligence without compromising individual privacy",
    time: "8:45",
    type: "true",
    icon2: "Notifications",
  },
  {
    id: 7,
    name: "Charity Golf Tournament Start",
    title:
      "Crowdsource community intelligence without compromising individual privacy",
    time: "8:45",
    type: "true",
  },
  {
    id: 8,
    name: "Charity Golf Tournament Start",
    title:
      "Crowdsource community intelligence without compromising individual privacy",
    time: "8:45",
    type: "true",
  },
];

export const deleteAccountReasonsData = [
  {
    id: 1,
    name: "No longer needed",
  },
  {
    id: 2,
    name: "Can't find golfer to connect",
  },
  {
    id: 3,
    name: "Other",
  },
];

export const sideBarTabs = [
  {
    id: 1,
    icon: Files.svgIcons.homeIcon,
    fillIcon: Files.svgIcons.homeFillIcon,
    mobFillIcon: Files.svgIcons.primaryFillHome,
    title: "Home",
    path: "/home",
  },
  {
    id: 2,
    icon: Files.svgIcons.mapIcon,
    fillIcon: Files.svgIcons.mapFillIcon,
    mobFillIcon: Files.svgIcons.primaryFillMap,
    title: "Map",
    path: "/map",
  },
  {
    id: 3,
    icon: Files.svgIcons.chatIcon,
    fillIcon: Files.svgIcons.chatFillIcon,
    mobFillIcon: Files.svgIcons.primaryFillChat,
    title: "Chat",
    path: "/chat",
  },
  {
    id: 4,
    icon: Files.svgIcons.eventIcon,
    fillIcon: Files.svgIcons.eventFillIcon,
    mobFillIcon: Files.svgIcons.primaryFillEvent,
    title: "Events",
    path: "/event",
  },
];

export const profileData = [
  {
    id: 1,
    avatar: "",
    name: "Serena",
    date: "Dec 12, 2021",
    value: 3,
    Comment: "Love this Club so much! The quality is amazing.",
  },
  {
    id: 2,
    avatar: "",
    name: "Alice",
    date: "Dec 12, 2021",
    value: 3,
    Comment: "Very comfortable and the golf club is beautiful.",
  },
  {
    id: 3,
    avatar: "",
    name: "Bob",
    date: "Dec 12, 2021",
    value: 3,
    Comment: "Great value for the price. Im very happy with my purchase.",
  },
];

export const statesData = [
  {
    id: 1,
    name: "Alaska",
  },
  {
    id: 1,
    name: "New york",
  },
];

export const eventsDetailsData = [
  {
    id: 1,
    img: Files.Images.eventDetails1,
  },
  {
    id: 2,
    img: Files.Images.eventDetails2,
  },
  {
    id: 3,
    img: Files.Images.eventDetails3,
  },
  {
    id: 4,
    img: Files.Images.eventDetails4,
  },
  {
    id: 5,
    img: Files.Images.eventDetails5,
  },
];

export const timeEventData = [
  {
    id: 1,
    Date: "May 25",
    data: [
      {
        id: 1,
        des: "10:30 AM Players arrive at Rich Harvest Farms and check into cottages",
      },
      {
        id: 2,
        des: "11:30 AM Lunch at Rich Harvest Farms",
      },
      {
        id: 3,
        des: "1:00 PM Tee times begin at Rich Harvest Farms",
      },
      {
        id: 4,
        des: "7:00 PM Group Dinner at Rich Harvest Farms",
      },
      {
        id: 5,
        des: "8:00 PM – 12:00 AM Indoor Arena Open",
      },
    ],
  },
  {
    id: 2,
    Date: "May 26",
    data: [
      {
        id: 1,
        des: "10:31 AM Players arrive at Rich Harvest Farms and check into cottages",
      },
      {
        id: 2,
        des: "11:30 AM Lunch at Rich Harvest Farms",
      },
      {
        id: 3,
        des: "1:00 PM Tee times begin at Rich Harvest Farms",
      },
      {
        id: 4,
        des: "7:00 PM Group Dinner at Rich Harvest Farms",
      },
      {
        id: 5,
        des: "8:00 PM – 12:00 AM Indoor Arena Open",
      },
    ],
  },
  {
    id: 3,
    Date: "May 27",
    data: [
      {
        id: 1,
        des: "10:32 AM Players arrive at Rich Harvest Farms and check into cottages",
      },
      {
        id: 2,
        des: "11:30 AM Lunch at Rich Harvest Farms",
      },
      {
        id: 3,
        des: "1:00 PM Tee times begin at Rich Harvest Farms",
      },
      {
        id: 4,
        des: "7:00 PM Group Dinner at Rich Harvest Farms",
      },
      {
        id: 5,
        des: "8:00 PM – 12:00 AM Indoor Arena Open",
      },
    ],
  },
];

export const membersData = [
  {
    id: 1,
    img: Files.Images.eventImage,
    name: "cooper",
  },
  {
    id: 2,
    img: Files.Images.eventImage,
    name: "cooper",
  },
  {
    id: 3,
    img: Files.Images.eventImage,
    name: "cooper",
  },
  {
    id: 4,
    img: Files.Images.eventImage,
    name: "cooper",
  },
  {
    id: 5,
    img: Files.Images.eventImage,
    name: "cooper",
  },
  {
    id: 6,
    img: Files.Images.eventImage,
    name: "cooper",
  },
  {
    id: 7,
    img: Files.Images.eventImage,
    name: "cooper",
  },
  {
    id: 7,
    img: Files.Images.eventImage,
    name: "cooper",
  },
  {
    id: 7,
    img: Files.Images.eventImage,
    name: "cooper",
  },
  {
    id: 7,
    img: Files.Images.eventImage,
    name: "cooper",
  },
];

export const customerSelectionData = [
  {
    id: 1,
    name: "All",
    title: "All",
  },
  {
    id: 2,
    name: "Custom selection",
    title: "Custom selection",
  },
];

export const chooseMembersArray = [
  {
    id: 1,
    name: "Guy Hawkins",
    image: Files.Images.member1,
    isSelected: false,
    quantity: 0,
  },
  {
    id: 2,
    name: "Kathryn Murphy",
    image: Files.Images.member2,
    isSelected: false,
    quantity: 0,
  },
  {
    id: 3,
    name: "Wade Warren",
    image: Files.Images.member3,
    isSelected: false,
    quantity: 0,
  },
];

export const revSortArray = [
  {
    id: 1,
    image: Files.Images.sort1,
    name: "Sort A to Z",
  },
  {
    id: 2,
    image: Files.Images.sort2,
    name: "Sort Z to A",
  },
  {
    id: 3,
    image: Files.Images.sort1,
    name: "Newest First",
  },
  {
    id: 4,
    image: Files.Images.sort2,
    name: "Oldest First",
  },
];

export const chatContactList = [
  {
    id: 1,
    profile_pic: Files.Images.eventImage,
    name: "Jhon Doe",
    club_name: "Green Valley Golf Club",
    chat: "What kind of strategy is better?",
    time: "22:33",
    chat_count: 4,
    isOnline: 1,
    status: "sent",
    chat_type: "text",
  },
  {
    id: 2,
    // profile_pic: "",
    name: "Ryan Gracia",
    club_name: "Crystal lake green golf club",
    chat: "Hii what's up, What kind of strategy is better?",
    time: "12:00",
    chat_count: 0,
    isOnline: 0,
    status: "delivered",
    chat_type: "text",
  },
  {
    id: 3,
    // profile_pic: "",
    name: "Black Rock",
    club_name: "Green lake golf club",
    chat: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed id pulvinar neque, et auctor dui. Aenean sed nunc laoreet, pellentesque felis a, finibus purus. Donec ut lacinia dui, ac lobortis eros. F",
    time: "01:23",
    chat_count: 14,
    isOnline: 1,
    status: "read",
    chat_type: "text",
  },
  {
    id: 4,
    profile_pic: Files.Images.eventImage,
    name: "Jhon Doe",
    club_name: "Green Valley Golf Club",
    chat: "https://www.Goattclub.com/",
    time: "22:33",
    chat_count: 4,
    isOnline: 1,
    chat_type: "link",
  },
  {
    id: 5,
    // profile_pic: "",
    name: "Ryan Gracia",
    club_name: "Crystal lake green golf club",
    chat: "Image",
    time: "12:00",
    chat_count: 0,
    isOnline: 0,
    chat_type: "image",
  },
  {
    id: 6,
    // profile_pic: "",
    name: "Black Rock",
    club_name: "Green lake golf club",
    chat: "Vedio",
    time: "01:23",
    chat_count: 14,
    isOnline: 1,
    chat_type: "vedio",
  },
  {
    id: 7,
    profile_pic: Files.Images.eventImage,
    name: "Jhon Doe",
    club_name: "Green Valley Golf Club",
    chat: "What kind of strategy is better?",
    time: "22:33",
    chat_count: 4,
    isOnline: 1,
    chat_type: "text",
  },
  {
    id: 8,
    // profile_pic: "",
    name: "Ryan Gracia",
    club_name: "Crystal lake green golf club",
    chat: "Hii what's up, What kind of strategy is better?",
    time: "12:00",
    chat_count: 0,
    isOnline: 0,
    chat_type: "text",
  },
];

export const chatGroupList = [
  {
    id: 1,
    profile_pic: Files.Images.eventImage,
    name: "Golfing",
    chat: "What kind of strategy is better?",
    time: "22:33",
    chat_count: 4,
    status: "sent",
    chat_type: "text",
    members: [],
  },
  {
    id: 2,
    profile_pic: "",
    name: "",
    chat: "What kind of strategy is better?",
    time: "22:33",
    chat_count: 4,
    status: "sent",
    chat_type: "text",
    members: ["jhone", "Rayan", "Black", "Jhony"],
  },
  {
    id: 3,
    profile_pic: "",
    name: "Golfing Club at the 8th street in this place.",
    chat: "What kind of strategy is better?",
    time: "22:33",
    chat_count: 4,
    status: "sent",
    chat_type: "text",
    members: [],
  },
];

export const blockContactList = [
  {
    id: 1,
    profile_pic: Files.Images.eventImage,
    name: "Jhon Doe",
    club_name: "Green Valley Golf Club",
    chat: "What kind of strategy is better?",
    time: "22:33",
    chat_count: 4,
    isOnline: 1,
    status: "sent",
    chat_type: "text",
  },
  {
    id: 2,
    // profile_pic: "",
    name: "Ryan Gracia",
    club_name: "Crystal lake green golf club",
    chat: "Hii what's up, What kind of strategy is better?",
    time: "12:00",
    chat_count: 0,
    isOnline: 0,
    status: "delivered",
    chat_type: "text",
  },
  {
    id: 3,
    // profile_pic: "",
    name: "Black Rock",
    club_name: "Green lake golf club",
    chat: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed id pulvinar neque, et auctor dui. Aenean sed nunc laoreet, pellentesque felis a, finibus purus. Donec ut lacinia dui, ac lobortis eros. F",
    time: "01:23",
    chat_count: 14,
    isOnline: 1,
    status: "read",
    chat_type: "text",
  },
];

export const archiveContactList = [
  {
    id: 1,
    profile_pic: Files.Images.eventImage,
    name: "Jhon Doe",
    club_name: "Green Valley Golf Club",
    chat: "https://www.Goattclub.com/",
    time: "22:33",
    chat_count: 4,
    isOnline: 1,
    chat_type: "link",
  },
  {
    id: 2,
    // profile_pic: "",
    name: "Ryan Gracia",
    club_name: "Crystal lake green golf club",
    chat: "Image",
    time: "12:00",
    chat_count: 0,
    isOnline: 0,
    chat_type: "image",
  },
  {
    id: 3,
    // profile_pic: "",
    name: "Black Rock",
    club_name: "Green lake golf club",
    chat: "Vedio",
    time: "01:23",
    chat_count: 14,
    isOnline: 1,
    chat_type: "vedio",
  },
];

export const personalChatData = [
  {
    sender: "John Doe",
    recipient: "Jane Doe",
    timestamp: "12:30",
    messageType: "text",
    message:
      "Oh, that sounds like a lot of fun! I haven't played in a while, but I'd definitely be up for it.",
    status: "read",
  },
  {
    sender: "Jane Doe",
    recipient: "John Doe",
    timestamp: "12:31",
    messageType: "text",
    message:
      "I was actually thinking about going to play some golf this weekend. Would you be interested in joining me?",
  },
  {
    sender: "John Doe",
    recipient: "Jane Doe",
    timestamp: "12:32",
    messageType: "image",
    mediaUrl: Files.Images.eventDetails1,
    status: "read",
  },
  {
    sender: "Jane Doe",
    recipient: "John Doe",
    timestamp: "12:32",
    messageType: "text",
    message: "That looks like a nice picture! Where was it taken?",
  },
  {
    sender: "John Doe",
    recipient: "Jane Doe",
    timestamp: "12:32",
    messageType: "image",
    mediaUrl: Files.Images.clubsImage,
    status: "read",
  },
  {
    sender: "Jane Doe",
    recipient: "John Doe",
    timestamp: "12:33",
    messageType: "text",
    message: "It was taken at the park last summer. We had a family picnic.",
  },
  {
    sender: "John Doe",
    recipient: "Jane Doe",
    timestamp: "12:33",
    messageType: "image",
    mediaUrl: Files.Images.bGImage,
    status: "read",
  },
  {
    sender: "Jane Doe",
    recipient: "John Doe",
    timestamp: "12:33",
    messageType: "text",
    message: "That video looks great! Thanks for sharing.",
  },
  {
    sender: "John Doe",
    recipient: "Jane Doe",
    timestamp: "12:34",
    messageType: "text",
    message: "You're welcome! I'm glad you liked it.",
    status: "read",
  },
  {
    sender: "Jane Doe",
    recipient: "John Doe",
    timestamp: "12:35",
    messageType: "text",
    message: "Do you have any plans for the weekend besides golf?",
  },
  {
    sender: "John Doe",
    recipient: "Jane Doe",
    timestamp: "12:36",
    messageType: "text",
    message: "Not really, just planning to relax. How about you?",
    status: "read",
  },
  {
    sender: "Jane Doe",
    recipient: "John Doe",
    timestamp: "12:36",
    messageType: "text",
    message:
      "I might go hiking if the weather is good. It's been a while since I last went.",
  },
  {
    sender: "John Doe",
    recipient: "Jane Doe",
    timestamp: "12:36",
    messageType: "image",
    mediaUrl: Files.Images.eventDetails3,
    status: "read",
  },
  {
    sender: "John Doe",
    recipient: "Jane Doe",
    timestamp: "12:37",
    messageType: "text",
    message: "That looks like a beautiful trail! Where is it?",
    status: "delivered",
  },
  {
    sender: "John Doe",
    recipient: "Jane Doe",
    timestamp: "12:37",
    messageType: "text",
    message:
      "It's in the mountains, about an hour's drive from here. We should go there together sometime.",
    status: "sent",
  },
];

export const groupChatData = [
  {
    sender: "John Doe",
    message: "Hey everyone!",
    timestamp: "12:00",
    messageType: "text",
  },
  {
    sender: "Jane Smith",
    message: "Hi John!",
    timestamp: "12:05",
    messageType: "text",
  },
  {
    sender: "Alice Johnson",
    message: "Hello everyone!",
    timestamp: "12:10",
    messageType: "text",
  },
  {
    sender: "Bob Williams",
    message: "Hey!",
    timestamp: "12:15",
    messageType: "text",
  },
  {
    sender: "John Doe",
    message: "What's up?",
    timestamp: "12:20",
    messageType: "text",
  },
  {
    sender: "Jane Smith",
    message: "Not much, just chilling.",
    timestamp: "12:25",
    messageType: "text",
  },
  {
    sender: "Alice Johnson",
    message: "I'm working on a new project.",
    timestamp: "12:30",
    messageType: "text",
  },
  {
    sender: "Bob Williams",
    message: "That sounds interesting!",
    timestamp: "12:35",
    messageType: "text",
  },
  {
    sender: "John Doe",
    message: "Yeah, tell us more about it!",
    timestamp: "12:40",
    messageType: "text",
  },
  {
    sender: "Jane Smith",
    message: "Sure, I'd love to share.",
    timestamp: "12:45",
    messageType: "text",
  },
  {
    sender: "Alice Johnson",
    message: "It involves machine learning algorithms.",
    timestamp: "12:50",
    messageType: "text",
  },
  {
    sender: "Bob Williams",
    message: "Fascinating! I'm also interested in machine learning.",
    timestamp: "12:55",
    messageType: "text",
  },
  {
    sender: "John Doe",
    message: "We should discuss this further.",
    timestamp: "13:00",
    messageType: "text",
  },
  {
    sender: "Jane Smith",
    message: "Agreed!",
    timestamp: "13:05",
    messageType: "text",
  },
  {
    sender: "Alice Johnson",
    message: "Count me in!",
    timestamp: "13:10",
    messageType: "text",
  },
  {
    sender: "Bob Williams",
    message: "Sounds like a plan!",
    timestamp: "13:15",
    messageType: "text",
  },
  {
    sender: "John Doe",
    message: "Great! Let's schedule a meeting.",
    timestamp: "13:20",
    messageType: "text",
  },
  {
    sender: "Jane Smith",
    message: "I'll create a doodle poll.",
    timestamp: "13:25",
    messageType: "text",
  },
];
