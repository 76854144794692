import { makeStyles } from "@mui/styles";
import BaseColor from "../../Config/colors";
import { isMobile, isTablet } from "react-device-detect";

const styles = makeStyles({
  main: {
    display: "flex",
    position: "relative",
    justifyContent: "flex-start",
    backgroundColor: BaseColor.white,
    flexDirection: "column",
    overflow: "hidden",
    borderRadius: 10,
    boxSizing: "border-box",
    width: "100%",
    cursor: !isMobile && !isTablet && "pointer",
    transition: "200ms",
    "@media (max-width:600px)": {
      minWidth: "300px !important",
    },
  },
  img: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    display: "flex",
    alignSelf: "center",
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
});

export default styles;
