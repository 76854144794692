import { makeStyles } from "@mui/styles";
import { FontFamily } from "../../Config/theme";
import { isMobile, isTablet } from "react-device-detect";

const styles = makeStyles({
  iconText: {
    fontFamily: FontFamily.Medium,
    fontSize: "13px !important",
    textAlign: "center",
  },
  iconContainer: {
    borderRadius: "10px",
    padding: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    cursor: !isMobile && !isTablet && "pointer",
  },

  scrollBar: {
    // overflowX: "scroll",
    "&::-webkit-scrollbar": {
      width: 3,
      height: 5,
    },
  },
  mainScrollBar: {
    // overflowX: "scroll",
    "&::-webkit-scrollbar": {
      width: 0,
      height: 5,
    },
  },
});

export default styles;
