import React, { useEffect, useRef, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CHeader from "../../Components/Header";
import BaseColor from "../../Config/colors";
import theme, { FontFamily } from "../../Config/theme";
import CInput from "../../Components/CInput";
import { CiSearch } from "react-icons/ci";
import { Setting } from "../../Utils/Setting";
import { getAPIProgressData } from "../../Utils/APIHelper";
import { toast } from "react-toastify";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import _, { isEmpty } from "lodash";
import { IoCloseOutline } from "react-icons/io5";
import { isIOS, isMobile, isTablet } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import CIcon from "../../Components/CIcon";
import Files from "../../Config/Files";
import { motion } from "framer-motion";
import styles from "./styles";

export default function Faqs() {
  // loader
  const [loader, setLoader] = useState(true);

  // styles and media queries
  const classes = styles();
  const md = useMediaQuery(theme.breakpoints.down("md"));

  // data
  const [faqData, setFaqData] = useState([]);

  // search
  const [search, setSearch] = useState("");
  const [termsData, setTermsData] = useState([]);
  const [policyData, setPolicyData] = useState([]);

  // expanded accordion
  const [expanded, setExpanded] = useState(false);

  // ref
  const lastAccordionRef = useRef(null);

  useEffect(() => {
    if (
      isMobile &&
      expanded === `panel${faqData.length - 1}` &&
      lastAccordionRef.current
    ) {
      setTimeout(() => {
        lastAccordionRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }, 100);
    }
  }, [expanded, faqData.length, isMobile]);

  useEffect(() => {
    GetFaqs();
  }, []);

  // get FAQ data api call
  const GetFaqs = async (search) => {
    setLoader(true);
    let endPoints = `${Setting.endpoints.getFaqList}`;

    let data = {};

    if (!_.isEmpty(search)) {
      data.slug = search;
    }
    try {
      const resp = await getAPIProgressData(endPoints, "POST", data);
      if (resp?.success) {
        if (!_.isEmpty(resp?.data)) {
          setLoader(false);
          setFaqData(resp?.data);
        }
      } else {
        setFaqData([]);
        setLoader(false);
      }
      setLoader(false);
    } catch (error) {
      toast(error.message, {
        type: "error",
      });
      setLoader(false);
    }
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    getTermsAndConditions();
    getPolicyData();
  }, []);

  // get policy data Api call
  const getPolicyData = async () => {
    setLoader(true);
    let endPoints = `${Setting.endpoints.getCmsPages}`;

    const data = {
      slug: "privacy_policy",
    };

    try {
      const resp = await getAPIProgressData(endPoints, "POST", data);
      if (resp?.success) {
        if (
          !_.isEmpty(resp?.data) &&
          !_.isNull(resp?.data) &&
          !_.isUndefined(resp?.data)
        ) {
          setLoader(false);
          setPolicyData(resp?.data);
        }
      } else {
        toast(resp?.message, {
          type: "error",
        });
        setLoader(false);
      }
      setLoader(false);
    } catch (error) {
      toast(error.message, {
        type: "error",
      });
      setLoader(false);
    }
  };

  // policy data
  const appBodyPolicy = (
    <div dangerouslySetInnerHTML={{ __html: policyData?.app_body }} />
  );

  // get terms & conditions api call
  const getTermsAndConditions = async () => {
    setLoader(true);
    let endPoints = `${Setting.endpoints.getCmsPages}`;

    const data = {
      slug: "terms_services",
    };

    try {
      const resp = await getAPIProgressData(endPoints, "POST", data);
      if (resp?.success) {
        if (
          !_.isEmpty(resp?.data) &&
          !_.isNull(resp?.data) &&
          !_.isUndefined(resp?.data)
        ) {
          setLoader(false);
          setTermsData(resp?.data);
        }
      } else {
        toast(resp?.message, {
          type: "error",
        });
        setLoader(false);
      }
      setLoader(false);
    } catch (error) {
      toast(error.message, {
        type: "error",
      });
      setLoader(false);
    }
  };

  // terms data
  const appBody = (
    <div dangerouslySetInnerHTML={{ __html: termsData?.app_body }} />
  );

  // navigation
  const navigate = useNavigate();

  return (
    <Grid
      className={classes.scrollBar}
      container
      style={{
        height:
          isIOS && window?.navigator?.standalone && isMobile
            ? "97vh"
            : isIOS && isMobile
            ? "calc(100vh - 100px)"
            : "100vh",
        overflowY: "auto",
      }}
    >
      <CHeader
        title={"FAQs"}
        backButtonClick={() => {
          navigate(-1);
        }}
      />

      <Grid item xs={12} style={{ height: "calc(100% - 90px)" }}>
        <Grid container>
          <Grid item xs={0.5} sm={0.5} md={1} lg={1}></Grid>
          <Grid item xs={11} sm={11} md={10} lg={10} paddingTop={!md && "20px"}>
            <Grid container>
              <Grid item xs={12}>
                <Grid
                  container
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {!md && (
                    <Grid item xs={3} display={"flex"} alignItems={"center"}>
                      <CIcon
                        src={Files.svgIcons.BackArrowIcon}
                        size={30}
                        style={{
                          marginLeft: "-4px",
                          cursor: !isMobile && !isTablet && "pointer",
                        }}
                        onClick={() => {
                          navigate(-1);
                        }}
                      />
                      <Typography
                        sx={{
                          color: BaseColor.primary,
                          fontFamily: FontFamily.GilbertQualifiDemo,
                          fontSize: 22,
                          marginLeft: "10px",
                        }}
                      >
                        FAQs
                      </Typography>
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={3}
                    display={"flex"}
                    alignItems={"center"}
                    marginTop={md && "20px"}
                  >
                    <CInput
                      hideLabel
                      style={{ marginTop: "0px" }}
                      value={search}
                      onChange={(val) => {
                        setSearch(val?.trimLeft());
                        if (val?.trim() !== "") {
                          setTimeout(() => {
                            GetFaqs(val?.trim());
                          }, 500);
                        }
                        if (_.isEmpty(val)) {
                          GetFaqs(val?.trim());
                        }
                      }}
                      placeholder="Search"
                      fullWidth
                      startIcon={<CiSearch />}
                      search
                      endIcon={
                        !_.isEmpty(search) && (
                          <IoCloseOutline
                            onClick={() => {
                              setSearch("");
                              GetFaqs();
                            }}
                            style={{
                              cursor: !isMobile && !isTablet && "pointer",
                            }}
                          />
                        )
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              {loader ? (
                <Grid
                  container
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  style={{
                    height: "50vh",
                  }}
                >
                  <CircularProgress size={40} color="primary" />
                </Grid>
              ) : (
                <motion.div
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 50 }}
                  transition={{ duration: 0.3 }}
                >
                  {/* Existing FAQs Section */}
                  {!_.isEmpty(faqData) && (
                    <Grid item xs={12} sm={12} md={12} lg={12} mt={"20px"}>
                      <Grid
                        container
                        display={"flex"}
                        justifyContent={"center"}
                        width={"100%"}
                      >
                        {faqData?.map((data, index) => (
                          <Accordion
                            key={index}
                            expanded={expanded === `panel${index}`}
                            onChange={handleAccordionChange(`panel${index}`)}
                            style={{
                              width: "100%",
                              boxShadow: "0 0 1px 1px rgb(0 0 0 / 10%)",
                              borderRadius: "25px",
                              marginBottom: "20px",
                              marginTop:
                                expanded === `panel${index}` ? "0" : "",
                            }}
                            sx={{
                              "&:before": {
                                display: "none",
                              },
                            }}
                            ref={
                              index === faqData.length - 1
                                ? lastAccordionRef
                                : null
                            }
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls={`panel${index}-content`}
                              id={`panel${index}-header`}
                              sx={{ fontFamily: FontFamily.Bold }}
                            >
                              {data.title}
                            </AccordionSummary>
                            <AccordionDetails
                              style={{
                                maxHeight: "200px",
                                overflowY: "auto",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontFamily: FontFamily.Medium,
                                  fontSize: 14,
                                  color: BaseColor.grey,
                                }}
                              >
                                {data.description}
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        ))}
                        {/* This div ensures the last accordion scrolls into view */}
                        <div ref={lastAccordionRef} />
                      </Grid>
                    </Grid>
                  )}
                  {/* New Section for Terms and Privacy Policy */}
                  {isEmpty(search) && (
                    <>
                      <Typography
                        sx={{
                          color: BaseColor.primary,
                          fontFamily: FontFamily.GilbertQualifiDemo,
                          fontSize: 24,
                          marginLeft: "10px",
                        }}
                      >
                        Terms and Policies
                      </Typography>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        mt={"20px"}
                        paddingBottom={2}
                      >
                        <Grid container>
                          {/* Terms and Conditions */}
                          <Accordion
                            expanded={expanded === "terms"}
                            onChange={handleAccordionChange("terms")}
                            style={{
                              width: "100%",
                              boxShadow: "0 0 1px 1px rgb(0 0 0 / 10%)",
                              borderRadius: "25px",
                              marginBottom: "20px",
                              marginTop: expanded === "terms" ? "0" : "",
                            }}
                            sx={{
                              "&:before": {
                                display: "none",
                              },
                            }}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="terms-content"
                              id="terms-header"
                              sx={{ fontFamily: FontFamily.Bold }}
                            >
                              Terms and Conditions
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography
                                sx={{
                                  fontFamily: FontFamily.Medium,
                                  fontSize: 14,
                                  color: BaseColor.grey,
                                }}
                              >
                                {appBody}
                              </Typography>
                            </AccordionDetails>
                          </Accordion>

                          {/* Privacy Policy */}
                          <Accordion
                            expanded={expanded === "policy"}
                            onChange={handleAccordionChange("policy")}
                            style={{
                              width: "100%",
                              boxShadow: "0 0 1px 1px rgb(0 0 0 / 10%)",
                              borderRadius: "25px",
                              marginBottom: "20px",
                              marginTop: expanded === "policy" ? "0" : "",
                            }}
                            sx={{
                              "&:before": {
                                display: "none",
                              },
                            }}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="policy-content"
                              id="policy-header"
                              sx={{ fontFamily: FontFamily.Bold }}
                            >
                              Privacy Policy
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography
                                sx={{
                                  fontFamily: FontFamily.Medium,
                                  fontSize: 14,
                                  color: BaseColor.grey,
                                }}
                              >
                                {appBodyPolicy}
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </motion.div>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
