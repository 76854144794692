import { useState, useEffect } from "react";
import { isMobile, isIOS } from "react-device-detect";

const useViewportHeight = () => {
  const [vh, setVh] = useState(window.innerHeight * 0.01);

  useEffect(() => {
    const handleResize = () => {
      if (isMobile && isIOS) {
        setVh(window.innerHeight * 0.01);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }, [vh]);
};

export default useViewportHeight;
