import { MenuItem, Select, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import styles from "./styles";
import _, { isArray, isEmpty, isObject } from "lodash";
import PropTypes from "prop-types";
import BaseColor from "../Config/colors";
import theme, { FontFamily } from "../Config/theme";
import { isMobile, isTablet } from "react-device-detect";
import CloseIcon from "@mui/icons-material/Close";

export default function CSelect(props) {
  const {
    onSelect = () => {},
    label = "",
    key = "",
    fullObj = false,
    placeholder = "",
    disabled = false,
    data = [],
    errorMsg = "",
    value,
    readOnly = false,
    style,
    phoneCode,
    currencyCode,
    sortSelect = false,
    isMap = false,
    onClear = () => {},
  } = props;

  const md = useMediaQuery(theme.breakpoints.down("md"));
  const classes = styles();

  const clearIcon = () => {
    return (
      <CloseIcon
        onClick={() => {
          onClear();
        }}
        style={{
          cursor: !isMobile && !isTablet && "pointer",
          color: BaseColor.primary,
          zIndex: 1,
        }}
      />
    );
  };

  return (
    <div style={style}>
      {label && (
        <Typography
          sx={{
            color: BaseColor.textColor,
            fontFamily: FontFamily.SemiBold,
            marginTop: 2,
            fontSize: isMobile ? 14 : 16,
          }}
        >
          {label}
        </Typography>
      )}

      <Select
        sx={{
          boxShadow: sortSelect && md && "none",
          ".MuiOutlinedInput-notchedOutline": { border: sortSelect && md && 0 },
          "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            border: sortSelect && md && 0,
          },
          "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              border: sortSelect && md && 0,
            },
        }}
        value={value}
        size="small"
        error={!_.isEmpty(errorMsg)}
        displayEmpty
        onChange={(e) => {
          onSelect(e.target.value);
        }}
        endAdornment={
          !sortSelect && (
            <div
              style={{
                position: "absolute",
                top: "50%",
                right: isMobile ? "32px" : "35px",
                width: "1px",
                height: "25px",
                backgroundColor: BaseColor.greyBorder,
                transform: "translateY(-50%)",
                marginRight: isMobile ? "15px" : "17px",
              }}
            ></div>
          )
        }
        disabled={disabled}
        IconComponent={
          readOnly
            ? null
            : isMap && !_.isEmpty(value)
            ? clearIcon
            : KeyboardArrowDownOutlinedIcon
        }
        style={{
          height: 50,
          marginTop: label && "10px",
          background: sortSelect && md ? "#F5F5F5" : BaseColor.white,
          fontFamily: FontFamily.Medium,
          color: `${BaseColor.blackColor} !important`,
          borderRadius: "25px !important",
          width: sortSelect && md ? "30vw" : sortSelect && "145px",
          border: "none !important",
          "& .MuiSelect-select": {
            marginLeft: 10,
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: phoneCode || currencyCode ? "none !important" : "",
          },
        }}
        readOnly={readOnly}
        renderValue={(value) => {
          const name = !_.isEmpty(value)
            ? !_.isEmpty(key)
              ? value[key]
              : isObject(value)
              ? value.name
              : value
            : "";
          return (
            <Typography
              style={{
                fontFamily: FontFamily.Medium,
                fontSize: md ? "14px" : "16px",
                color: !_.isEmpty(name)
                  ? BaseColor.textColor
                  : !_.isEmpty(errorMsg)
                  ? BaseColor.errorRed
                  : BaseColor.grey,
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: sortSelect && md && "54px",
              }}
            >
              {!_.isEmpty(name) ? name : placeholder}
            </Typography>
          );
        }}
        className={classes.select}
        inputProps={{ "aria-label": "Without label" }}
        MenuProps={{
          sx: {
            "& .MuiSelect-root": {
              backgroundColor: BaseColor.pink,
              marginLeft: 15,
              marginRight: 1,
              borderRadius: 10,
            },
            "& .MuiPaper-root": {
              borderRadius: sortSelect ? "0px" : "20px",
              marginTop: "10px",
              border: `1px solid ${BaseColor.grey}`,
            },
          },
        }}
      >
        {/* Additional content before menu items */}
        {sortSelect && ( // Render only if no item is selected
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: "5px 16px",
            }}
          >
            <Typography
              style={{
                color: BaseColor.blackColor,
                fontFamily: FontFamily.Regular,
                fontSize: 12,
                marginRight: 5,
                fontWeight: 600,
              }}
            >
              Sort
            </Typography>
            <hr
              style={{
                flexGrow: 1,
                margin: "5px 0px 5px 6px",
              }}
            />
          </div>
        )}
        {!isEmpty(data) &&
          isArray(data) &&
          data.map((item, index) => {
            const name = !_.isEmpty(key)
              ? item[key]
              : phoneCode
              ? item.phone_code
              : item.name;
            const allValue = fullObj ? item : name;
            return (
              <MenuItem
                value={allValue}
                className={classes.menu}
                style={{
                  fontFamily: FontFamily.Medium,
                  color: BaseColor.blackColor,
                  fontSize: 16,
                }}
              >
                {sortSelect && (
                  <img
                    alt="dropdownImage"
                    src={item?.image}
                    style={{
                      height: "13.7px",
                      width: "15.5px",
                      marginRight: 5,
                    }}
                    draggable={false}
                  />
                )}
                {name}
              </MenuItem>
            );
          })}
      </Select>

      {!_.isEmpty(errorMsg) && (
        <div className={classes.errorMsgContainer}>
          <span className={classes.errorMsgText}>{errorMsg}</span>
        </div>
      )}
    </div>
  );
}

CSelect.propTypes = {
  onSelect: PropTypes.func,
  key: PropTypes.string,
  fullObj: PropTypes.bool,
  country: PropTypes.bool,
  placeholder: PropTypes.string,
  data: PropTypes.array,
  errorMsg: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  disabled: PropTypes.bool,
};
