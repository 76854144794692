import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import authActions from "./Redux/reducers/auth/actions";
import { store } from "./Redux/store/configureStore";

const firebaseConfig = {
  apiKey: "AIzaSyAOKQiBwn1MveIwjrItsUi0cFlCC4_CcuU",
  authDomain: "golf-goatt-cc03f.firebaseapp.com",
  projectId: "golf-goatt-cc03f",
  storageBucket: "golf-goatt-cc03f.appspot.com",
  messagingSenderId: "311086033613",
  appId: "1:311086033613:web:5649fa64005e6b0ee39784",
  measurementId: "G-MF935SLTTB",
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const askForPermissionToReceiveNotifications = async () => {
  try {
    const currentToken = await getToken(messaging, {
      vapidKey:
        "BLPdFNk0KjAjPiNYnV_8hLrNyBpMC6XVct_MP45Bq42UiHMSXBVLEPXY6GGif8XLFvkgZF8CJR80emx1liCFmWc",
    });
    if (currentToken) {
      console.log("current token for client: ", currentToken);
      store.dispatch(authActions.setUserUUID(currentToken));
    } else {
      console.log(
        "No registration token available. Request permission to generate one."
      );
    }
  } catch (err) {
    console.log("An error occurred while retrieving token. ", err);
  }
};

export function onMessageListener() {
  return new Promise((resolve, reject) => {
    onMessage(messaging, (payload) => {
      const notiData = payload;
      store.dispatch(authActions.setNotiData(notiData));
      store.dispatch(authActions.displayNotificationPopUp(true));
      setTimeout(() => {
        store.dispatch(authActions.displayNotificationPopUp(false));
      }, 5000);
    });
  });
}
