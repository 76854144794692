import { makeStyles } from "@mui/styles";
import BaseColor from "../../Config/colors";
import { isMobile, isTablet } from "react-device-detect";

const useStyles = makeStyles((theme) => ({
  headerMainCon: {
    width: "100%",
    backgroundColor: BaseColor.white,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "sticky",
    top: "0px",
    zIndex: 100,
  },

  headerMenu: {
    display: "flex",
    alignItems: "center",
    height: "100%",
  },

  drawerMenu: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "column",
  },

  logoContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    width: "70px",
    height: "70px",
    border: `1px solid ${BaseColor.grey}`,
    marginTop: "60px",
    marginLeft: "20px",
    padding: "10px",
  },

  menuLink: {
    textDecoration: "none",
    color: BaseColor.primary,
    borderBottom: "none", // Remove underline
    padding: "10px",
    cursor: !isMobile && !isTablet && "pointer",
    display: "flex",
  },
  headerSubCon: {
    display: "flex",
    justifyContent: "flex-start",
  },
  scrollBar: {
    // overflowX: "scroll",
    "&::-webkit-scrollbar": {
      width: 3,
      height: 3,
    },

    /* Track */
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 5px transperent",
      borderRadius: 10,
    },

    /* Handle */
    "&::-webkit-scrollbar-thumb": {
      background: BaseColor.primary,
      borderRadius: 10,
    },

    /* Handle on hover */
    "&::-webkit-scrollbar-thumb:hover": {
      boxShadow: "inset 0 0 5px grey",
    },
  },
}));

export default useStyles;
