import React, { useState, useEffect } from "react";
import styles from "./styles";
import {
  TextField,
  Typography,
  InputAdornment,
  Tooltip,
  Box,
  Popover,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import _ from "lodash";
import theme, { FontFamily } from "../../Config/theme";
import { isMobile } from "react-device-detect";
import BaseColor from "../../Config/colors.js";

const CInput = (props) => {
  const classes = styles();
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const {
    value = "",
    label = "",
    placeholder = "",
    size = "small",
    errorMsg = "",
    error = false,
    variant = "outlined",
    sx = {},
    onChange = () => {},
    multiline,
    style,
    rows,
    minRows,
    maxRows,
    maxLength,
    disabled = false,
    type = "",
    hide = false,
    errorMsgStyle = {},
    image = "",
    hideLabel = false,
    isRequired = false,
    endIcon,
    startIcon,
    search = false,
    handleKey = () => {},
    required = false,
    readOnly = false,
    addressBool = false,
    onBlur = () => {},
    onFocus = () => {},
  } = props;
  const [characterCount, setCharacterCount] = useState(value?.length);
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleTextChange = (val) => {
    setCharacterCount(val.length);
  };

  // Update character count when the value changes
  useEffect(() => {
    setCharacterCount(value?.length);
  }, [value]);

  const currentDate = new Date();
  const formattedCurrentDate = `${currentDate.getFullYear()}-${String(
    currentDate.getMonth() + 1
  ).padStart(2, "0")}-${String(currentDate.getDate()).padStart(2, "0")}`;

  return (
    <div className={classes.root}>
      <div className={classes.headerCon}>
        {!hideLabel && (
          <Typography
            sx={{
              color: BaseColor.textColor,
              fontFamily: FontFamily.SemiBold,
              fontSize: isMobile ? 14 : 16,
              marginTop: 2,
            }}
          >
            {label}
            {required && (
              <span
                style={{
                  color: BaseColor.errorRed,
                  fontSize: "16px",
                  fontFamily: FontFamily.Bold,
                }}
              >
                *
              </span>
            )}
          </Typography>
        )}
        {image ? (
          !md ? (
            <Tooltip
              enterTouchDelay={0}
              placement="bottom-end"
              componentsProps={{
                tooltip: {
                  sx: {
                    fontSize: 17,
                    backgroundColor: BaseColor.white,
                    color: BaseColor.red,
                    fontFamily: FontFamily.Medium,
                    boxShadow: " 0 0 10px 5px rgb(0 0 0 / 10%)",
                  },
                },
              }}
              title={
                <>
                  <Typography
                    style={{
                      fontSize: "13px",
                      fontFamily: FontFamily.Medium,
                      color: BaseColor.lightGray,
                    }}
                  >
                    Passwords must be a minimum of 8 characters. Including
                    letters, one number, one uppercase letter and special
                    characters.
                  </Typography>
                </>
              }
            >
              <img src={image} alt={"Golf"} className={classes.image} />
            </Tooltip>
          ) : (
            <>
              <IconButton onClick={handlePopoverOpen}>
                <img
                  src={image}
                  alt={"Golf"}
                  draggable={false}
                  className={classes.image}
                />
              </IconButton>
              <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <Typography
                  style={{
                    fontSize: "13px",
                    fontFamily: FontFamily.Medium,
                    color: BaseColor.lightGray,
                    width: "300px",
                    padding: "10px",
                  }}
                >
                  Passwords must be a minimum of 8 characters. Including
                  letters, one number, one uppercase letter and special
                  characters.
                </Typography>
              </Popover>
            </>
          )
        ) : null}
      </div>
      <TextField
        isRequired={isRequired}
        disableScrollLock
        placeholder={placeholder}
        variant={variant}
        size={size}
        error={error}
        disabled={disabled}
        value={hide ? "" : value}
        style={{ ...style }}
        multiline={multiline}
        rows={rows}
        minRows={minRows}
        maxRows={maxRows}
        type={type}
        onBlur={onBlur}
        onFocus={onFocus}
        sx={[
          {
            input: {
              color: BaseColor.textColor,
              "&::placeholder": {
                opacity: 1,
                fontFamily: FontFamily.Medium,
                fontSize: isMobile ? 14 : 16,
                color: !_.isEmpty(errorMsg)
                  ? BaseColor.errorRed
                  : BaseColor.grey,
              },
            },
            width: "100%",
            filter: hide && "blur(4px)",
            borderRadius: "50%",
            marginTop: "10px",
            boxShadow: type === "map" && "none",
            ".MuiOutlinedInput-notchedOutline": {
              border: type === "map" && "0.5px solid #004B76",
            },
            "& .MuiInputBase-inputMultiline": {
              paddingRight: multiline && "10px",
              color: BaseColor.textColor,
              "&::placeholder": {
                opacity: 1,
                fontFamily: FontFamily.Medium,
                fontSize: isMobile ? 14 : 16,
                color: !_.isEmpty(errorMsg)
                  ? BaseColor.errorRed
                  : BaseColor.grey,
              },
            },
          },
          sx,
        ]}
        onKeyDown={(evt) => {
          if (type === "number" && (evt.key === "-" || evt.key === "e")) {
            evt.preventDefault();
          }
          if (evt.key === "Enter") {
            handleKey();
          }
        }}
        onChange={(e) => {
          if (type === "number" && e.target.value >= 0) {
            onChange(e.target.value);
          } else {
            onChange(e.target.value);
            handleTextChange(e.target.value);
          }
        }}
        inputProps={{
          style: {
            fontSize: "16px",
            paddingLeft: "5px",
            overflow: "hidden",
            textOverflow: addressBool ? "" : "ellipsis",
            whiteSpace: "break-spaces",
          },
          maxLength: maxLength,
          inputMode: type === "number" && "numeric",
          pattern: type === "number" && "[0-9]*",
          max: formattedCurrentDate,
          min: type === "date" ? "1950-01-01" : type === "number" && 1,
        }}
        InputProps={{
          readOnly: readOnly,
          endAdornment: endIcon && (
            <InputAdornment
              position={multiline ? "end" : "start"}
              style={{ fontSize: 24, color: BaseColor.blackColor }}
            >
              {multiline ? (
                <span
                  style={{
                    fontSize: "14px",
                    position: "absolute",
                    right: 12,
                    bottom: 5,
                    color: BaseColor.placeHolderColor,
                    fontFamily: FontFamily.Medium,
                    backgroundColor: BaseColor.white,
                  }}
                >
                  {characterCount} / {maxLength}
                </span>
              ) : (
                <Box
                  sx={{
                    borderLeft: `1px solid ${BaseColor.greyBorder}`,
                    pl: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginLeft: 1,
                  }}
                >
                  {endIcon}
                </Box>
              )}
            </InputAdornment>
          ),
          startAdornment: startIcon && (
            <InputAdornment
              position="start"
              style={{
                fontSize: 18,
                color: BaseColor.textColor,
                fontFamily: FontFamily.Medium,
              }}
            >
              {search ? (
                startIcon
              ) : (
                <Box
                  sx={{
                    borderRight: "1px solid gray",
                    pr: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {startIcon}
                </Box>
              )}
            </InputAdornment>
          ),
        }}
      />

      {!_.isEmpty(errorMsg) && (
        <div className={classes.errorMsgContainer} style={errorMsgStyle}>
          <span className={classes.errorMsgText}>{errorMsg}</span>
        </div>
      )}
    </div>
  );
};

export default CInput;
