import React from "react";
import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import { usePlacesWidget } from "react-google-autocomplete";
import { Setting } from "../../Utils/Setting";
import BaseColor from "../../Config/colors";
import { CiLocationOn } from "react-icons/ci";
import { FontFamily } from "../../Config/theme";
import { isMobile, isTablet } from "react-device-detect";
import _ from "lodash";
import CloseIcon from "@mui/icons-material/Close";

function PlaceAutoComplete(props) {
  const {
    errorMsg = "",
    disabled = false,
    onChange = () => null,
    endIcon = false,
    value = "",
    notShowLabel = false,
    clearIcon = false,
    onClear = () => {},
    placeholder,
  } = props;

  const { ref } = usePlacesWidget({
    apiKey: Setting.googleMapApiKey,
    onPlaceSelected: (place) => {
      const latitude = place.geometry?.location?.lat();
      const longitude = place.geometry?.location?.lng();
      const stateComponent = place.address_components.find((component) =>
        component.types.includes("administrative_area_level_1")
      );
      const obj = {
        location: place?.formatted_address || "",
        lat: latitude,
        lng: longitude,
        state: stateComponent ? stateComponent.long_name : "", // Extracting state name if available
      };
      onChange(obj);
    },
    options: {
      types: ["geocode", "establishment"],
    },
  });

  const handleInputChange = (event) => {
    onChange({ location: event.target.value });
  };

  return (
    <>
      {!notShowLabel && (
        <Typography
          sx={{
            color: BaseColor.textColor,
            fontFamily: FontFamily.SemiBold,
            fontSize: isMobile ? 14 : 16,
            marginTop: 2,
          }}
        >
          {"Address"}
        </Typography>
      )}

      {endIcon ? (
        <TextField
          inputRef={ref}
          placeholder="Address"
          disabled={disabled}
          sx={{
            input: {
              color: BaseColor.textColor,
              "&::placeholder": {
                opacity: 1,
                fontFamily: FontFamily.Medium,
                fontSize: isMobile ? 14 : 16,
                color: !_.isEmpty(errorMsg)
                  ? BaseColor.errorRed
                  : BaseColor.grey,
              },
            },
            width: "100%",
            borderRadius: "50%",
            marginTop: "10px",
            "&::placeholder": {
              opacity: 1,
              fontFamily: FontFamily.Medium,
              fontSize: isMobile ? 14 : 16,
              color: !_.isEmpty(errorMsg) ? BaseColor.errorRed : BaseColor.grey,
            },
          }}
          inputProps={{
            style: {
              fontSize: "16px",
              paddingLeft: "5px",
            },
          }}
          InputProps={{
            endAdornment: endIcon && (
              <InputAdornment
                position={"start"}
                style={{ fontSize: 24, color: BaseColor.blackColor }}
              >
                <Box
                  sx={{
                    borderLeft: `1px solid ${BaseColor.greyBorder}`,
                    pl: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginLeft: 1,
                  }}
                >
                  <CiLocationOn color={BaseColor.textColor} />
                </Box>
              </InputAdornment>
            ),
          }}
        />
      ) : (
        <TextField
          inputRef={ref}
          placeholder={placeholder || "Address"}
          disabled={disabled}
          value={!endIcon && value}
          onChange={handleInputChange}
          sx={{
            input: {
              color: BaseColor.textColor,
              "&::placeholder": {
                opacity: 1,
                fontFamily: FontFamily.Medium,
                fontSize: isMobile ? 14 : 16,
                color: !_.isEmpty(errorMsg)
                  ? BaseColor.errorRed
                  : BaseColor.grey,
              },
            },
            width: "100%",
            borderRadius: "50%",
            marginTop: notShowLabel ? "30px" : "10px",
            "&::placeholder": {
              opacity: 1,
              fontFamily: FontFamily.Medium,
              fontSize: isMobile ? 14 : 16,
              color: !_.isEmpty(errorMsg) ? BaseColor.errorRed : BaseColor.grey,
            },
          }}
          inputProps={{
            style: {
              fontSize: "16px",
              paddingLeft: "5px",
            },
          }}
          InputProps={{
            endAdornment: endIcon && (
              <InputAdornment
                position={"start"}
                style={{ fontSize: 24, color: BaseColor.blackColor }}
              >
                <Box
                  sx={{
                    borderLeft: `1px solid ${BaseColor.greyBorder}`,
                    pl: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginLeft: 1,
                  }}
                >
                  <CiLocationOn color={BaseColor.textColor} />
                </Box>
              </InputAdornment>
            ),
            endAdornment: clearIcon && !_.isEmpty(value) && (
              <InputAdornment
                position={"end"}
                style={{ fontSize: 24, color: BaseColor.blackColor }}
              >
                <Box
                  sx={{
                    borderLeft: `1px solid ${BaseColor.greyBorder}`,
                    pl: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginLeft: 1,
                  }}
                >
                  <CloseIcon
                    onClick={() => {
                      onClear();
                    }}
                    style={{
                      cursor: !isMobile && !isTablet && "pointer",
                      color: BaseColor.primary,
                      zIndex: 1,
                    }}
                  />
                </Box>
              </InputAdornment>
            ),
          }}
        />
      )}

      {!_.isEmpty(errorMsg) && (
        <div
          style={{
            marginTop: 6,
            marginLeft: 3,
          }}
        >
          <span
            style={{
              fontSize: 14,
              color: BaseColor.errorRed,
              fontFamily: FontFamily.Medium,
            }}
          >
            {errorMsg}
          </span>
        </div>
      )}
    </>
  );
}

export default PlaceAutoComplete;
