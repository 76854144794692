import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store, persistor } from "./Redux/store/configureStore";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import { ThemeProvider } from "@mui/material/styles";
import theme, { FontFamily } from "./Config/theme";
import "./index.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPWAInstallProvider from "react-pwa-install";

ReactDOM.render(
  <React.StrictMode>
    <ReactPWAInstallProvider enableLogging>
      <Provider store={store}>
        {/* <MyProvider> */}
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={theme}>
            <App />
            <ToastContainer
              theme="light"
              className="toast_container"
              toastStyle={{
                fontSize: 16,
                fontFamily: FontFamily.Medium,
              }}
              bodyClassName={{ fontFamily: FontFamily.Regular }}
              position="top-right"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </ReactPWAInstallProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
