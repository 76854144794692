import React, { useEffect, useState } from "react";
import { Divider, Grid, Typography, useMediaQuery } from "@mui/material";
import CHeader from "../../Components/Header/index.js";
import theme from "../../Config/theme.js";
import BaseColor from "../../Config/colors.js";
import styles from "./styles.js";
import HomeComponent from "../HomeComponent/index.js";
import CIcon from "../../Components/CIcon/index.js";
import { sideBarTabs } from "../../Config/staticData.js";
import { store } from "../../Redux/store/configureStore.js";
import authActions from "../../Redux/reducers/auth/actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { isIOS, isMobile, isTablet } from "react-device-detect";
import { getAPIProgressData } from "../../Utils/APIHelper.js";
import { toast } from "react-toastify";
import { Setting } from "../../Utils/Setting.js";
import _ from "lodash";
import { socket } from "../../Context/ContextSocket";
import FeedbackModal from "../Feedback/index.js";
import { motion } from "framer-motion";

export default function Home() {
  // styles and media queries
  const classes = styles();
  const md = useMediaQuery(theme.breakpoints.down("md"));

  // navigation
  const navigate = useNavigate();
  const {
    auth: { activeTab },
  } = store.getState();

  //review data
  const [reviewData, setReviewData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  // Redux
  const { userData, isUnread } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const {
    setActiveHomeTab,
    setIsUnread,
    setAccessToken,
    setUserData,
    setIsAdmin,
    setChatMessages,
    setNotificationCount,
    setHomeBackLatLng,
  } = authActions;

  // url params
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (
      searchParams.has("token") &&
      searchParams.has("email") &&
      searchParams.has("isAdmin")
    ) {
      const token = searchParams.get("token");
      const email = searchParams.get("email");
      const isAdmin = searchParams.get("isAdmin") === "1" ? true : false;

      dispatch(setAccessToken(token));
      dispatch(setIsAdmin(isAdmin));
      getCommonData(email);
    }
  }, []);

  useEffect(() => {
    dispatch(setActiveHomeTab("Home"));
  }, []);

  // common data api call
  const getCommonData = async (email) => {
    let endPoints = Setting.endpoints.commonData;

    const params = {
      email: email,
    };

    try {
      const resp = await getAPIProgressData(endPoints, "POST", params);
      if (resp?.success) {
        if (!_.isEmpty(resp?.data)) {
          dispatch(setUserData({}));
          dispatch(setUserData(resp?.data));
          const isChatPage = searchParams.has("chat")
            ? searchParams.get("chat") === "1"
              ? true
              : false
            : false;
          if (isChatPage) {
            setTimeout(() => {
              navigate("/chat");
            }, 100);
          } else {
            setTimeout(() => {
              navigate("/profile");
            }, 100);
          }
          setSearchParams({}, { replace: true });
        }
      } else {
        toast(resp?.message, {
          type: "error",
        });
      }
    } catch (error) {
      toast(error.message, {
        type: "error",
      });
    }
  };

  // review modal data
  const getReviewModel = async (pageLoad) => {
    let endPoints = Setting.endpoints.getReviewModelList;

    try {
      const resp = await getAPIProgressData(endPoints, "POST", {}, true);
      if (resp && resp.success && resp.data && resp.data.length > 0) {
        resp.data[0].open = true;
        setReviewData(resp.data);
      } else {
        console.error("Response data is empty or undefined:", resp);
      }
    } catch (error) {
      toast(error.message, { type: "error" });
    }
  };

  useEffect(() => {
    userConnect();
    getReviewModel();
    getNotificationList();
  }, []);

  const handleModalClose = () => {
    setCurrentIndex((prevIndex) => prevIndex + 1);
  };

  // user connection use
  const userConnect = () => {
    const requestdata = {
      user_id: userData.id,
      type: "",
      chat_type: "s",
      page: 1,
    };
    socket.emit("user_connection", requestdata, (response) => {
      const hasUnread = response?.data?.conversations?.some(
        (conversation) => conversation?.unread > 0
      );
      if (!hasUnread) {
        dispatch(setIsUnread(hasUnread));
      } else {
        dispatch(setIsUnread(hasUnread));
      }

      if (response.status === false) {
      } else {
      }
    });
  };

  // notification list
  const getNotificationList = async () => {
    let endPoints = Setting.endpoints.notificationList;
    const data = {
      is_modal: 0,
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", data, true);
      if (resp?.success) {
        if (!_.isEmpty(resp?.data)) {
          let hasUnreadNotifications = false;

          resp.data.forEach((entry) => {
            if (
              entry.notification_list.some(
                (notification) => notification.is_read == 0
              )
            ) {
              hasUnreadNotifications = true;
              return;
            }
          });

          dispatch(setNotificationCount(hasUnreadNotifications));
        }
      } else {
        toast(resp?.message, {
          type: "error",
        });
      }
    } catch (error) {}
  };
  const [isZoomed, setIsZoomed] = useState(false);

  return (
    <Grid container style={{ backgroundColor: BaseColor.white }}>
      <CHeader />
      {md || isTablet || isMobile ? (
        <>
          <Grid item xs={0.5} sm={0.5} md={1} lg={1} />
          <Grid
            item
            xs={11}
            sm={11}
            md={10}
            lg={10}
            style={{
              height:
                (window.matchMedia("(display-mode: standalone)")?.matches ||
                  window?.navigator?.standalone) &&
                (isMobile || isTablet)
                  ? "calc(100vh - 150px )"
                  : "calc(100vh - 200px )",
              overflowY: "auto",
            }}
          >
            <Grid container height={"100%"}>
              <Grid
                item
                xs={12}
                style={{
                  overflowX: "auto",
                  height: "100%",
                  paddingBottom:
                    isMobile && isIOS ? "80px" : isTablet ? "50px" : "10px",
                }}
                className={(isMobile || isTablet) && classes.mainScrollBar}
              >
                <Divider style={{ marginTop: "6px" }} />
                <HomeComponent />
              </Grid>
            </Grid>

            <Grid
              container
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                position: "fixed",
                left: "0px",
                bottom: "0px",
                backgroundColor: BaseColor.white,
                zIndex: 1111,
                height: "80px",
              }}
            >
              {sideBarTabs?.map((data) => (
                <motion.div
                  whileTap={{ scale: data?.title === "Home" ? 0.8 : null }}
                  whileHover={{ scale: 0.9 }}
                  initial={{ scale: data?.title === "Home" ? 0.7 : null }}
                  animate={{
                    scale: isZoomed && data?.title === "Home" ? 1.1 : 1,
                  }}
                  key={data?.title}
                  style={{ position: "relative", textAlign: "center" }}
                >
                  <CIcon
                    src={
                      activeTab === data?.title ? data?.mobFillIcon : data?.icon
                    }
                    onClick={() => {
                      dispatch(setHomeBackLatLng({}));
                      if (data?.title === "Map") {
                        dispatch(setActiveHomeTab(data?.title));
                        navigate("/map");
                      }
                      if (data?.title === "Events") {
                        dispatch(setActiveHomeTab(data?.title));
                        navigate("/event");
                      }
                      if (data.title === "Chat") {
                        dispatch(setActiveHomeTab(data?.title));
                        navigate(data.path);
                      }
                    }}
                    size={35}
                  />
                  {isUnread && data?.title === "Chat" && (
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        backgroundColor: "green",
                        width: 12,
                        height: 12,
                        borderRadius: "50%",
                        zIndex: 1111,
                      }}
                    />
                  )}
                  <Typography className={classes.iconText}>
                    {data?.title}
                  </Typography>
                </motion.div>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={0.5} sm={0.5} md={1} lg={1} />
        </>
      ) : (
        <>
          <Grid item xs={0.5} sm={0.5} md={1} lg={1}>
            <Grid
              container
              style={{
                height: "calc(100vh - 90px)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              {sideBarTabs?.map((data) => (
                <>
                  <motion.div
                    className={classes.iconContainer}
                    style={{
                      backgroundColor:
                        activeTab === data?.title
                          ? BaseColor.primary
                          : "#EDF1F2",
                      position: "relative",
                    }}
                    onClick={() => {
                      dispatch(setHomeBackLatLng({}));
                      if (data?.title === "Map") {
                        dispatch(setActiveHomeTab(data?.title));
                        navigate("/map");
                      }
                      if (data?.title === "Events") {
                        dispatch(setActiveHomeTab(data?.title));
                        navigate("/event");
                      }
                      if (data.title === "Chat") {
                        dispatch(setActiveHomeTab(data?.title));
                        navigate(data.path);
                      }
                    }}
                    whileTap={{ scale: data?.title === "Home" ? 0.8 : null }}
                    whileHover={{ scale: 0.9 }}
                    initial={{ scale: data?.title === "Home" ? 0.7 : null }}
                    animate={{
                      scale: isZoomed && data?.title === "Home" ? 1.1 : 1,
                    }}
                    transition={{ duration: 0.4 }}
                  >
                    <CIcon
                      src={
                        activeTab === data?.title ? data?.fillIcon : data?.icon
                      }
                      size={35}
                      style={{
                        cursor: !isMobile && !isTablet && "pointer",
                      }}
                    />
                    {isUnread && data?.title === "Chat" && (
                      <div
                        style={{
                          position: "absolute",
                          top: 10,
                          right: 8,
                          backgroundColor: "green",
                          width: 12,
                          height: 12,
                          borderRadius: "50%",
                          zIndex: 1111,
                        }}
                      />
                    )}
                  </motion.div>
                  <Typography
                    className={classes.iconText}
                    style={{
                      color:
                        activeTab === data?.title
                          ? BaseColor.primary
                          : BaseColor.grey,
                      marginBottom: "20px",
                    }}
                  >
                    {data?.title}
                  </Typography>
                </>
              ))}
            </Grid>
          </Grid>
          <Grid
            items
            xs={11.5}
            sm={11.5}
            md={11}
            lg={11}
            paddingTop={"20px"}
            style={{ overflowX: "auto", height: "calc(100vh - 90px)" }}
            // className={classes.scrollBar}
          >
            <Grid container style={{ maxHeight: "calc(100vh - 90px)" }}>
              <Grid
                item
                xs={11.5}
                sm={11.5}
                md={11}
                lg={11}
                style={{ overflowX: "auto" }}
              >
                <HomeComponent />
              </Grid>
              <Grid item xs={0.5} sm={0.5} md={1} lg={1}></Grid>
            </Grid>
          </Grid>
        </>
      )}
      {reviewData.map((review, index) => (
        <FeedbackModal
          key={index}
          review={review}
          reviewData={reviewData}
          reviewModal={review?.open}
          setReviewData={setReviewData}
          currentIndex={currentIndex}
          onClose={handleModalClose}
        />
      ))}
    </Grid>
  );
}
