import React, { useEffect, useRef, useState } from "react";
import Files from "../../../Config/Files";
import styles from "./chatSection.styles";
import {
  Avatar,
  Box,
  Divider,
  CircularProgress,
  FormControlLabel,
  Grid,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Popover,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  useMediaQuery,
  Badge,
  Tooltip,
} from "@mui/material";
import _, { isEmpty, isNull } from "lodash";
import MoreVertTwoToneIcon from "@mui/icons-material/MoreVertTwoTone";
import CloseIcon from "@mui/icons-material/Close";
import { CiSearch } from "react-icons/ci";
import AddIcon from "@mui/icons-material/Add";
import { isIOS, isMobile, isTablet } from "react-device-detect";
import CheckIcon from "@mui/icons-material/Check";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import BaseColor from "../../../Config/colors";
import theme, { FontFamily } from "../../../Config/theme";
import ChatMessage from "./ChatMessage";
import SendMessageInput from "../../../Components/SendMessageInput";
import CModal from "../../../Components/CModal";
import CInput from "../../../Components/CInput";
import CButton from "../../../Components/CButton";
import authActions from "../../../Redux/reducers/auth/actions";
import { socket } from "../../../Context/ContextSocket";
import StyledBadge from "../../../Components/StyledBadge/StyledBadge";
import ReservationModel from "../../../Components/ReservationModel/ReservationModel";
import { Setting } from "../../../Utils/Setting";
import { getAPIProgressData } from "../../../Utils/APIHelper";
import { motion } from "framer-motion";
import CIcon from "../../../Components/CIcon";

export default function ChatSection(props) {
  const {
    openChat,
    setOpenChat,
    listType,
    onlineUsersList,
    particularUser,
    handleOpenParticularchat = () => {},
    setParticularUser,
    habdlegetChatList = () => {},
    changePageNo = () => {},
    isMore = false,
    pageNo,
    singleChatLoader,
    isTypingData1 = () => {},
    isMoreClick,
    updateList = () => null,
    style,
    chatData,
    particularUserChat,
    chatSectionLoader,
    changeTab = () => {},
    setParticularUserChat,
    dnd = false,
  } = props;

  // media queries and styles
  const classes = styles();
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  // data from redux
  const { setSelectedChatData, setCTab } = authActions;
  const dispatch = useDispatch();

  // reservation modal
  const [showReservationModel, setShowReservationModel] = useState({
    vis: false,
    data: [],
  });

  // create group
  const [createGroup, setCreateGroup] = useState(false);

  // view group member
  const [viewMember, setViewMember] = useState(false);

  //guest with members
  const [isSingle, setIsSingle] = useState(false);

  //conversation name
  const [newName, setNewName] = useState("");

  //clear chat
  const [clearChat, setClearChat] = useState(false);

  // keyboard open and close
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);

  useEffect(() => {
    if (!isKeyboardOpen) {
      window.scrollTo(0, 0);
    }
  }, [isKeyboardOpen]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [createGroup]);

  // block user
  const [blockUser, setBlockUser] = useState(false);

  //rename group name
  const [renameGroup, setRenameGroup] = useState(false);

  //report chat
  const [report, setReport] = useState(false);

  // exit from group
  const [exitGroup, setExitGroup] = useState(false);

  // open popover for chat actions
  const [anchorEl, setAnchorEl] = useState(null);

  //send message
  const [sendedMessage, setSendedMessage] = useState("");

  //group name
  const [groupName, setGroupName] = useState("");

  // get data from redux
  const { userData, selectedChatData, activeMenu, cTab } = useSelector(
    (state) => state.auth
  );
  const isAdmin = useSelector((state) => state.auth.isAdmin);

  // chat messages
  const chatMessages = useSelector((state) => state.auth.chatMessage);

  // Member Selection
  const [selectedAvatars, setSelectedAvatars] = useState(new Set());
  const [selectedValue, setSelectedValue] = useState("");
  const [isTypingData, setIsTypingData] = useState(null);
  const [selectedMembers, setSelectedMembers] = useState([]);

  // report user input
  const [inputValue, setInputValue] = useState("");

  // create group name
  const [createGroupName, setCreateGroupName] = useState("");

  // modals btn loaders
  const [reportBtnLoader, setReportBtnLoader] = useState(false);
  const [exitBtnLoader, setExitBtnLoader] = useState(false);
  const [blockUserBtnLoader, setBlockUserBtnLoader] = useState(false);
  const [clearChatBtnLoader, setClearChatBtnLoader] = useState(false);
  const [renameGroupBtnLoader, setRenameGroupBtnLoader] = useState(false);
  const [createGroupBtnLoader, setCreateGroupBtnLoader] = useState(false);

  const [memberList, setMemberList] = useState([]);
  const [groupMemberList, setGroupMemberList] = useState([]);
  const [membersLoader, setMembersLoader] = useState(false);

  const [verifyUserList, setVerifyUserList] = useState([]);
  const [verifyData, setVerifyData] = useState([]);

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const openCreateGroupModal = () => {
    if (cTab === "groups") {
      const array1Ids = groupMemberList.map((item) => Number(item.id));
      const NewList = verifyUserList.filter(
        (item) => !array1Ids.includes(Number(item.id))
      );
      setVerifyUserList(NewList);
      setCreateGroup(true);
    } else if (cTab === "personal") {
      const NewList = verifyUserList.filter(
        (item) => item?.id != selectedChatData?.receiver_id
      );
      setVerifyUserList(NewList);
      setCreateGroup(true);
    } else {
      setCreateGroup(true);
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event);
  };
  const handleAvatarClick = (index) => {
    const newSelectedAvatars = new Set(selectedAvatars);
    if (newSelectedAvatars.has(index)) {
      newSelectedAvatars.delete(index);
    } else {
      newSelectedAvatars.add(index);
    }
    setSelectedAvatars(newSelectedAvatars);
  };
  // End

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Scroll To bottom
  const chatContainerRef = useRef(null);

  const scrollToBottom = () => {
    const chatContainer = chatContainerRef.current;
    if (chatContainer) {
      chatContainer.scrollTop = chatContainer.scrollHeight;
    }
  };

  useEffect(() => {
    if (!isMoreClick) {
      scrollToBottom();
    }
  }, [openChat, sendedMessage, props.particularUserChat, isMoreClick]);

  useEffect(() => {
    setNewName(chatData?.conv_name);
    setGroupName(chatData?.conv_name);
  }, [chatData]);

  useEffect(() => {
    getVerifyUsersList();
    getGroupMembersList(true);
  }, [openChat, props.particularUserChat]);

  //rename groupname
  function renameGroupname() {
    setRenameGroupBtnLoader(true);
    const requestData = {
      conversation_id: particularUser?.conversation_id,
      new_name: groupName,
      user_id: userData?.id,
    };

    socket.emit("rename_conversation", requestData, (data) => {
      if (data.status) {
        handleOpenParticularchat(
          data?.data?.conversations[0],
          1,
          false,
          activeMenu === "archiveList" ? "archiveList" : "send"
        );
        setNewName(data?.data?.conversations[0]?.conv_name);
        setGroupName("");
        setRenameGroupBtnLoader(false);
        setRenameGroup(false);
      } else {
        setRenameGroupBtnLoader(false);
      }
    });
  }

  // scroll end
  function handleClearChat() {
    setClearChatBtnLoader(true);
    const requestData = {
      conversation_id: particularUser?.conversation_id,
      user_id: userData.id,
    };

    socket.emit("clear_chat", requestData, (data) => {
      if (data.status === true) {
        handleOpenParticularchat(selectedChatData, 1, false, "send");
        if (activeMenu === "archiveList") {
          updateList("unarchive");
        } else if (activeMenu === "blockList") {
          updateList("block");
        } else {
          updateList("clear");
        }
        setClearChatBtnLoader(false);
        setClearChat(false);
      } else {
        setClearChatBtnLoader(false);
      }
      setClearChatBtnLoader(false);
    });
  }

  // this function for block user
  function handleBlockUser() {
    setClearChat(false);
    setBlockUserBtnLoader(true);
    const requestData = {
      conversation_id: particularUser?.conversation_id,
      block_user_id: openChat?.data?.receiver_id,
      block_by_user_id: userData?.id,
    };

    socket.emit("block_chat", requestData, (data) => {
      if (data.status === false) {
        toast(data?.message, {
          type: "error",
        });
        setBlockUserBtnLoader(false);
      } else {
        if (activeMenu === "archiveList") {
          updateList("unarchive");
        } else {
          updateList("block");
        }
        dispatch(setSelectedChatData({}));
        setOpenChat({ open: false, data: {} });
        setParticularUser("");
        setBlockUserBtnLoader(false);
      }
      setBlockUser(false);
      setBlockUserBtnLoader(false);
    });
  }

  // this function for archive user
  function archiveUser() {
    const requestdata = {
      user_id: userData.id,
      conversation_id: particularUser?.conversation_id,
    };
    socket.emit("archive_chat", requestdata, (response) => {
      if (response.status === false) {
        toast(response?.message, {
          type: "error",
        });
      } else {
        updateList("archive");
        dispatch(setSelectedChatData({}));
        setOpenChat({ open: false, data: {} });
        setParticularUser("");
      }
    });
  }

  function handleSubmitReport() {
    setReportBtnLoader(true);
    setClearChat(false);
    const requestData = {
      conversation_id: particularUser?.conversation_id,
      report_type:
        selectedValue == "inappropriateBehavior"
          ? 0
          : selectedValue == "cheatingViolations"
          ? 1
          : selectedValue == "safetyConcerns"
          ? 2
          : selectedValue == "somethingElse"
          ? 3
          : null,
      user_id: particularUser?.owner_id,
      description: selectedValue === "somethingElse" ? inputValue : "",
    };

    socket.emit("report_chat", requestData, (data) => {
      if (data?.status) {
        toast(data?.message, {
          type: "success",
        });
        setBlockUser(false);
        setSelectedValue("");
        setInputValue("");
        setReportBtnLoader(false);
      } else {
        toast(data?.message, {
          type: "error",
        });
        setReportBtnLoader(false);
      }
      setReportBtnLoader(false);
    });
  }
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // unblock
  function handleRemoveFromBlockList() {
    const requestdata = {
      conversation_id: particularUser.conversation_id,
      block_user_id: particularUser.receiver_id,
      block_by_user_id: userData.id,
    };

    socket.emit("unblock_chat", requestdata, (response) => {
      if (response.status === false) {
      } else {
        updateList("unblock");
        handleClose();
        setParticularUser("");
        setOpenChat({ open: false, data: {} });
      }
    });
  }

  //un archived
  function unarchiveUser() {
    const requestdata = {
      user_id: userData.id,
      conversation_id: particularUser?.conversation_id,
    };

    socket.emit("unarchive_chat", requestdata, (response) => {
      if (response.status === false) {
      } else {
        updateList("unarchive");
        dispatch(setSelectedChatData({}));
        setOpenChat({ open: false, data: {} });
        setParticularUser("");
        setAnchorEl(null);
      }
    });
  }

  // exit the group
  const exitFromGroup = () => {
    setExitBtnLoader(true);
    const requestData = {
      user_id: userData.id,
      conversation_id: particularUser?.conversation_id,
    };
    socket.emit("exit_group", requestData, (response) => {
      if (response?.status) {
        toast(response?.message, {
          type: "success",
        });
        setExitBtnLoader(false);
        setExitGroup(false);
        if (activeMenu === "blockList") {
          updateList("unblock");
        } else {
          updateList("groups");
        }
        dispatch(setSelectedChatData({}));
        setOpenChat({ open: false, data: {} });
        setParticularUser("");
      } else {
        toast(response?.message, {
          type: "error",
        });
        setExitBtnLoader(false);
      }
      setExitBtnLoader(false);
    });
  };

  // members list in groups
  function getGroupMembersList(group = false) {
    setMembersLoader(true);
    const requestdata = {
      conversation_id: particularUser?.conversation_id,
    };
    socket.emit("get_conversation_users", requestdata, (response) => {
      if (response.status === false) {
        setMembersLoader(false);
      } else {
        if (!_.isEmpty(response?.users)) {
          if (group) {
            setGroupMemberList(response?.users);
          } else {
            setMemberList(response?.users);
          }
        }
        setMembersLoader(false);
      }
    });
  }
  const isHost = chatData?.host_user_ids?.find((e) => e == userData?.id);

  const [searchQuery, setSearchQuery] = useState("");

  const filteredData = verifyUserList?.filter((member) =>
    member?.nick_name?.toLowerCase()?.includes(searchQuery?.toLowerCase())
  );

  const getVerifyUsersList = async () => {
    let endPoints = Setting.endpoints.verifyUserList;

    let data = {};

    try {
      const resp = await getAPIProgressData(endPoints, "POST", data, true);
      if (resp?.success) {
        if (!_.isEmpty(resp?.data) && _.isArray(resp?.data)) {
          setVerifyUserList(resp?.data);
          setVerifyData(resp?.data);
        } else {
          setVerifyUserList([]);
        }
      } else {
        toast(resp?.message, {
          type: "error",
        });
      }
    } catch (error) {
      toast(error.message, { type: "error" });
    }
  };

  // create group for personal
  const addGroupForPersonal = () => {
    setCreateGroupBtnLoader(true);
    const newmemeberId = Array?.from(selectedAvatars);
    newmemeberId.push(userData?.id);
    if (!newmemeberId.includes(particularUser?.receiver_id)) {
      newmemeberId.push(particularUser?.receiver_id);
    }

    const requestData = {
      s_id: userData.id,
      r_id: newmemeberId,
      trip_id: "",
      trip_type: "g",
      con_name: createGroupName,
    };
    socket.emit("create_conversation", requestData, (response) => {
      if (response?.status) {
        toast(response?.message, {
          type: "success",
        });
        const requestdata = {
          file_type: "text",
          s_id: userData?.id,
          c_id: response.data.conv_id,
          msg: chatMessages?.groupChat,
        };
        socket.emit("send_message", requestdata, (response) => {
          if (response?.status) {
            dispatch(setCTab("groups"));
            changeTab();
            updateList();
            dispatch(setSelectedChatData({}));
            setParticularUser({});
            setParticularUserChat([]);
            setOpenChat({ open: false, data: {} });
          }
        });
        setCreateGroupBtnLoader(false);
        setCreateGroupName("");
        setCreateGroup(false);
        if (isMobile && isIOS) {
          window.scrollTo(0, 0);
        }
        setSelectedAvatars();
      } else {
        toast(response?.message, {
          type: "error",
        });
        setCreateGroupBtnLoader(false);
      }
      setCreateGroupBtnLoader(false);
    });
  };

  // create group for group
  const addGroup = () => {
    setCreateGroupBtnLoader(true);
    const requestData = {
      user_ids: Array?.from(selectedAvatars),
      conversation_id: particularUser?.conversation_id,
      con_name: createGroupName,
    };
    socket.emit("add_members_to_conversation", requestData, (response) => {
      if (response?.status) {
        toast(response?.message, {
          type: "success",
        });
        setCreateGroupBtnLoader(false);
        setCreateGroup(false);
        if (isMobile && isIOS) {
          window.scrollTo(0, 0);
        }
        setSelectedAvatars();
        handleOpenParticularchat(
          { conversation_id: response?.data?.conversation_id },
          1,
          false,
          activeMenu === "archiveList" ? "archiveList" : "send"
        );
      } else {
        toast(response?.message, {
          type: "error",
        });
        setCreateGroupBtnLoader(false);
      }
      setCreateGroupBtnLoader(false);
    });
  };
  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 50 }}
      transition={{ duration: 0.4 }}
      style={([style], { width: "100%", height: "100%" })}
    >
      {chatSectionLoader ? (
        <Grid
          height={"calc(100vh - 90px)"}
          width={"100%"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={"column"}
        >
          <CircularProgress color="primary" size={35} />
        </Grid>
      ) : !isEmpty(particularUser) ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
        >
          <Grid
            height={isMobile && isIOS ? "calc(100vh - 90px)" : "100%"}
            width={"100%"}
            display={"flex"}
            sx={{
              backgroundImage: `url(${Files.Images.chatBackground}) !important`,
            }}
            flexDirection={"column"}
          >
            <div
              className={classes.profile_div}
              style={{ marginLeft: !md && "4px" }}
            >
              <div className={classes.profile_container}>
                <ListItemAvatar
                  sx={{
                    margin: isMobile
                      ? "10px 10px 10px -10px"
                      : "15px 15px 15px 0",
                    position: "relative",
                  }}
                  className={classes.profile}
                >
                  {md && (
                    <CIcon
                      src={Files.svgIcons.BackArrowIcon}
                      size={20}
                      onClick={() => {
                        setOpenChat({ open: false, data: {} });
                        dispatch(setSelectedChatData({}));
                      }}
                    />
                  )}

                  {onlineUsersList?.includes(
                    Number(openChat.data?.receiver_id)
                  ) ? (
                    <StyledBadge
                      overlap="circular"
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      variant="dot"
                    >
                      <Avatar
                        alt={openChat.data?.name}
                        sx={{ width: md ? 40 : 50, height: md ? 40 : 50 }}
                        src={
                          !_.isEmpty(openChat.data?.photo)
                            ? openChat.data.photo
                            : cTab === "groups"
                            ? Files.Images.groupAvatar
                            : "/img"
                        }
                      />
                    </StyledBadge>
                  ) : (
                    <Avatar
                      alt={openChat.data?.name}
                      sx={{ width: md ? 40 : 50, height: md ? 40 : 50 }}
                      src={
                        !_.isEmpty(openChat.data?.photo)
                          ? openChat.data.photo
                          : cTab === "groups"
                          ? Files.Images.groupAvatar
                          : "/img"
                      }
                    />
                  )}
                  <Typography
                    fontSize={md ? "16px" : "20px"}
                    component={"span"}
                    style={{
                      flexDirection: "column",
                      display: "flex",
                    }}
                    color={BaseColor.blackColor}
                    fontFamily={FontFamily.GilbertQualifiDemo}
                  >
                    {isMobile ? (
                      <Tooltip
                        enterTouchDelay={0}
                        placement="top-start"
                        componentsProps={{
                          tooltip: {
                            sx: {
                              fontSize: 17,
                              backgroundColor: BaseColor.white,
                              color: BaseColor.red,
                              fontFamily: FontFamily.Medium,
                              boxShadow: " 0 0 10px 5px rgb(0 0 0 / 10%)",
                            },
                          },
                        }}
                        title={
                          <Typography
                            fontSize={14}
                            component={"span"}
                            color={"black"}
                            fontFamily={FontFamily.SemiBold}
                          >
                            {!isEmpty(newName)
                              ? newName
                              : !isEmpty(particularUser?.username)
                              ? particularUser?.username
                              : !_.isEmpty(particularUser?.username)
                              ? particularUser?.username?.join(", ")
                              : particularUser?.conv_name}
                          </Typography>
                        }
                      >
                        <span
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            textWrap: "nowrap",
                            width: sm ? "35vw" : !md ? "30vw" : "60vw",
                            userSelect: "none",
                          }}
                        >
                          {!isEmpty(newName)
                            ? newName
                            : !isEmpty(particularUser?.username)
                            ? particularUser?.username
                            : !_.isEmpty(particularUser?.username)
                            ? particularUser?.username?.join(", ")
                            : particularUser?.conv_name}
                        </span>
                      </Tooltip>
                    ) : (
                      <span
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          textWrap: "nowrap",
                          width: sm ? "35vw" : !md ? "30vw" : "60vw",
                          userSelect: "none",
                        }}
                      >
                        {!isEmpty(newName)
                          ? newName
                          : !isEmpty(particularUser?.username)
                          ? particularUser?.username
                          : !_.isEmpty(particularUser?.username)
                          ? particularUser?.username?.join(", ")
                          : particularUser?.conv_name}
                      </span>
                    )}

                    {isNull(isTypingData) && cTab !== "groups" && (
                      <span
                        style={{
                          color: BaseColor.primary,
                          fontSize: 10,
                          fontFamily: FontFamily.Bold,
                        }}
                      >
                        {particularUser?.primaryClubName?.name || ""}
                      </span>
                    )}

                    {/* Typing indicator */}
                    {!isNull(isTypingData) &&
                      isTypingData?.c_id == particularUser?.conversation_id && (
                        <span
                          style={{
                            color: BaseColor.primary,
                            fontSize: 14,
                            fontFamily: FontFamily.Bold,
                            marginLeft: 5, // Adjust spacing as needed
                          }}
                        >
                          is typing...
                        </span>
                      )}
                    {dnd && (
                      <Typography
                        variant="contained"
                        color="secondary"
                        style={{
                          color: BaseColor.primary,
                          fontSize: isMobile ? "12px" : "14px",
                          fontFamily: FontFamily.Medium,
                          verticalAlign: "center",
                        }}
                      >
                        ⚠️{" "}
                        {!isEmpty(newName)
                          ? newName
                          : !isEmpty(particularUser?.username)
                          ? particularUser?.username
                          : !_.isEmpty(particularUser?.username)
                          ? particularUser?.username?.join(", ")
                          : particularUser?.conv_name}{" "}
                        has enabled "Do Not Disturb" mode and will not receive
                        messages at this time
                      </Typography>
                    )}
                  </Typography>
                </ListItemAvatar>
                <ListItemText primary={<div></div>} />
              </div>

              <div style={{ display: "flex", alignItems: "center" }}>
                {!isAdmin && activeMenu !== "blockList" && (
                  <img
                    src={Files.Images.addMember}
                    alt="addMember"
                    onClick={() => {
                      openCreateGroupModal();
                    }}
                    style={{ cursor: "pointer" }}
                    draggable={false}
                  />
                )}
                {!isAdmin && (
                  <MoreVertTwoToneIcon
                    className={classes.menuCss}
                    sx={{
                      fontSize: "34px",
                      marginLeft: md ? "10px" : "34px",
                      "&:hover": {
                        cursor: "pointer",
                      },
                    }}
                    onClick={handleClick}
                  />
                )}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                id="chat-container"
                ref={chatContainerRef}
                className={`custom-scrollbar-horizontal ${classes.chat_container}`}
                style={{
                  maxHeight:
                    isMobile && isIOS ? "62vh" : isMobile ? "67vh" : "68vh",
                  overflowY: "auto",
                }}
              >
                {userData?.id == isHost && activeMenu !== "blockList" && (
                  <CButton
                    onClick={() => {
                      setShowReservationModel({
                        vis: true,
                        data: [],
                      });
                      if (cTab === "personal") {
                        setSelectedMembers([
                          {
                            default_quantity: 0,
                            id: openChat.data.receiver_id,
                            isSelected: true,
                            profile_pic: null,
                            profile_pic_url: !isNull(openChat.data.photo)
                              ? openChat.data.photo
                              : "https://golf.teamgroovy.com/img_assets/boy.jpeg",
                            username: openChat.data.conv_name,
                            nick_name: openChat?.data?.username,
                          },
                        ]);
                      }
                    }}
                    btnStyle={{ width: "9vw" }}
                    variant="contained"
                    color="secondary"
                    style={{
                      marginTop: "2vh",
                      height: "30px",
                      zIndex: 100,
                      position: "fixed",
                      border: "none",
                      width: "fit-content",
                      padding: "10px 15px",
                      boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.5)",
                    }}
                    children={
                      <Typography
                        fontSize={"13px"}
                        component={"span"}
                        color={BaseColor.blackColor}
                        fontFamily={FontFamily.SemiBold}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "3px",
                        }}
                      >
                        <AddIcon style={{ fontSize: "16px" }} /> Create
                        Reservation
                      </Typography>
                    }
                  />
                )}

                {isMore && (
                  <div
                    style={{
                      display: "flex",
                      alignSelf: "center",
                      width: "auto",
                      cursor: "pointer",
                      marginBottom: 15,
                      marginTop: 25,
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      changePageNo(pageNo + 1);
                      handleOpenParticularchat(
                        particularUser,
                        pageNo + 1,
                        true
                      );
                    }}
                  >
                    {singleChatLoader ? (
                      <CircularProgress
                        size={18}
                        style={{ marginTop: "20px" }}
                      />
                    ) : (
                      <span
                        style={{
                          fontSize: 14,
                          fontFamily: FontFamily.Medium,
                          color: BaseColor.primary,
                          marginTop: "10px",
                        }}
                      >
                        Load More
                      </span>
                    )}
                  </div>
                )}
                {props.particularUserChat.map((msg, index) => (
                  <>
                    <ChatMessage
                      chatData={chatData}
                      listType={cTab}
                      key={index}
                      message={msg.text}
                      isSender={msg.sender_id == userData.id}
                      messageType={msg.type}
                      mediaUrl={msg.mediaUrl}
                      timestamp={msg.updatedAt}
                      messageData={msg}
                      showMembers={(data) => {
                        setMemberList(data);
                        setViewMember(true);
                        setIsSingle(true);
                      }}
                      editReservation={(data) => {
                        console.log("data1234567", data);
                        setShowReservationModel({
                          vis: true,
                          data: data,
                        });
                        const newArr = data?.usersDetails.filter((data) => {
                          return userData?.id != data?.id;
                        });

                        let memberArr = [];
                        newArr.map((data) => {
                          memberArr.push({
                            default_quantity: data?.guest_cnt,
                            id: data?.id,
                            isSelected: true,
                            profile_pic: null,
                            profile_pic_url:
                              data?.profile_pic ||
                              "https://golf.teamgroovy.com/img_assets/boy.jpeg",
                            username: openChat?.data?.conv_name,
                            nick_name: data?.username,
                          });
                        });

                        setSelectedMembers(memberArr);
                      }}
                      index={index}
                      afterRejectCall={() => {
                        handleOpenParticularchat(
                          selectedChatData,
                          1,
                          false,
                          "send"
                        );
                      }}
                    />
                  </>
                ))}
              </div>
            </div>

            <div
              className={classes.inputMessage}
              style={{ width: md ? "96vw" : "52.75vw" }}
            >
              {activeMenu !== "blockList" && !isAdmin && (
                <SendMessageInput
                  setSendedMessage={setSendedMessage}
                  handlePageNo={() => {
                    changePageNo(1);
                  }}
                  handleIsTyping={(data) => {
                    if (data?.c_id === particularUser?.conversation_id) {
                      isTypingData1(data);
                      setIsTypingData(data);
                    } else {
                      setIsTypingData(null);
                      isTypingData1(null);
                    }
                  }}
                  particularUser={particularUser}
                  handleOpenParticularchat={props.handleOpenParticularchat}
                  habdlegetChatList={habdlegetChatList}
                  onBlur={() => {
                    setIsKeyboardOpen(false);
                  }}
                  isDnd={dnd}
                  onFocus={() => {
                    setIsKeyboardOpen(true);
                  }}
                />
              )}
            </div>
          </Grid>
        </motion.div>
      ) : (
        <Grid
          height={"calc(100vh - 90px)"}
          width={"100%"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={"column"}
        >
          <img src={Files.Images.emptyChatBg} alt="logo" draggable={false} />
          <Typography className={classes.emptyText}>Empty</Typography>
          <Typography className={classes.emptyText2}>
            You have no chats yet.
          </Typography>
        </Grid>
      )}

      {/* Create Group Modal */}

      <CModal
        visible={createGroup}
        onClose={() => {
          setCreateGroup(false);
          setSearchQuery("");
          setSelectedAvatars();
          setVerifyUserList(verifyData);
          setCreateGroupName("");
          if (isMobile && isIOS) {
            window.scrollTo(0, 0);
          }
        }}
        children={
          <div
            className={classes.modal_container}
            style={{
              position: "relative",
              width: sm ? "70vw" : "500px",
              maxHeight: "80vh",
              padding: "40px",
              overflowY: "auto",
            }}
          >
            <CloseIcon
              onClick={() => {
                setCreateGroup(false);
                setSelectedAvatars();
                setSearchQuery("");
                setVerifyUserList(verifyData);
                setCreateGroupName("");
                if (isMobile && isIOS) {
                  window.scrollTo(0, 0);
                }
              }}
              style={{
                position: "absolute",
                top: 10,
                right: 15,
                fontSize: 30,
                cursor: "pointer",
                color: BaseColor.primary,
                zIndex: 1,
              }}
            />
            <div className={classes.modal_create}>
              <Typography
                fontFamily={FontFamily.GilbertQualifiDemo}
                fontSize={"24px"}
                textAlign={"center"}
              >
                {cTab === "groups" ? "Add Members" : " Create Group"}
              </Typography>
              {sm && <Divider style={{ marginTop: "1vh" }} />}

              {cTab !== "groups" && (
                <CInput
                  onChange={(val) => {
                    setCreateGroupName(val);
                  }}
                  placeholder="Enter group name"
                  fullWidth
                  value={createGroupName}
                  onFocus={() => {
                    setIsKeyboardOpen(true);
                  }}
                  onBlur={() => {
                    setIsKeyboardOpen(false);
                  }}
                />
              )}

              <CInput
                onChange={(val) => {
                  setSearchQuery(val);
                }}
                placeholder="Search Members"
                fullWidth
                startIcon={<CiSearch />}
                search
                value={searchQuery}
                onFocus={() => {
                  setIsKeyboardOpen(true);
                }}
                onBlur={() => {
                  setIsKeyboardOpen(false);
                }}
              />

              {!_.isEmpty(filteredData) ? (
                <div
                  className={
                    sm
                      ? classes.avatar_responsive_container
                      : classes.avatar_container
                  }
                  style={{
                    overflowY: "auto",
                    maxHeight: "250px",
                  }}
                >
                  {filteredData.map((contact) => (
                    <Box
                      key={contact}
                      sx={{
                        textAlign: "center",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        width: "72px",
                        height: "110px",
                        overflow: "hidden",
                      }}
                    >
                      <Box position="relative">
                        <Avatar
                          alt={contact.username}
                          sx={{
                            width: 65,
                            height: 65,
                            cursor: "pointer",
                            backgroundColor: selectedAvatars?.has(contact.id)
                              ? "#042b48b5" // blue color with 70% opacity
                              : "",
                            transition: "background-color 0.3s ease",
                          }}
                          src={contact.profile_pic_url}
                          selected={selectedAvatars?.has(contact.id)}
                          onClick={() => {
                            if (contact?.is_blocked) {
                              toast(
                                "You cannot select this user as you have been blocked by them.",
                                {
                                  type: "info",
                                }
                              );
                            } else {
                              handleAvatarClick(contact.id);
                            }
                          }}
                          className={classes.avatar}
                        />{" "}
                        <Typography
                          fontSize={"14px"}
                          component={"span"}
                          color={BaseColor.blackColor}
                          fontFamily={FontFamily.SemiBold}
                          style={{
                            wordBreak: "break-word",
                            userSelect: "none",
                          }}
                        >
                          {contact.nick_name}
                        </Typography>
                        {selectedAvatars?.has(contact.id) && (
                          <Box
                            sx={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              width: "66px",
                              height: "66px",
                              backgroundColor: "#042b48b5",
                              zIndex: 1,
                              opacity: 0.5,
                              borderRadius: "50%",
                            }}
                          />
                        )}
                        {selectedAvatars?.has(contact.id) && (
                          <Box
                            sx={{
                              position: "absolute",
                              top: 35,
                              left: 35,
                              transform: "translate(-50%, -50%)",
                              color: "white",
                              cursor: "pointer",
                              zIndex: 2,
                            }}
                            onClick={() => {
                              handleAvatarClick(contact.id);
                            }}
                          >
                            <CheckIcon sx={{ fontSize: 40 }} />
                          </Box>
                        )}
                      </Box>
                    </Box>
                  ))}
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "20px",
                    height: "250px",
                  }}
                >
                  <Typography sx={{ color: BaseColor.blackColor }}>
                    No group member found
                  </Typography>
                </div>
              )}
            </div>
            {selectedAvatars?.size > 0 && (
              <CButton
                variant="contained"
                color="primary"
                style={{ marginTop: "10px" }}
                onClick={() => {
                  if (cTab === "groups") {
                    addGroup();
                  } else {
                    addGroupForPersonal();
                  }
                }}
                disabled={createGroupName?.trim() === "" && cTab !== "groups"}
                loading={createGroupBtnLoader}
                children={
                  <Typography
                    fontSize={"14px"}
                    component={"span"}
                    color={BaseColor.whiteColor}
                    fontFamily={FontFamily.GilbertQualifiDemo}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "3px",
                    }}
                  >
                    Add
                  </Typography>
                }
              />
            )}
          </div>
        }
      />

      {/* End */}

      {/* Reservation Modal */}

      <ReservationModel
        reservationModal={showReservationModel}
        setReservationModal={setShowReservationModel}
        editData={showReservationModel?.data}
        selectedMembers={selectedMembers}
        setSelectedMembers={setSelectedMembers}
        conversation_id={particularUser?.conversation_id}
        handleOpenParticularchat={() => {
          handleOpenParticularchat(selectedChatData, 1, false, "send");
        }}
      />

      {/* End */}

      {/* Popover */}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Grid
          style={{
            backgroundColor: BaseColor.white,
            padding: "12px 16px",
            borderRadius: 10,
          }}
        >
          {/* {cTab !== "groups" && ( */}
          <ListItemButton
            style={{
              marginLeft: -8,
              fontSize: 16,
              fontFamily: FontFamily.Medium,
              color: BaseColor.blackColor,
              gap: "10px",
            }}
            onClick={() => {
              if (activeMenu === "blockList") {
                handleRemoveFromBlockList();
              } else {
                setBlockUser(true);
                handleClose();
              }
            }}
          >
            <img
              alt="archive-icon"
              width={"20px"}
              src={Files.pngIcons.blockIcon}
              draggable={false}
            />{" "}
            <span className={classes.popOverText}>
              {activeMenu === "blockList" ? "Unblock" : "Block"}
            </span>
          </ListItemButton>
          {/* )} */}
          {cTab === "groups" && (
            <ListItemButton
              style={{
                marginLeft: -8,
                fontSize: 16,
                fontFamily: FontFamily.Medium,
                color: BaseColor.blackColor,
                gap: "10px",
              }}
              onClick={() => {
                setViewMember(true);
                setIsSingle(false);
                getGroupMembersList();
                handleClose();
              }}
            >
              <img
                alt="profile-icon"
                width={"20px"}
                src={Files.pngIcons.profileIcon}
                draggable={false}
              />
              <span className={classes.popOverText}>View Members</span>
            </ListItemButton>
          )}
          <div
            style={{
              borderBottom: `1px solid ${BaseColor.popOverSeperate}`,
            }}
          />
          {cTab === "groups" && activeMenu !== "blockList" && (
            <>
              <ListItemButton
                style={{
                  marginLeft: -8,
                  fontSize: 16,
                  fontFamily: FontFamily.Medium,
                  color: BaseColor.blackColor,
                  gap: "10px",
                }}
                onClick={() => {
                  setRenameGroup(true);
                  setGroupName(chatData?.conv_name);
                  handleClose();
                }}
              >
                <img
                  alt="group-icon"
                  width={"20px"}
                  src={Files.pngIcons.groupIcon}
                  draggable={false}
                />{" "}
                <span className={classes.popOverText}>Rename Group</span>
              </ListItemButton>

              <div
                style={{
                  borderBottom: `1px solid ${BaseColor.popOverSeperate}`,
                }}
              />
            </>
          )}
          {listType !== "blockList" && (
            <ListItemButton
              style={{
                marginLeft: -8,
                fontSize: 16,
                fontFamily: FontFamily.Medium,
                color: BaseColor.blackColor,
                gap: "10px",
              }}
              onClick={() => {
                setReport(true);
                handleClose();
              }}
            >
              <img
                alt="report-icon"
                width={"20px"}
                src={Files.pngIcons.reportIcon}
                draggable={false}
              />{" "}
              <span className={classes.popOverText}>Report</span>
            </ListItemButton>
          )}

          <div
            style={{
              borderBottom: `1px solid ${BaseColor.popOverSeperate}`,
            }}
          />
          {listType !== "blockList" && (
            <>
              <ListItemButton
                style={{
                  marginLeft: -8,
                  fontSize: 16,
                  fontFamily: FontFamily.Medium,
                  color: BaseColor.blackColor,
                  gap: "10px",
                }}
                onClick={() => {
                  if (activeMenu === "archiveList") {
                    unarchiveUser();
                  } else {
                    archiveUser();
                    setAnchorEl(null);
                  }
                }}
              >
                <img
                  alt="archive-icon"
                  width={"20px"}
                  src={Files.pngIcons.archiveIcon}
                  draggable={false}
                />{" "}
                <span className={classes.popOverText}>
                  {activeMenu === "archiveList" ? "Unarchive" : "Archived"}
                </span>
              </ListItemButton>
              <div
                style={{
                  borderBottom: `1px solid ${BaseColor.popOverSeperate}`,
                }}
              />
            </>
          )}

          {!_.isEmpty(particularUserChat) &&
            _.isArray(particularUserChat) &&
            listType !== "blockList" && (
              <ListItemButton
                style={{
                  marginLeft: -8,
                  fontSize: 16,
                  fontFamily: FontFamily.Medium,
                  color: BaseColor.red,
                  gap: "10px",
                }}
                onClick={() => {
                  setClearChat(true);
                  handleClose();
                }}
              >
                <img
                  alt="clearChat-icon"
                  width={"20px"}
                  src={Files.pngIcons.clearChatIcon}
                  draggable={false}
                />{" "}
                <span className={classes.popOverText}>Clear Chat</span>
              </ListItemButton>
            )}

          {cTab === "groups" && (
            <>
              <div
                style={{
                  borderBottom: `1px solid ${BaseColor.popOverSeperate}`,
                }}
              />
              <ListItemButton
                style={{
                  marginLeft: -8,
                  fontSize: 16,
                  fontFamily: FontFamily.Medium,
                  color: BaseColor.red,
                  gap: "10px",
                }}
                onClick={() => {
                  setExitGroup(true);
                  handleClose();
                }}
              >
                <img
                  alt="exit-icon"
                  width={"20px"}
                  src={Files.pngIcons.exitGroupIcon}
                  draggable={false}
                />{" "}
                <span className={classes.popOverText}>Exit Group</span>
              </ListItemButton>
            </>
          )}
        </Grid>
      </Popover>

      {/* End */}

      {/* View Members Modal */}

      <CModal
        visible={viewMember}
        onClose={() => {
          setViewMember(false);
          setMemberList([]);
        }}
        children={
          <div
            className={classes.modal_container}
            style={{
              padding: sm ? "40px 10px" : "40px",
              maxHeight: "80vh",
              maxWidth: sm ? "90vw" : "450px",
            }}
          >
            <CloseIcon
              onClick={() => {
                setViewMember(false);
                setMemberList([]);
              }}
              style={{
                position: "absolute",
                top: 10,
                right: 15,
                fontSize: 30,
                cursor: "pointer",
                color: BaseColor.primary,
                zIndex: 1,
              }}
            />
            {membersLoader ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: sm ? "90vw" : "500px",
                  maxHeight: "80vh",
                  minHeight: "10vh",
                }}
              >
                <CircularProgress color="primary" size={30} />
              </div>
            ) : (
              <>
                <Typography
                  fontFamily={FontFamily.GilbertQualifiDemo}
                  fontSize={"24px"}
                  textAlign={"center"}
                >
                  Members ({memberList?.length})
                </Typography>

                <div
                  style={{
                    display: "flex",
                    gap: "20px",
                    marginTop: "2vh",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    overflowY: "auto",
                  }}
                >
                  {memberList.map((contact) => {
                    return (
                      <Stack
                        direction="column"
                        spacing={0}
                        alignItems={"center"}
                        className={classes.profileStack}
                      >
                        <Badge
                          key={contact.id}
                          badgeContent={
                            contact?.guest_cnt || contact?.guest_cnt == 0
                              ? contact?.guest_cnt
                              : isSingle
                              ? "Host"
                              : null
                          }
                          color="primary"
                          overlap="circular"
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                        >
                          <Avatar
                            alt="Avatar"
                            sx={{ width: 60, height: 60 }}
                            src={
                              contact?.profile_pic ||
                              "https://i.ibb.co/D4kB907/Goat-01-1.png"
                            }
                          />
                        </Badge>
                        <Typography
                          fontSize={"14px"}
                          component={"span"}
                          color={
                            contact?.payment_status === "reject"
                              ? BaseColor.red
                              : contact?.payment_status === "confirm"
                              ? BaseColor.green
                              : BaseColor.primary
                          }
                          textAlign={"center"}
                          fontFamily={FontFamily.SemiBold}
                          mt={"10px"}
                          style={{
                            overflowWrap: "break-word",
                            maxWidth: "85px",
                          }}
                        >
                          {contact.username}
                        </Typography>
                      </Stack>
                    );
                  })}
                </div>
              </>
            )}
          </div>
        }
      />

      {/* End */}

      {/* Rename Group Modal */}

      <CModal
        visible={renameGroup}
        onClose={() => {
          setRenameGroup(false);
          setGroupName("");
        }}
        children={
          <div className={classes.modal_container}>
            <CloseIcon
              onClick={() => {
                setRenameGroup(false);
                setGroupName("");
              }}
              style={{
                position: "absolute",
                top: 10,
                right: 15,
                fontSize: 30,
                cursor: "pointer",
                color: BaseColor.primary,
                zIndex: 1,
              }}
            />
            <Typography
              fontFamily={FontFamily.GilbertQualifiDemo}
              fontSize={"24px"}
              textAlign={"center"}
            >
              Rename Group
            </Typography>
            <Grid
              container
              padding={isMobile ? "20px" : "40px"}
              style={{
                position: "relative",
                width: isTablet
                  ? "75vw"
                  : isMobile
                  ? "90vw"
                  : md
                  ? "90vw"
                  : "500px",
                maxHeight: isMobile ? "100vh" : "80vh",
                overflowY: "auto",
                padding: isMobile ? "20px" : "0px 40px",
              }}
              className={classes.scrollBar}
            >
              <Grid item xs={12} marginTop="20px">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                  style={{ width: "100%" }}
                >
                  <CInput
                    label="Group Name"
                    placeholder="Enter Group Name"
                    value={groupName}
                    onChange={(val) => {
                      setGroupName(val);
                    }}
                    onFocus={() => {
                      setIsKeyboardOpen(true);
                    }}
                    onBlur={() => {
                      setIsKeyboardOpen(false);
                    }}
                  />
                </form>
              </Grid>
              <Grid item xs={12} marginTop="100px">
                <CButton
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    renameGroupname();
                  }}
                  disabled={groupName?.trim() === ""}
                  loading={renameGroupBtnLoader}
                  children={
                    <Typography
                      fontSize={"14px"}
                      component={"span"}
                      color={BaseColor.whiteColor}
                      fontFamily={FontFamily.GilbertQualifiDemo}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "3px",
                      }}
                    >
                      Save
                    </Typography>
                  }
                />
              </Grid>
            </Grid>
          </div>
        }
      />

      {/* End */}

      {/* Report Modal */}

      <CModal
        visible={report}
        onClose={() => {
          setReport(false);
          setSelectedValue("");
          setInputValue("");
        }}
        children={
          <Grid
            container
            style={{
              position: "relative",
              width: isTablet
                ? "75vw"
                : isMobile
                ? "90vw"
                : md
                ? "90vw"
                : "500px",
              maxHeight: isMobile ? "100vh" : "80vh",
              overflowY: "auto",
              padding: isMobile ? "20px" : "40px",
            }}
            className={classes.scrollBar}
          >
            <CloseIcon
              onClick={() => {
                setReport(false);
                setSelectedValue("");
                setInputValue("");
              }}
              style={{
                position: "absolute",
                top: 10,
                right: 15,
                fontSize: 30,
                cursor: "pointer",
                color: BaseColor.primary,
                zIndex: 1,
              }}
            />
            <Typography
              fontFamily={FontFamily.GilbertQualifiDemo}
              fontSize={"24px"}
              sx={{ width: "100%" }}
              textAlign={"center"}
            >
              Report
            </Typography>

            <Grid item xs={12} mt={"20px"}>
              <RadioGroup
                row
                value={selectedValue}
                onChange={handleRadioChange}
                sx={{
                  flexDirection: "column",
                  "& .MuiFormControlLabel-label": {
                    fontFamily: FontFamily.Medium,
                    color: BaseColor.blackColor,
                  },
                  "& .css-as6sap-MuiFormControlLabel-root .MuiFormControlLabel-label":
                    {
                      fontFamily: FontFamily.Medium,
                      color: BaseColor.blackColor,
                    },
                  "& .css-10ks82k-MuiButtonBase-root-MuiRadio-root": {
                    color: BaseColor.primary,
                  },
                }}
              >
                <FormControlLabel
                  value="inappropriateBehavior"
                  control={
                    <Radio
                      style={{
                        paddingLeft: "0px",
                      }}
                    />
                  }
                  label="Inappropriate Behavior"
                />
                <FormControlLabel
                  value="cheatingViolations"
                  control={
                    <Radio
                      style={{
                        paddingLeft: "0px",
                      }}
                    />
                  }
                  label="Cheating or Violations of Rules"
                />
                <FormControlLabel
                  value="safetyConcerns"
                  control={
                    <Radio
                      style={{
                        paddingLeft: "0px",
                      }}
                    />
                  }
                  label="Safety Concerns"
                />
                <FormControlLabel
                  value="somethingElse"
                  control={
                    <Radio
                      style={{
                        paddingLeft: "0px",
                      }}
                    />
                  }
                  label="Something Else"
                />
              </RadioGroup>
              {selectedValue === "somethingElse" && (
                <CInput
                  value={inputValue}
                  onChange={handleInputChange}
                  placeholder="Type your reason here..."
                  multiline
                  rows={4}
                  inputProps={{ maxLength: 300 }}
                  fullWidth
                  onFocus={() => {
                    setIsKeyboardOpen(true);
                  }}
                  onBlur={() => {
                    setIsKeyboardOpen(false);
                  }}
                />
              )}
            </Grid>
            <div
              style={{
                display: "flex",
                width: "100%",
                marginTop: "20px",
                justifyContent: "space-between",
              }}
            >
              <CButton
                btnStyle={{ width: "47%" }}
                variant="contained"
                color="secondary"
                onClick={() => {
                  setReport(false);
                  setSelectedValue("");
                  setInputValue("");
                }}
                children={
                  <Typography
                    fontSize={"14px"}
                    component={"span"}
                    color={BaseColor.blackColor}
                    fontFamily={FontFamily.GilbertQualifiDemo}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "3px",
                    }}
                  >
                    Cancel
                  </Typography>
                }
              />
              <CButton
                btnStyle={{ width: "47%" }}
                variant="contained"
                color="primary"
                loading={reportBtnLoader}
                disabled={
                  _.isEmpty(selectedValue)
                    ? true
                    : selectedValue === "somethingElse" &&
                      inputValue?.trim() == ""
                    ? true
                    : false
                }
                onClick={() => {
                  handleSubmitReport();
                  setReport(false);
                  setSelectedValue("");
                  setInputValue("");
                }}
                children={
                  <Typography
                    fontSize={"14px"}
                    component={"span"}
                    color={BaseColor.whiteColor}
                    fontFamily={FontFamily.GilbertQualifiDemo}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "3px",
                    }}
                  >
                    Report
                  </Typography>
                }
              />
            </div>
          </Grid>
        }
      />

      {/* End */}

      {/* Clear Chat Modal */}

      <CModal
        visible={clearChat}
        onClose={() => {
          setClearChat(false);
        }}
        children={
          <div className={classes.modal_container} style={{ padding: "40px" }}>
            <CloseIcon
              onClick={() => {
                setClearChat(false);
              }}
              style={{
                position: "absolute",
                top: 10,
                right: 15,
                fontSize: 30,
                cursor: "pointer",
                color: BaseColor.primary,
                zIndex: 1,
              }}
            />
            <Typography
              fontFamily={FontFamily.GilbertQualifiDemo}
              fontSize={"24px"}
              textAlign={"center"}
            >
              Clear Chat
            </Typography>
            <Typography
              fontSize={"16px"}
              component={"span"}
              color={BaseColor.blackColor}
              fontFamily={FontFamily.SemiBold}
              style={{
                margin: "24px 0px",
                width: "303px",
                textAlign: "center",
              }}
            >
              Are you sure you want to clear chat to this Conversation?
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "12px",
              }}
            >
              <CButton
                btnStyle={{ width: "150px" }}
                variant="contained"
                color="secondary"
                onClick={() => setClearChat(false)}
                children={
                  <Typography
                    fontSize={"14px"}
                    component={"span"}
                    color={BaseColor.blackColor}
                    fontFamily={FontFamily.GilbertQualifiDemo}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "3px",
                    }}
                  >
                    Cancel
                  </Typography>
                }
              />
              <CButton
                btnStyle={{ width: "150px" }}
                variant="contained"
                color="primary"
                loading={clearChatBtnLoader}
                onClick={() => handleClearChat()}
                children={
                  <Typography
                    fontSize={"14px"}
                    component={"span"}
                    color={BaseColor.whiteColor}
                    fontFamily={FontFamily.GilbertQualifiDemo}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "3px",
                    }}
                  >
                    Clear
                  </Typography>
                }
              />
            </div>
          </div>
        }
      />

      {/* End */}

      {/* Exit Group Modal */}

      <CModal
        visible={exitGroup}
        onClose={() => {
          setExitGroup(false);
        }}
        children={
          <div className={classes.modal_container} style={{ padding: "40px" }}>
            <CloseIcon
              onClick={() => {
                setExitGroup(false);
              }}
              style={{
                position: "absolute",
                top: 10,
                right: 15,
                fontSize: 30,
                cursor: "pointer",
                color: BaseColor.primary,
                zIndex: 1,
              }}
            />
            <Typography
              fontFamily={FontFamily.GilbertQualifiDemo}
              fontSize={"24px"}
              textAlign={"center"}
            >
              Exit Group
            </Typography>
            <Typography
              fontSize={"16px"}
              component={"span"}
              color={BaseColor.blackColor}
              fontFamily={FontFamily.SemiBold}
              style={{
                margin: "24px 0px",
                width: "303px",
                textAlign: "center",
              }}
            >
              Are you sure you want to Exit from this Group?
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "12px",
              }}
            >
              <CButton
                btnStyle={{ width: "150px" }}
                variant="contained"
                color="secondary"
                onClick={() => setExitGroup(false)}
                children={
                  <Typography
                    fontSize={"14px"}
                    component={"span"}
                    color={BaseColor.blackColor}
                    fontFamily={FontFamily.GilbertQualifiDemo}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "3px",
                    }}
                  >
                    Cancel
                  </Typography>
                }
              />
              <CButton
                btnStyle={{ width: "150px" }}
                variant="contained"
                color="primary"
                loading={exitBtnLoader}
                onClick={() => {
                  exitFromGroup();
                }}
                children={
                  <Typography
                    fontSize={"14px"}
                    component={"span"}
                    color={BaseColor.whiteColor}
                    fontFamily={FontFamily.GilbertQualifiDemo}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "3px",
                    }}
                  >
                    Exit
                  </Typography>
                }
              />
            </div>
          </div>
        }
      />

      {/* End */}

      {/* Block User Modal */}

      <CModal
        visible={blockUser}
        onClose={() => {
          setBlockUser(false);
        }}
        children={
          <Grid
            container
            padding={isMobile ? "20px" : "40px"}
            style={{
              position: "relative",
              width: sm ? "90vw" : "580px",
              maxHeight: isMobile ? "100vh" : "80vh",
              overflowY: "auto",
              padding: isMobile ? "20px" : "40px",
            }}
            className={classes.scrollBar}
          >
            <CloseIcon
              onClick={() => {
                setBlockUser(false);
              }}
              style={{
                position: "absolute",
                top: 10,
                right: 15,
                fontSize: 30,
                cursor: "pointer",
                color: BaseColor.primary,
                zIndex: 1,
              }}
            />
            <Typography
              fontFamily={FontFamily.GilbertQualifiDemo}
              sx={{ color: BaseColor.blackColor, width: "100%" }}
              fontSize={"24px"}
              textAlign={"center"}
            >
              Block User
            </Typography>

            <Typography
              fontSize={"16px"}
              component={"span"}
              color={BaseColor.blackColor}
              fontFamily={FontFamily.SemiBold}
              style={{
                margin: "20px 0px",
                textAlign: "center",
                width: "100%",
              }}
            >
              {`Are you sure you want to Block ${particularUser?.conv_name}?`}
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <CButton
                btnStyle={{ width: "47%" }}
                variant="contained"
                color="secondary"
                onClick={() => setBlockUser(false)}
                children={
                  <Typography
                    fontSize={"14px"}
                    component={"span"}
                    color={BaseColor.blackColor}
                    fontFamily={FontFamily.GilbertQualifiDemo}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "3px",
                    }}
                  >
                    Cancel
                  </Typography>
                }
              />
              <CButton
                btnStyle={{ width: "47%" }}
                variant="contained"
                color="primary"
                loading={blockUserBtnLoader}
                onClick={() => handleBlockUser()}
                children={
                  <Typography
                    fontSize={"14px"}
                    component={"span"}
                    color={BaseColor.whiteColor}
                    fontFamily={FontFamily.GilbertQualifiDemo}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "3px",
                    }}
                  >
                    Block
                  </Typography>
                }
              />
            </div>
          </Grid>
        }
      />

      {/* End */}
    </motion.div>
  );
}
