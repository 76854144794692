import {
  CircularProgress,
  Divider,
  Grid,
  Rating,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { IoIosArrowRoundBack } from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";
import CHeader from "../../Components/Header";
import { isTablet } from "react-device-detect";
import theme, { FontFamily } from "../../Config/theme";
import BaseColor from "../../Config/colors";
import { styled } from "@mui/material/styles";
import { getAPIProgressData } from "../../Utils/APIHelper";
import { Setting } from "../../Utils/Setting";
import _, { isEmpty } from "lodash";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const StyledRating = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#222222",
  },
  "& .MuiRating-iconHover": {
    color: "#222222",
  },
});
const UserProfile = () => {
  const navigate = useNavigate();
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [userData, setUserData] = useState({});
  const [reviewData, setReviewData] = useState([]);
  const [birthDate, setBirthDate] = useState("");
  const [pageLoader, setPageLoader] = useState(true);
  const location = useLocation();
  const userProfileData = location.state.param1.profileData;

  const isAdmin = useSelector((state) => state.auth.isAdmin);

  const getCommonData = async () => {
    let endPoints = Setting.endpoints.commonData;

    const params = {
      email: userProfileData.email,
    };

    try {
      const resp = await getAPIProgressData(endPoints, "POST", params);
      if (resp?.success) {
        if (!isEmpty(resp?.data)) {
          setUserData(resp?.data);
          if (resp?.data?.birth_date) {
            const date = new Date(resp?.data?.birth_date);

            const formattedDate = date.toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "long",
              year: "numeric",
            });
            setBirthDate(formattedDate);
          }
          getReviewData(resp?.data?.id);
        }
      } else {
        toast.error(resp?.message, {
          position: "top-right",
        });
      }
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
      });
    }
  };

  const getReviewData = async (id) => {
    setPageLoader(true);
    let endPoints = Setting.endpoints.getReviewList;

    const params = {
      user_id: id,
      all_data: "",
    };

    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
      if (resp?.success) {
        if (!isEmpty(resp?.data)) {
          setReviewData(resp?.data);
        }
        setPageLoader(false);
      } else {
        toast(resp?.message, {
          type: "error",
        });
        setPageLoader(false);
      }
    } catch (error) {
      toast(error.message, {
        type: "error",
      });
      setPageLoader(false);
    }
  };

  useEffect(() => {
    getCommonData();
  }, []);

  return (
    <>
      {!md ? (
        <Grid
          container
          style={{
            backgroundColor: BaseColor.white,
            overflowY: "auto",
          }}
        >
          <CHeader title={"Profile"} />
          <Grid item xs={0.5} sm={0.5} md={1} lg={1} />
          {pageLoader ? (
            <Grid
              container
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              width={"100%"}
              height={"85vh"}
            >
              <CircularProgress size={40} color="primary" />
            </Grid>
          ) : (
            <>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={3}
                style={{
                  backgroundColor: md ? "#FFFFFF" : "#F5F5F5",
                  padding: 10,
                  height: !md && "100vh",
                }}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "25px",
                      }}
                    >
                      <IoIosArrowRoundBack
                        onClick={() => {
                          navigate(-1);
                        }}
                        style={{
                          fontSize: 40,
                          cursor: !isMobile && !isTablet && "pointer",
                        }}
                      />

                      <Typography
                        style={{
                          textAlign: "center",
                          flexGrow: 1,
                          fontSize: 24,
                          fontFamily: FontFamily.GilbertQualifiDemo,
                          color: BaseColor.blackColor,
                          marginRight: "10px",
                        }}
                      >
                        Profile
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div
                      style={{
                        marginTop: !md && "40px",
                        display: "flex",
                        justifyContent: !md && "center",
                        alignItems: "center",
                        height: md ? "150px" : "165px",
                        flexDirection: md ? "row" : "column",
                        marginLeft: 15,
                        gap: md && "14px",
                      }}
                    >
                      <img
                        src={
                          userData?.profile_pic ||
                          "https://i.ibb.co/D4kB907/Goat-01-1.png"
                        }
                        draggable={false}
                        alt="profile"
                        style={{
                          objectFit: "cover",
                          width: "80px",
                          height: "80px",
                          borderRadius: "50%",
                          border: `3px solid ${BaseColor.primary}`,
                        }}
                      />
                      <Typography
                        style={{
                          textAlign: "center",
                          color: BaseColor.blackColor,
                          marginTop: !md && "20px",
                          marginBottom: !md && "35px",
                          fontFamily: md
                            ? FontFamily.GilbertQualifiDemo
                            : FontFamily.SemiBold,
                          fontSize: md && "22px",
                        }}
                      >
                        {userData?.nick_name}
                        <Typography
                          style={{
                            textAlign: "center",
                            color: BaseColor.blackColor,
                            marginBottom: !md && "35px",
                            fontFamily: FontFamily.Medium,
                            fontSize: "12px",
                          }}
                        >
                          {userData?.club_data[0]?.name}
                        </Typography>
                      </Typography>
                    </div>
                    <div
                      style={{
                        margin: !md ? "20px 10px" : "0px 10px",
                      }}
                    >
                      {isAdmin && (
                        <>
                          {" "}
                          <Typography
                            style={{
                              color: BaseColor.blackColor,
                              fontSize: 16,
                              fontFamily: FontFamily.Medium,
                              marginBottom: "5px",
                            }}
                          >
                            {birthDate}
                          </Typography>
                          <Typography
                            style={{
                              color: BaseColor.blackColor,
                              fontSize: 16,
                              marginBottom: "5px",
                            }}
                          >
                            {userData?.email}
                          </Typography>
                          <Typography
                            style={{
                              color: BaseColor.blackColor,
                              fontSize: 16,
                              marginBottom: "5px",
                            }}
                          >
                            {userData?.phone}
                          </Typography>
                          <Typography
                            style={{
                              color: BaseColor.blackColor,
                              fontSize: 16,
                              marginBottom: "5px",
                            }}
                          >
                            {userData?.address}
                          </Typography>
                          <Divider style={{ margin: "20px 0" }} />
                        </>
                      )}

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div style={{ textAlign: "center", margin: "0 10px" }}>
                          <Typography
                            style={{
                              fontWeight: 600,
                              fontSize: "20px",
                              fontFamily: FontFamily.SemiBold,
                              color: BaseColor.blackColor,
                            }}
                          >
                            {userData?.total_tee_times
                              ? userData?.total_tee_times
                              : 0}
                          </Typography>
                          <Typography
                            style={{
                              fontSize: 16,
                              fontFamily: FontFamily.Medium,
                              fontWeight: 500,
                              color: BaseColor.grey,
                            }}
                          >
                            Tee Times
                          </Typography>
                        </div>
                        <div
                          style={{
                            textAlign: "center",
                            margin: "0 10px",
                            marginLeft: "30px ",
                          }}
                        >
                          <Typography
                            style={{
                              fontWeight: 600,
                              fontSize: 20,
                              fontFamily: FontFamily.SemiBold,
                              color: BaseColor.blackColor,
                            }}
                          >
                            {userData?.total_event ? userData?.total_event : 0}
                          </Typography>
                          <Typography
                            style={{
                              fontSize: 16,
                              fontFamily: FontFamily.Medium,
                              fontWeight: 500,
                              color: BaseColor.grey,
                            }}
                          >
                            Total Host Event
                          </Typography>
                        </div>
                      </div>
                      <Divider style={{ margin: "20px 0" }} />

                      <div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            marginBottom: "5px",
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: 16,
                              fontWeight: 500,
                              fontFamily: FontFamily.Medium,
                              color: BaseColor.blackColor,
                            }}
                          >
                            Handicap index
                          </Typography>

                          <Typography
                            style={{
                              fontSize: 16,
                              fontFamily: FontFamily.Medium,
                              color: BaseColor.grey,
                            }}
                          >
                            {userData?.other_details?.handicap_or_ghin}
                          </Typography>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            marginBottom: "5px",
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: 16,
                              fontWeight: 500,
                              fontFamily: FontFamily.Medium,
                              color: BaseColor.blackColor,
                            }}
                          >
                            Enjoys music on Course
                          </Typography>

                          <Typography
                            style={{
                              fontSize: 16,
                              fontFamily: FontFamily.Medium,
                              color: BaseColor.grey,
                            }}
                          >
                            {userData?.other_details?.listen_to_music
                              ? "Yes"
                              : "No"}
                          </Typography>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            marginBottom: "5px",
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: 16,
                              fontWeight: 500,
                              fontFamily: FontFamily.Medium,
                              color: BaseColor.blackColor,
                            }}
                          >
                            Enjoy Drinks on Course
                          </Typography>

                          <Typography
                            style={{
                              fontSize: 16,
                              fontFamily: FontFamily.Medium,
                              color: BaseColor.grey,
                            }}
                          >
                            {userData?.other_details?.beverages_available
                              ? "Yes"
                              : "No"}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid mt={2} item xs={12} sm={12} md={7} lg={7}>
                <Grid>
                  <Grid item xs={12}>
                    {md && <Divider />}
                    <Typography
                      style={{
                        fontSize: 22,
                        color: BaseColor.blackColor,
                        fontWeight: 400,
                        fontFamily: FontFamily.GilbertQualifiDemo,
                        marginLeft: md ? 15 : 35,
                        marginTop: md && 14,
                      }}
                    >
                      Reviews
                    </Typography>

                    <div
                      style={{
                        margin: 30,
                        marginLeft: md ? 15 : 40,
                        overflowY: "auto",
                        height: !md && "80vh",
                      }}
                    >
                      {isEmpty(reviewData) ? (
                        <Typography
                          style={{
                            fontFamily: FontFamily.SemiBold,
                            fontSize: "20px",
                          }}
                        >
                          No Reviews Yet !!
                        </Typography>
                      ) : (
                        <>
                          {reviewData.map((item, index) => (
                            <>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginBottom: "10px",
                                }}
                              >
                                <div
                                  style={{
                                    width: "50px",
                                    height: "50px",
                                    borderRadius: "50%",
                                    overflow: "hidden",
                                    marginRight: "10px",
                                  }}
                                >
                                  <img
                                    src={item.feedback_sender_pic_url}
                                    alt={item.name}
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "cover",
                                    }}
                                    draggable={false}
                                  />
                                </div>

                                <div style={{ marginLeft: "10px" }}>
                                  <Typography
                                    style={{
                                      color: BaseColor.blackColor,
                                      fontSize: "16px",
                                      // fontFamily: FontFamily.Regular,
                                    }}
                                  >
                                    {item.feedback_sender_name}
                                  </Typography>
                                  <Typography
                                    style={{
                                      fontSize: "14px",

                                      fontFamily: FontFamily.Regular,
                                      color: BaseColor.grey,
                                    }}
                                  >
                                    {item.created_at}
                                  </Typography>
                                </div>
                              </div>

                              <Rating
                                name="star-rating"
                                value={item.rating}
                                precision={0.5}
                                sx={{
                                  fontSize: "20px",
                                  color: BaseColor.blackColor,
                                }}
                                readOnly
                              />

                              <Typography
                                style={{
                                  fontFamily: FontFamily.Regular,
                                  fontSize: "13px",
                                  textAlign: "justify",
                                  marginTop: "6px",
                                  width: sm ? "70vw" : md ? "50vw" : "25vw",
                                }}
                              >
                                {item.comment}
                              </Typography>
                            </>
                          ))}
                        </>
                      )}
                    </div>
                    {/* )} */}
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      ) : (
        <Grid
          container
          style={{
            backgroundColor: BaseColor.white,
            overflowY: "auto",
            maxHeight: "100vh",
          }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={3}
            style={{
              backgroundColor: md ? "#FFFFFF" : "#F5F5F5",
              padding: 10,
              height: !md && "100vh",
            }}
          >
            <Grid container>
              <Grid
                item
                xs={12}
                style={{
                  position: "fixed",
                  top: 0,
                  background: BaseColor.white,
                  width: "100%",
                  zIndex: 1,
                  left: 0,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "25px",
                  }}
                >
                  <IoIosArrowRoundBack
                    onClick={() => {
                      navigate(-1);
                    }}
                    style={{
                      fontSize: 40,
                      cursor: !isMobile && !isTablet && "pointer",
                    }}
                  />

                  <Typography
                    style={{
                      textAlign: "center",
                      flexGrow: 1,
                      fontSize: 24,
                      fontFamily: FontFamily.GilbertQualifiDemo,
                      color: BaseColor.blackColor,
                      marginRight: "15px",
                    }}
                  >
                    Profile
                  </Typography>
                </div>
              </Grid>

              <Grid item xs={12} mt={8}>
                <div
                  style={{
                    marginTop: !md && "40px",
                    display: "flex",
                    justifyContent: !md && "center",
                    alignItems: "center",
                    height: md ? "150px" : "165px",
                    flexDirection: md ? "row" : "column",
                    marginLeft: 15,
                    gap: md && "14px",
                  }}
                >
                  <img
                    src={
                      userData?.profile_pic ||
                      "https://i.ibb.co/D4kB907/Goat-01-1.png"
                    }
                    alt="profile"
                    style={{
                      objectFit: "cover",
                      width: "80px",
                      height: "80px",
                      borderRadius: "50%",
                      border: `3px solid ${BaseColor.primary}`,
                    }}
                    draggable={false}
                  />
                  <div>
                    <Typography
                      style={{
                        textAlign: "start",
                        color: BaseColor.blackColor,
                        marginTop: !md && "20px",
                        marginBottom: !md && "35px",
                        fontFamily: md
                          ? FontFamily.GilbertQualifiDemo
                          : FontFamily.SemiBold,
                        fontSize: md && "22px",
                      }}
                    >
                      {userData?.nick_name}
                      <Typography
                        style={{
                          textAlign: "start",
                          color: BaseColor.blackColor,
                          marginBottom: !md && "35px",
                          fontFamily: FontFamily.Medium,
                          fontSize: "12px",
                        }}
                      >
                        {!_.isEmpty(userData?.club_data)
                          ? userData?.club_data[0]?.name
                          : ""}
                      </Typography>
                    </Typography>
                    {/* <Typography
                      style={{
                        textAlign: "start",
                        color: BaseColor.blackColor,
                        marginTop: !md && "20px",
                        marginBottom: !md && "35px",
                        fontFamily: FontFamily.Medium,
                        fontSize: "14px",
                      }}
                    >
                      {userData.nick_name}
                    </Typography> */}
                  </div>
                </div>
                <div
                  style={{
                    margin: !md ? "20px 10px" : "0px 10px",
                  }}
                >
                  {isAdmin && (
                    <>
                      <Typography
                        style={{
                          color: BaseColor.blackColor,
                          fontSize: 16,
                          fontFamily: FontFamily.Medium,
                          marginBottom: "5px",
                        }}
                      >
                        {birthDate}
                      </Typography>
                      <Typography
                        style={{
                          color: BaseColor.blackColor,
                          fontSize: 16,
                          marginBottom: "5px",
                        }}
                      >
                        {userData?.email}
                      </Typography>
                      <Typography
                        style={{
                          color: BaseColor.blackColor,
                          fontSize: 16,
                          marginBottom: "5px",
                        }}
                      >
                        {userData?.phone}
                      </Typography>
                      <Typography
                        style={{
                          color: BaseColor.blackColor,
                          fontSize: 16,
                          marginBottom: "5px",
                        }}
                      >
                        {userData?.address}
                      </Typography>
                      <Divider style={{ margin: "20px 0" }} />
                    </>
                  )}

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div style={{ textAlign: "center", margin: "0 10px" }}>
                      <Typography
                        style={{
                          fontWeight: 600,
                          fontSize: "20px",
                          fontFamily: FontFamily.SemiBold,
                          color: BaseColor.blackColor,
                        }}
                      >
                        {userData?.total_tee_times
                          ? userData?.total_tee_times
                          : 0}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: 16,
                          fontFamily: FontFamily.Medium,
                          fontWeight: 500,
                          color: BaseColor.grey,
                        }}
                      >
                        Tee Times
                      </Typography>
                    </div>
                    <div
                      style={{
                        textAlign: "center",
                        margin: "0 10px",
                        marginLeft: "30px ",
                      }}
                    >
                      <Typography
                        style={{
                          fontWeight: 600,
                          fontSize: 20,
                          fontFamily: FontFamily.SemiBold,
                          color: BaseColor.blackColor,
                        }}
                      >
                        {userData?.total_event ? userData?.total_event : 0}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: 16,
                          fontFamily: FontFamily.Medium,
                          fontWeight: 500,
                          color: BaseColor.grey,
                        }}
                      >
                        Total Host Event
                      </Typography>
                    </div>
                  </div>
                  <Divider style={{ margin: "20px 0" }} />

                  <div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBottom: "5px",
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: 16,
                          fontWeight: 500,
                          fontFamily: FontFamily.Medium,
                          color: BaseColor.blackColor,
                        }}
                      >
                        Handicap index
                      </Typography>

                      <Typography
                        style={{
                          fontSize: 16,
                          fontFamily: FontFamily.Medium,
                          color: BaseColor.grey,
                        }}
                      >
                        {userData?.other_details?.handicap_or_ghin}
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBottom: "5px",
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: 16,
                          fontWeight: 500,
                          fontFamily: FontFamily.Medium,
                          color: BaseColor.blackColor,
                        }}
                      >
                        Enjoys music on Course
                      </Typography>

                      <Typography
                        style={{
                          fontSize: 16,
                          fontFamily: FontFamily.Medium,
                          color: BaseColor.grey,
                        }}
                      >
                        {userData?.other_details?.listen_to_music
                          ? "Yes"
                          : "No"}
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBottom: "5px",
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: 16,
                          fontWeight: 500,
                          fontFamily: FontFamily.Medium,
                          color: BaseColor.blackColor,
                        }}
                      >
                        Enjoy Drinks on Course
                      </Typography>

                      <Typography
                        style={{
                          fontSize: 16,
                          fontFamily: FontFamily.Medium,
                          color: BaseColor.grey,
                        }}
                      >
                        {userData?.other_details?.beverages_available
                          ? "Yes"
                          : "No"}
                      </Typography>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid mt={2} item xs={12} sm={12} md={7} lg={7}>
            <Grid>
              <Grid item xs={12}>
                {md && <Divider />}
                <Typography
                  style={{
                    fontSize: 22,
                    color: BaseColor.blackColor,
                    fontWeight: 400,
                    fontFamily: FontFamily.GilbertQualifiDemo,
                    marginLeft: md ? 15 : 35,
                    marginTop: md && 14,
                  }}
                >
                  Reviews
                </Typography>

                {pageLoader ? (
                  <Grid
                    container
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <CircularProgress size={40} color="primary" />
                  </Grid>
                ) : (
                  <div
                    style={{
                      margin: 30,
                      marginLeft: md ? 15 : 40,
                      overflowY: "auto",
                      height: !md && "80vh",
                    }}
                  >
                    {isEmpty(reviewData) ? (
                      <Typography
                        style={{
                          fontFamily: FontFamily.SemiBold,
                          fontSize: "20px",
                        }}
                      >
                        No Reviews Yet !!
                      </Typography>
                    ) : (
                      <>
                        {reviewData.map((item, index) => (
                          <>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "10px",
                              }}
                            >
                              <div
                                style={{
                                  width: "50px",
                                  height: "50px",
                                  borderRadius: "50%",
                                  overflow: "hidden",
                                  marginRight: "10px",
                                }}
                              >
                                <img
                                  src={item.feedback_sender_pic_url}
                                  alt={item.name}
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                  }}
                                  draggable={false}
                                />
                              </div>

                              <div style={{ marginLeft: "10px" }}>
                                <Typography
                                  style={{
                                    color: BaseColor.blackColor,
                                    fontSize: "16px",
                                    // fontFamily: FontFamily.Regular,
                                  }}
                                >
                                  {item.feedback_sender_name}
                                </Typography>
                                <Typography
                                  style={{
                                    fontSize: "14px",

                                    fontFamily: FontFamily.Regular,
                                    color: BaseColor.grey,
                                  }}
                                >
                                  {item.created_at}
                                </Typography>
                              </div>
                            </div>

                            <Rating
                              name="star-rating"
                              value={item.rating}
                              precision={0.5}
                              sx={{
                                fontSize: "20px",
                                color: BaseColor.blackColor,
                              }}
                              readOnly
                            />

                            <Typography
                              style={{
                                fontFamily: FontFamily.Regular,
                                fontSize: "13px",
                                textAlign: "justify",
                                marginTop: "6px",
                                width: sm ? "90vw" : md ? "50vw" : "25vw",
                              }}
                            >
                              {item.comment}
                            </Typography>
                          </>
                        ))}
                      </>
                    )}
                  </div>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default UserProfile;
