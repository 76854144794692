import React, { useEffect, useState } from "react";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import CHeader from "../../Components/Header/index.js";
import theme, { FontFamily } from "../../Config/theme.js";
import BaseColor from "../../Config/colors.js";
import styles from "./styles.js";
import CIcon from "../../Components/CIcon/index.js";
import { sideBarTabs } from "../../Config/staticData.js";
import { store } from "../../Redux/store/configureStore.js";
import authActions from "../../Redux/reducers/auth/actions";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import EventComponent from "./EventsComponent/index.js";
import { isIOS, isMobile, isTablet } from "react-device-detect";
import { IoIosArrowRoundBack } from "react-icons/io";
import { socket } from "../../Context/ContextSocket.js";
import { motion } from "framer-motion";
import { Setting } from "../../Utils/Setting.js";
import { getAPIProgressData } from "../../Utils/APIHelper.js";
import _ from "lodash";
import { toast } from "react-toastify";

export default function Events() {
  // style and media queries
  const classes = styles();
  const md = useMediaQuery(theme.breakpoints.down("md"));

  //navigation
  const navigate = useNavigate();

  // location params
  const location = useLocation();
  const [isZoomed, setIsZoomed] = useState(false);
  const { event_data } = location?.state || "";

  useEffect(() => {
    getEventList(true);
  }, []);

  // redux
  const {
    auth: { activeTab, isUnread, userData, latLng },
  } = store.getState();
  const dispatch = useDispatch();
  const { setActiveHomeTab, setIsUnread } = authActions;

  // page loader
  const [pageLoader, setPageLoader] = useState(false);

  // event list data
  const [eventList, setEventList] = useState([]);

  // pagination and bottom loader
  const [bottomLoader, setBottomLoader] = useState(false);
  const [pagination, setPagination] = useState({});
  const [pageNo, setPageNo] = useState(1);

  useEffect(() => {
    userConnect();
    dispatch(setActiveHomeTab("Events"));
  }, []);

  // user connect emit
  const userConnect = () => {
    const requestdata = {
      user_id: userData.id,
      type: "",
      chat_type: "s",
      page: 1,
    };
    socket.emit("user_connection", requestdata, (response) => {
      const hasUnread = response?.data?.conversations?.some(
        (conversation) => conversation?.unread > 0
      );
      if (!hasUnread) {
        dispatch(setIsUnread(hasUnread));
      } else {
        dispatch(setIsUnread(hasUnread));
      }

      if (response.status === false) {
      } else {
      }
    });
  };

  // get event list
  const getEventList = async (pageLoad = false, page = 1) => {
    if (pageLoad) {
      setPageLoader(true);
    }
    let endPoints = Setting.endpoints.getEventList;
    const params = {
      user_lat: latLng.lat,
      user_lng: latLng.lng,
      page: page,
    };

    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
      if (resp?.success) {
        if (!_.isEmpty(resp?.data) && _.isArray(resp?.data)) {
          if (page !== 1) {
            setEventList([...eventList, ...resp?.data]);
            setBottomLoader(false);
          } else {
            setEventList(resp?.data);
            setBottomLoader(false);
          }
          setPagination(resp?.pagination);
          setBottomLoader(false);
        }
        setBottomLoader(false);
        if (pageLoad) {
          setPageLoader(false);
        }
      } else {
        toast(resp?.message, {
          type: "error",
        });
        if (pageLoad) {
          setPageLoader(false);
        }
        setBottomLoader(false);
      }
      setBottomLoader(false);
      setPageLoader(false);
    } catch (error) {
      toast(error.message, { type: "error" });
      if (pageLoad) {
        setPageLoader(false);
      }
      setBottomLoader(false);
    }
  };

  // scroll event for pagination
  const handleScroll = (e) => {
    const container = e?.target;
    const isReachBottom = container?.scrollHeight - container?.scrollTop;
    if (
      !_.isEmpty(eventList) &&
      pagination?.isMore &&
      isReachBottom === container.clientHeight &&
      !bottomLoader
    ) {
      const page = pageNo + 1;
      getEventList(false, page);
      setPageNo(page);
    }
  };

  return (
    <Grid container style={{ backgroundColor: BaseColor.white }}>
      {md && event_data ? <></> : <CHeader />}
      {md || isTablet || isMobile ? (
        <>
          <Grid item xs={0.5} sm={0.5} md={1} lg={1} />
          <Grid
            item
            xs={11}
            sm={11}
            md={10}
            lg={10}
            style={{
              height: event_data
                ? "calc(100vh - 60px )"
                : "calc(100vh - 150px )",
            }}
          >
            {event_data && (
              <Grid
                xs={12}
                style={{
                  alignItems: "center",
                  display: "flex",
                  padding: "12px 35px 0px 0px",
                  justifyContent: "space-between",
                }}
              >
                <IoIosArrowRoundBack
                  onClick={() => {
                    navigate("/home");
                  }}
                  style={{
                    fontSize: 40,
                    color: BaseColor.blackColor,
                    cursor: !isMobile && !isTablet && "pointer",
                  }}
                />

                <Typography
                  style={{
                    fontSize: 25,
                    color: BaseColor.textColor,
                    fontFamily: FontFamily.GilbertQualifiDemo,
                    textAlign: "center",
                  }}
                >
                  Events
                </Typography>

                <div></div>
              </Grid>
            )}
            <Grid container height={"100%"}>
              <Grid
                item
                xs={12}
                style={{
                  overflowX: "auto",
                  height: "100%",
                  paddingBottom:
                    isMobile && isIOS ? "80px" : isTablet ? "90px" : "50px",
                }}
                onScroll={(e) => {
                  handleScroll(e);
                }}
                className={(isMobile || isTablet) && classes.mainScrollBar}
              >
                <EventComponent
                  getEventList={(bool) => {
                    getEventList(bool);
                  }}
                  pageLoader={pageLoader}
                  eventList={eventList}
                  bottomLoader={bottomLoader}
                  page={pageNo}
                />
              </Grid>
            </Grid>

            {!event_data && (
              <Grid
                container
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  position: "fixed",
                  left: "0px",
                  bottom: "0px",
                  backgroundColor: BaseColor.white,
                  zIndex: 1111,
                  height: "80px",
                }}
              >
                {sideBarTabs?.map((data) => (
                  <motion.div
                    whileTap={{ scale: data?.title === "Events" ? 0.8 : null }}
                    whileHover={{ scale: 0.9 }}
                    initial={{ scale: data?.title === "Events" ? 0.7 : null }}
                    animate={{
                      scale: isZoomed && data?.title === "Events" ? 1.1 : 1,
                    }}
                    key={data?.title}
                    style={{ position: "relative", textAlign: "center" }}
                  >
                    <CIcon
                      src={
                        activeTab === data?.title
                          ? data?.mobFillIcon
                          : data?.icon
                      }
                      onClick={() => {
                        if (data?.title === "Map") {
                          dispatch(setActiveHomeTab(data?.title));
                          navigate("/map");
                        }
                        if (data?.title === "Home") {
                          dispatch(setActiveHomeTab(data?.title));
                          navigate("/home");
                        }
                        if (data.title === "Chat") {
                          dispatch(setActiveHomeTab(data?.title));
                          navigate(data.path);
                        }
                      }}
                      size={35}
                    />
                    {isUnread && data?.title === "Chat" && (
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          backgroundColor: "green",
                          width: 12,
                          height: 12,
                          borderRadius: "50%",
                          zIndex: 1111,
                        }}
                      />
                    )}
                    <Typography className={classes.iconText}>
                      {data?.title}
                    </Typography>
                  </motion.div>
                ))}
              </Grid>
            )}
          </Grid>
          <Grid item xs={0.5} sm={0.5} md={1} lg={1} />
        </>
      ) : (
        <>
          <Grid item xs={0.5} sm={0.5} md={1} lg={1}>
            <Grid
              container
              style={{
                height: "calc(100vh - 90px)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              {event_data !== "event_data" &&
                sideBarTabs?.map((data) => (
                  <>
                    <motion.div
                      className={classes.iconContainer}
                      style={{
                        backgroundColor:
                          activeTab === data?.title
                            ? BaseColor.primary
                            : "#EDF1F2",
                        position: "relative",
                      }}
                      whileTap={{
                        scale: data?.title === "Events" ? 0.8 : null,
                      }}
                      whileHover={{ scale: 0.9 }}
                      initial={{ scale: data?.title === "Events" ? 0.7 : null }}
                      animate={{
                        scale: isZoomed && data?.title === "Events" ? 1.1 : 1,
                      }}
                      transition={{ duration: 0.4 }}
                      onClick={() => {
                        if (data?.title === "Home") {
                          dispatch(setActiveHomeTab(data?.title));
                          navigate("/home");
                        }
                        if (data?.title === "Map") {
                          dispatch(setActiveHomeTab(data?.title));
                          navigate("/map");
                        }
                        if (data?.title === "Home") {
                          dispatch(setActiveHomeTab(data?.title));
                          navigate("/home");
                        }
                        if (data.title === "Chat") {
                          dispatch(setActiveHomeTab(data?.title));
                          navigate(data.path);
                        }
                      }}
                    >
                      <CIcon
                        src={
                          activeTab === data?.title
                            ? data?.fillIcon
                            : data?.icon
                        }
                        size={35}
                        style={{ cursor: !isMobile && !isTablet && "pointer" }}
                      />
                      {isUnread && data?.title === "Chat" && (
                        <div
                          style={{
                            position: "absolute",
                            top: 10,
                            right: 8,
                            backgroundColor: "green",
                            width: 12,
                            height: 12,
                            borderRadius: "50%",
                            zIndex: 1111,
                          }}
                        />
                      )}
                    </motion.div>
                    <Typography
                      className={classes.iconText}
                      style={{
                        color:
                          activeTab === data?.title
                            ? BaseColor.primary
                            : BaseColor.grey,
                        marginBottom: "20px",
                      }}
                    >
                      {data?.title}
                    </Typography>
                  </>
                ))}
            </Grid>
          </Grid>
          <Grid
            items
            xs={11.5}
            sm={11.5}
            md={11}
            lg={11}
            paddingTop={"20px"}
            style={{ overflowY: "auto", height: "calc(100vh - 90px)" }}
            onScroll={(e) => {
              handleScroll(e);
            }}
          >
            <Grid container style={{ maxHeight: "calc(100vh - 90px)" }}>
              <Grid
                item
                xs={11.5}
                sm={11.5}
                md={11}
                lg={11}
                style={{ overflowX: "auto" }}
              >
                <EventComponent
                  getEventList={(bool) => {
                    getEventList(bool);
                  }}
                  pageLoader={pageLoader}
                  eventList={eventList}
                  bottomLoader={bottomLoader}
                  page={pageNo}
                  handlePageLoader={(bool) => {
                    setPageLoader(bool);
                  }}
                />
              </Grid>
              <Grid item xs={0.5} sm={0.5} md={1} lg={1}></Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
}
