import {
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import theme, { FontFamily } from "../../../../Config/theme";
import BaseColor from "../../../../Config/colors";
import Files from "../../../../Config/Files";
import _ from "lodash";
import CButton from "../../../../Components/CButton";
import CInput from "../../../../Components/CInput";
import styles from "./styles";
import CHeader from "../../../../Components/Header";
import { useNavigate } from "react-router-dom";
import { Setting } from "../../../../Utils/Setting";
import { getAPIProgressData } from "../../../../Utils/APIHelper";
import { toast } from "react-toastify";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import authActions from "../../../../Redux/reducers/auth/actions";
import { isMobile, isTablet } from "react-device-detect";
import PlacesAutocomplete from "../../../../Components/PlaceAutoComplete";
import { motion } from "framer-motion";

const ProfilePersonalInfoMobile = () => {
  // styles and media queries
  const classes = styles();
  const md = useMediaQuery(theme.breakpoints.down("md"));

  // calender
  const [calendarOpen, setCalendarOpen] = useState(false);

  // navigation
  const navigate = useNavigate();

  // Redux
  const { userData } = useSelector((state) => state.auth);
  const { setUserData } = authActions;
  const dispatch = useDispatch();

  //loader
  const [pageLoader, setPageLoader] = useState(false);

  // edit
  const [edit, setEdit] = useState(false);

  // personal info data
  const [personalInfoData, setPersonalInfoData] = useState({});

  //profile image
  const [profileImage, setProfileImage] = useState("");
  const [profileImageErr, setProfileImageErr] = useState("");

  //form state
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [nickName, setNickName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [birthDate, setBirthDate] = useState(null);
  const [showBirthDate, setshowBirthDate] = useState("");
  const [address, setAddress] = useState("");
  const [imageObject, setImageObject] = useState({});

  //form error state
  const [firstNameErrorText, setFirstNameErrorText] = useState("");
  const [lastNameErrorText, setLastNameErrorText] = useState("");
  const [nickNameErrorText, setNickNameErrorText] = useState("");
  const [emailErrorText, setEmailErrorText] = useState("");
  const [phoneNumberErrorText, setPhoneNumberErrorText] = useState("");
  const [birthDateErr, setBirthDateErr] = useState(false);
  const [birthDateErrorText, setBirthDateErrorText] = useState("");
  const [AddressErrorText, setAddressErrorText] = useState("");

  // btn loader
  const [updateBtnLoader, setUpdateBtnLoader] = useState(false);

  useEffect(() => {
    getCommonData();
  }, []);

  // upload image
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/jpeg"
    ) {
      setProfileImage(file);
      setImageObject(file);
    } else {
      toast("Only files with these extensions are allowed: png, jpg, jpeg.", {
        type: "error",
      });
    }
  };

  // change birth-date format
  const formattedBirthDate = (data) => {
    const birthDateStr = data?.birth_date;

    if (!birthDateStr) return "";

    const dateObj = new Date(birthDateStr);

    if (isNaN(dateObj.getTime())) {
      return "";
    }

    const day = dateObj.getDate().toString().padStart(2, "0");
    const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
    const year = dateObj.getFullYear();

    return `${day}/${month}/${year}`;
  };

  // set data
  const setData = (data) => {
    const firstName =
      !_.isEmpty(data?.firstname) &&
      !_.isNull(data?.firstname) &&
      !_.isUndefined(data?.firstname)
        ? data?.firstname
        : "";
    const lastName =
      !_.isEmpty(data?.lastname) &&
      !_.isNull(data?.lastname) &&
      !_.isUndefined(data?.lastname)
        ? data?.lastname
        : "";
    const nickName =
      !_.isEmpty(data?.nick_name) &&
      !_.isNull(data?.nick_name) &&
      !_.isUndefined(data?.nick_name)
        ? data?.nick_name
        : "";
    const email =
      !_.isEmpty(data?.email) &&
      !_.isNull(data?.email) &&
      !_.isUndefined(data?.email)
        ? data?.email
        : "";
    const phoneNumber =
      !_.isEmpty(String(data?.phone)) &&
      !_.isNull(data?.phone) &&
      !_.isUndefined(data?.phone)
        ? data?.phone
        : "";
    const birthDate =
      !_.isEmpty(String(data?.birth_date)) &&
      !_.isNull(data?.birth_date) &&
      !_.isUndefined(data?.birth_date)
        ? dayjs(data?.birth_date)
        : "";
    const showBirthDate =
      !_.isEmpty(String(data?.birth_date)) &&
      !_.isNull(data?.birth_date) &&
      !_.isUndefined(data?.birth_date)
        ? formattedBirthDate(data)
        : "";

    const address =
      !_.isEmpty(String(data?.address)) &&
      !_.isNull(data?.address) &&
      !_.isUndefined(data?.address)
        ? data?.address
        : "";

    const profilePic =
      !_.isEmpty(String(data?.profile_pic)) &&
      !_.isNull(data?.profile_pic) &&
      !_.isUndefined(data?.profile_pic)
        ? data?.profile_pic
        : "https://i.ibb.co/D4kB907/Goat-01-1.png";

    setFirstName(firstName);
    setLastName(lastName);
    setNickName(nickName);
    setEmail(email);
    setPhoneNumber(phoneNumber);
    setBirthDate(birthDate);
    setshowBirthDate(showBirthDate);
    setAddress(address);
    setPageLoader(false);
    setProfileImage(profilePic);
  };

  // validation
  const validation = () => {
    const phoneRegex = /^\d{10,14}$/;
    let isValid = true;
    // Utility function to calculate age
    const calculateAge = (birthDate) => {
      const today = dayjs();
      const birthDateDayjs = dayjs(birthDate);
      return today.diff(birthDateDayjs, "year");
    };

    if (firstName?.trim() === "") {
      setFirstNameErrorText("First name is required");
      isValid = false;
    }
    if (
      firstName?.trim() !== "" &&
      (!/^[a-zA-Z]+$/.test(firstName) || firstName.length < 3)
    ) {
      setFirstNameErrorText("Enter valid first name");
      isValid = false;
    }
    if (lastName?.trim() === "") {
      setLastNameErrorText("Last name is required");
      isValid = false;
    }
    if (
      lastName?.trim() !== "" &&
      (!/^[a-zA-Z]+$/.test(lastName) || lastName.length < 3)
    ) {
      setLastNameErrorText("Enter valid last name");
      isValid = false;
    }
    if (nickName?.trim() === "") {
      setNickNameErrorText("Username is required");
      isValid = false;
    }
    if (phoneNumber?.trim() === "") {
      setPhoneNumberErrorText("Phone number is required");
      isValid = false;
    }
    if (phoneNumber?.trim() !== "" && !phoneRegex?.test(phoneNumber)) {
      setPhoneNumberErrorText("Enter valid phone number");
      isValid = false;
    }
    if (!birthDate) {
      setBirthDateErr(true);
      setBirthDateErrorText("Birth Date is required.");
      isValid = false;
    } else {
      const age = calculateAge(birthDate);
      if (age < 18) {
        setBirthDateErr(true);
        setBirthDateErrorText("You must be at least 18 years old.");
        isValid = false;
      } else {
        setBirthDateErr(false);
      }
    }

    if (_.isEmpty(address)) {
      setAddressErrorText("Address is required");
      isValid = false;
    }

    if (isValid) {
      updatePersonalProfileData();
    }
  };

  // get common data api call
  const getCommonData = async () => {
    setPageLoader(true);
    let endPoints = Setting.endpoints.commonData;

    const params = {
      email: userData?.email,
    };

    try {
      const resp = await getAPIProgressData(endPoints, "POST", params);
      if (resp?.success) {
        if (!_.isEmpty(resp?.data)) {
          setPersonalInfoData(resp?.data);
          setData(resp?.data);
          dispatch(setUserData(resp?.data));
        }
      } else {
        toast(resp?.message, {
          type: "error",
        });
        setPageLoader(false);
      }
      setUpdateBtnLoader(false);
    } catch (error) {
      toast(error.message, {
        type: "error",
      });
      setPageLoader(false);
      setUpdateBtnLoader(false);
    }
  };

  // update personal info
  const updatePersonalProfileData = async () => {
    setUpdateBtnLoader(true);
    let endPoints = Setting.endpoints.userProfile;
    const toDate = !_.isNull(birthDate)
      ? dayjs(birthDate).format("YYYY-MM-DD")
      : "";
    const params = {
      "SignupForm[firstname]": firstName,
      "SignupForm[lastname]": lastName,
      "SignupForm[email]": userData?.email,
      "SignupForm[nick_name]": nickName,
      "SignupForm[phone_code]": "+1",
      "SignupForm[phone]": phoneNumber,
      "SignupForm[profile_pic]": imageObject,
      "SignupForm[birthdate]": toDate,
      "SignupForm[address]": address,
    };

    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
      if (resp?.success) {
        getCommonData();
        toast(resp?.message, {
          type: "success",
        });
        setEdit(false);
      } else {
        toast(resp?.message, {
          type: "error",
        });
      }
      setUpdateBtnLoader(false);
    } catch (error) {
      toast(error.message, {
        type: "error",
      });
      setUpdateBtnLoader(false);
    }
  };

  return (
    <Grid container>
      <CHeader
        title={"Personal"}
        edit={!pageLoader}
        onEditClick={() => {
          setEdit(!edit);
        }}
        backButtonClick={() => {
          navigate("/profile");
        }}
      />
      {pageLoader ? (
        <Grid
          container
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          height={"80vh"}
        >
          <CircularProgress size={40} color="primary" />
        </Grid>
      ) : (
        <>
          <Grid item xs={0.5} sm={0.5} md={1} lg={1}></Grid>

          <Grid
            item
            xs={11}
            sm={11}
            md={10}
            lg={10}
            style={{
              height:
                (window.matchMedia("(display-mode: standalone)")?.matches ||
                  window?.navigator?.standalone) &&
                (isMobile || isTablet)
                  ? "calc(100vh - 70px)"
                  : "calc(100vh - 150px)",
              overflowY: "auto",
            }}
          >
            <motion.div
              initial={{ opacity: 0, y: 50 }} // Start with the element lower and transparent
              animate={{ opacity: 1, y: 0 }} // Animate to fully opaque and original position
              exit={{ opacity: 0, y: 50 }} // Fade out and move down
              transition={{ delay: 1, duration: 0.3 }} // Duration in seconds
            >
              <Grid
                container
                height={edit ? "90%" : "100%"}
                style={{ overflowY: "auto" }}
              >
                <Grid item xs={12}>
                  <Grid
                    container
                    display="flex"
                    justifyContent="center"
                    mt={"20px"}
                  >
                    <div className={classes.createProfileContainer}>
                      <div className={classes.createProfileImageContainer}>
                        {profileImage ? (
                          <img
                            src={
                              _.isObject(profileImage)
                                ? URL.createObjectURL(profileImage)
                                : profileImage ||
                                  "https://i.ibb.co/D4kB907/Goat-01-1.png"
                            }
                            alt="Profile"
                            className={classes.createProfileImage}
                            draggable={false}
                            style={{
                              border: `3px solid ${BaseColor.primary}`,
                            }}
                          />
                        ) : (
                          <img
                            src={Files.svgIcons.userIcon}
                            alt="uploadImage"
                            className={classes.createProfilePlaceholderImg}
                            draggable={false}
                          />
                        )}
                      </div>
                      {edit && (
                        <label style={{ position: "relative" }}>
                          <CButton cameraIcon onClick={() => {}} />
                          <input
                            className={classes.createProfileContainer}
                            type="file"
                            accept="image/*"
                            id="inputTag"
                            onChange={(e) => {
                              handleImageUpload(e);
                            }}
                            style={{
                              display: "none",
                            }}
                          />
                        </label>
                      )}
                    </div>
                    {profileImageErr && (
                      <Typography variant="body2" color="error" align="center">
                        Please upload a profile image.
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} mt={"20px"}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Grid
                          container
                          display={"flex"}
                          justifyContent={"space-between"}
                        >
                          <Grid
                            item
                            xs={12}
                            sm={5.8}
                            md={5.8}
                            lg={5.8}
                            mb={"10px"}
                          >
                            {edit ? (
                              <CInput
                                value={firstName}
                                placeholder={"Enter first name"}
                                label={"First Name"}
                                onChange={(val) => {
                                  setFirstName(val);
                                  setFirstNameErrorText("");
                                }}
                                errorMsg={firstNameErrorText}
                                error={!_.isEmpty(firstNameErrorText)}
                              />
                            ) : (
                              <>
                                <Typography className={classes.inputTile}>
                                  First Name
                                </Typography>
                                <Typography className={classes.inputValue}>
                                  {firstName}
                                </Typography>
                              </>
                            )}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={5.8}
                            md={5.8}
                            lg={5.8}
                            mb={"10px"}
                          >
                            {edit ? (
                              <CInput
                                value={lastName}
                                placeholder={"Enter last name"}
                                label={"Last Name"}
                                onChange={(val) => {
                                  setLastName(val);
                                  setLastNameErrorText("");
                                }}
                                errorMsg={lastNameErrorText}
                                error={!_.isEmpty(lastNameErrorText)}
                              />
                            ) : (
                              <>
                                <Typography className={classes.inputTile}>
                                  Last Name
                                </Typography>
                                <Typography className={classes.inputValue}>
                                  {lastName}
                                </Typography>
                              </>
                            )}
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          display={"flex"}
                          justifyContent={"space-between"}
                        >
                          <Grid
                            item
                            xs={12}
                            sm={5.8}
                            md={5.8}
                            lg={5.8}
                            mb={"10px"}
                          >
                            {edit ? (
                              <CInput
                                value={nickName}
                                label={"Username"}
                                placeholder={"Enter username"}
                                onChange={(val) => {
                                  setNickName(val);
                                  setNickNameErrorText("");
                                }}
                                errorMsg={nickNameErrorText}
                                error={!_.isEmpty(nickNameErrorText)}
                              />
                            ) : (
                              <>
                                <Typography className={classes.inputTile}>
                                  Username
                                </Typography>
                                <Typography className={classes.inputValue}>
                                  {nickName}
                                </Typography>
                              </>
                            )}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={5.8}
                            md={5.8}
                            lg={5.8}
                            mb={"10px"}
                          >
                            {edit ? (
                              <CInput
                                value={email}
                                placeholder={"Enter email"}
                                label={"Email ID"}
                                onChange={(val) => {
                                  setEmail(val);
                                  setEmailErrorText("");
                                }}
                                errorMsg={emailErrorText}
                                error={!_.isEmpty(emailErrorText)}
                                disabled={true}
                              />
                            ) : (
                              <>
                                <Typography className={classes.inputTile}>
                                  Email ID
                                </Typography>
                                <Typography className={classes.inputValue}>
                                  {email}
                                </Typography>
                              </>
                            )}
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          display={"flex"}
                          justifyContent={"space-between"}
                        >
                          <Grid
                            item
                            xs={12}
                            sm={5.8}
                            md={5.8}
                            lg={5.8}
                            mb={"10px"}
                          >
                            {edit ? (
                              <CInput
                                value={phoneNumber}
                                label={"Phone Number"}
                                placeholder={"Enter phone number"}
                                onChange={(val) => {
                                  setPhoneNumber(val);
                                  setPhoneNumberErrorText("");
                                }}
                                errorMsg={phoneNumberErrorText}
                                error={!_.isEmpty(phoneNumberErrorText)}
                              />
                            ) : (
                              <>
                                <Typography className={classes.inputTile}>
                                  Phone Number
                                </Typography>
                                <Typography className={classes.inputValue}>
                                  {phoneNumber}
                                </Typography>
                              </>
                            )}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={5.8}
                            md={5.8}
                            lg={5.8}
                            mb={"10px"}
                          >
                            {edit ? (
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Typography
                                  sx={{
                                    color: "black",
                                    fontFamily: FontFamily.SemiBold,
                                    fontSize: 16,
                                    marginTop: 2,
                                    marginBottom: "10px",
                                  }}
                                >
                                  {"Birth Date"}
                                </Typography>
                                <DesktopDatePicker
                                  sx={{
                                    width: "100%",
                                  }}
                                  disableFuture
                                  views={["year", "month", "day"]}
                                  value={birthDate}
                                  onChange={(val) => {
                                    setBirthDate(val);
                                    setBirthDateErr(false);
                                    setBirthDateErrorText("");
                                  }}
                                  open={calendarOpen} // Use the state to control the calendar open/close
                                  onClose={() => setCalendarOpen(false)} // Close the calendar
                                  slotProps={{
                                    textField: {
                                      readOnly: true,
                                      inputProps: {
                                        style: {
                                          color: birthDateErr
                                            ? "red"
                                            : "inherit",
                                        },
                                      },
                                      onClick: () => setCalendarOpen(true), // Open the calendar when input is clicked
                                      sx: {
                                        width: "100%",
                                        "& .MuiOutlinedInput-root": {
                                          "& fieldset": {
                                            borderColor: birthDateErr
                                              ? "red"
                                              : "default",
                                          },
                                          "&:hover fieldset": {
                                            borderColor: birthDateErr
                                              ? "red"
                                              : "default",
                                          },
                                          "&.Mui-focused fieldset": {
                                            borderColor: birthDateErr
                                              ? "red"
                                              : "default",
                                          },
                                        },
                                      },
                                    },
                                  }}
                                  onOpen={() => setCalendarOpen(true)}
                                />

                                {birthDateErr && (
                                  <div
                                    style={{
                                      marginTop: 6,
                                      marginLeft: 3,
                                      fontSize: 14,
                                      color: BaseColor.errorRed,
                                      fontFamily: FontFamily.Medium,
                                    }}
                                  >
                                    {birthDateErrorText}
                                  </div>
                                )}
                              </LocalizationProvider>
                            ) : (
                              <>
                                <Typography className={classes.inputTile}>
                                  Birth Date
                                </Typography>
                                <Typography className={classes.inputValue}>
                                  {showBirthDate}
                                </Typography>
                              </>
                            )}
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} mb={"20px"}>
                          {edit ? (
                            <>
                              {/* <CInput
                              label={"Address"}
                              placeholder={"Enter address"}
                              value={address}
                              onChange={(val) => {
                                setAddress(val);
                                setAddressErrorText("");
                              }}
                              errorMsg={AddressErrorText}
                              error={!_.isEmpty(AddressErrorText)}
                            /> */}
                              <PlacesAutocomplete
                                onChange={(val) => {
                                  setAddress(val?.location);
                                  setAddressErrorText(false);
                                }}
                                value={address}
                                error={!_.isEmpty(AddressErrorText)}
                                errorMsg={AddressErrorText}
                              />
                            </>
                          ) : (
                            <>
                              <Typography className={classes.inputTile}>
                                Address
                              </Typography>
                              <Typography className={classes.inputValue}>
                                {address}
                              </Typography>
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {edit && (
                <Grid
                  container
                  height={"10%"}
                  style={{
                    position: "sticky",
                    bottom: 0,
                    backgroundColor: BaseColor.white,
                    minHeight: "60px",
                  }}
                >
                  <Grid item xs={12} display={"flex"} alignItems={"center"}>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: md ? "space-between" : "flex-end",
                        alignItems: "center",
                      }}
                    >
                      <CButton
                        variant={md ? "outlined" : "textPrimary"}
                        btnStyle={{ width: "50%" }}
                        onClick={() => {
                          setEdit(false);
                          setData(personalInfoData);
                        }}
                      >
                        Cancel
                      </CButton>
                      <CButton
                        variant="contained"
                        loading={updateBtnLoader}
                        onClick={() => {
                          validation();
                        }}
                        btnStyle={{ width: "50%", marginLeft: "10px" }}
                      >
                        Update
                      </CButton>
                    </div>
                  </Grid>
                </Grid>
              )}
            </motion.div>
          </Grid>

          <Grid item xs={0.5} sm={0.5} md={1} lg={1}></Grid>
        </>
      )}
    </Grid>
  );
};

export default ProfilePersonalInfoMobile;
