import { makeStyles } from "@mui/styles";

import { isMobile } from "react-device-detect";
import { FontFamily } from "../../../../Config/theme";
import BaseColor from "../../../../Config/colors";

const styles = makeStyles({
  switchDiv: {
    display: "flex",
    borderRadius: "20px",
    backgroundColor: BaseColor.offWhiteBg,
    "@media (max-width:900px)": {
      width: "100%",
    },
  },
  switchTab: {
    padding: "12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "20px",
    width: "50%",
    cursor: "pointer",
  },
  scrollBar: {
    // overflowX: "scroll",
    "&::-webkit-scrollbar": {
      width: 3,
      height: 3,
    },

    /* Track */
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 5px transperent",
      borderRadius: 10,
    },

    /* Handle */
    "&::-webkit-scrollbar-thumb": {
      background: BaseColor.primary,
      borderRadius: 10,
    },

    /* Handle on hover */
    "&::-webkit-scrollbar-thumb:hover": {
      boxShadow: "inset 0 0 5px grey",
    },
  },
});

export default styles;
