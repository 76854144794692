import { makeStyles } from "@mui/styles";
import BaseColor from "../../Config/colors";
import { isMobile } from "react-device-detect";
import { FontFamily } from "../../Config/theme";

const useStyles = makeStyles((theme) => ({
  icon: {
    borderRadius: 4,
    width: isMobile ? 22 : 24,
    height: isMobile ? 22 : 24,
    borderWidth: "1px",
    boxShadow: "inset 0 0 0 1px #797979, inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "transparent",
  },
  checkedIcon: {
    borderRadius: 4,
    borderWidth: 20,
    backgroundColor: BaseColor.primary,
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: isMobile ? 22 : 24,
      height: isMobile ? 22 : 24,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
  },
  errorMsgContainer: {
    marginTop: 6,
    marginLeft: 3,
  },
  errorMsgText: {
    fontSize: 14,
    color: BaseColor.errorRed,
    fontFamily: FontFamily.Medium,
  },
  scrollBar: {
    // overflowX: "scroll",
    "&::-webkit-scrollbar": {
      width: 3,
      height: 3,
    },

    /* Track */
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 5px transperent",
      borderRadius: 10,
    },

    /* Handle */
    "&::-webkit-scrollbar-thumb": {
      background: BaseColor.primary,
      borderRadius: 10,
    },

    /* Handle on hover */
    "&::-webkit-scrollbar-thumb:hover": {
      boxShadow: "inset 0 0 5px grey",
    },
  },
}));

export default useStyles;
