import { makeStyles } from "@mui/styles";
import BaseColor from "../../Config/colors";
const styles = makeStyles({
  inputMessage: {
    backgroundColor: `${BaseColor.popOverSeperate} !important`,
    borderRadius: "12px !important",
    boxShadow: "none !important",
  },
});

export default styles;
