import { makeStyles } from "@mui/styles";
import theme, { FontFamily } from "../../../../Config/theme";
import BaseColor from "../../../../Config/colors";

const styles = makeStyles({
  container: {
    borderRadius: "10px",
    marginTop: "50px !important",
    marginLeft: "10px !important",
    paddingLeft: "20px",
  },
  card: {
    borderRadius: "15px",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
    padding: "10px",
    backgroundColor: "#042B48 !important",
  },
  headerContainer: {
    marginTop: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  backButton: {
    fontSize: 30,
    marginLeft: 10,
  },
  headerTitle: {
    fontSize: "22px !important",
    color: BaseColor.blackColor,
    textAlign: "center ",
    fontFamily: `${FontFamily.Bold} !important`,
    marginRight: "30px !important",
  },
  subscriptionInfo: {
    marginBottom: "10px !important",
    marginTop: "10px !important",
    fontFamily: `${FontFamily.Medium} !important`,
    fontSize: "15px !important",
    color: `${BaseColor.white} !important`,
  },
  priceContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  price: {
    fontFamily: FontFamily.Bold,
    fontSize: 24,
    marginRight: theme.spacing(1),
    color: BaseColor.white,
  },
  subscriptionStatus: {
    fontFamily: FontFamily.Bold,
    fontSize: 15,
  },
  subscribeButton: {
    marginTop: "20px",
  },
  subscriptionWarning: {
    color: `${BaseColor.white} !important`,
    fontFamily: `${FontFamily.Regular} !important`,
    fontSize: "18px !important",
  },
  expireText: {
    fontFamily: FontFamily.Bold,
    color: BaseColor.red,
    fontSize: "18px !important",
  },
});

export default styles;
