import React, { useEffect, useState } from "react";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import "./styles.css";
import { Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { introScreenData } from "../../Config/staticData.js";
import BaseColor from "../../Config/colors.js";
import { FontFamily } from "../../Config/theme.js";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import authActions from "../../Redux/reducers/auth/actions";
import { isTablet } from "react-device-detect";

const useStyles = makeStyles(() => ({
  container: {
    margin: "0px 20px",
    minHeight: "100vh",
  },
  sliderContainer: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    height: isTablet ? "70vh" : "60vh",
    "@media (max-width: 900px)": {
      display: "block",
    },
    marginTop: "20px",
  },
  image: {
    width: "100%",
    height: isTablet ? "65vh" : "55vh",
    marginTop: "20px",
  },
  dots: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "20px 0",
  },
  getStartedButton: {
    position: "fixed",
    textAlign: "center",
    width: isTablet ? "95%" : "90%",
    bottom: 0,
    marginBottom: "20px",
    zIndex: "9999",
  },
  introButton: {
    backgroundColor: BaseColor.primary,
    color: BaseColor.white,
    width: "100%",
  },
}));

const Intro = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { setWalkthrough } = authActions;
  const navigate = useNavigate();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [sliderRef, instanceRef] = useKeenSlider({
    initial: 0,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
  });

  // Autoplay functionality
  useEffect(() => {
    if (loaded && instanceRef.current) {
      const interval = setInterval(() => {
        instanceRef.current.next();
      }, 3000); // Adjust the interval duration as needed (in milliseconds)

      return () => clearInterval(interval);
    }
  }, [loaded, instanceRef]);

  return (
    <div className={classes.container}>
      {!_.isEmpty(introScreenData) && _.isArray(introScreenData) ? (
        <div className={classes.sliderContainer}>
          <div ref={sliderRef} className="keen-slider">
            {introScreenData.map((e, index) => (
              <div key={index} className={`keen-slider__slide`}>
                <img
                  src={e.image}
                  alt={`Intro ${index + 1}`}
                  className={classes.image}
                  draggable={false}
                />
              </div>
            ))}
          </div>
        </div>
      ) : null}
      {loaded && instanceRef.current && (
        <div className={classes.dots}>
          {[
            ...Array(instanceRef.current.track.details.slides.length).keys(),
          ].map((idx) => {
            return (
              <button
                key={idx}
                onClick={() => {
                  instanceRef.current?.moveToIdx(idx);
                }}
                className={"dot" + (currentSlide === idx ? " active" : "")}
              ></button>
            );
          })}
        </div>
      )}
      <Typography
        style={{
          marginTop: "10px",
          color: BaseColor.textColor,
          fontFamily: FontFamily.GilbertQualifiDemo,
          fontSize: 18,
          textAlign: "center",
          height: "50px",
        }}
      >
        {introScreenData[currentSlide] && introScreenData[currentSlide].title}
      </Typography>
      <div className={classes.getStartedButton}>
        <Button
          variant="contained"
          className={classes.introButton}
          onClick={() => {
            dispatch(setWalkthrough(false));
            navigate("/login");
          }}
        >
          Get started
        </Button>
      </div>
    </div>
  );
};

export default Intro;
