import { Grid, Step, StepLabel, Stepper, useMediaQuery } from "@mui/material";
import React from "react";
import theme, { FontFamily } from "../../Config/theme";
import BaseColor from "../../Config/colors";

const CStepper = (props) => {
  const { activeStep, steps = [] } = props;

  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sm={12}
        style={{
          paddingRight: "20px",
          paddingLeft: "20px",
          paddingTop: "20px",
        }}
      >
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          sx={{
            width: "100%",
            fontFamily: FontFamily.Medium,
            "& .MuiStepLabel-alternativeLabel": {
              fontSize: sm ? "14px" : "16px",
              fontFamily: FontFamily.Medium,
            },
            "& .MuiStepIcon-root": {
              fontSize: "35px",
              fontFamily: FontFamily.Medium,
            },
            "& .MuiStepConnector-root": {
              marginTop: "5px",
              border: `0px dashed ${BaseColor.grey}`,
            },
            "& .MuiStepIcon-text": {
              fontFamily: FontFamily.Medium,
            },
            "& .css-z7uhs0-MuiStepConnector-line": {
              borderTopStyle: "dashed",
              borderTopWidth: "2px",
            },
            "& .MuiStepConnector-root.Mui-active .MuiStepConnector-line": {
              borderColor: BaseColor.primary,
              borderTopStyle: "solid",
            },
            "& .MuiStepConnector-root.Mui-completed .MuiStepConnector-line": {
              borderColor: BaseColor.primary,
              borderTopStyle: "solid",
            },
            "& .css-95m0ql": {
              borderTopStyle: "dashed",
              borderTopWidth: "2px",
            },
          }}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Grid>
    </Grid>
  );
};

export default CStepper;
