import React, { useEffect, useState } from "react";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import CHeader from "../../Components/Header/index.js";
import theme from "../../Config/theme.js";
import BaseColor from "../../Config/colors.js";
import styles from "./styles.js";
import CIcon from "../../Components/CIcon/index.js";
import { sideBarTabs } from "../../Config/staticData.js";
import { store } from "../../Redux/store/configureStore.js";
import authActions from "../../Redux/reducers/auth/actions.js";
import { useDispatch, useSelector } from "react-redux";
import MapComponent from "./MapComponent/index.js";
import { useNavigate } from "react-router-dom";
import { isIOS, isMobile, isTablet } from "react-device-detect";
import { socket } from "../../Context/ContextSocket.js";
import { motion } from "framer-motion";

export default function Map() {
  // styles and media queries
  const classes = styles();
  const md = useMediaQuery(theme.breakpoints.down("md"));

  // unread count
  const { isUnread } = useSelector((state) => state.auth);

  // navigation
  const navigate = useNavigate();

  // Redux
  const {
    auth: { activeTab },
  } = store.getState();
  const dispatch = useDispatch();
  const {
    setIsUnread,
    setActiveHomeTab,
    setBackLatLng,
    setBackSearch,
    setBackState,
    setBackLocationLatLng,
  } = authActions;

  // unread messages
  const [hasUnreadMessages, setHasUnreadMessages] = useState(false);
  const { userData } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(setActiveHomeTab("Map"));
    userConnect();
  }, []);

  // user connect emit
  const userConnect = () => {
    const requestdata = {
      user_id: userData.id,
      type: "",
      chat_type: "s",
      page: 1,
    };
    socket.emit("user_connection", requestdata, (response) => {
      const hasUnread = response?.data?.conversations?.some(
        (conversation) => conversation?.unread > 0
      );
      setHasUnreadMessages(hasUnread);
      if (!hasUnread) {
        dispatch(setIsUnread(hasUnread));
      } else {
        dispatch(setIsUnread(hasUnread));
      }
      if (response.status === false) {
      } else {
      }
    });
  };

  const [isZoomed, setIsZoomed] = useState(false);

  return (
    <Grid container style={{ background: BaseColor.white }}>
      <CHeader />
      {md || isTablet || isMobile ? (
        <>
          <Grid item xs={0.5} sm={0.5} md={1} lg={1} />
          <Grid
            item
            xs={11}
            sm={11}
            md={10}
            lg={10}
            style={{
              height:
                (window.matchMedia("(display-mode: standalone)")?.matches ||
                  window?.navigator?.standalone) &&
                (isMobile || isTablet)
                  ? "calc(100vh - 150px )"
                  : "calc(100vh - 150px )",
              overflowY: "auto",
            }}
          >
            <Grid container height={"100%"}>
              <Grid
                item
                xs={12}
                style={{
                  overflowX: "auto",
                  height: "100%",
                  paddingBottom:
                    isMobile && isIOS ? "30px" : isTablet ? "50px" : "10px",
                }}
              >
                <MapComponent />
              </Grid>
            </Grid>

            <Grid
              container
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                position: "fixed",
                left: "0px",
                bottom: "0px",
                backgroundColor: BaseColor.white,
                zIndex: 1111,
                height: "80px",
              }}
            >
              {sideBarTabs?.map((data) => (
                <motion.div
                  key={data?.title}
                  whileTap={{ scale: data?.title === "Map" ? 0.8 : null }}
                  whileHover={{ scale: 0.9 }}
                  initial={{ scale: data?.title === "Map" ? 0.7 : null }}
                  animate={{
                    scale: isZoomed && data?.title === "Map" ? 1.1 : 1,
                  }}
                  style={{ position: "relative", textAlign: "center" }}
                >
                  <CIcon
                    src={
                      activeTab === data?.title ? data?.mobFillIcon : data?.icon
                    }
                    onClick={() => {
                      dispatch(setBackLatLng({}));
                      if (data?.title === "Home") {
                        dispatch(setActiveHomeTab(data?.title));
                        dispatch(setBackSearch(""));
                        dispatch(setBackState({}));
                        dispatch(setBackLocationLatLng({}));
                        navigate("/home");
                      }
                      if (data?.title === "Events") {
                        dispatch(setActiveHomeTab(data?.title));
                        navigate("/event");
                      }
                      if (data.title === "Chat") {
                        dispatch(setActiveHomeTab(data?.title));
                        navigate(data.path);
                      }
                    }}
                    size={35}
                  />
                  {isUnread && data?.title === "Chat" && (
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        backgroundColor: "green",
                        width: 12,
                        height: 12,
                        borderRadius: "50%",
                        zIndex: 1111,
                      }}
                    />
                  )}
                  <Typography className={classes.iconText}>
                    {data?.title}
                  </Typography>
                </motion.div>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={0.5} sm={0.5} md={1} lg={1} />
        </>
      ) : (
        <>
          <Grid item xs={0.5} sm={0.5} md={1} lg={1}>
            <Grid
              container
              style={{
                height: "calc(100vh - 90px)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              {sideBarTabs?.map((data) => (
                <>
                  <motion.div
                    className={classes.iconContainer}
                    style={{
                      backgroundColor:
                        activeTab === data?.title
                          ? BaseColor.primary
                          : "#EDF1F2",
                      position: "relative",
                    }}
                    onClick={() => {
                      dispatch(setBackLatLng({}));
                      dispatch(setBackSearch(""));
                      dispatch(setBackState({}));
                      dispatch(setBackLocationLatLng({}));
                      if (data?.title === "Home") {
                        dispatch(setActiveHomeTab(data?.title));
                        navigate("/home");
                      }
                      if (data?.title === "Events") {
                        dispatch(setActiveHomeTab(data?.title));
                        navigate("/event");
                      }
                      if (data.title === "Chat") {
                        dispatch(setActiveHomeTab(data?.title));
                        navigate(data.path);
                      }
                    }}
                    whileTap={{ scale: data?.title === "Map" ? 0.8 : null }}
                    whileHover={{ scale: 0.9 }}
                    initial={{ scale: data?.title === "Map" ? 0.7 : null }}
                    animate={{
                      scale: isZoomed && data?.title === "Map" ? 1.1 : 1,
                    }}
                    transition={{ duration: 0.4 }}
                  >
                    {isUnread && data?.title === "Chat" && (
                      <div
                        style={{
                          position: "absolute",
                          top: 10,
                          right: 8,
                          backgroundColor: "green",
                          width: 12,
                          height: 12,
                          borderRadius: "50%",
                          zIndex: 1111,
                        }}
                      />
                    )}
                    <CIcon
                      src={
                        activeTab === data?.title ? data?.fillIcon : data?.icon
                      }
                      size={35}
                      style={{ cursor: !isMobile && !isTablet && "pointer" }}
                    />
                  </motion.div>
                  <Typography
                    className={classes.iconText}
                    style={{
                      color:
                        activeTab === data?.title
                          ? BaseColor.primary
                          : BaseColor.grey,
                      marginBottom: "20px",
                    }}
                  >
                    {data?.title}
                  </Typography>
                </>
              ))}
            </Grid>
          </Grid>
          <Grid
            items
            xs={11.5}
            sm={11.5}
            md={11}
            lg={11}
            paddingTop={"20px"}
            style={{ overflowX: "auto", height: "calc(100vh - 90px)" }}
            // className={classes.scrollBar}
          >
            <Grid container style={{ maxHeight: "calc(100vh - 90px)" }}>
              <Grid
                item
                xs={11.5}
                sm={11.5}
                md={11}
                lg={11}
                style={{ overflowX: "auto" }}
              >
                <MapComponent />
              </Grid>
              <Grid item xs={0.5} sm={0.5} md={1} lg={1}></Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
    // </Grid>
  );
}
