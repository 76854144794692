import React, { useEffect, useState } from "react";
import useStyles from "./styles.js";
import Files from "../../Config/Files.js";
import {
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import theme, { FontFamily } from "../../Config/theme.js";
import CButton from "../../Components/CButton/index.js";
import BaseColor from "../../Config/colors";
import CModal from "../../Components/CModal";
import CloseIcon from "@mui/icons-material/Close";
import CInput from "../../Components/CInput/index.js";
import { isMobile, isTablet, isIOS } from "react-device-detect";
import { CiEdit } from "react-icons/ci";
import Carousel from "react-material-ui-carousel";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import COtpInput from "../../Components/COtpInput/index.js";
import _ from "lodash";
import CHeader from "../../Components/Header";
import CGoogle from "../../Components/CGoogle";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Setting } from "../../Utils/Setting.js";
import { getApiData, getAPIProgressData } from "../../Utils/APIHelper";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import authActions from "../../Redux/reducers/auth/actions";
import Lottie from "lottie-react";
import CApple from "../../Components/CApple/index.js";
import { useReactPWAInstall } from "react-pwa-install";

export default function Login() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setUserData, setAccessToken, setSignupData, setIsAdmin } =
    authActions;
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const signUpData = useSelector((state) => state.auth.signUpData);

  const [pageLoader, setPageLoader] = useState(true);

  const [openModal, setOpenModal] = useState(false);
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [accVerifyModal, setAccVerifyModal] = useState(false);
  const [reviewModalBool, setReviewModalBool] = useState(false);

  const [emailId, setEmailId] = useState("");
  const [emailIdErr, setEmailIdErr] = useState(false);
  const [emailIdMsg, setEmailIdMsg] = useState("");

  const [emailIdNew, setEmailIdNew] = useState("");
  const [emailIdNewErr, setEmailIdNewErr] = useState(false);
  const [emailIdNewMsg, setEmailIdNewMsg] = useState("");

  const [password, setPassword] = useState("");
  const [passwordErr, setPasswordErr] = useState(false);
  const [passwordMsg, setPasswordMsg] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showNewPasswordLogin, setShowNewPasswordLogin] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const [content, setContent] = useState("");

  useEffect(() => {
    getDescription();
  }, []);

  useEffect(() => {
    if (
      searchParams.has("token") &&
      searchParams.has("email") &&
      searchParams.has("isAdmin")
    ) {
      const token = searchParams.get("token");
      const email = searchParams.get("email");
      const isAdmin = searchParams.get("isAdmin") === "1" ? true : false;

      dispatch(setAccessToken(token));
      getCommonData(email);
      dispatch(setIsAdmin(isAdmin));
    }
  }, []);

  const getCommonData = async (email) => {
    let endPoints = Setting.endpoints.commonData;

    const params = {
      email: email,
    };

    try {
      const resp = await getAPIProgressData(endPoints, "POST", params);
      if (resp?.success) {
        if (!_.isEmpty(resp?.data)) {
          dispatch(setUserData({}));
          dispatch(setUserData(resp?.data));
          const isChatPage = searchParams.has("chat")
            ? searchParams.get("chat") === "1"
              ? true
              : false
            : false;
          if (isChatPage) {
            setTimeout(() => {
              navigate("/chat");
            }, 100);
          } else {
            setTimeout(() => {
              navigate("/profile");
            }, 100);
          }
          setSearchParams({}, { replace: true });
        }
      } else {
        toast(resp?.message, {
          type: "error",
        });
      }
    } catch (error) {
      toast(error.message, {
        type: "error",
      });
    }
  };

  const BgImgArr = md
    ? [
        {
          id: 1,
          url: require("../../../src/Assets/Images/MobileLoginbg1.png"),
        },
        {
          id: 2,
          url: require("../../../src/Assets/Images/mobileLoginBg2.png"),
        },
      ]
    : [
        {
          id: 1,
          url: require("../../../src/Assets/Images/bGImage.png"),
        },
        {
          id: 2,
          url: require("../../../src/Assets/Images/car.png"),
        },
      ];

  const [seconds, setSeconds] = useState(null);

  const startTimer = () => {
    setSeconds(3);

    const timerInterval = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);

    setTimeout(() => {
      setAccVerifyModal(false);
      clearInterval(timerInterval);
      setSeconds("");
      setOpenModal(true);
      setIsOtpVerify(true);
    }, 3000);
  };

  const [otp, setOTP] = useState("");
  const [timer, setTimer] = useState(90); // Initial timer value in seconds
  const [isTimerRunning, setIsTimerRunning] = useState(false);

  //Modal
  const [forgotPasswordModal, setForgotPasswordModal] = useState(false);
  const [isOtpVerify, setIsOtpVerify] = useState(false);
  const [otpError, setOtpError] = useState(false);
  const [otpMsg, setOtpMsg] = useState("");

  // Forgot password
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
  const [isFPOtpGenerated, setIsFPOtpGenerated] = useState(true);
  const [FpOtp, setFpOtp] = useState("");
  const [isFPOtpVerify, setIsFPOtpVerify] = useState(false); //  create password in forgot

  // for forgot password otp
  const [timerForgot, setTimerForgot] = useState(90); // Initial timer value in seconds
  const [isTimerRunningForgot, setIsTimerRunningForgot] = useState(true);
  const [otpErrorForgot, setOtpErrorForgot] = useState("");
  const [isOtpValidForgot, setIsOtpValidForgot] = useState(false);
  const [fpToken, setFpToken] = useState("");

  // create new password
  const [FPNewPassword, setFPNewPassword] = useState("");
  const [FPConfirmPassword, setFPConfirmPassword] = useState("");
  const [FPNewPasswordErrorText, setFPNewPasswordErrorText] = useState("");
  const [FPConfirmPasswordErrorText, setFPConfirmPasswordErrorText] =
    useState("");

  // Forgot password errors
  const [forgotPasswordEmailErrorText, setForgotPasswordEmailErrorText] =
    useState("");

  // show password
  const [showNewForgotPassword, setShowNewForgotPassword] = useState(false);
  const [showForgotConfirmPassword, setShowForgotConfirmPassword] =
    useState(false);

  //loader
  const [loader, setLoader] = useState(false);
  const [otpLoader, setOtpLoader] = useState(false);
  const [pwdLoader, setPwdLoader] = useState(false);
  const [getOtpFpBtnLoader, setGetOtpFpBtnLoader] = useState(false);
  const [verifyOtpFpBtnLoader, setVerifyOtpFpBtnLoader] = useState(false);
  const [saveForgotPasswordBtnLoader, setSaveForgotPasswordBtnLoader] =
    useState(false);
  const [loginBtnLoader, setLoginBtnLoader] = useState(false);
  const [resendLoader, setResendLoader] = useState(false);
  const [resendOtpLoader, setResendOtpLoader] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  // Forgot password Get otp validation
  const getOTPForgotPassword = (resendCount) => {
    if (forgotPasswordEmail.trim() === "") {
      setForgotPasswordEmailErrorText("Email is required");
    } else if (!isValidEmail(forgotPasswordEmail)) {
      setForgotPasswordEmailErrorText("Enter valid email");
    } else {
      generateOtpForgotPassword(resendCount);
    }
  };

  // for forgot password
  const isLengthValid = FPNewPassword.length >= 8;
  const hasUpperCase = /[A-Z]/.test(FPNewPassword);
  const hasSpecialChar = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(
    FPNewPassword
  );
  const hasNumberValid = /.*\d.*/.test(FPNewPassword);
  const hasBlank = /.*\s+.*/.test(FPNewPassword);
  const hasNumber = /[0-9]/.test(FPNewPassword);
  const passwordsMatch =
    FPNewPassword === FPConfirmPassword && FPNewPassword !== "";
  const { pwaInstall, supported, isInstalled } = useReactPWAInstall();

  // Countdown timer effect
  useEffect(() => {
    const interval = setInterval(() => {
      if (isTimerRunning && timer > 0) {
        setTimer((prevTimer) => prevTimer - 1);
      } else {
        setIsTimerRunning(false);
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [isTimerRunning, timer]);

  const handleClick = () => {
    pwaInstall({
      title: "Install Goatt App",
      logo: "https://i.ibb.co/0MB05MD/icon-192-maskable.png",
    })
      .then(() => {
        console.log(
          "App installed successfully or instructions for install shown"
        );
        setTimeout(() => {
          window?.location?.reload();
        }, 3000);
      })
      .catch(() => {
        console.log("user Cancelled");
      });
  };
  // Countdown timer effect for forgot password otp
  useEffect(() => {
    const interval = setInterval(() => {
      if (isTimerRunningForgot && timerForgot > 0) {
        setTimerForgot((prevTimer) => prevTimer - 1);
      } else {
        setIsTimerRunningForgot(false);
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [isTimerRunningForgot, timerForgot]);

  const [isEmailView, setIsEmailView] = useState(true);

  const isValidEmail = (email) => {
    const emailPattern = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    return emailPattern.test(email);
  };
  const handleResend = () => {
    setResendOtpLoader(true);
    requestOTP(false);
  };

  const getDescription = async () => {
    let endPoints = Setting.endpoints.chatMessage;
    try {
      const resp = await getApiData(endPoints, "GET", {}, true);
      if (resp?.success) {
        if (!_.isEmpty(resp?.data)) {
          const content = resp?.data?.find(
            (data) => data?.meta_key === "Home Page Content"
          );
          setContent(content?.meta_name);
          setPageLoader(false);
        }
      } else {
        setPageLoader(false);
      }
    } catch (error) {
      toast(error.message, {
        type: "error",
      });
      setPageLoader(false);
    }
  };

  const handleGetOTP = () => {
    let valid = true;
    if (_.isEmpty(emailIdNew)) {
      setEmailIdNewErr(true);
      setEmailIdNewMsg("Please enter email");
      valid = false;
    } else if (!isValidEmail(emailIdNew)) {
      setEmailIdNewErr(true);
      setEmailIdNewMsg("Please enter valid email");
      valid = false;
    } else {
      setEmailIdNewErr(false);
      setEmailIdNewMsg("");
    }
    if (valid) {
      requestOTP(true); // API Call
    }
  };

  const handleVerifyOTPForgotPassword = () => {
    let isOtpValid = true;
    if (FpOtp.trim() === "") {
      setOtpErrorForgot("Please enter the OTP");
      isOtpValid = false;
    } else if (FpOtp.trim().length !== 4) {
      setOtpErrorForgot("OTP should be 4 characters long.");
      isOtpValid = false;
    } else {
      setOtpError(false);
      setOtpErrorForgot("");
    }

    if (isOtpValid) {
      verifyOtpForgotPassword(); // API Call
    }
  };

  const requestOTP = async (isNewId) => {
    setLoader(true);
    let endPoints = Setting.endpoints.signupOtp;

    const params = {
      "OTP[identifier]": emailIdNew || signUpData?.email,
      "OTP[resend]": isNewId ? 0 : 1,
      "OTP[type]": "signup",
    };

    try {
      const resp = await getAPIProgressData(endPoints, "POST", params);
      if (resp?.success) {
        toast.success(resp?.message, {
          position: "top-right",
        });
        setIsTimerRunning(true);
        setIsEmailView(false); // Switch to OTP view
        setTimer(90);
        dispatch(setSignupData({ ...signUpData, email: emailIdNew }));
      } else {
        if (resp?.data?.signup_pending === 1) {
          setIsEmailView(false);
          setOpenModal(false);
          setOpenLoginModal(true);
          setEmailId(emailIdNew);
          dispatch(setSignupData({ ...signUpData, email: emailIdNew }));
        } else {
          setIsEmailView(true);
        }
        toast.error(resp?.message, {
          position: "top-right",
        });
      }
      setLoader(false);
      setResendOtpLoader(false);
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
      });
      setLoader(false);
      setIsEmailView(true);
      setResendOtpLoader(false);
    }
  };

  const handleVerifyOTP = () => {
    let isOtpValid = true;
    if (otp.trim() === "") {
      setOtpError(true);
      setOtpMsg("Please enter the OTP");
      isOtpValid = false;
    } else if (otp.trim().length !== 4) {
      setOtpError(true);
      setOtpMsg("OTP should be 4 characters long.");
      isOtpValid = false;
    } else {
      setOtpError(false);
      setOtpMsg("");
    }

    if (isOtpValid) {
      verifyOTP(); // API Call
    }
  };

  const verifyOTP = async () => {
    setOtpLoader(true);
    let endPoints = Setting.endpoints.verifyOtp;
    const params = {
      email: emailIdNew || signUpData?.email,
      otp: otp,
    };

    try {
      const resp = await getAPIProgressData(endPoints, "POST", params);
      if (resp?.success) {
        setAccVerifyModal(true);
        setIsOtpVerify(true);
        setReviewModalBool(false);
        startTimer();
      } else {
        toast.error(resp?.message, {
          position: "top-right",
        });
        setIsOtpVerify(false);
        setOTP("");
      }
      setOtpLoader(false);
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
      });
      setOtpLoader(false);
      setIsOtpVerify(false);
      setOTP("");
    }
  };

  // get OTP for forgot password
  const generateOtpForgotPassword = async (resendCount) => {
    setGetOtpFpBtnLoader(true);
    let endPoints = Setting.endpoints.signupOtp;

    const params = {
      "OTP[identifier]": forgotPasswordEmail,
      "OTP[resend]": resendCount,
      "OTP[type]": "forgot",
    };

    try {
      const resp = await getAPIProgressData(endPoints, "POST", params);
      if (resp?.success) {
        toast.success(resp?.message, {
          position: "top-right",
        });
        setIsFPOtpGenerated(true);
        setIsTimerRunningForgot(true);
        setTimerForgot(90);
      } else {
        toast(resp?.message, { type: "error" });
      }
      setResendLoader(false);
      setGetOtpFpBtnLoader(false);
    } catch (error) {
      toast(error.message, {
        type: "error",
      });
      setResendLoader(false);
      setGetOtpFpBtnLoader(false);
    }
  };

  // verify OTP for forgot password
  const verifyOtpForgotPassword = async () => {
    setVerifyOtpFpBtnLoader(true);
    let endPoints = Setting.endpoints.verifyForgotPasswordOtp;

    const params = {
      email: forgotPasswordEmail,
      otp: FpOtp,
    };

    try {
      const resp = await getAPIProgressData(endPoints, "POST", params);
      if (resp?.success) {
        toast(resp?.message, {
          type: "success",
        });
        setFpToken(resp?.data);
        setIsFPOtpVerify(true);
        setFpOtp("");
        setIsFPOtpGenerated(false);
      } else {
        toast(resp?.message, {
          type: "error",
        });
        setFpOtp("");
        setOtpErrorForgot("");
      }
      setVerifyOtpFpBtnLoader(false);
    } catch (error) {
      toast(error.message, {
        type: "error",
      });
      setVerifyOtpFpBtnLoader(false);
      setFpOtp("");
      setOtpErrorForgot("");
    }
  };

  // create new password in forgot password
  const createPasswordForgotPassword = async () => {
    setSaveForgotPasswordBtnLoader(true);
    let endPoints = Setting.endpoints.forgotPassword;

    const params = {
      email: forgotPasswordEmail,
      "ChangePasswordForm[password]": FPNewPassword,
      "ChangePasswordForm[confirm_password]": FPConfirmPassword,
      token: fpToken,
    };

    try {
      const resp = await getAPIProgressData(endPoints, "POST", params);
      if (resp?.success) {
        toast(resp?.message, {
          type: "success",
        });
        setIsFPOtpVerify(false);
        setSaveForgotPasswordBtnLoader(false);
        setForgotPasswordModal(false);
        setForgotPasswordEmail("");
        setForgotPasswordEmailErrorText("");
        setFPNewPassword("");
        setFPConfirmPassword("");
        setIsFPOtpGenerated(false);
        setIsFPOtpVerify(false);
        setOpenLoginModal(true);
      } else {
        toast(resp?.message, {
          type: "error",
        });
      }
      setSaveForgotPasswordBtnLoader(false);
    } catch (error) {
      toast(error.message, {
        type: "error",
      });
      setSaveForgotPasswordBtnLoader(false);
    }
  };

  const getTruncatedEmail = (email) => {
    const [username, domain] = email.split("@");
    const truncatedUsername = username.slice(0, 4) + "....";
    const truncatedEmail = truncatedUsername + "@" + domain;
    return truncatedEmail;
  };
  const resetModalState = () => {
    setEmailId("");
    setOTP("");
    setIsEmailView(true); // Reset to email view
    setOpenModal(false);
  };

  const resetModalStateLogin = () => {
    setEmailId("");
    setPassword("");
    setOpenLoginModal(false);
  };
  const validateForm = () => {
    const uppercaseRegex = /[A-Z]/;
    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
    let valid = true;

    if (_.isEmpty(emailId)) {
      setEmailIdErr(true);
      setEmailIdMsg("Please enter email");
      valid = false;
    } else if (!isValidEmail(emailId)) {
      setEmailIdErr(true);
      setEmailIdMsg("Please enter valid email");
      valid = false;
    } else {
      setEmailIdErr(false);
      setEmailIdMsg("");
    }

    if (_.isEmpty(password)) {
      setPasswordErr(true);
      setPasswordMsg("Please enter password");
      valid = false;
      // } else if (password.length < 8) {
      //   setPasswordErr(true);
      //   setPasswordMsg("Password must be at least 8 characters long");
      //   valid = false;
      // } else if (!uppercaseRegex.test(password)) {
      //   setPasswordErr(true);
      //   setPasswordMsg("Password must contain at least one uppercase letter");
      //   valid = false;
      // } else if (!specialCharRegex.test(password)) {
      //   setPasswordErr(true);
      //   setPasswordMsg("Password must contain at least one special character");
      //   valid = false;
    } else {
      setPasswordErr(false);
      setPasswordMsg("");
    }

    if (valid) {
      loginUser(); // API Call
    }
  };

  const clearData = () => {
    setEmailId("");
    setPassword("");
  };

  const loginUser = async () => {
    setLoginBtnLoader(true);
    let endPoints = Setting.endpoints.login;
    const params = {
      "LoginForm[email]": emailId,
      "LoginForm[password]": password,
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", params);

      if (resp?.success) {
        const data = resp?.data;
        if (data?.signup_pending) {
          navigate("/new-member", { state: { data } });
          dispatch(setAccessToken(data?.user_data?.access_token));
          dispatch(setUserData(data));
        } else if (data?.under_review) {
          setAccVerifyModal(true);
          setReviewModalBool(true);
          setOpenLoginModal(false);
        } else {
          clearData();
          navigate("/home");
          dispatch(setAccessToken(data?.access_token));
          dispatch(setUserData(data));
          toast.success(resp?.message, { position: "top-right" });
        }
        setLoginBtnLoader(false);
      } else {
        toast.error(resp?.message, { position: "top-right" });
      }
      setLoginBtnLoader(false);
    } catch (error) {
      toast.error(error?.message, {
        position: "top-right",
      });
      setLoginBtnLoader(false);
    }
  };

  //sign up generate Password
  const [newPass, setNewPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [newPassError, setNewPassError] = useState("");
  const [confirmPassError, setConfirmPassError] = useState("");

  // for signup create  password
  const isLengthValid2 = newPass.length >= 8;
  const hasUpperCase2 = /[A-Z]/.test(newPass);
  const hasSpecialChar2 = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(newPass);
  const hasNumber2 = /[0-9]/.test(newPass);
  const hasBlankValid = /.*\s+.*/.test(newPass);
  const passwordsMatch2 = newPass === confirmPass && newPass !== "";

  const handleNewPassChange = (value) => {
    const trimmedValue = value.trim();
    setNewPass(trimmedValue);
    setNewPassError("");
  };

  const handleConfirmPassChange = (value) => {
    const trimmedValue = value.trim();
    setConfirmPass(trimmedValue);
    setConfirmPassError("");
  };

  const handleSubmit = () => {
    if (!newPass.trim()) {
      setNewPassError("Password is required");
      return;
    }

    if (newPass.length < 8) {
      setNewPassError("Password must be at least 8 characters long");
      return;
    }

    const uppercaseRegex = /[A-Z]/;
    if (!uppercaseRegex.test(newPass)) {
      setNewPassError("Password must contain at least one uppercase letter");
      return;
    }

    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
    if (!specialCharRegex.test(newPass)) {
      setNewPassError("Password must contain at least one special character");
      return;
    }
    const hasNumber = /.*\d.*/;
    if (!hasNumber.test(newPass)) {
      setNewPassError("Password must contain at least one number");
      return;
    }
    const hasBlankValid = /.*\s+.*/;
    if (hasBlankValid.test(newPass)) {
      setNewPassError("Blank space is not allowed");
      return;
    }
    if (confirmPass?.trim() === "") {
      setConfirmPassError("Confirm password is required");
      return;
    }

    if (confirmPass !== newPass && confirmPass?.trim() !== "") {
      setConfirmPassError("Password and confirm password should be the same");
      return;
    }
    // Password is valid, proceed with form submission or other actions
    // Perform additional actions here, such as API calls, etc.
    getCreatePassword();
  };

  const getCreatePassword = async () => {
    setPwdLoader(true);
    let endPoints = Setting.endpoints.createPassword;
    const params = {
      email: emailIdNew || signUpData?.email,
      "ChangePasswordForm[password]": newPass,
      "ChangePasswordForm[confirm_password]": confirmPass,
      otp: otp || "",
    };

    try {
      const resp = await getAPIProgressData(endPoints, "POST", params);
      if (resp?.success) {
        toast.success(resp?.message, {
          position: "top-right",
        });
        dispatch(setAccessToken(resp?.data?.user_data?.access_token));
        dispatch(setUserData(resp?.data));
        setIsOtpVerify(true);
        setIsEmailView(false);
        navigate("/new-member", {
          state: {
            data: resp?.data,
          },
        });
      } else {
        toast.error(resp?.message, {
          position: "top-right",
        });
      }
      setPwdLoader(false);
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
      });
      setPwdLoader(false);
    }
  };

  //Forgot password validation
  const createNewPasswordValidation = () => {
    if (!FPNewPassword.trim()) {
      setFPNewPasswordErrorText("Password is required");
      return;
    }

    if (FPNewPassword.length < 8) {
      setFPNewPasswordErrorText("Password must be at least 8 characters long");
      return;
    }

    const uppercaseRegex = /[A-Z]/;
    if (!uppercaseRegex.test(FPNewPassword)) {
      setFPNewPasswordErrorText(
        "Password must contain at least one uppercase letter"
      );
      return;
    }

    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
    if (!specialCharRegex.test(FPNewPassword)) {
      setFPNewPasswordErrorText(
        "Password must contain at least one special character"
      );
      return;
    }
    const hasNumber = /.*\d.*/;
    if (!hasNumber.test(FPNewPassword)) {
      setFPNewPasswordErrorText("Password must contain at least one number");
      return;
    }
    const hasBlankValid = /.*\s+.*/;
    if (hasBlankValid.test(FPNewPassword)) {
      setFPNewPasswordErrorText("Blank space is not allowed");
      return;
    }
    if (FPConfirmPassword?.trim() === "") {
      setFPConfirmPasswordErrorText("Confirm password is required");
      return;
    }

    if (FPConfirmPassword !== FPNewPassword) {
      setFPConfirmPasswordErrorText(
        "Password and confirm password should be the same"
      );
      return;
    }

    createPasswordForgotPassword();
  };

  return (
    <Grid container className={classes.root} alignItems={"center"}>
      <CHeader />
      {pageLoader ? (
        <Grid
          container
          className={classes.pageLoader}
          style={{
            height: md ? "calc(100vh - 150px)" : "calc(100vh - 110px)",
          }}
        >
          <CircularProgress color="primary" size={40} />
        </Grid>
      ) : (
        <Grid
          container
          className={classes.root2}
          style={{
            marginTop: md && "10px",
            maxHeight: isMobile ? "calc(100vh - 140px)" : "calc(100vh - 80px)",
          }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={5}
            lg={5}
            className={classes.imageContainer}
            height={md ? "40%" : "calc(100vh - 90px)"}
          >
            <Carousel
              animation="slide"
              autoPlay={true}
              indicators={true}
              navButtonsAlwaysInvisible
              duration={500}
              sx={{
                height: !md && "100%",
                width: "100%",
              }}
              indicatorContainerProps={{
                style: {
                  position: "absolute",
                  zIndex: 10,
                  bottom: "20px",
                },
              }}
              indicatorIconButtonProps={{
                style: {
                  color: BaseColor.grey,
                  height: "10px",
                },
              }}
              activeIndicatorIconButtonProps={{
                style: {
                  color: BaseColor.white,
                },
              }}
              className={classes.car}
            >
              {BgImgArr.map((item) => (
                <img
                  key={item.id}
                  alt="Golf"
                  src={item.url}
                  draggable={false}
                  style={{
                    objectFit: "fill",
                    width: "100%",
                    height: md ? "calc(50vh - 60px)" : "calc(100vh - 90px)",
                  }}
                />
              ))}
            </Carousel>
          </Grid>

          {/* Login Content */}
          <Grid
            item
            xs={12}
            sm={12}
            md={7}
            lg={7}
            className={classes.scrollBar}
            style={{
              width: md ? "100%" : "58%",
              height: md ? "50%" : "calc(100vh - 90px)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: BaseColor.white,
              overflowY: !md && "auto",
              paddingTop: md ? "0px" : "20px",
              position: "relative",
            }}
          >
            {!isMobile && (
              <>
                <img
                  src={Files.svgIcons.loginBg1}
                  draggable={false}
                  alt="Group1"
                  style={{
                    position: "absolute",
                    top: "-35px",
                    right: 0,
                    width: "47%",
                    height: "60%",
                  }}
                />
                <img
                  src={Files.svgIcons.loginBg2}
                  draggable={false}
                  alt="Group2"
                  style={{
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                    width: "46%",
                    height: "60%",
                  }}
                />
              </>
            )}
            <Grid
              container
              maxHeight={"100%"}
              style={{
                background: isMobile && `url(${Files.svgIcons.golfBall})`,
                backgroundPosition: isMobile && "center",
                backgroundSize: isMobile && "contain",
                backgroundRepeat: isMobile && "no-repeat",
              }}
            >
              {md ? null : (
                <div className={classes.logoContainer}>
                  <img
                    src={Files.svgIcons.newLogo}
                    alt="logo"
                    className={classes.logo}
                    draggable={false}
                  />
                </div>
              )}

              <div className={classes.titleContainer}>
                <Typography
                  variant="h1"
                  className={classes.title}
                  style={{ fontSize: sm ? 30 : 40 }}
                >
                  Join Us Today!
                </Typography>
              </div>
              <div className={classes.descriptionContainer}>
                <Typography
                  variant="body1"
                  className={classes.description}
                  style={{
                    fontSize: sm ? 14 : 18,
                    width: sm ? "90%" : "50%",
                    color: BaseColor.grey,
                  }}
                >
                  {content}
                </Typography>
              </div>
              <div className={classes.buttonContainer}>
                <div
                  style={{
                    width: sm ? "85%" : "80%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: sm && "column",
                  }}
                >
                  <CButton
                    variant="contained"
                    btnStyle={{ width: sm ? "100%" : "250px" }}
                    onClick={() => {
                      setOpenModal(true);
                      setEmailIdNew("");
                      setIsEmailView(true);
                      setIsOtpVerify(false);
                      setEmailIdNewErr(false);
                    }}
                  >
                    New Member Application
                  </CButton>
                  <CButton
                    variant="contained"
                    btnStyle={{
                      width: sm ? "100%" : "250px",
                      marginLeft: !sm && "40px",
                      marginTop: sm && "15px",
                    }}
                    onClick={() => {
                      setOpenLoginModal(true);
                      setIsFPOtpGenerated(false);
                      setEmailIdNew("");
                      setEmailId("");
                      setPassword("");
                      setEmailIdErr(false);
                      setPasswordErr(false);
                      setEmailIdNewErr(false);
                    }}
                  >
                    Member Login
                  </CButton>
                  {supported() && (isMobile || isTablet) && (
                    <CButton
                      variant="contained"
                      btnStyle={{
                        width: sm ? "100%" : "250px",
                        marginLeft: !sm && "40px",
                        marginTop: sm && "15px",
                        marginBottom: isIOS && sm && "50px",
                      }}
                      onClick={() => {
                        handleClick();
                      }}
                    >
                      Download the App
                    </CButton>
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      )}

      {/* OTP Modal for signup and otp */}
      <CModal
        visible={openModal}
        onClose={!isOtpVerify && resetModalState}
        children={
          <Grid
            container
            padding={isMobile ? "20px" : "40px"}
            style={{
              position: "relative",
              width: sm ? "90vw" : "580px",
              maxHeight: isMobile ? "100vh" : "80vh",
              overflowY: "auto",
              padding: isMobile ? "20px" : "40px",
            }}
            className={classes.scrollBar}
          >
            {!isOtpVerify && (
              <CloseIcon
                onClick={resetModalState}
                style={{
                  position: "absolute",
                  top: 10,
                  right: 15,
                  fontSize: 30,
                  cursor: !isMobile && !isTablet && "pointer",
                  color: BaseColor.primary,
                  zIndex: 1,
                }}
              />
            )}

            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                style={{
                  fontFamily: FontFamily.GilbertQualifiDemo,
                  fontSize: "24px",
                  color: BaseColor.blackColor,
                  textAlign: "center",
                }}
              >
                {isEmailView
                  ? "New member sign up"
                  : isOtpVerify
                  ? "Create Password"
                  : "Verify Validation Code"}
              </Typography>
            </Grid>
            {isEmailView ? (
              <Grid item xs={12} marginTop="20px">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleGetOTP();
                  }}
                  style={{ width: "100%" }}
                >
                  <CInput
                    label="Email ID"
                    placeholder="Enter your email"
                    value={emailIdNew}
                    onChange={(val) => {
                      setEmailIdNew(val?.toLowerCase());
                      setEmailIdNewErr(false);
                      setEmailIdNewMsg("");
                    }}
                    error={emailIdNewErr}
                    errorMsg={emailIdNewErr ? emailIdNewMsg : null}
                  />
                </form>
              </Grid>
            ) : isOtpVerify ? (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
                style={{ width: "100%" }}
              >
                <Grid item xs={12} marginTop="20px">
                  <CInput
                    label="New Password"
                    placeholder="Enter New Password"
                    value={newPass}
                    type={showNewPassword ? "text" : "password"}
                    onChange={handleNewPassChange}
                    image={Files.Images.infoIcon}
                    error={Boolean(newPassError)}
                    errorMsg={newPassError}
                    handleKey={() => {
                      handleSubmit();
                    }}
                    endIcon={
                      showNewPassword ? (
                        <AiOutlineEye
                          className="CInputStartIcon"
                          onClick={() => setShowNewPassword(false)}
                          style={{
                            cursor: !isMobile && !isTablet && "pointer",
                          }}
                        />
                      ) : (
                        <AiOutlineEyeInvisible
                          className="CInputStartIcon"
                          onClick={() => setShowNewPassword(true)}
                          style={{
                            cursor: !isMobile && !isTablet && "pointer",
                          }}
                        />
                      )
                    }
                  />
                  <div style={{ marginTop: "10px" }}>
                    <CInput
                      label="Confirm Password"
                      placeholder="Confirm New Password"
                      value={confirmPass}
                      onChange={handleConfirmPassChange}
                      error={Boolean(confirmPassError)}
                      errorMsg={confirmPassError}
                      type={showConfirmPassword ? "text" : "password"}
                      handleKey={() => {
                        handleSubmit();
                      }}
                      endIcon={
                        showConfirmPassword ? (
                          <AiOutlineEye
                            className="CInputStartIcon"
                            onClick={() => setShowConfirmPassword(false)}
                            style={{
                              cursor: !isMobile && !isTablet && "pointer",
                            }}
                          />
                        ) : (
                          <AiOutlineEyeInvisible
                            className="CInputStartIcon"
                            onClick={() => setShowConfirmPassword(true)}
                            style={{
                              cursor: !isMobile && !isTablet && "pointer",
                            }}
                          />
                        )
                      }
                    />
                  </div>
                </Grid>
              </form>
            ) : (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                display={"flex"}
              >
                {/* Title */}
                <Grid item xs={12} textAlign="center">
                  <p
                    style={{
                      fontSize: "16px",
                      fontFamily: FontFamily.Medium,
                      color: BaseColor.grey,
                      marginTop: "15px",
                      marginBottom: "25px",
                    }}
                  >
                    Check your inbox in the email and enter the validation code
                    here.
                  </p>
                </Grid>
                <Grid
                  item
                  xs={12}
                  textAlign="center"
                  justifyContent={"center"}
                  alignItems={"center"}
                  display={"flex"}
                  style={{
                    marginBottom: "25px",
                  }}
                >
                  <Typography
                    style={{
                      color: BaseColor.textColor,
                      fontFamily: FontFamily.Medium,
                      textAlign: "center",
                      fontSize: "16px",
                    }}
                  >
                    {getTruncatedEmail(emailIdNew)}
                  </Typography>
                  <CiEdit
                    size={23}
                    style={{
                      marginLeft: "10px",
                      cursor: !isMobile && !isTablet && "pointer",
                      color: BaseColor.green,
                    }}
                    onClick={() => {
                      setIsEmailView(!isEmailView);
                      setOTP("");
                      setOtpError(false);
                      setOtpMsg("");
                      setIsTimerRunning(false);
                    }}
                  />
                </Grid>
                {/* OTP Input */}
                <Grid
                  item
                  xs={12}
                  justifyContent={"center"}
                  alignItems={"center"}
                  display={"flex"}
                >
                  <COtpInput
                    value={otp}
                    onChange={(val) => {
                      setOTP(val);
                      setOtpError(false);
                      setOtpMsg("");
                    }}
                    error={otpError}
                    errorMsg={otpMsg}
                    handleKeyEvent={(event) => {
                      handleVerifyOTP();
                    }}
                  />
                </Grid>

                {/* Timer and Resend Button */}
                <Grid
                  item
                  xs={8}
                  lg={6}
                  container
                  mt={!_.isEmpty(otpError) ? "10px" : null}
                  justifyContent="center"
                  alignItems="center"
                  display={"flex"}
                >
                  <Grid item xs={6} textAlign="left">
                    <Typography>
                      {isTimerRunning
                        ? `${Math.floor(timer / 60)}:${String(
                            timer % 60
                          ).padStart(2, "0")}`
                        : "0:00"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} textAlign="right">
                    <button
                      onClick={handleResend}
                      disabled={isTimerRunning}
                      style={{
                        background: "none",
                        border: "none",
                        color: isTimerRunning
                          ? BaseColor.disableGreen
                          : BaseColor.green,
                        fontFamily: FontFamily.SemiBold,
                        fontSize: "14px",
                        cursor: isTimerRunning ? "not-allowed" : "pointer",
                      }}
                    >
                      {resendOtpLoader ? (
                        <CircularProgress size={15} color="primary" />
                      ) : (
                        "Resend OTP"
                      )}
                    </button>
                  </Grid>
                </Grid>

                {/* Verify Button */}
              </Grid>
            )}

            {isOtpVerify && !_.isEmpty(newPass) && (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                mt={"20px"}
                style={{ justifyContent: "center" }}
              >
                <Typography
                  style={{
                    color: _.isEmpty(newPass)
                      ? BaseColor.blackColor
                      : isLengthValid2
                      ? BaseColor.green
                      : BaseColor.errorRed,
                    fontSize: isMobile ? "12px" : "14px",
                    fontFamily: FontFamily.Medium,
                  }}
                >
                  Password must be at least 8 characters long.
                </Typography>

                <Typography
                  style={{
                    color: _.isEmpty(newPass)
                      ? BaseColor.blackColor
                      : hasUpperCase2
                      ? BaseColor.green
                      : BaseColor.errorRed,
                    fontSize: isMobile ? "12px" : "14px",
                    fontFamily: FontFamily.Medium,
                  }}
                >
                  Password must contain at least one uppercase letter.
                </Typography>
                <Typography
                  style={{
                    color: _.isEmpty(newPass)
                      ? BaseColor.blackColor
                      : hasSpecialChar2
                      ? BaseColor.green
                      : BaseColor.errorRed,
                    fontSize: isMobile ? "12px" : "14px",
                    fontFamily: FontFamily.Medium,
                  }}
                >
                  Password must contain at least one special character.
                </Typography>
                <Typography
                  style={{
                    color: _.isEmpty(newPass)
                      ? BaseColor.blackColor
                      : hasNumber2
                      ? BaseColor.green
                      : BaseColor.errorRed,
                    fontSize: isMobile ? "12px" : "14px",
                    fontFamily: FontFamily.Medium,
                  }}
                >
                  Password must contain at least one number.
                </Typography>
                <Typography
                  style={{
                    color: _.isEmpty(newPass)
                      ? BaseColor.blackColor
                      : passwordsMatch2
                      ? BaseColor.green
                      : BaseColor.errorRed,
                    fontSize: isMobile ? "12px" : "14px",
                    fontFamily: FontFamily.Medium,
                  }}
                >
                  Passwords match.
                </Typography>
              </Grid>
            )}
            <Grid
              item
              xs={12}
              marginTop="50px"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              {isEmailView ? (
                <CButton
                  variant="contained"
                  btnStyle={{
                    width: !isMobile && "42%",
                    marginTop: isMobile ? "4vh" : "8vh",
                  }}
                  onClick={handleGetOTP}
                  // disabled={!isValidEmail(emailIdNew)}
                  loading={loader}
                >
                  Get Verification Code
                </CButton>
              ) : isOtpVerify ? (
                <CButton
                  variant="contained"
                  btnStyle={{ width: "40%" }}
                  onClick={handleSubmit}
                  loading={pwdLoader}
                >
                  Submit
                </CButton>
              ) : (
                <CButton
                  variant="contained"
                  btnStyle={{ width: "40%" }}
                  onClick={handleVerifyOTP}
                  loading={otpLoader}
                >
                  Verify OTP
                </CButton>
              )}
            </Grid>
          </Grid>
        }
      />

      {/* OTP Modal for login */}
      <CModal
        visible={openLoginModal}
        onClose={resetModalStateLogin}
        children={
          <Grid
            container
            padding={isMobile ? "20px" : "40px"}
            style={{
              position: "relative",
              width: sm ? "90vw" : "600px",
              maxHeight: "90vh",
              overflowY: "auto",
            }}
            className={classes.scrollBar}
          >
            {isMobile ? null : (
              <CloseIcon
                onClick={resetModalStateLogin}
                style={{
                  position: "absolute",
                  top: 10,
                  right: 15,
                  fontSize: 30,
                  cursor: !isMobile && !isTablet && "pointer",
                  color: BaseColor.primary,
                  zIndex: 1,
                }}
              />
            )}
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                style={{
                  fontFamily: FontFamily.GilbertQualifiDemo,
                  fontSize: "24px",
                  color: BaseColor.blackColor,
                  textAlign: "center",
                }}
              >
                Login
              </Typography>
            </Grid>

            {/* <form
              onSubmit={(e) => {
                e.preventDefault();
                validateForm();
              }}
              style={{ width: "100%" }}
            > */}
            <Grid item xs={12} marginTop="20px">
              <CInput
                label="Email ID"
                placeholder="Email"
                value={emailId}
                onChange={(val) => {
                  setEmailId(val);
                  setEmailIdErr(false);
                  setEmailIdMsg("");
                }}
                handleKey={() => {
                  validateForm();
                }}
                error={emailIdErr}
                errorMsg={emailIdErr ? emailIdMsg : null}
              />
              <div style={{ marginTop: "10px" }}>
                <CInput
                  label="Password"
                  placeholder="Password"
                  value={password}
                  onChange={(val) => {
                    const trimmedValue = val.trim();
                    setPassword(trimmedValue);
                    setPasswordErr(false);
                    setPasswordMsg("");
                  }}
                  handleKey={() => {
                    validateForm();
                  }}
                  type={showNewPasswordLogin ? "text" : "password"}
                  showPassword
                  error={passwordErr}
                  errorMsg={passwordErr ? passwordMsg : null}
                  endIcon={
                    showNewPasswordLogin ? (
                      <AiOutlineEye
                        className="CInputStartIcon"
                        onClick={() => setShowNewPasswordLogin(false)}
                        style={{ cursor: !isMobile && !isTablet && "pointer" }}
                      />
                    ) : (
                      <AiOutlineEyeInvisible
                        className="CInputStartIcon"
                        onClick={() => setShowNewPasswordLogin(true)}
                        style={{ cursor: !isMobile && !isTablet && "pointer" }}
                      />
                    )
                  }
                />
              </div>
            </Grid>
            {/* </form> */}
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              <Typography
                style={{
                  fontFamily: FontFamily.Medium,
                  fontSize: "14px",
                  color: BaseColor.green,
                  marginTop: "15px",
                  cursor: !isMobile && !isTablet && "pointer",
                }}
                onClick={() => {
                  setForgotPasswordModal(true);
                  setOpenLoginModal(false);
                }}
              >
                Forgot password?
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              marginTop="50px"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <CButton
                variant="contained"
                btnStyle={{ width: isMobile ? "100%" : "40%" }}
                onClick={validateForm}
                loading={loginBtnLoader}
              >
                Login
              </CButton>
            </Grid>
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="center"
              alignItems="center"
              marginTop={"20px"}
              marginBottom={"10px"}
            >
              <Typography
                style={{
                  fontFamily: FontFamily.SemiBold,
                  fontSize: "16px",
                  color: BaseColor.blackColor,
                  marginTop: "15px",
                }}
              >
                Or
              </Typography>
            </Grid>
            <Grid
              container
              xs={12}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <GoogleOAuthProvider clientId="775626380675-pb02p6kof4fbbbkhd15rci5rp62dfpkg.apps.googleusercontent.com">
                <div style={{ cursor: !isMobile && !isTablet && "pointer" }}>
                  <CGoogle
                    setOpenLoginModal={setOpenLoginModal}
                    setAccVerifyModal={setAccVerifyModal}
                    setReviewModalBool={setReviewModalBool}
                  />
                </div>
              </GoogleOAuthProvider>
              <div>
                <CApple
                  setOpenLoginModal={setOpenLoginModal}
                  setAccVerifyModal={setAccVerifyModal}
                  setReviewModalBool={setReviewModalBool}
                />
              </div>
            </Grid>

            <Grid
              item
              xs={12}
              mt={"40px"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Typography
                style={{
                  color: BaseColor.textColor,
                  fontSize: "16px",
                  fontFamily: FontFamily.Medium,
                }}
              >
                Don't have an account?
              </Typography>
              <Typography
                style={{
                  color: BaseColor.green,
                  fontSize: "16px",
                  fontFamily: FontFamily.Medium,
                  cursor: !isMobile && !isTablet && "pointer",
                  marginLeft: "5px",
                }}
                onClick={() => {
                  setIsEmailView(true);
                  setOpenModal(true);
                  setOpenLoginModal(false);
                }}
              >
                Sign up
              </Typography>
            </Grid>
          </Grid>
        }
      />

      {/* OTP Modal for account verify successfully */}
      <CModal
        visible={accVerifyModal}
        onClose={() => {
          setAccVerifyModal(false);
        }}
        children={
          <Grid
            container
            padding={isMobile ? "20px" : "60px"}
            style={{
              position: "relative",
              width: sm ? "90vw" : "600px",
              maxHeight: "80vh",
              overflowY: "auto",
            }}
            className={classes.scrollBar}
          >
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              {/* <img
                src={Files.Images.paySuccess} // Images.processPending
                alt="success"
                className={classes.success}
              /> */}
              {reviewModalBool ? (
                <div style={{ height: "154px", width: "154px" }}>
                  <Lottie
                    animationData={Files.lottie.review}
                    loop={false}
                    height={30}
                    width={20}
                  />
                </div>
              ) : (
                <Lottie
                  animationData={Files.lottie.verify}
                  loop={true}
                  height={50}
                  width="100%"
                />
              )}
            </Grid>
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                style={{
                  fontFamily: isMobile
                    ? FontFamily.Medium
                    : FontFamily.GilbertQualifiDemo,
                  fontSize: isMobile ? "16px" : "22px",
                  color: BaseColor.blackColor,
                  textAlign: "center",
                }}
              >
                {reviewModalBool
                  ? "Your application is under review."
                  : "Email Verified Successfully"}
              </Typography>
            </Grid>
            {reviewModalBool ? null : (
              <Grid
                item
                xs={12}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Typography
                  style={{
                    fontFamily: FontFamily.Medium,
                    fontSize: isMobile ? "14px" : "16px",
                    color: BaseColor.grey,
                    marginTop: "15px",
                    textAlign: "center",
                    color: BaseColor.blackColor,
                  }}
                >
                  Your email is verified successfully
                </Typography>
              </Grid>
            )}
            {reviewModalBool ? null : (
              <Grid
                item
                xs={12}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Typography
                  style={{
                    fontFamily: FontFamily.Medium,
                    fontSize: isMobile ? "14px" : "16px",
                    color: BaseColor.grey,
                    marginTop: "15px",
                    textAlign: "center",
                  }}
                >
                  You're redirecting to the next screen in: 0{"0:0" + seconds}
                </Typography>
              </Grid>
            )}

            {reviewModalBool ? (
              <Grid
                item
                xs={12}
                marginTop="50px"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <CButton
                  variant="contained"
                  btnStyle={{ width: isMobile ? "80%" : "40%" }}
                  onClick={() => {
                    setAccVerifyModal(!accVerifyModal);
                    setOpenLoginModal(true);
                    setEmailId("");
                    setPassword("");
                  }}
                >
                  Go back
                </CButton>
              </Grid>
            ) : null}
          </Grid>
        }
      />

      {/* forgot password modal */}
      <CModal
        visible={forgotPasswordModal}
        onClose={() => {
          setForgotPasswordModal(false);
          setForgotPasswordEmail("");
          setForgotPasswordEmailErrorText("");
          setIsFPOtpGenerated(false);
          setIsFPOtpVerify(false);
          setIsOtpValidForgot(false);
          setFPNewPassword("");
          setFPConfirmPassword("");
          setFPNewPasswordErrorText("");
          setFPConfirmPasswordErrorText("");
          setOtpErrorForgot("");
          setFpOtp("");
        }}
        children={
          <Grid
            container
            padding={isMobile ? "20px" : "40px"}
            style={{
              position: "relative",
              width: sm ? "90vw" : "500px",
              maxHeight: "80vh",
              overflowY: "auto",
            }}
            className={classes.scrollBar}
          >
            {!isMobile && (
              <CloseIcon
                onClick={() => {
                  setForgotPasswordModal(false);
                  setForgotPasswordEmail("");
                  setForgotPasswordEmailErrorText("");
                  setIsFPOtpGenerated(false);
                  setIsFPOtpVerify(false);
                  setIsOtpValidForgot(false);
                  setFPNewPassword("");
                  setFPConfirmPassword("");
                  setFPNewPasswordErrorText("");
                  setFPConfirmPasswordErrorText("");
                  setOtpErrorForgot("");
                  setFpOtp("");
                }}
                style={{
                  position: "absolute",
                  top: 10,
                  right: 15,
                  fontSize: 30,
                  cursor: !isMobile && !isTablet && "pointer",
                  color: BaseColor.primary,
                  zIndex: 1,
                }}
              />
            )}

            <Grid
              item
              xs={12}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Typography
                style={{
                  fontFamily: FontFamily.GilbertQualifiDemo,
                  fontSize: isMobile ? "20px" : "24px",
                  color: BaseColor.blackColor,
                  textAlign: "center",
                }}
              >
                {isFPOtpVerify ? "Create a New Password" : "Forgot Password"}
              </Typography>
            </Grid>
            {isFPOtpGenerated ? (
              <>
                <Grid
                  item
                  xs={12}
                  display={"flex"}
                  justifyContent={"center"}
                  mt={"30px"}
                >
                  <Typography
                    style={{
                      color: BaseColor.grey,
                      fontFamily: FontFamily.Medium,
                      width: "80%",
                      contain: "content",
                      textAlign: "center",
                    }}
                  >
                    Go to your inbox in the email and enter the validation code
                    here.
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  mt={"20px"}
                >
                  <Typography
                    style={{
                      color: BaseColor.textColor,
                      fontFamily: FontFamily.Medium,
                      // width: "80%",
                      contain: "content",
                      textAlign: "center",
                    }}
                  >
                    {getTruncatedEmail(forgotPasswordEmail)}
                  </Typography>
                  <CiEdit
                    size={23}
                    style={{
                      marginLeft: "10px",
                      cursor: !isMobile && !isTablet && "pointer",
                      color: BaseColor.green,
                    }}
                    onClick={() => {
                      setIsFPOtpGenerated(false);
                      setFpOtp("");
                      setOtpErrorForgot("");
                      setIsTimerRunningForgot(false);
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  mt={isOtpValidForgot ? "30px" : "30px"}
                >
                  <COtpInput
                    value={FpOtp}
                    onChange={(val) => {
                      setFpOtp(val);
                      setOtpErrorForgot("");
                      setIsOtpValidForgot(false);
                    }}
                    handleKeyEvent={(event) => {
                      handleVerifyOTPForgotPassword();
                    }}
                    errorMsg={otpErrorForgot} // Display OTP error message
                  />
                </Grid>

                {/* Timer and Resend Button */}
                <Grid
                  item
                  xs={8.5}
                  sm={7}
                  md={6}
                  lg={6}
                  container
                  mt={!_.isEmpty(otpErrorForgot) ? "10px" : null}
                  justifyContent="center"
                  alignItems="center"
                  display={"flex"}
                >
                  <Grid
                    item
                    xs={12}
                    textAlign="left"
                    display={"flex"}
                    justifyContent={"space-between"}
                  >
                    <Typography>
                      {isTimerRunningForgot
                        ? `${Math.floor(timerForgot / 60)}:${String(
                            timerForgot % 60
                          ).padStart(2, "0")}`
                        : "0:00"}
                    </Typography>
                    {/* </Grid> */}
                    {/* <Grid item xs={6} textAlign="right" border={"2px solid red"}> */}
                    <button
                      onClick={() => {
                        setResendLoader(true);
                        generateOtpForgotPassword(0);
                        setOtpErrorForgot("");
                      }}
                      disabled={isTimerRunningForgot}
                      style={{
                        background: "none",
                        border: "none",
                        color: isTimerRunningForgot
                          ? BaseColor.disableGreen
                          : BaseColor.green,
                        fontFamily: FontFamily.SemiBold,
                        fontSize: "14px",
                        cursor: isTimerRunningForgot
                          ? "not-allowed"
                          : "pointer",
                      }}
                    >
                      {resendLoader ? (
                        <CircularProgress size={15} color="primary" />
                      ) : (
                        "Resend OTP"
                      )}
                    </button>
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={12}
                  mt={"40px"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <CButton
                    btnStyle={{
                      width: isTablet ? "50%" : isMobile ? "100%" : "50%",
                    }}
                    loading={verifyOtpFpBtnLoader}
                    onClick={(e) => {
                      handleVerifyOTPForgotPassword();
                    }}
                  >
                    Confirm
                  </CButton>
                </Grid>
                <Grid
                  item
                  xs={12}
                  mt={"40px"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Typography
                    style={{
                      color: BaseColor.textColor,
                      fontSize: "16px",
                      fontFamily: FontFamily.Medium,
                    }}
                  >
                    Back to
                  </Typography>
                  <Typography
                    style={{
                      color: BaseColor.green,
                      fontSize: "16px",
                      fontFamily: FontFamily.Medium,
                      cursor: !isMobile && !isTablet && "pointer",
                      marginLeft: "5px",
                    }}
                    onClick={() => {
                      setOpenLoginModal(true);
                      setForgotPasswordModal(false);
                      setForgotPasswordEmail("");
                      setForgotPasswordEmailErrorText("");
                      setIsFPOtpGenerated(false);
                      setIsFPOtpVerify(false);
                      setIsOtpValidForgot(false);
                      setFPNewPassword("");
                      setFPConfirmPassword("");
                      setFPNewPasswordErrorText("");
                      setFPConfirmPasswordErrorText("");
                      setOtpErrorForgot("");
                      setFpOtp("");
                      setEmailId("");
                      setPassword("");
                      setPasswordErr(false);
                      setEmailIdErr(false);
                    }}
                  >
                    Login
                  </Typography>
                </Grid>
              </>
            ) : isFPOtpVerify ? (
              <>
                <Grid item xs={12} mt={"20px"}>
                  <CInput
                    placeholder={"Enter new password"}
                    label={"New Password"}
                    showLabel={!isMobile}
                    value={FPNewPassword}
                    image={Files.Images.infoIcon}
                    onChange={(val) => {
                      const trimmedValue = val.trim();
                      setFPNewPassword(trimmedValue);
                      setFPNewPasswordErrorText("");
                    }}
                    handleKey={() => {
                      createNewPasswordValidation();
                    }}
                    type={showNewForgotPassword ? "text" : "password"}
                    error={!_.isEmpty(FPNewPasswordErrorText)}
                    errorMsg={FPNewPasswordErrorText}
                    endIcon={
                      showNewForgotPassword ? (
                        <AiOutlineEye
                          className="CInputStartIcon"
                          onClick={() => setShowNewForgotPassword(false)}
                          style={{
                            cursor: !isMobile && !isTablet && "pointer",
                          }}
                        />
                      ) : (
                        <AiOutlineEyeInvisible
                          className="CInputStartIcon"
                          onClick={() => setShowNewForgotPassword(true)}
                          style={{
                            cursor: !isMobile && !isTablet && "pointer",
                          }}
                        />
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <CInput
                    placeholder={"Enter confirm password"}
                    label={"Confirm Password"}
                    type={showForgotConfirmPassword ? "text" : "password"}
                    showLabel={!isMobile}
                    value={FPConfirmPassword}
                    showPassword
                    onChange={(val) => {
                      const trimmedValue = val.trim();
                      setFPConfirmPassword(trimmedValue);
                      setFPConfirmPasswordErrorText("");
                    }}
                    endIcon={
                      showForgotConfirmPassword ? (
                        <AiOutlineEye
                          className="CInputStartIcon"
                          onClick={() => setShowForgotConfirmPassword(false)}
                          style={{
                            cursor: !isMobile && !isTablet && "pointer",
                          }}
                        />
                      ) : (
                        <AiOutlineEyeInvisible
                          className="CInputStartIcon"
                          onClick={() => setShowForgotConfirmPassword(true)}
                          style={{
                            cursor: !isMobile && !isTablet && "pointer",
                          }}
                        />
                      )
                    }
                    handleKey={() => {
                      createNewPasswordValidation();
                    }}
                    error={!_.isEmpty(FPConfirmPasswordErrorText)}
                    errorMsg={FPConfirmPasswordErrorText}
                  />
                </Grid>
                {!_.isEmpty(FPNewPassword) && (
                  <Grid item xs={12} sm={12} md={12} lg={12} mt={"20px"}>
                    <Typography
                      style={{
                        color: _.isEmpty(FPNewPassword)
                          ? BaseColor.blackColor
                          : isLengthValid
                          ? BaseColor.green
                          : BaseColor.errorRed,
                        fontSize: sm ? "12px" : "13px",
                        fontFamily: FontFamily.Medium,
                      }}
                    >
                      Password must be at least 8 characters long.
                    </Typography>

                    <Typography
                      style={{
                        color: _.isEmpty(FPNewPassword)
                          ? BaseColor.blackColor
                          : hasUpperCase
                          ? BaseColor.green
                          : BaseColor.errorRed,
                        fontSize: sm ? "12px" : "13px",
                        fontFamily: FontFamily.Medium,
                      }}
                    >
                      Password must contain at least one uppercase letter.
                    </Typography>
                    <Typography
                      style={{
                        color: _.isEmpty(FPNewPassword)
                          ? BaseColor.blackColor
                          : hasSpecialChar
                          ? BaseColor.green
                          : BaseColor.errorRed,
                        fontSize: sm ? "12px" : "13px",
                        fontFamily: FontFamily.Medium,
                      }}
                    >
                      Password must contain at least one special character.
                    </Typography>
                    <Typography
                      style={{
                        color: _.isEmpty(FPNewPassword)
                          ? BaseColor.blackColor
                          : hasNumber
                          ? BaseColor.green
                          : BaseColor.errorRed,
                        fontSize: sm ? "12px" : "13px",
                        fontFamily: FontFamily.Medium,
                      }}
                    >
                      Password must contain at least one number.
                    </Typography>
                    <Typography
                      style={{
                        color: _.isEmpty(FPNewPassword)
                          ? BaseColor.blackColor
                          : passwordsMatch
                          ? BaseColor.green
                          : BaseColor.errorRed,
                        fontSize: sm ? "12px" : "13px",
                      }}
                    >
                      Passwords match.
                    </Typography>
                  </Grid>
                )}

                <Grid
                  item
                  xs={12}
                  mt={"40px"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <CButton
                    btnStyle={{ width: isMobile ? "100%" : "40%" }}
                    loading={saveForgotPasswordBtnLoader}
                    onClick={(e) => {
                      createNewPasswordValidation(e);
                    }}
                  >
                    Save
                  </CButton>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12} mt={"20px"}>
                  <CInput
                    placeholder={"Enter your email"}
                    label={"Email ID"}
                    value={forgotPasswordEmail}
                    onChange={(val) => {
                      setForgotPasswordEmail(val);
                      setForgotPasswordEmailErrorText("");
                    }}
                    handleKey={() => {
                      getOTPForgotPassword(1);
                    }}
                    errorMsg={forgotPasswordEmailErrorText}
                    error={!_.isEmpty(forgotPasswordEmailErrorText)}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  mt={"40px"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <CButton
                    btnStyle={{ width: isMobile ? "100%" : "40%" }}
                    onClick={() => {
                      getOTPForgotPassword(0);
                    }}
                    loading={getOtpFpBtnLoader}
                  >
                    Send OTP
                  </CButton>
                </Grid>
                <Grid
                  item
                  xs={12}
                  mt={"40px"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Typography
                    style={{
                      color: BaseColor.textColor,
                      fontSize: "16px",
                      fontFamily: FontFamily.Medium,
                    }}
                  >
                    Back to
                  </Typography>
                  <Typography
                    style={{
                      color: BaseColor.green,
                      fontSize: "16px",
                      fontFamily: FontFamily.Medium,
                      cursor: !isMobile && !isTablet && "pointer",
                      marginLeft: "5px",
                    }}
                    onClick={() => {
                      setOpenLoginModal(true);
                      setForgotPasswordModal(false);
                      setForgotPasswordEmail("");
                      setForgotPasswordEmailErrorText("");
                      setIsFPOtpGenerated(false);
                      setIsFPOtpVerify(false);
                      setIsOtpValidForgot(false);
                      setFPNewPassword("");
                      setFPConfirmPassword("");
                      setFPNewPasswordErrorText("");
                      setFPConfirmPasswordErrorText("");
                      setOtpErrorForgot("");
                      setFpOtp("");
                      setEmailId("");
                      setPassword("");
                      setPasswordErr(false);
                      setEmailIdErr(false);
                    }}
                  >
                    Login
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
        }
      />
    </Grid>
  );
}
