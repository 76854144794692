import { makeStyles } from "@mui/styles";
import BaseColor from "../../Config/colors";

const useStyles = makeStyles(() => ({
  splashScreen: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: BaseColor.primary,
    zIndex: 9999,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  loadingContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  image: {
    height: "75px",
    width: "187.99px",
  },
  "@keyframes scaleIn": {
    from: {
      transform: "scale(0)",
    },
    to: {
      transform: "scale(1)",
    },
  },
  // Apply the animation to the logo
  logoAnimation: {
    animation: "$scaleIn 1s ease-out", // Adjust duration and timing as needed
  },
}));

export default useStyles;
