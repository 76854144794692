import React, { useEffect, useState } from "react";
import styles from "./styles";
import {
  CircularProgress,
  Divider,
  Grid,
  Rating,
  Typography,
  useMediaQuery,
} from "@mui/material";
import BaseColor from "../../../../Config/colors";
import theme, { FontFamily } from "../../../../Config/theme";
import { useSelector } from "react-redux";
import { getAPIProgressData } from "../../../../Utils/APIHelper";
import { toast } from "react-toastify";
import _, { isEmpty } from "lodash";
import { Setting } from "../../../../Utils/Setting";
import { useNavigate, useSearchParams } from "react-router-dom";
import CHeader from "../../../../Components/Header";
import { motion } from "framer-motion";

const ReviewsMobile = () => {
  // styles and media queries
  const classes = styles();
  const md = useMediaQuery(theme.breakpoints.down("md"));

  // Redux
  const { userData } = useSelector((state) => state.auth);

  // review data
  const [reviewData, setReviewData] = useState([]);
  const [myReviewData, setMyReviewData] = useState([]);

  // page loader
  const [pageLoader, setPageLoader] = useState(false);

  // URL search params
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedFilter = searchParams.has("selected_tab")
    ? searchParams.get("selected_tab")
    : "My_Reviews";
  const [selectedTab, setSelectedTab] = useState(selectedFilter);
  const navigate = useNavigate();

  // common data api call
  const getCommonData = async () => {
    setPageLoader(true);
    let endPoints = Setting.endpoints.getReviewList;

    const params = {
      user_id: userData?.id,
      all_data: selectedTab === "Reviews" ? 1 : "",
    };

    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
      if (resp?.success) {
        if (!_.isEmpty(resp?.data)) {
          if (selectedTab === "Reviews") {
            setReviewData(resp?.data);
            setMyReviewData([]);
          } else {
            setMyReviewData(resp?.data);
            setReviewData([]);
          }
        }
        setPageLoader(false);
      } else {
        toast(resp?.message, {
          type: "error",
        });
        setPageLoader(false);
      }
      setPageLoader(false);
    } catch (error) {
      toast(error.message, {
        type: "error",
      });
      setPageLoader(false);
    }
  };

  useEffect(() => {
    getCommonData();
  }, [selectedTab]);

  return (
    <Grid container style={{ height: "100%" }}>
      <>
        <Grid item xs={12}>
          <CHeader
            title={selectedTab === "Reviews" ? "Reviews" : "My Reviews"}
            backButtonClick={() => {
              navigate("/profile");
            }}
          />
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={0.5} sm={0.5} md={1} lg={1}></Grid>
              <Grid item xs={11} sm={11} md={11} lg={11}>
                <Grid container height={"90%"}>
                  <Grid item xs={12}>
                    <Grid
                      container
                      style={{
                        marginBottom: "2vh",
                        justifyContent: "center",
                      }}
                    >
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={5}
                        style={{
                          alignItems: "flex-start",
                          marginTop: "3vh",
                        }}
                      >
                        <div className={classes.switchDiv}>
                          <div
                            style={{
                              backgroundColor:
                                selectedTab === "My_Reviews"
                                  ? BaseColor.primary
                                  : BaseColor.offWhiteBg,
                              color:
                                selectedTab === "My_Reviews"
                                  ? BaseColor.white
                                  : BaseColor.blackColor,
                              width: !md && "200px",
                              fontFamily: FontFamily.Medium,
                            }}
                            onClick={() => {
                              setSelectedTab("My_Reviews");
                            }}
                            className={classes.switchTab}
                          >
                            My Reviews
                          </div>
                          <div
                            style={{
                              backgroundColor:
                                selectedTab === "Reviews"
                                  ? BaseColor.primary
                                  : BaseColor.offWhiteBg,
                              color:
                                selectedTab === "Reviews"
                                  ? BaseColor.white
                                  : BaseColor.blackColor,
                              width: !md && "200px",
                              fontFamily: FontFamily.Medium,
                            }}
                            onClick={() => {
                              setSelectedTab("Reviews");
                            }}
                            className={classes.switchTab}
                          >
                            Reviews
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                    <Divider style={{ marginBottom: "2vh" }} />
                    {pageLoader ? (
                      <Grid
                        container
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <CircularProgress size={40} color="primary" />
                      </Grid>
                    ) : (
                      <motion.div
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: 50 }}
                        transition={{ duration: 0.3 }}
                      >
                        <div
                          style={{
                            width: "90vw",
                            overflowY: "auto",
                            height: "calc(100vh - 170px)",
                          }}
                          className={classes.scrollBar}
                        >
                          {(
                            selectedTab === "Reviews"
                              ? isEmpty(reviewData)
                              : isEmpty(myReviewData)
                          ) ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                              }}
                            >
                              <Typography
                                style={{
                                  textAlign: "center",
                                  fontFamily: FontFamily.SemiBold,
                                  fontSize: "20px",
                                }}
                              >
                                No Reviews Yet !!
                              </Typography>
                            </div>
                          ) : (
                            (selectedTab === "Reviews"
                              ? reviewData
                              : myReviewData
                            ).map((review) => (
                              <div
                                key={review.id}
                                style={{
                                  marginBottom: "1vh",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: "1vh",
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "50px",
                                      height: "50px",
                                      borderRadius: "50%",
                                      overflow: "hidden",
                                      marginRight: "10px",
                                    }}
                                  >
                                    <img
                                      src={review.feedback_sender_pic_url}
                                      alt={review.name}
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover",
                                      }}
                                      draggable={false}
                                    />
                                  </div>
                                  <div>
                                    <Typography
                                      style={{
                                        fontFamily: FontFamily.Regular,
                                        fontSize: "14px",
                                        marginBottom: "1px",
                                      }}
                                    >
                                      {review.feedback_sender_name}
                                    </Typography>
                                    <Typography
                                      style={{
                                        fontFamily: FontFamily.Regular,
                                        color: BaseColor.grey,
                                        fontSize: "12px",
                                      }}
                                    >
                                      {review.created_at}
                                    </Typography>
                                  </div>
                                </div>
                                <div style={{ marginBottom: "1vh" }}>
                                  <Rating
                                    name="star-rating"
                                    value={review.rating}
                                    precision={0.5}
                                    sx={{
                                      fontSize: "20px",
                                      color: BaseColor.blackColor,
                                    }}
                                    readOnly
                                  />
                                  <Typography
                                    style={{
                                      fontFamily: FontFamily.Regular,
                                      fontSize: "13px",
                                      textAlign: "justify",
                                      marginTop: "6px",
                                    }}
                                  >
                                    {review.comment}
                                  </Typography>
                                </div>
                              </div>
                            ))
                          )}
                        </div>
                      </motion.div>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    </Grid>
  );
};

export default ReviewsMobile;
