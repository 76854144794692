import { makeStyles } from "@mui/styles";
import theme, { FontFamily } from "../../Config/theme";
import BaseColor from "../../Config/colors";
import { isMobile, isTablet } from "react-device-detect";

const styles = makeStyles({
  title: {
    fontFamily: `${FontFamily.GilbertQualifiDemo} !important`,
    fontSize: isMobile ? "20px" : "22px !important",
    marginLeft: "10px !important",
  },

  smallImgs: {
    height: 100,
    width: 100,
    objectFit: "cover",
    borderRadius: 10,
    boxShadow: `1px 2px 3px ${BaseColor.textColor}`,
    cursor: !isMobile && !isTablet && "pointer",
    "@media (max-width:1500px)": {
      width: 75,
      height: 75,
    },
    "@media (max-width:600px)": {
      width: 50,
      height: 50,
    },
    "@media (max-width:400px)": {
      width: 45,
      height: 45,
    },
  },

  car: {
    "& .css-1g04gm2-MuiButtonBase-root-MuiIconButton-root": {
      backgroundColor: "transparent",
      color: BaseColor.primary,
      border: `1px solid ${BaseColor.primary}`,
    },
    "& .css-1abc02a:hover button": {
      backgroundColor: BaseColor.primary,
      color: BaseColor.white,
    },
    "& .css-hn784z:hover button": {
      backgroundColor: BaseColor.primary,
      color: BaseColor.white,
    },
  },
  carImg: {
    height: !isMobile && "calc(100vh - 310px) !important",
    width: "100%",
    objectFit: "cover",
    borderRadius: 10,
    [theme.breakpoints.down("sm")]: {
      height: 300,
    },
    [theme.breakpoints.down("md")]: {
      height: !isMobile && 400,
    },
    "@media (max-width:900px)": {
      height: "400px !important",
    },
    "@media (max-width:400px)": {
      height: "300px !important",
    },
  },
  carImageZoom: {
    transition: "transform .2s",
  },

  eventDescription: {
    fontFamily: `${FontFamily.Regular} !important`,
    color: `${BaseColor.grey} !important`,
    fontSize: "16px !important",
    "@media (max-width:400px)": {
      fontSize: "14px !important",
    },
  },

  location: {
    fontFamily: `${FontFamily.GilbertQualifiDemo} !important`,
    fontSize: "20px !important",
    "@media (max-width:400px)": {
      fontSize: "18px !important",
    },
    color: `${BaseColor.blackColor} !important`,
  },

  details: {
    fontFamily: `${FontFamily.GilbertQualifiDemo} !important`,
    fontSize: "18px !important",
    "@media (max-width:400px)": {
      fontSize: "16px !important",
    },
    textWrap: "nowrap",
    color: `${BaseColor.blackColor} !important`,
  },

  time: {
    fontFamily: `${FontFamily.Regular} !important`,
    fontSize: "14px !important",
    color: `${BaseColor.green} !important`,
    textDecoration: "underline",
    cursor: !isMobile && !isTablet && "pointer",
  },

  timeAndDetails: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "10px",
  },

  timeDescription: {
    fontFamily: `${FontFamily.Regular} !important`,
    color: `${BaseColor.grey} !important`,
    fontSize: "14px !important",
    "@media (max-width:400px)": {
      fontSize: "14px !important",
    },
  },

  price: {
    fontFamily: `${FontFamily.Bold} !important`,
    fontSize: "30px !important",
    color: `${BaseColor.blackColor} !important`,
  },

  scrollBar: {
    "&::-webkit-scrollbar": {
      width: 3,
      height: 5,
    },

    /* Track */
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 5px transperent",
      borderRadius: 10,
    },

    /* Handle */
    "&::-webkit-scrollbar-thumb": {
      background: BaseColor.primary,
      borderRadius: 10,
    },

    /* Handle on hover */
    "&::-webkit-scrollbar-thumb:hover": {
      boxShadow: "inset 0 0 5px grey",
    },
  },
  scrollBarImages: {
    // overflowX: "scroll",
    "&::-webkit-scrollbar": {
      width: 0,
      height: 3,
    },

    /* Track */
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 5px transperent",
      borderRadius: 10,
    },

    /* Handle */
    "&::-webkit-scrollbar-thumb": {
      background: BaseColor.primary,
      borderRadius: 10,
    },

    /* Handle on hover */
    "&::-webkit-scrollbar-thumb:hover": {
      boxShadow: "inset 0 0 5px grey",
    },
  },
});

export default styles;
