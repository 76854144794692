import React, { useEffect, useState } from "react";
import ArrowBack from "@mui/icons-material/ArrowBack";
import BaseColor from "../../Config/colors";
import { Button, CircularProgress, Grid, Typography } from "@mui/material";
import { NotificationsNoneSharp } from "@mui/icons-material";
import { FontFamily } from "../../Config/theme";
import { useNavigate } from "react-router-dom";
import { isMobile, isTablet } from "react-device-detect";
import { Setting } from "../../Utils/Setting";
import { getAPIProgressData } from "../../Utils/APIHelper";
import { toast } from "react-toastify";
import _ from "lodash";
import authActions from "../../Redux/reducers/auth/actions.js";
import { useDispatch } from "react-redux";

const Notification = () => {
  // navigation
  const navigate = useNavigate();

  //loader
  const [loader, setLoader] = useState(false);
  const [removeLoader, setRemoveLoader] = useState(false);

  // data
  const [notificationData, setNotificationData] = useState([]);
  const dispatch = useDispatch();
  const { setNotificationCount } = authActions;

  useEffect(() => {
    getNotificationList();
  }, []);

  // get notification list api
  const getNotificationList = async () => {
    setLoader(true);
    let endPoints = Setting.endpoints.notificationList;
    const data = {
      is_modal: 1,
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", data, true);
      if (resp?.success) {
        if (!_.isEmpty(resp?.data)) {
          setNotificationData(resp?.data);
        }
        setLoader(false);
        if (!_.isEmpty(resp?.data)) {
          let hasUnreadNotifications = false;

          resp.data.forEach((entry) => {
            if (
              entry.notification_list.some(
                (notification) => notification.is_read == 0
              )
            ) {
              hasUnreadNotifications = true;
              return;
            }
          });

          dispatch(setNotificationCount(hasUnreadNotifications));
        }
      } else {
        toast(resp?.message, {
          type: "error",
        });
        setLoader(false);
        setNotificationData([]);
      }
    } catch (error) {
      toast(error.message, {
        type: "error",
      });
      setLoader(false);
    }
  };

  // remove notifications
  const getRemoveNotificationList = async () => {
    setRemoveLoader(true);
    let endPoints = Setting.endpoints.removeNotificationList;

    try {
      const resp = await getAPIProgressData(endPoints, "POST", {}, true);
      if (resp?.success) {
        setNotificationData([]);
        setRemoveLoader(false);
      } else {
        toast(resp?.message, {
          type: "error",
        });
        setRemoveLoader(false);
      }
    } catch (error) {
      toast(error.message, {
        type: "error",
      });
      setRemoveLoader(false);
    }
  };

  const getYesterday = () => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    return yesterday.toISOString().slice(0, 10);
  };

  const today = new Date().toISOString().slice(0, 10);

  const groupedNotifications = notificationData.reduce(
    (acc, notification) => {
      const { date, day, notification_list } = notification;

      // Determine if the notification date is today, yesterday, or in the past
      if (date === today) {
        acc.today.push({ day, notification_list });
      } else if (date === getYesterday()) {
        acc.yesterday.push({ day, notification_list });
      } else {
        acc.past.push({ day, notification_list, date });
      }

      return acc;
    },
    { today: [], yesterday: [], past: [] }
  );

  return (
    <>
      <div
        style={{
          marginTop: 10,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Grid
          onClick={() => {
            navigate("/");
          }}
        >
          <ArrowBack style={{ fontSize: 30, marginLeft: 10 }} />
        </Grid>
        <Typography
          style={{
            fontSize: 22,
            color: "black",
            textAlign: "center",
            fontFamily: FontFamily.Bold,
            marginRight: _.isEmpty(notificationData) && "30px",
          }}
        >
          Notifications
        </Typography>
        {!_.isEmpty(notificationData) ? (
          <Button
            onClick={() => {
              getRemoveNotificationList();
            }}
          >
            {removeLoader ? (
              <CircularProgress size={20} color="primary" />
            ) : (
              <Typography
                style={{
                  fontSize: "16px",
                  color: BaseColor.red,
                }}
              >
                {"Clear All"}
              </Typography>
            )}
          </Button>
        ) : (
          <>
            {" "}
            <Grid xs={0.5} sm={0.5}></Grid>
          </>
        )}
      </div>
      {loader ? (
        <Grid
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "calc(100vh - 90px)",
          }}
        >
          <CircularProgress size={25} color="primary" />
        </Grid>
      ) : (
        <>
          {_.isEmpty(notificationData) ? (
            <Grid
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "calc(100vh - 90px)",
              }}
            >
              <Typography
                style={{
                  fontSize: 16,
                  color: BaseColor.textColor,
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                No notifications found.
              </Typography>
            </Grid>
          ) : (
            <div
              style={{
                overflowY: "auto",
                height: "calc(100vh - 120px)",
                padding: "0vh 2vw 2vh 2vw",
              }}
            >
              {groupedNotifications.today.length > 0 && (
                <div>
                  <Typography
                    style={{
                      fontSize: 18,
                      fontFamily: FontFamily.Bold,
                      color: BaseColor.textColor,
                      marginTop: 10,
                      marginBottom: 10,
                      marginLeft: 10,
                    }}
                  >
                    Today
                  </Typography>
                  {groupedNotifications.today.map(
                    ({ day, notification_list }) => (
                      <>
                        {notification_list.map((notification) => {
                          const unixTimestamp = notification.created_at;
                          const date = new Date(unixTimestamp * 1000);

                          const hours = date.getHours();
                          const minutes = date
                            .getMinutes()
                            .toString()
                            .padStart(2, "0");

                          // Determine AM or PM
                          const amPM = hours >= 12 ? "PM" : "AM";

                          // Convert hours from 24-hour to 12-hour format
                          const twelveHourFormat = hours % 12 || 12; // 0 should be converted to 12

                          const time12 = `${twelveHourFormat}:${minutes} ${amPM}`;

                          return (
                            <Grid
                              key={notification.id}
                              onClick={() => {
                                if (
                                  notification?.type ===
                                  "new_reservation_created"
                                ) {
                                  navigate("/reservation", {
                                    state: {
                                      notificationData: notification,
                                    },
                                  });
                                } else if (
                                  notification?.type ===
                                    "user_reservation_rejected" ||
                                  notification?.type ===
                                    "user_reservation_accepted" ||
                                  notification?.type === "chat_notification"
                                ) {
                                  navigate("/chat");
                                }
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  margin: 5,
                                  padding: 5,
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                  }}
                                >
                                  {/* {notification.type ? (
                                    <Circle
                                      style={{
                                        position: "relative",
                                        fontSize: 13,
                                        color: BaseColor.green,
                                        right: "30px",
                                        left: 13,
                                        bottom: -10,
                                        border: `2px solid ${BaseColor.white}`,
                                        borderRadius: "50px",
                                      }}
                                    />
                                  ) : null} */}
                                  <NotificationsNoneSharp
                                    style={{
                                      color: BaseColor.primary,
                                      fontSize: 30,
                                      cursor:
                                        !isMobile && !isTablet && "pointer",
                                      backgroundColor: "#EAF3FA",
                                      borderRadius: "50%",
                                      padding: 5,
                                      border: `2px solid ${BaseColor.primary}`,
                                    }}
                                  />
                                </div>
                                <div style={{ marginLeft: 10 }}>
                                  <div
                                    style={{
                                      // width: "80vw",
                                      textAlign: "center",
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Typography
                                      style={{
                                        fontFamily: FontFamily.SemiBold,
                                        color: BaseColor.blackColor,
                                        fontSize: 16,
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        maxWidth: "60vw",
                                      }}
                                    >
                                      {notification.title}
                                    </Typography>

                                    <Typography
                                      style={{
                                        fontSize: 14,
                                        color: BaseColor.blackColor,
                                        fontFamily: FontFamily.Bold,
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        maxWidth: "50vw",
                                      }}
                                    >
                                      {time12}
                                    </Typography>
                                  </div>

                                  <Typography
                                    style={{
                                      color: BaseColor.grey,
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                      maxWidth: "75vw",
                                    }}
                                  >
                                    {notification.message}
                                  </Typography>
                                </div>
                              </div>
                            </Grid>
                          );
                        })}
                      </>
                    )
                  )}
                </div>
              )}

              {groupedNotifications.yesterday.length > 0 && (
                <div>
                  <Typography
                    style={{
                      fontSize: 18,
                      fontFamily: FontFamily.Bold,
                      color: BaseColor.textColor,
                      marginTop: 10,
                      marginBottom: 10,
                      marginLeft: 10,
                    }}
                  >
                    Yesterday
                  </Typography>
                  {groupedNotifications.yesterday.map(
                    ({ day, notification_list }) => (
                      <>
                        {notification_list.map((notification) => {
                          const unixTimestamp = notification.created_at;
                          const date = new Date(unixTimestamp * 1000);

                          const hours = date.getHours();
                          const minutes = date
                            .getMinutes()
                            .toString()
                            .padStart(2, "0");

                          // Determine AM or PM
                          const amPM = hours >= 12 ? "PM" : "AM";

                          // Convert hours from 24-hour to 12-hour format
                          const twelveHourFormat = hours % 12 || 12; // 0 should be converted to 12

                          const time12 = `${twelveHourFormat}:${minutes} ${amPM}`;

                          return (
                            <Grid
                              key={notification.id}
                              onClick={() => {
                                if (
                                  notification?.type ===
                                  "new_reservation_created"
                                ) {
                                  navigate("/reservation", {
                                    state: {
                                      notificationData: notification,
                                    },
                                  });
                                } else if (
                                  notification?.type ===
                                    "user_reservation_rejected" ||
                                  notification?.type ===
                                    "user_reservation_accepted" ||
                                  notification?.type === "chat_notification"
                                ) {
                                  navigate("/chat");
                                }
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  margin: 5,
                                  padding: 5,
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                  }}
                                >
                                  {/* {notification.type ? (
                                    <Circle
                                      style={{
                                        position: "relative",
                                        fontSize: 13,
                                        color: BaseColor.green,
                                        right: "30px",
                                        left: 13,
                                        bottom: -10,
                                        border: `2px solid ${BaseColor.white}`,
                                        borderRadius: "50px",
                                      }}
                                    />
                                  ) : null} */}
                                  <NotificationsNoneSharp
                                    style={{
                                      color: BaseColor.primary,
                                      fontSize: 30,
                                      cursor:
                                        !isMobile && !isTablet && "pointer",
                                      backgroundColor: "#EAF3FA",
                                      borderRadius: "50%",
                                      padding: 5,
                                      border: `2px solid ${BaseColor.primary}`,
                                    }}
                                  />
                                </div>
                                <div style={{ marginLeft: 10 }}>
                                  <div
                                    style={{
                                      textAlign: "center",
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Typography
                                      style={{
                                        fontFamily: FontFamily.SemiBold,
                                        color: BaseColor.blackColor,
                                        fontSize: 16,
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        maxWidth: "60vw",
                                      }}
                                    >
                                      {notification.title}
                                    </Typography>

                                    <Typography
                                      style={{
                                        fontSize: 14,
                                        color: BaseColor.blackColor,
                                        fontFamily: FontFamily.Bold,
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        maxWidth: "50vw",
                                      }}
                                    >
                                      {time12}
                                    </Typography>
                                  </div>
                                  <Typography
                                    style={{
                                      color: BaseColor.grey,
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                      maxWidth: "75vw",
                                    }}
                                  >
                                    {notification.message}
                                  </Typography>
                                </div>
                              </div>
                            </Grid>
                          );
                        })}
                      </>
                    )
                  )}
                </div>
              )}

              {groupedNotifications.past.length > 0 && (
                <div>
                  <Typography
                    style={{
                      fontSize: 20,
                      fontFamily: FontFamily.Bold,
                      color: BaseColor.textColor,
                      marginTop: 10,
                      marginBottom: 10,
                      marginLeft: 10,
                    }}
                  >
                    Past Days
                  </Typography>
                  {groupedNotifications.past.map(
                    ({ day, notification_list, date }) => {
                      // Parse the ISO date string into a Date object
                      const parsedDate = new Date(date);

                      // Extract day, month, and year from the parsed date
                      const dayOfMonth = parsedDate
                        .getDate()
                        .toString()
                        .padStart(2, "0");
                      const monthOfYear = (parsedDate.getMonth() + 1)
                        .toString()
                        .padStart(2, "0"); // getMonth() returns 0-based index, hence +1
                      const year = parsedDate.getFullYear();

                      // Construct the formatted date in "dd-mm-yyyy" format
                      const formattedDate = `${monthOfYear}-${dayOfMonth}-${year}`;

                      return (
                        <div key={day}>
                          <Typography
                            style={{
                              fontSize: 18,
                              fontFamily: FontFamily.Bold,
                              color: BaseColor.textColor,
                              marginTop: 10,
                              marginBottom: 10,
                              marginLeft: 10,
                            }}
                          >
                            {day} ({formattedDate})
                          </Typography>

                          <ul>
                            {notification_list.map((notification) => {
                              const unixTimestamp = notification.created_at;
                              const notificationDate = new Date(
                                unixTimestamp * 1000
                              );

                              const hours = notificationDate.getHours();
                              const minutes = notificationDate
                                .getMinutes()
                                .toString()
                                .padStart(2, "0");

                              // Determine AM or PM
                              const amPM = hours >= 12 ? "PM" : "AM";

                              // Convert hours from 24-hour to 12-hour format
                              const twelveHourFormat = hours % 12 || 12; // 0 should be converted to 12

                              const time12 = `${twelveHourFormat}:${minutes} ${amPM}`;

                              return (
                                <li key={notification.id}>
                                  <Grid
                                    onClick={() => {
                                      if (
                                        notification?.type ===
                                        "new_reservation_created"
                                      ) {
                                        navigate("/reservation", {
                                          state: {
                                            notificationData: notification,
                                          },
                                        });
                                      } else if (
                                        notification?.type ===
                                          "user_reservation_rejected" ||
                                        notification?.type ===
                                          "user_reservation_accepted" ||
                                        notification?.type ===
                                          "chat_notification"
                                      ) {
                                        navigate("/chat");
                                      }
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        padding: 5,
                                        margin: 5,
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          alignItems: "center",
                                        }}
                                      >
                                        {/* {notification.type ? (
                                          <Circle
                                            style={{
                                              position: "relative",
                                              fontSize: 13,
                                              color: BaseColor.green,
                                              right: "30px",
                                              left: 13,
                                              bottom: -10,
                                              border: `2px solid ${BaseColor.white}`,
                                              borderRadius: "50px",
                                            }}
                                          />
                                        ) : null} */}
                                        <NotificationsNoneSharp
                                          style={{
                                            color: BaseColor.primary,
                                            fontSize: 30,
                                            cursor:
                                              !isMobile &&
                                              !isTablet &&
                                              "pointer",
                                            backgroundColor: "#EAF3FA",
                                            borderRadius: "50%",
                                            padding: 5,
                                            border: `2px solid ${BaseColor.primary}`,
                                          }}
                                        />
                                      </div>
                                      <div style={{ marginLeft: 10 }}>
                                        <div
                                          style={{
                                            textAlign: "center",
                                            display: "flex",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <Typography
                                            style={{
                                              fontFamily: FontFamily.SemiBold,
                                              color: BaseColor.blackColor,
                                              fontSize: 16,
                                              overflow: "hidden",
                                              textOverflow: "ellipsis",
                                              whiteSpace: "nowrap",
                                              maxWidth: "60vw",
                                            }}
                                          >
                                            {notification.title}
                                          </Typography>

                                          <Typography
                                            style={{
                                              fontSize: 14,
                                              color: BaseColor.blackColor,
                                              fontFamily: FontFamily.Bold,
                                              overflow: "hidden",
                                              textOverflow: "ellipsis",
                                              whiteSpace: "nowrap",
                                              maxWidth: "50vw",
                                            }}
                                          >
                                            {time12}
                                          </Typography>
                                        </div>

                                        <Typography
                                          style={{
                                            color: BaseColor.grey,
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            maxWidth: "75vw",
                                          }}
                                        >
                                          {notification.message}
                                        </Typography>
                                      </div>
                                    </div>
                                  </Grid>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      );
                    }
                  )}
                </div>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Notification;
