import { CircularProgress, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import BaseColor from "../../../../Config/colors";
import { FontFamily } from "../../../../Config/theme";
import styles from "./styles";
import { useSelector } from "react-redux";
import { Setting } from "../../../../Utils/Setting";
import { getAPIProgressData } from "../../../../Utils/APIHelper";
import { toast } from "react-toastify";
import _ from "lodash";
import { motion } from "framer-motion";

const GolfClubs = () => {
  const classes = styles();

  const { userData } = useSelector((state) => state.auth);

  const [clubData, setClubData] = useState([]);

  // page loader
  const [pageLoader, setPageLoader] = useState(true);

  useEffect(() => {
    getCommonData();
  }, []);

  const getCommonData = async () => {
    setPageLoader(true);
    let endPoints = Setting.endpoints.commonData;

    const params = {
      email: userData?.email,
    };

    try {
      const resp = await getAPIProgressData(endPoints, "POST", params);
      if (resp?.success) {
        if (!_.isEmpty(resp?.data)) {
          setClubData(resp?.data?.club_data);
        }
        setPageLoader(false);
      } else {
        toast(resp?.message, {
          type: "error",
        });
        setPageLoader(false);
      }
      setPageLoader(false);
    } catch (error) {
      toast(error.message, {
        type: "error",
      });
      setPageLoader(false);
    }
  };

  return (
    <Grid container style={{ height: "100%" }}>
      {pageLoader ? (
        <Grid
          container
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <CircularProgress size={40} color="primary" />
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Grid container height={"100%"} style={{ overflowY: "auto" }}>
            <Grid container height={"100%"}>
              <Grid item xs={0.5} sm={0.5} md={1} lg={1}></Grid>

              <Grid item xs={11} sm={11} md={11} lg={11}>
                <Grid container height={"90%"}>
                  <Grid item xs={12}>
                    <Grid item xs={12} mt={"20px"}>
                      <Typography
                        style={{
                          color: BaseColor.blackColor,
                          fontFamily: FontFamily.GilbertQualifiDemo,
                          fontSize: 22,
                          marginBottom: "10px",
                        }}
                      >
                        Golf Clubs
                      </Typography>
                      <hr
                        style={{
                          // width: "100%",
                          borderTop: "1px solid white",
                          marginBottom: 16,
                        }}
                      />
                    </Grid>
                    <motion.div
                      initial={{ opacity: 0, y: 50 }} // Start with the element lower and transparent
                      animate={{ opacity: 1, y: 0 }} // Animate to fully opaque and original position
                      exit={{ opacity: 0, y: 50 }} // Fade out and move down
                      transition={{ duration: 0.3 }} // Duration in seconds
                    >
                      {clubData?.map((data, index) => (
                        <>
                          <Grid item xs={12} mt={"20px"}>
                            <Grid container>
                              <Grid
                                item
                                xs={12}
                                mb={"10px"}
                                display={"flex"}
                                alignItems={"center"}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: FontFamily.Bold,
                                    color: BaseColor.blackColor,
                                    fontSize: 22,
                                  }}
                                >
                                  {index === 0
                                    ? "Home Club"
                                    : index === 1
                                    ? "Secondary Club"
                                    : `Additional Club ${Number(index) - 1}`}
                                </Typography>
                                {!_.isEmpty(data?.type) && (
                                  <Typography
                                    className={classes.inputValue}
                                    ml={"10px"}
                                  >
                                    ({data?.type})
                                  </Typography>
                                )}
                              </Grid>
                            </Grid>
                            <Grid container>
                              <Grid item xs={12}>
                                <Grid
                                  container
                                  display={"flex"}
                                  justifyContent={"space-between"}
                                  alignItems={"center"}
                                >
                                  <Grid
                                    item
                                    xs={5.8}
                                    sm={5.8}
                                    md={5.8}
                                    lg={5.8}
                                    mb={"10px"}
                                  >
                                    <Typography className={classes.inputTile}>
                                      Name of your home club
                                    </Typography>
                                    <Typography className={classes.inputValue}>
                                      {data?.name}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={5.8}
                                    sm={5.8}
                                    md={5.8}
                                    lg={5.8}
                                    mb={"10px"}
                                  >
                                    <Typography className={classes.inputTile}>
                                      Address
                                    </Typography>
                                    <Typography className={classes.inputValue}>
                                      {data?.address}
                                    </Typography>
                                  </Grid>
                                </Grid>
                                <Grid
                                  container
                                  display={"flex"}
                                  justifyContent={"space-between"}
                                  alignItems={"center"}
                                >
                                  <Grid
                                    item
                                    xs={5.8}
                                    sm={5.8}
                                    md={5.8}
                                    lg={5.8}
                                    mb={"10px"}
                                  >
                                    <Typography className={classes.inputTile}>
                                      Holes
                                    </Typography>
                                    <Typography className={classes.inputValue}>
                                      {data?.holes}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={5.8}
                                    sm={5.8}
                                    md={5.8}
                                    lg={5.8}
                                    mb={"10px"}
                                  >
                                    <Typography className={classes.inputTile}>
                                      Courses
                                    </Typography>
                                    <Typography className={classes.inputValue}>
                                      {data?.courses}
                                    </Typography>
                                  </Grid>
                                </Grid>
                                <Grid
                                  container
                                  display={"flex"}
                                  justifyContent={"space-between"}
                                  alignItems={"center"}
                                >
                                  <Grid
                                    item
                                    xs={5.8}
                                    sm={5.8}
                                    md={5.8}
                                    lg={5.8}
                                    mb={"10px"}
                                  >
                                    <Typography className={classes.inputTile}>
                                      State
                                    </Typography>
                                    <Typography className={classes.inputValue}>
                                      {data?.state}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={5.8}
                                    sm={5.8}
                                    md={5.8}
                                    lg={5.8}
                                    mb={"10px"}
                                  >
                                    <Typography className={classes.inputTile}>
                                      Guest fees
                                    </Typography>
                                    <Typography className={classes.inputValue}>
                                      ${data?.guest_fees}
                                    </Typography>
                                  </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                  <Grid
                                    container
                                    display={"flex"}
                                    justifyContent={"space-between"}
                                    alignItems={"center"}
                                  >
                                    <Grid
                                      item
                                      xs={5.8}
                                      sm={5.8}
                                      md={5.8}
                                      lg={5.8}
                                      mb={"10px"}
                                    >
                                      <Typography className={classes.inputTile}>
                                        About Golf Club
                                      </Typography>
                                      <Typography
                                        className={classes.inputValue}
                                      >
                                        {data?.about_club}
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={5.8}
                                      sm={5.8}
                                      md={5.8}
                                      lg={5.8}
                                      mb={"10px"}
                                    >
                                      <Typography className={classes.inputTile}>
                                        Unaccompanied Play
                                      </Typography>
                                      <Typography
                                        className={classes.inputValue}
                                      >
                                        {data?.unaccompanied_pay == 0
                                          ? "No"
                                          : "Yes"}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>

                                {!_.isEmpty(data?.course_web_link) && (
                                  <Grid
                                    item
                                    xs={5.8}
                                    sm={5.8}
                                    md={5.8}
                                    lg={5.8}
                                    mb={"20px"}
                                  >
                                    <Typography className={classes.inputTile}>
                                      Course Website
                                    </Typography>
                                    <a
                                      href={data?.course_web_link}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className={classes.inputValue}
                                    >
                                      <Typography className={classes.urlStyle}>
                                        {data?.course_web_link}
                                      </Typography>
                                    </a>
                                  </Grid>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                          <hr
                            style={{
                              // width: "100%",
                              borderTop: "1px solid white",
                              marginBottom: 16,
                            }}
                          />
                        </>
                      ))}
                    </motion.div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default GolfClubs;
