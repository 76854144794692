import { makeStyles } from "@mui/styles";
import { FontFamily } from "../../../Config/theme";
import BaseColor from "../../../Config/colors";
import { isMobile, isTablet } from "react-device-detect";

const styles = makeStyles({
  title: {
    fontFamily: `${FontFamily.GilbertQualifiDemo} !important`,
    fontSize: "25px !important",
    marginLeft: "10px !important",
    "@media (max-width:900px)": {
      marginLeft: "0px !important",
    },
  },
  switchDiv: {
    display: "flex",
    borderRadius: "20px",
    backgroundColor: BaseColor.offWhiteBg,
    "@media (max-width:900px)": {
      width: "100%",
    },
  },
  switchTab: {
    padding: "12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "20px",
    width: "50%",
    cursor: !isMobile && !isTablet && "pointer",
  },
  searchAndFilter: {
    display: "flex",
    alignItems: "center",
  },
  filterIcon: {
    backgroundColor: BaseColor.green,
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "10px",
    cursor: !isMobile && !isTablet && "pointer",
  },
  scrollBar: {
    "&::-webkit-scrollbar": {
      width: 3,
      height: 3,
    },

    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 5px transperent",
      borderRadius: 10,
    },

    "&::-webkit-scrollbar-thumb": {
      background: BaseColor.primary,
      borderRadius: 10,
    },

    "&::-webkit-scrollbar-thumb:hover": {
      boxShadow: "inset 0 0 5px grey",
    },
  },
  mainScrollBar: {
    "&::-webkit-scrollbar": {
      width: 0,
      height: 0,
    },
  },

  noReservationContainer: {
    border: `1px solid ${BaseColor.cursorColor}`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100px",
    marginTop: "10px !important",
    marginBottom: "10px !important",
    borderRadius: "5px",
    marginLeft: "10px !important",
    "@media (max-width:900px)": {
      marginLeft: "0px !important",
    },
  },
});

export default styles;
