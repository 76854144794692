import {
  CircularProgress,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import theme, { FontFamily } from "../../../../Config/theme";
import BaseColor from "../../../../Config/colors";
import Files from "../../../../Config/Files";
import CIcon from "../../../../Components/CIcon";
import _ from "lodash";
import CButton from "../../../../Components/CButton";
import CInput from "../../../../Components/CInput";
import styles from "./styles";
import CSelect from "../../../../CSelect";
import { isMobile, isTablet } from "react-device-detect";
import { Setting } from "../../../../Utils/Setting";
import { getAPIProgressData } from "../../../../Utils/APIHelper";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import authActions from "../../../../Redux/reducers/auth/actions";
import { motion } from "framer-motion";

const GolfOtherDetails = () => {
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const { userData } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const { setUserData } = authActions;

  const hearAboutOptionData = [
    { id: 1, name: "Referral" },
    { id: 2, name: "Social Media" },
    { id: 3, name: "Internet" },
    { id: 4, name: "Google search" },
  ];

  const shirtSizeArray = [
    { id: 1, name: "Extra Small" },
    { id: 2, name: "Small" },
    { id: 3, name: "Medium" },
    { id: 4, name: "Large" },
    { id: 5, name: "Extra Large" },
    { id: 6, name: "XXL" },
    { id: 7, name: "XXXL" },
  ];

  const [edit, setEdit] = useState(false);

  const classes = styles();

  // page loader
  const [pageLoader, setPageLoader] = useState(false);

  const [golfOtherDetailsData, setGolfOtherDetailsData] = useState({});

  const [tellAboutYourself, setTellAboutYourself] = useState("");
  const [tellAboutYourselfErr, setTellAboutYourselfErr] = useState(false);
  const [tellAboutYourselfMsg, setTellAboutYourselfMsg] = useState("");

  const [fitForClubReason, setFitForClubReason] = useState("");
  const [fitForClubReasonErr, setFitForClubReasonErr] = useState(false);
  const [fitForClubReasonMsg, setFitForClubReasonMsg] = useState("");

  const [hearAboutOption, setHearAboutOption] = useState({});
  const [hearAboutOptionErr, setHearAboutOptionErr] = useState(false);
  const [hearAboutOptionMsg, setHearAboutOptionMsg] = useState("");

  const [handicapOption, setHandicapOption] = useState("");
  const [handicapOptionErr, setHandicapOptionErr] = useState(false);
  const [handicapOptionMsg, setHandicapOptionMsg] = useState("");

  const [beerAndCocktails, setBeerAndCocktails] = useState("");
  const [beerAndCocktailsErr, setBeerAndCocktailsErr] = useState(false);
  const [beerAndCocktailsMsg, setBeerAndCocktailsMsg] = useState("");

  const [listenToMusic, setListenToMusic] = useState("");
  const [listenToMusicErr, setListenToMusicErr] = useState(false);
  const [listenToMusicMsg, setListenToMusicMsg] = useState("");

  const [hostMoreGuests, setHostMoreGuests] = useState("");
  const [hostMoreGuestsErr, setHostMoreGuestsErr] = useState(false);
  const [hostMoreGuestsMsg, setHostMoreGuestsMsg] = useState("");

  const [hasGolfSimulator, setHasGolfSimulator] = useState("");
  const [hasGolfSimulatorErr, setHasGolfSimulatorErr] = useState(false);
  const [hasGolfSimulatorMsg, setHasGolfSimulatorMsg] = useState("");

  const [referralName, setReferralName] = useState("");
  const [referralNameErr, setReferralNameErr] = useState(false);
  const [referralNameMsg, setReferralNameMsg] = useState("");

  const [shirtSize, setShirtSize] = useState({});
  const [shirtSizeErr, setShirtSizeErr] = useState(false);
  const [shirtSizeMsg, setShirtSizeMsg] = useState("");

  useEffect(() => {
    getCommonData();
  }, []);

  // btn loader
  const [updateBtnLoader, setUpdateBtnLoader] = useState(false);

  const validation = () => {
    let isValid = true;

    if (tellAboutYourself.trim() === "") {
      setTellAboutYourselfErr(true);
      setTellAboutYourselfMsg(
        "Please enter a brief description about yourself"
      );
      isValid = false;
    } else {
      setTellAboutYourselfErr(false);
      setTellAboutYourselfMsg("");
    }

    if (fitForClubReason.trim() === "") {
      setFitForClubReasonErr(true);
      setFitForClubReasonMsg(
        "Please enter a brief description of GOATT golf club"
      );
    } else {
      setFitForClubReasonErr(false);
      setFitForClubReasonMsg("");
    }

    if (_.isEmpty(hearAboutOption)) {
      setHearAboutOptionErr(true);
      setHearAboutOptionMsg(
        "Please select an option for how you heard about GOATT Golf Club"
      );
      isValid = false;
    } else {
      setHearAboutOptionErr(false);
      setHearAboutOptionMsg("");

      if (hearAboutOption.name === "Referral") {
        if (referralName.trim() === "") {
          setReferralNameErr(true);
          setReferralNameMsg("Please enter a reference name");
          isValid = false;
        } else {
          setReferralNameErr(false);
          setReferralNameMsg("");
        }
      }
    }

    if (handicapOption.trim() === "") {
      setHandicapOptionErr(true);
      setHandicapOptionMsg("Please enter your Handicap/GHIN");
      isValid = false;
    } else {
      setHandicapOptionErr(false);
      setHandicapOptionMsg("");
    }

    if (beerAndCocktails === "") {
      setBeerAndCocktailsErr(true);
      setBeerAndCocktailsMsg(
        "Please select  beer and cocktails while playing golf"
      );
      isValid = false;
    } else {
      setBeerAndCocktailsErr(false);
      setBeerAndCocktailsMsg("");
    }

    if (listenToMusic === "") {
      setListenToMusicErr(true);
      setListenToMusicMsg("Please select  listen to music while playing golf");
      isValid = false;
    } else {
      setListenToMusicErr(false);
      setListenToMusicMsg("");
    }

    if (hostMoreGuests === "") {
      setHostMoreGuestsErr(true);
      setHostMoreGuestsMsg("Please select  host more than one guest at a time");
      isValid = false;
    } else {
      setHostMoreGuestsErr(false);
      setHostMoreGuestsMsg("");
    }

    if (hasGolfSimulator === "") {
      setHasGolfSimulatorErr(true);
      setHasGolfSimulatorMsg("Please select  golf simulator in your home");
      isValid = false;
    } else {
      setHasGolfSimulatorErr(false);
      setHasGolfSimulatorMsg("");
    }

    if (shirtSize === "") {
      setShirtSizeErr(true);
      setShirtSizeMsg("Please select your shirt size");
      isValid = false;
    } else {
      setShirtSizeErr(false);
      setShirtSizeMsg("");
    }

    if (isValid) {
      updateGolfOtherDetails();
    }
  };

  // get golf other details
  const getCommonData = async () => {
    setPageLoader(true);
    let endPoints = Setting.endpoints.commonData;

    const params = {
      email: userData?.email,
    };

    try {
      const resp = await getAPIProgressData(endPoints, "POST", params);
      if (resp?.success) {
        if (!_.isEmpty(resp?.data)) {
          setGolfOtherDetailsData(resp?.data);
          setData(resp?.data);
          dispatch(setUserData(resp?.data));
        }
      } else {
        toast(resp?.message, {
          type: "error",
        });
        setPageLoader(false);
      }
      setUpdateBtnLoader(false);
    } catch (error) {
      toast(error.message, {
        type: "error",
      });
      setPageLoader(false);
      setUpdateBtnLoader(false);
    }
  };

  const setData = (data) => {
    const golfOtherDetails =
      !_.isEmpty(data?.other_details) &&
      !_.isNull(data?.other_details) &&
      !_.isUndefined(data?.other_details)
        ? data?.other_details
        : {};

    const aboutSelf =
      !_.isEmpty(golfOtherDetails?.about_yourself) &&
      !_.isNull(golfOtherDetails?.about_yourself) &&
      !_.isUndefined(golfOtherDetails?.about_yourself)
        ? golfOtherDetails?.about_yourself
        : "";

    const fitReason =
      !_.isEmpty(golfOtherDetails?.fit_reason) &&
      !_.isNull(golfOtherDetails?.fit_reason) &&
      !_.isUndefined(golfOtherDetails?.fit_reason)
        ? golfOtherDetails?.fit_reason
        : "";

    const source =
      !_.isEmpty(golfOtherDetails?.goatt_source) &&
      !_.isNull(golfOtherDetails?.goatt_source) &&
      !_.isUndefined(golfOtherDetails?.goatt_source)
        ? hearAboutOptionData?.find(
            (data) => data?.name === golfOtherDetails?.goatt_source
          )
        : "";

    const handicap =
      !_.isEmpty(golfOtherDetails?.handicap_or_ghin) &&
      !_.isNull(golfOtherDetails?.handicap_or_ghin) &&
      !_.isUndefined(golfOtherDetails?.handicap_or_ghin)
        ? golfOtherDetails?.handicap_or_ghin
        : "";
    const beverages =
      !_.isNull(golfOtherDetails?.beverages_available) &&
      !_.isUndefined(golfOtherDetails?.beverages_available)
        ? golfOtherDetails?.beverages_available == 1
          ? "Yes"
          : "No"
        : "";

    const music =
      !_.isNull(golfOtherDetails?.listen_to_music) &&
      !_.isUndefined(golfOtherDetails?.listen_to_music)
        ? golfOtherDetails?.listen_to_music == 1
          ? "Yes"
          : "No"
        : "";
    const guest =
      !_.isNull(golfOtherDetails?.multi_guest) &&
      !_.isUndefined(golfOtherDetails?.multi_guest)
        ? golfOtherDetails?.multi_guest == 1
          ? "Yes"
          : "No"
        : "";

    const hasGolfSimulator =
      !_.isNull(golfOtherDetails?.home_golf_simulator) &&
      !_.isUndefined(golfOtherDetails?.home_golf_simulator)
        ? golfOtherDetails?.home_golf_simulator == 1
          ? "Yes"
          : "No"
        : "";

    const hasShirtSize =
      !_.isEmpty(golfOtherDetails?.shirt_size) &&
      !_.isNull(golfOtherDetails?.shirt_size) &&
      !_.isUndefined(golfOtherDetails?.shirt_size)
        ? shirtSizeArray?.find(
            (data) => data?.name === golfOtherDetails?.shirt_size
          )
        : "";

    const referenceName =
      !_.isEmpty(golfOtherDetails?.reference_name) &&
      !_.isNull(golfOtherDetails?.reference_name) &&
      !_.isUndefined(golfOtherDetails?.reference_name)
        ? golfOtherDetails?.reference_name
        : "";

    setTellAboutYourself(aboutSelf);
    setFitForClubReason(fitReason);
    setHearAboutOption(source);
    setHandicapOption(handicap);
    setBeerAndCocktails(beverages);
    setListenToMusic(music);
    setHostMoreGuests(guest);
    setHasGolfSimulator(hasGolfSimulator);
    setShirtSize(hasShirtSize);
    setReferralName(referenceName);
    setPageLoader(false);
  };

  // update golf other details
  const updateGolfOtherDetails = async () => {
    setUpdateBtnLoader(true);
    let endPoints = Setting.endpoints.golfOtherDetails;
    const params = {
      email: userData?.email,
      "MembersOtherDetails[about_yourself]": tellAboutYourself || "",
      "MembersOtherDetails[fit_reason]": fitForClubReason || "",
      "MembersOtherDetails[goatt_source]": hearAboutOption?.name || "",
      "MembersOtherDetails[reference_name]":
        hearAboutOption?.name === "Referral" ? referralName : "" || "",
      "MembersOtherDetails[handicap_or_ghin]": handicapOption || "",
      "MembersOtherDetails[beverages_available]":
        beerAndCocktails === "No" ? 0 : 1 || "",
      "MembersOtherDetails[listen_to_music]":
        listenToMusic === "No" ? 0 : 1 || "",
      "MembersOtherDetails[home_golf_simulator]":
        hasGolfSimulator === "No" ? 0 : 1 || "",
      "MembersOtherDetails[multi_guest]": hostMoreGuests === "No" ? 0 : 1,
      "MembersOtherDetails[shirt_size]": shirtSize?.name || "",
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
      if (resp?.success) {
        toast(resp?.message, {
          type: "success",
        });
        getCommonData();
        setEdit(false);
      } else {
        toast(resp?.message, {
          type: "error",
        });
      }
      setUpdateBtnLoader(false);
    } catch (error) {
      toast(error?.message, {
        type: "error",
      });
      setUpdateBtnLoader(false);
    }
  };

  return (
    <Grid container height={"100%"}>
      {pageLoader ? (
        <Grid
          container
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <CircularProgress size={40} color="primary" />
        </Grid>
      ) : (
        <>
          <Grid container>
            <Grid item xs={0.5} sm={0.5} md={1} lg={1}></Grid>

            <Grid item xs={11} sm={11.5} md={11} lg={11}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  height={"100%"}
                  style={{ overflowY: "auto" }}
                >
                  <Grid item xs={12} mt={"20px"}>
                    <Grid
                      container
                      display={"flex"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Typography
                        style={{
                          color: BaseColor.blackColor,
                          fontFamily: FontFamily.GilbertQualifiDemo,
                          fontSize: 22,
                          marginBottom: "10px",
                        }}
                      >
                        Golf Other Details
                      </Typography>
                      <CIcon
                        src={Files.svgIcons.editGreen}
                        size={25}
                        onClick={() => {
                          setEdit(!edit);
                        }}
                        style={{
                          cursor: !isMobile && !isTablet && "pointer",
                        }}
                      />
                    </Grid>
                    <hr
                      style={{
                        // width: "100%",
                        borderTop: "1px solid white",
                        marginBottom: 16,
                      }}
                    />
                  </Grid>
                  <motion.div
                    initial={{ opacity: 0, y: 50 }} // Start with the element lower and transparent
                    animate={{ opacity: 1, y: 0 }} // Animate to fully opaque and original position
                    exit={{ opacity: 0, y: 50 }} // Fade out and move down
                    transition={{ duration: 0.3 }} // Duration in seconds
                  >
                    <Grid item xs={12} mt={"20px"}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Grid
                            container
                            display={"flex"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                          >
                            <Grid
                              item
                              xs={5.8}
                              sm={5.8}
                              md={edit ? 12 : 5.8}
                              lg={edit ? 12 : 5.8}
                              mb={!edit && "10px"}
                            >
                              {edit ? (
                                <CInput
                                  fullWidth
                                  label="Tell us about yourself"
                                  placeholder="Brief description"
                                  multiline
                                  rows={4}
                                  variant="outlined"
                                  value={tellAboutYourself}
                                  onChange={(e) => {
                                    setTellAboutYourself(e);
                                    setTellAboutYourselfErr(false);
                                    setTellAboutYourselfMsg("");
                                  }}
                                  error={tellAboutYourselfErr}
                                  errorMsg={
                                    tellAboutYourselfErr
                                      ? tellAboutYourselfMsg
                                      : null
                                  }
                                  maxLength={300}
                                  endIcon
                                />
                              ) : (
                                <>
                                  <Typography className={classes.inputTile}>
                                    About Me
                                  </Typography>
                                  <Typography className={classes.inputValue}>
                                    {tellAboutYourself}
                                  </Typography>
                                </>
                              )}
                            </Grid>
                            <Grid
                              item
                              xs={5.8}
                              sm={5.8}
                              md={edit ? 12 : 5.8}
                              lg={edit ? 12 : 5.8}
                              mb={!edit && "10px"}
                            >
                              {edit ? (
                                <CInput
                                  fullWidth
                                  placeholder="Brief description"
                                  label="Why do you think you'd be a good fit for GOATT golf club"
                                  multiline
                                  rows={4}
                                  variant="outlined"
                                  value={fitForClubReason}
                                  onChange={(e) => {
                                    setFitForClubReason(e);
                                    setFitForClubReasonErr(false);
                                    setFitForClubReasonMsg("");
                                  }}
                                  error={fitForClubReasonErr}
                                  errorMsg={
                                    fitForClubReasonErr
                                      ? fitForClubReasonMsg
                                      : null
                                  }
                                  maxLength={300}
                                  endIcon
                                />
                              ) : (
                                <>
                                  <Typography className={classes.inputTile}>
                                    Good fit for GOATT golf club
                                  </Typography>
                                  <Typography className={classes.inputValue}>
                                    {fitForClubReason}
                                  </Typography>
                                </>
                              )}
                            </Grid>
                          </Grid>

                          <Grid item xs={12}>
                            <Grid
                              container
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Grid
                                item
                                xs={12}
                                sm={5.8}
                                md={edit ? 12 : 5.8}
                                lg={edit ? 12 : 5.8}
                                mb={!edit && "10px"}
                              >
                                {edit ? (
                                  <CSelect
                                    fullObj
                                    data={hearAboutOptionData}
                                    value={hearAboutOption}
                                    label="How did you hear about GOATT Golf Club"
                                    placeholder="Select Option"
                                    onSelect={(val) => {
                                      setHearAboutOption(val);
                                      setHearAboutOptionErr(false);
                                      setHearAboutOptionMsg("");
                                      setReferralName("");
                                    }}
                                    error={hearAboutOptionErr}
                                    errorMsg={
                                      hearAboutOptionErr
                                        ? hearAboutOptionMsg
                                        : null
                                    }
                                  />
                                ) : (
                                  <>
                                    <Typography className={classes.inputTile}>
                                      How did you hear about GOATT golf club
                                    </Typography>
                                    <Typography className={classes.inputValue}>
                                      {hearAboutOption?.name}
                                    </Typography>
                                  </>
                                )}
                              </Grid>
                              {sm && (
                                <>
                                  {!_.isEmpty(hearAboutOption) &&
                                    hearAboutOption?.name === "Referral" && (
                                      <Grid
                                        item
                                        xs={12}
                                        sm={5.8}
                                        md={edit ? 12 : 5.8}
                                        lg={edit ? 12 : 5.8}
                                      >
                                        {edit ? (
                                          <CInput
                                            fullWidth
                                            label="Reference Name"
                                            multiline
                                            variant="outlined"
                                            value={referralName}
                                            onChange={(e) => {
                                              setReferralName(e);
                                              setReferralNameErr(false);
                                              setReferralNameMsg("");
                                            }}
                                            error={referralNameErr}
                                            errorMsg={
                                              referralNameErr
                                                ? referralNameMsg
                                                : null
                                            }
                                          />
                                        ) : (
                                          <>
                                            <Typography
                                              className={classes.inputTile}
                                            >
                                              Reference
                                            </Typography>
                                            <Typography
                                              className={classes.inputValue}
                                            >
                                              {referralName}
                                            </Typography>
                                          </>
                                        )}
                                      </Grid>
                                    )}
                                </>
                              )}
                              <Grid
                                item
                                xs={12}
                                sm={5.8}
                                md={edit ? 12 : 5.8}
                                lg={edit ? 12 : 5.8}
                                mb={!edit && "10px"}
                              >
                                {edit ? (
                                  <CInput
                                    fullWidth
                                    placeholder="Handicap/GHIN"
                                    label="Handicap/GHIN"
                                    multiline
                                    variant="outlined"
                                    value={handicapOption}
                                    onChange={(e) => {
                                      // Allow only numeric and decimal input
                                      const numericValue = e.replace(
                                        /[^0-9.]/g,
                                        ""
                                      ); // Replace non-numeric and non-decimal characters
                                      const decimalCount = (
                                        numericValue.match(/\./g) || []
                                      ).length; // Count decimal points

                                      let formattedValue = numericValue;

                                      // Check if there is more than one decimal point
                                      if (decimalCount > 1) {
                                        // If more than one decimal point, remove the extra ones
                                        const parts = numericValue.split(".");
                                        const integerPart = parts[0];
                                        const decimalPart = parts
                                          .slice(1)
                                          .join("");
                                        formattedValue = `${integerPart}.${decimalPart}`;
                                      }

                                      // Check if the numeric value is valid and within the range 0 to 54
                                      const numericValueFloat =
                                        parseFloat(formattedValue);
                                      if (
                                        numericValueFloat > 54 ||
                                        numericValueFloat < 0 ||
                                        isNaN(numericValueFloat)
                                      ) {
                                        formattedValue = ""; // If value exceeds 54, is less than 0, or is NaN, clear the input
                                      }

                                      setHandicapOption(formattedValue);
                                      setHandicapOptionErr(false);
                                      setHandicapOptionMsg("");
                                    }}
                                    error={handicapOptionErr}
                                    errorMsg={
                                      handicapOptionErr
                                        ? handicapOptionMsg
                                        : null
                                    }
                                  />
                                ) : (
                                  <>
                                    <Typography className={classes.inputTile}>
                                      Handicap/GHIN
                                    </Typography>
                                    <Typography className={classes.inputValue}>
                                      {handicapOption}
                                    </Typography>
                                  </>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item xs={12}>
                            <Grid
                              container
                              display={"flex"}
                              justifyContent={"space-between"}
                            >
                              <Grid
                                item
                                xs={12}
                                sm={5.8}
                                md={edit ? 12 : 5.8}
                                lg={edit ? 12 : 5.8}
                                mb={!edit && "10px"}
                              >
                                {!sm && hearAboutOption?.name === "Referral" ? (
                                  edit ? (
                                    <CInput
                                      fullWidth
                                      label="Reference Name"
                                      multiline
                                      variant="outlined"
                                      value={referralName}
                                      onChange={(e) => {
                                        setReferralName(e);
                                        setReferralNameErr(false);
                                        setReferralNameMsg("");
                                      }}
                                      error={referralNameErr}
                                      errorMsg={
                                        referralNameErr ? referralNameMsg : null
                                      }
                                    />
                                  ) : (
                                    <>
                                      <Typography className={classes.inputTile}>
                                        Reference Name
                                      </Typography>
                                      <Typography
                                        className={classes.inputValue}
                                      >
                                        {referralName}
                                      </Typography>
                                    </>
                                  )
                                ) : edit ? (
                                  <>
                                    <Typography
                                      style={{
                                        fontSize: 16,
                                        fontFamily: FontFamily.SemiBold,
                                        color: BaseColor.textColor,
                                        marginTop: 2,
                                      }}
                                    >
                                      Beer and cocktails while you play?
                                    </Typography>
                                    <RadioGroup
                                      row
                                      value={beerAndCocktails}
                                      onChange={(e) => {
                                        setBeerAndCocktails(e.target.value);
                                        setBeerAndCocktailsErr(false);
                                        setBeerAndCocktailsMsg("");
                                      }}
                                    >
                                      <FormControlLabel
                                        value="Yes"
                                        control={<Radio />}
                                        label="Yes"
                                      />
                                      <FormControlLabel
                                        value="No"
                                        control={<Radio />}
                                        label="No"
                                      />
                                    </RadioGroup>
                                    {beerAndCocktailsErr && (
                                      <Typography
                                        style={{
                                          fontSize: 14,
                                          color: BaseColor.errorRed,
                                          fontFamily: FontFamily.Medium,
                                        }}
                                      >
                                        {beerAndCocktailsMsg}
                                      </Typography>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <Typography className={classes.inputTile}>
                                      Beer and cocktails while you play
                                    </Typography>
                                    <Typography className={classes.inputValue}>
                                      {beerAndCocktails}
                                    </Typography>
                                  </>
                                )}
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={5.8}
                                md={edit ? 12 : 5.8}
                                lg={edit ? 12 : 5.8}
                                mb={!edit && "10px"}
                              >
                                {edit ? (
                                  <>
                                    <Typography
                                      style={{
                                        fontSize: 16,
                                        fontFamily: FontFamily.SemiBold,
                                        color: BaseColor.textColor,
                                        marginTop: "15px",
                                      }}
                                    >
                                      Do you listen to music while you play
                                      golf?
                                    </Typography>
                                    <RadioGroup
                                      row
                                      value={listenToMusic}
                                      onChange={(e) => {
                                        setListenToMusic(e.target.value);
                                        setListenToMusicErr(false);
                                        setListenToMusicMsg("");
                                      }}
                                    >
                                      <FormControlLabel
                                        value="Yes"
                                        control={<Radio />}
                                        label="Yes"
                                      />
                                      <FormControlLabel
                                        value="No"
                                        control={<Radio />}
                                        label="No"
                                      />
                                    </RadioGroup>
                                    {listenToMusicErr && (
                                      <Typography
                                        style={{
                                          fontSize: 14,
                                          color: BaseColor.errorRed,
                                          fontFamily: FontFamily.Medium,
                                        }}
                                      >
                                        {listenToMusicMsg}
                                      </Typography>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <Typography className={classes.inputTile}>
                                      Do you listen to music while you play golf
                                    </Typography>
                                    <Typography className={classes.inputValue}>
                                      {listenToMusic}
                                    </Typography>
                                  </>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item xs={12}>
                            <Grid
                              container
                              display={"flex"}
                              justifyContent={"space-between"}
                            >
                              <Grid
                                item
                                xs={12}
                                sm={5.8}
                                md={edit ? 12 : 5.8}
                                lg={edit ? 12 : 5.8}
                                mb={!edit && "10px"}
                              >
                                {hearAboutOption?.name === "Referral" && !sm ? (
                                  edit ? (
                                    <>
                                      <Typography
                                        style={{
                                          fontSize: 16,
                                          fontFamily: FontFamily.SemiBold,
                                          color: BaseColor.textColor,
                                          marginTop: 2,
                                        }}
                                      >
                                        Beer and cocktails while you play?
                                      </Typography>
                                      <RadioGroup
                                        row
                                        value={beerAndCocktails}
                                        onChange={(e) => {
                                          setBeerAndCocktails(e.target.value);
                                          setBeerAndCocktailsErr(false);
                                          setBeerAndCocktailsMsg("");
                                        }}
                                      >
                                        <FormControlLabel
                                          value="Yes"
                                          control={<Radio />}
                                          label="Yes"
                                        />
                                        <FormControlLabel
                                          value="No"
                                          control={<Radio />}
                                          label="No"
                                        />
                                      </RadioGroup>
                                      {beerAndCocktailsErr && (
                                        <Typography
                                          style={{
                                            fontSize: 14,
                                            color: BaseColor.errorRed,
                                            fontFamily: FontFamily.Medium,
                                          }}
                                        >
                                          {beerAndCocktailsMsg}
                                        </Typography>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      <Typography className={classes.inputTile}>
                                        Beer and cocktails while you play?
                                      </Typography>
                                      <Typography
                                        className={classes.inputValue}
                                      >
                                        {beerAndCocktails}
                                      </Typography>
                                    </>
                                  )
                                ) : edit ? (
                                  <>
                                    <Typography
                                      style={{
                                        fontSize: 16,
                                        fontFamily: FontFamily.SemiBold,
                                        color: BaseColor.textColor,
                                        marginTop: 2,
                                      }}
                                    >
                                      Will you host more than one guest at a
                                      time?
                                    </Typography>
                                    <RadioGroup
                                      row
                                      value={hostMoreGuests}
                                      onChange={(e) => {
                                        setHostMoreGuests(e.target.value);
                                        setHostMoreGuestsErr(false);
                                        setHostMoreGuestsMsg("");
                                      }}
                                    >
                                      <FormControlLabel
                                        value="Yes"
                                        control={<Radio />}
                                        label="Yes"
                                      />
                                      <FormControlLabel
                                        value="No"
                                        control={<Radio />}
                                        label="No"
                                      />
                                    </RadioGroup>
                                    {hostMoreGuestsErr && (
                                      <Typography
                                        style={{
                                          fontSize: 14,
                                          color: BaseColor.errorRed,
                                          fontFamily: FontFamily.Medium,
                                        }}
                                      >
                                        {hostMoreGuestsMsg}
                                      </Typography>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <Typography className={classes.inputTile}>
                                      Will you host more than one guest at a
                                      time?
                                    </Typography>
                                    <Typography className={classes.inputValue}>
                                      {hostMoreGuests}
                                    </Typography>
                                  </>
                                )}
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={5.8}
                                md={edit ? 12 : 5.8}
                                lg={edit ? 12 : 5.8}
                                mb={!edit && "10px"}
                              >
                                {edit ? (
                                  <>
                                    {" "}
                                    <Typography
                                      style={{
                                        fontSize: 16,
                                        fontFamily: FontFamily.SemiBold,
                                        color: BaseColor.textColor,
                                        marginTop: 2,
                                      }}
                                    >
                                      Do you have a golf simulator in your home?
                                    </Typography>
                                    <RadioGroup
                                      row
                                      value={hasGolfSimulator}
                                      onChange={(e) => {
                                        setHasGolfSimulator(e.target.value);
                                        setHasGolfSimulatorErr(false);
                                        setHasGolfSimulatorMsg("");
                                      }}
                                    >
                                      <FormControlLabel
                                        value="Yes"
                                        control={<Radio />}
                                        label="Yes"
                                      />
                                      <FormControlLabel
                                        value="No"
                                        control={<Radio />}
                                        label="No"
                                      />
                                    </RadioGroup>
                                    {hasGolfSimulatorErr && (
                                      <Typography
                                        style={{
                                          fontSize: 14,
                                          color: BaseColor.errorRed,
                                          fontFamily: FontFamily.Medium,
                                        }}
                                      >
                                        {hasGolfSimulatorMsg}
                                      </Typography>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <Typography className={classes.inputTile}>
                                      Do you have a golf simulator in your home?
                                    </Typography>
                                    <Typography className={classes.inputValue}>
                                      {hasGolfSimulator}
                                    </Typography>
                                  </>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>

                          {hearAboutOption?.name === "Referral" && !sm && (
                            <Grid
                              item
                              xs={12}
                              sm={5.8}
                              md={edit ? 12 : 5.8}
                              lg={edit ? 12 : 5.8}
                              mb={!edit && "10px"}
                            >
                              {edit ? (
                                <>
                                  <Typography
                                    style={{
                                      fontSize: 16,
                                      fontFamily: FontFamily.SemiBold,
                                      color: BaseColor.textColor,
                                    }}
                                  >
                                    Will you host more than one guest at a time?
                                  </Typography>
                                  <RadioGroup
                                    row
                                    value={hostMoreGuests}
                                    onChange={(e) => {
                                      setHostMoreGuests(e.target.value);
                                      setHostMoreGuestsErr(false);
                                      setHostMoreGuestsMsg("");
                                    }}
                                  >
                                    <FormControlLabel
                                      value="Yes"
                                      control={<Radio />}
                                      label="Yes"
                                    />
                                    <FormControlLabel
                                      value="No"
                                      control={<Radio />}
                                      label="No"
                                    />
                                  </RadioGroup>
                                  {hostMoreGuestsErr && (
                                    <Typography
                                      style={{
                                        fontSize: 14,
                                        color: BaseColor.errorRed,
                                        fontFamily: FontFamily.Medium,
                                      }}
                                    >
                                      {hostMoreGuestsMsg}
                                    </Typography>
                                  )}
                                </>
                              ) : (
                                <>
                                  <Typography className={classes.inputTile}>
                                    Will you host more than one guest at a time?
                                  </Typography>
                                  <Typography className={classes.inputValue}>
                                    {hostMoreGuests}
                                  </Typography>
                                </>
                              )}
                            </Grid>
                          )}
                          <Grid
                            item
                            xs={12}
                            sm={5.8}
                            md={edit ? 12 : 5.8}
                            lg={edit ? 12 : 5.8}
                            mb={!edit && "10px"}
                          >
                            {edit ? (
                              <CSelect
                                fullObj
                                data={shirtSizeArray}
                                value={shirtSize}
                                label="What is your shirt size?"
                                placeholder="Select Size"
                                onSelect={(val) => {
                                  setShirtSize(val);
                                  setShirtSizeErr(false);
                                  setShirtSizeMsg("");
                                }}
                                error={shirtSizeErr}
                                errorMsg={shirtSizeErr ? shirtSizeMsg : null}
                              />
                            ) : (
                              <>
                                <Typography className={classes.inputTile}>
                                  What is your shirt size?
                                </Typography>
                                <Typography className={classes.inputValue}>
                                  {shirtSize?.name}
                                </Typography>
                              </>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </motion.div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            height={"10%"}
            style={{
              position: "sticky",
              bottom: 0,
              backgroundColor: BaseColor.white,
              overflowX: "hidden",
            }}
          >
            {edit && (
              <Grid item xs={12} display={"flex"} alignItems={"center"}>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: md ? "space-between" : "flex-end",
                    alignItems: "center",
                  }}
                >
                  <CButton
                    variant={md ? "outlined" : "textPrimary"}
                    btnStyle={{ width: "25%" }}
                    onClick={() => {
                      setEdit(false);
                      setData(golfOtherDetailsData);
                    }}
                  >
                    Cancel
                  </CButton>
                  <CButton
                    variant="contained"
                    btnStyle={{ width: "25%", marginLeft: "10px" }}
                    loading={updateBtnLoader}
                    onClick={() => {
                      validation();
                    }}
                  >
                    Update
                  </CButton>
                </div>
              </Grid>
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default GolfOtherDetails;
