import { makeStyles } from "@mui/styles";
import { FontFamily } from "../../Config/theme";
import BaseColor from "../../Config/colors";

const styles = makeStyles({
  title: {
    fontFamily: `${FontFamily.SemiBold} !important`,
    fontSize: "20px !important",
    color: `${BaseColor.blackColor} !important`,
    textOverflow: "ellipsis !important",
    WebkitLineClamp: 1,
    overflow: "hidden",
    WebkitBoxOrient: "vertical",
    display: "-webkit-box",
    "@media (max-width:900px)": {
      fontSize: "16px !important",
    },
  },
  time: {
    fontFamily: `${FontFamily.Medium} !important`,
    fontSize: "15px !important",
    color: `${BaseColor.grey} !important`,
    "@media (max-width:900px)": {
      fontSize: "12px !important",
    },
  },
  host: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "5px",
    "@media (max-width:600px)": {
      fontSize: "13px !important",
    },
  },
  card: {
    display: "flex",
    height: "120px",
    alignItems: "center",
    backgroundColor: BaseColor.white,
    padding: "10px",
    borderRadius: "6px !important",
    width: "100%",
    border: "1px solid #B4BDC3",
    boxShadow: "0px 0px 0px 0px !important",
  },
  image: {
    borderRadius: "5.33px",
  },
  content: {
    flexGrow: 1,
    padding: "0px 12px !important",
  },
});

export default styles;
