import React from "react";
import Files from "../../Config/Files";
import BaseColor from "../../Config/colors";
import CIcon from "../CIcon";
import { Typography } from "@mui/material";
import { FontFamily } from "../../Config/theme";
import { isMobile, isTablet } from "react-device-detect";
import { useNavigate } from "react-router-dom";
const CMarker = (props) => {
  const { name, img, data, zoom, onClick = () => {} } = props;

  // navigation
  const navigate = useNavigate();

  // Adjust this zoom level threshold as per your requirement
  const showName = zoom >= 12;
  const showImage = zoom >= 10;
  const showInit = zoom >= 7;

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        cursor: "pointer",
      }}
      onClick={() => {
        onClick();
      }}
    >
      <div
        style={{
          position: "absolute",
          marginTop: showImage && "-110px",
        }}
      >
        {showImage && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={img ? img : Files.Images.contactUsImage}
              style={{
                height: "50px",
                width: "50px",
                borderRadius: "50%",
                position: "relative",
                zIndex: 2,
                top: "40px",
                border: `2px solid ${BaseColor.green}`,
                cursor: !isMobile && !isTablet && "pointer",
                pointerEvents: "auto",
              }}
              onClick={(e) => {
                navigate(`/club-details/${data?.id}`);
              }}
              alt="location"
              draggable={false}
            ></img>
          </div>
        )}
        {showImage && (
          <CIcon
            src={Files.svgIcons.MapMarker}
            size={"40px"}
            style={{ position: "relative", top: "10px" }}
          />
        )}
        {showInit && !showImage && (
          <img
            width={100}
            height={100}
            src="https://i.ibb.co/5rKm7X2/images-removebg-preview.png"
            style={{
              marginTop: "-50px",
              position: "relative",
              top: "28px",
            }}
            alt={name}
          />
        )}
        {!showInit && !showImage && (
          <div>
            <img
              width={80}
              height={80}
              src="https://i.ibb.co/ZSQ507R/447-4473995-golf-hole-flag-png-flag-transparent-png-removebg-preview.png"
              style={{
                objectFit: "contain",
                marginTop: "-50px",
                // position: "relative",
                top: "28px",
                marginLeft: "26px",
              }}
              alt={name}
            />
          </div>
        )}

        {showImage && (
          <CIcon
            src={Files.svgIcons.RoundMarker}
            size={showImage ? "15px" : "8px"}
            style={{ marginLeft: "1px" }}
          />
        )}
        {showName && ( // Conditionally render name based on zoom level
          <div
            style={{
              backgroundColor: BaseColor.white,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50px",
              marginTop: "5px",
              textOverflow: "ellipsis",
              width: "max-content",
              cursor: !isMobile && !isTablet && "pointer",
              position: "relative",
              zIndex: 10,
            }}
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/club-details/${data?.id}`);
            }}
          >
            <Typography
              sx={{
                color: BaseColor.blackColor,
                position: "relative",
                zIndex: 1,
                fontSize: "13px",
                fontFamily: FontFamily.SemiBold,
                padding: "6.47px 12.93px",
                textOverflow: "ellipsis",
              }}
            >
              {name}
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};

export default CMarker;
