import { makeStyles } from "@mui/styles";
import { isMobile } from "react-device-detect";
import theme, { FontFamily } from "../../../../Config/theme";
import BaseColor from "../../../../Config/colors";

const styles = makeStyles({
  createProfileContainer: {
    height: isMobile ? 130 : 100,
    width: isMobile ? 130 : 100,
    borderRadius: "100%",
    [theme.breakpoints.down("md")]: {
      height: 100,
      width: 100,
    },
  },
  createProfileImageContainer: {
    height: "100%",
    width: "100%",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  createProfileImage: {
    objectFit: "fill",
    height: "100%",
    width: "100%",
    borderRadius: "50%",
  },
  createProfilePlaceholderImg: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignSelf: "center",
    borderRadius: "50%",
  },

  inputTile: {
    color: "black !important",
    fontFamily: `${FontFamily.SemiBold} !important`,
    fontSize: "16px !important",
  },
  inputValue: {
    color: `${BaseColor.grey} !important`,
    fontFamily: `${FontFamily.Medium} !important`,
    fontSize: "14px !important",
    marginTop: "5px !important",
  },
});

export default styles;
