import React from "react";
import CModal from "../../../Components/CModal";
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  useMediaQuery,
} from "@mui/material";
import theme, { FontFamily } from "../../../Config/theme";
import CloseIcon from "@mui/icons-material/Close";
import BaseColor from "../../../Config/colors";
import styles from "./chatSection.styles";

export default function PaymentModel(props) {
  const classes = styles();
  const { showPaymentModel, setShowPaymentModel, messageData } = props;
  const md = useMediaQuery(theme.breakpoints.down("md"));

  // Submit
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const payment_option = data.get("payment_option");
    messageData.confirm = true;
    messageData.payment_option = payment_option;
    setShowPaymentModel(false);
  };

  return (
    <CModal
      visible={showPaymentModel}
      onClose={() => {
        setShowPaymentModel(false);
      }}
    >
      <Grid
        container
        style={{
          position: "relative",
          width: md ? 365 : 445,
          overflowY: "auto",
          padding: md ? "26px 20px 12px 20px" : "45px 30px 33px 28px",
        }}
      >
        <CloseIcon
          onClick={() => {
            setShowPaymentModel(false);
          }}
          style={{
            position: "absolute",
            top: 10,
            right: 15,
            fontSize: 30,
            cursor: "pointer",
            color: BaseColor.primary,
            zIndex: 1,
          }}
        />
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            style={{
              fontFamily: FontFamily.GilbertQualifiDemo,
              fontSize: "24px",
              color: BaseColor.blackColor,
            }}
          >
            Payment Options
          </Typography>
        </Grid>
        <Box
          component="form"
          sx={{ width: "100%", mt: "20px" }}
          noValidate
          onSubmit={handleSubmit}
        >
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="female"
            sx={{ width: "100%", gap: "10px" }}
            name="payment_option"
          >
            <FormControlLabel
              value="e-wallet"
              sx={{ width: "100%" }}
              className={classes.paymentLabelClass}
              control={<Radio />}
              label={"Pay with your e-wallet"}
            />
            <FormControlLabel
              value="credit-card"
              className={classes.paymentLabelClass}
              control={<Radio />}
              label={"Pay with credit card"}
            />
            <FormControlLabel
              value="debit-card"
              className={classes.paymentLabelClass}
              control={<Radio />}
              label={"Pay with debit card"}
            />
            <FormControlLabel
              value="saved-card"
              className={classes.paymentLabelClass}
              control={<Radio />}
              label={"Pay with saved cards"}
            />
          </RadioGroup>
          <Button
            type="submit"
            fullWidth
            sx={{ mt: "20px" }}
            variant="contained"
          >
            Continue
          </Button>
        </Box>
      </Grid>
    </CModal>
  );
}
