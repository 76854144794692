import React from "react";
import { useSelector } from "react-redux";
import Files from "../../Config/Files";
import styles from "./styles";
import { Paper, Typography } from "@mui/material";
import { FontFamily } from "../../Config/theme";
import BaseColor from "../../Config/colors";
import { useNavigate } from "react-router-dom";
import { store } from "../../Redux/store/configureStore";
import authActions from "../../Redux/reducers/auth/actions";

const NotificationPopup = () => {
  const classes = styles();
  const navigate = useNavigate();
  const { isNotifiy, notiData } = useSelector((state) => state.auth);
  const title =
    notiData?.notification?.title !== "" ? notiData?.notification?.title : "-";
  const description =
    notiData?.notification?.body !== "" ? notiData?.notification?.body : "-";

  return isNotifiy ? (
    <div
      className={classes.root}
      onClick={() => {
        store.dispatch(authActions.displayNotificationPopUp(false));
        if (notiData?.data?.type === "new_reservation_created") {
          const reservationDetailString = notiData?.data?.reservation_detail;
          navigate(`/reservation?reservationDetail=${reservationDetailString}`);
        } else if (
          notiData?.data?.type === "user_reservation_rejected" ||
          notiData?.data?.type === "user_reservation_accepted" ||
          notiData?.data?.type === "chat_notification"
        ) {
          navigate("/chat");
        }
      }}
    >
      <Paper
        elevation={3}
        className={`${classes.notificationContainer} ${classes.topRight}`}
      >
        <div className={classes.subDivForNotification}>
          <div className={classes.subFlexCon}>
            <div className={classes.notificationImage}>
              <img
                src={Files.svgIcons.goatt}
                alt="AppIcon"
                className={classes.notiAppLogo}
                draggable={false}
              />
              <Typography
                style={{
                  marginLeft: 5,
                  fontSize: 14,
                  fontFamily: FontFamily.SemiBold,
                  color: BaseColor.primary,
                }}
              >
                Golf-goatt
              </Typography>
            </div>
            <Typography
              style={{
                fontSize: 14,
                fontFamily: FontFamily.SemiBold,
                color: BaseColor.primary,
              }}
            >
              {title}
            </Typography>
            <Typography
              style={{
                fontSize: 12,
                color: BaseColor.primary,
                fontFamily: FontFamily.Medium,
              }}
            >
              {description}
            </Typography>
          </div>
        </div>
      </Paper>
    </div>
  ) : null;
};

export default NotificationPopup;
