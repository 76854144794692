import User from "../Assets/Images/User.svg";
import Camera from "../Assets/Images/Camera.svg";
import loginBg1 from "../Assets/Images/loginBg1.svg";
import loginBg2 from "../Assets/Images/loginBg2.svg";
import golfBall from "../Assets/Images/golfBall.svg";
import Mail from "../Assets/Images/Mail.svg";
import Contact from "../Assets/Images/Contact.svg";
import editGreeen from "../Assets/Images/editGreen.svg";
import BackArrowIcon from "../Assets/Images/BackArrow.svg";
import Calender from "../Assets/Images/calender.svg";
import PrimaryRound from "../Assets/Images/primaryRound.svg";
import Marker from "../Assets/Images/marker.svg";
import home from "../Assets/Images/home.svg";
import Map from "../Assets/Images/map.svg";
import Chat from "../Assets/Images/Chat.svg";
import Event from "../Assets/Images/Event.svg";
import homeFill from "../Assets/Images/homeFill.svg";
import mapFill from "../Assets/Images/mapFill.svg";
import chatFill from "../Assets/Images/chatFill.svg";
import eventFill from "../Assets/Images/eventFill.svg";
import whatsappChat from "../Assets/Images/whatsappChat.svg";
import primaryFillHome from "../Assets/Images/primaryFillHome.svg";
import primaryFillChat from "../Assets/Images/primaryFillChat.svg";
import primaryFillEvent from "../Assets/Images/primaryFillEvent.svg";
import primaryFillMap from "../Assets/Images/primaryFillMap.svg";
import location from "../Assets/Images/location.svg";
import filter from "../Assets/Images/filter.svg";
import roundLogo from "../Assets/Images/roundLogo.svg";
import bookmark from "../Assets/Images/bookmark.svg";
import fillBookmark from "../Assets/Images/fillBookmark.svg";
import drawerBg from "../Assets/Images/drawerBg.svg";
import newLogo from "../Assets/Images/newLogo.svg";
import appLogo from "../Assets/Images/appLogo.svg";
import goatt from "../Assets/Images/goatt.svg";
import Delete from "../Assets/Images/Delete.svg";

const Files = {
  Images: {
    logo: require("../Assets/Images/logo.png"),
    bGImage: require("../Assets/Images/bGImage.png"),
    blueLogo: require("../Assets/Images/Goatt golf blue logo.png"),
    Menu: require("../Assets/Images/Menu.png"),
    close: require("../Assets/Images/close.png"),
    appLogo: require("../Assets/Images/appLogo.png"),
    intro1: require("../Assets/Images/Intro1.png"),
    intro2: require("../Assets/Images/intro2.png"),
    intro3: require("../Assets/Images/intro3.png"),
    google: require("../Assets/Images/google.png"),
    apple: require("../Assets/Images/apple.png"),
    openEye: require("../Assets/Images/openEye.png"),
    eyeSlash: require("../Assets/Images/eye-slash.png"),
    paySuccess: require("../Assets/Images/payment-successful.png"),
    processPending: require("../Assets/Images/process-pending.png"),
    editIcon: require("../Assets/Images/Edit.png"),
    infoIcon: require("../Assets/Images/info.png"),
    roundLogo: require("../Assets/Images/roundLogo.png"),
    rectangle: require("../Assets/Images/Rectangle.png"),
    reservation: require("../Assets/Images/reservation.png"),
    eventImage: require("../Assets/Images/Events.jpeg"),
    clubsImage: require("../Assets/Images/clubs.png"),
    contactUsImage: require("../Assets/Images/contactusImage.png"),
    profilImage: require("../Assets/Images/profile.png"),
    member1: require("../Assets/Images/member1.png"),
    member2: require("../Assets/Images/member2.png"),
    member3: require("../Assets/Images/member3.png"),
    userImage: require("../Assets/Images/userImage.png"),
    paymentbg: require("../Assets/Images/paymentbg.png"),
    mobilePaymentbg: require("../Assets/Images/mobilePayment.png"),
    eventDetails1: require("../Assets/Images/eventDetails1.png"),
    eventDetails2: require("../Assets/Images/eventDetails2.png"),
    eventDetails3: require("../Assets/Images/eventDetails3.png"),
    eventDetails4: require("../Assets/Images/eventDetails4.png"),
    eventDetails5: require("../Assets/Images/eventDetails5.png"),
    sort1: require("../Assets/Images/sort1.png"),
    sort2: require("../Assets/Images/sort2.png"),
    calender: require("../Assets/Images/Calender.png"),
    splashTopBg: require("../Assets/Images/splashbgtop.png"),
    splashBottomBg: require("../Assets/Images/splashbgbottom.png"),
    // ********** Chat ***********
    emptyChatBg: require("../Assets/Images/emptyChatBg.png"),
    chatClubPic: require("../Assets/Images/chatClubImg.png"),
    groupAvatar: require("../Assets/Images/groupAvatar.png"),
    addMember: require("../Assets/Images/AddMember.png"),
    chatBackground: require("../Assets/Images/chatBackground.png"),
    sendMessage: require("../Assets/Images/sendMessage.png"),
    addGuest: require("../Assets/Images/addGuest.png"),
  },
  lottie: {
    loader: require("../Assets/Lottie/lineLoader.json"),
    warning: require("../Assets/Lottie/warning.json"),
    verify: require("../Assets/Lottie/verify.json"),
    review: require("../Assets/Lottie/review.json"),
    locationLottie: require("../Assets/Lottie/location.json"),
    mapLoader: require("../Assets/Lottie/linearLoader.json"),
    paymentModal: require("../Assets/Lottie/payment.json"),
    subscriptionLottie: require("../Assets/Lottie/subscription.json"),
  },
  svgIcons: {
    userIcon: User,
    cameraIcon: Camera,
    loginBg1: loginBg1,
    loginBg2: loginBg2,
    golfBall: golfBall,
    mail: Mail,
    contact: Contact,
    editGreen: editGreeen,
    BackArrowIcon: BackArrowIcon,
    Calender: Calender,
    RoundMarker: PrimaryRound,
    MapMarker: Marker,
    homeIcon: home,
    chatIcon: Chat,
    mapIcon: Map,
    eventIcon: Event,
    homeFillIcon: homeFill,
    chatFillIcon: chatFill,
    mapFillIcon: mapFill,
    eventFillIcon: eventFill,
    whatsappChatIcon: whatsappChat,
    primaryFillHome: primaryFillHome,
    primaryFillChat: primaryFillChat,
    primaryFillEvent: primaryFillEvent,
    primaryFillMap: primaryFillMap,
    locationIcon: location,
    filterIcon: filter,
    roundLogo: roundLogo,
    bookmark: bookmark,
    fillBookmark: fillBookmark,
    drawerBg: drawerBg,
    newLogo: newLogo,
    goatt: goatt,
    deleteIcon: Delete,
    appLogo: appLogo,
  },
  pngIcons: {
    singleTickIcon: require("../Assets/Icons/singleTickIcon.png"),
    doubleTickIcon: require("../Assets/Icons/doubleTickIcon.png"),
    greenDoubleTickIcon: require("../Assets/Icons/greenDoubleTickIcon.png"),
    imageIcon: require("../Assets/Icons/imageIcon.png"),
    linkIcon: require("../Assets/Icons/linkIcon.png"),
    attachment: require("../Assets/Icons/Attachment.png"),
    vedioIcon: require("../Assets/Icons/vedioIcon.png"),
    groupIcon: require("../Assets/Icons/groupIcon.png"),
    menuIcon: require("../Assets/Icons/menuIcon.png"),
    cameraIcon: require("../Assets/Icons/Camera.png"),
    downloadMedia: require("../Assets/Icons/downloadMedia.png"),
    archiveIcon: require("../Assets/Icons/ArchiveIcon.png"),
    blockIcon: require("../Assets/Icons/BlockIcon.png"),
    reportIcon: require("../Assets/Icons/Report.png"),
    clearChatIcon: require("../Assets/Icons/clearChat.png"),
    profileIcon: require("../Assets/Icons/Profile.png"),
    groupProfileIcon: require("../Assets/Icons/groupProfile.png"),
    exitGroupIcon: require("../Assets/Icons/exitGroup.png"),
    withdraw: require("../Assets/Icons/withdraw.png"),
    deleteIcon: require("../Assets/Icons/Delete.png"),
  },
};

export default Files;
