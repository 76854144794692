import React from "react";
import styles from "./styles";
import { CircularProgress, Typography } from "@mui/material";
import { FontFamily } from "../../Config/theme";
import BaseColor from "../../Config/colors";
import { CiLocationOn } from "react-icons/ci";
import CIcon from "../CIcon";
import Files from "../../Config/Files";
import { isMobile, isTablet } from "react-device-detect";
import { motion } from "framer-motion";

const CEventCard = (props) => {
  const {
    style = {},
    onClick = () => {},
    image = "",
    address = "",
    name = "",
    distance = "",
    bookMarkClick = () => {},
    bookMarkLoader = false,
    isBookMark = false,
    showBookmark = true,
  } = props;

  const classes = styles();

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      style={style}
      className={classes.main}
      onClick={() => {
        onClick();
      }}
    >
      <div
        style={{
          height: isMobile ? 220 : 300,
          overflow: "hidden",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          borderBottomLeftRadius: 10,
          borderBottomRightRadius: 10,
        }}
      >
        <div
          style={{
            height: 300,
            width: "100%",
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10,
          }}
        >
          <img
            src={image}
            draggable={false}
            style={{ userSelect: "none" }}
            className={classes.img}
            alt="event"
            title={name}
          />
          <div
            style={{
              position: "absolute",
              backgroundColor: "#EEFAFF",
              right: "10px",
              bottom: isMobile ? "55px" : "65px",
              borderRadius: "20px",
              padding: "10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                fontFamily: FontFamily.Regular,
                color: BaseColor.blackColor,
                fontSize: "12px",
                textAlign: "center",
                userSelect: "none",
              }}
            >
              <CiLocationOn
                style={{
                  userSelect: "none",
                  color: BaseColor.red,
                  fontSize: "14px",
                  marginBottom: "-2px",
                }}
              />{" "}
              {distance}
            </Typography>
          </div>
          {showBookmark && (
            <div
              style={{
                position: "absolute",
                backgroundColor: "#EEFAFF",
                right: "10px",
                top: "10px",
                borderRadius: "20px",
                padding: "10px",
                display: "flex",
                alignItems: "center",
                cursor: !isMobile && !isTablet && "pointer",
              }}
              onClick={(e) => {
                e.stopPropagation();
                bookMarkClick();
              }}
            >
              {bookMarkLoader ? (
                <CircularProgress size={12} color="primary" />
              ) : (
                <CIcon
                  src={
                    isBookMark
                      ? Files.svgIcons.fillBookmark
                      : Files.svgIcons.bookmark
                  }
                  size={15}
                  style={{
                    cursor: !isMobile && !isTablet && "pointer",
                    userSelect: "none",
                  }}
                />
              )}
            </div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            width: "100%",
            flexDirection: "column",
            marginTop: "5px",
            paddingLeft: "10px",
          }}
        >
          <Typography
            style={{
              fontFamily: FontFamily.GilbertQualifiDemo,
              fontSize: isMobile ? 16 : 18,
              color: BaseColor.primary,
              textOverflow: "ellipsis",
              WebkitLineClamp: 1,
              overflow: "hidden",
              WebkitBoxOrient: "vertical",
              display: "-webkit-box",
              userSelect: "none",
            }}
          >
            {name}
          </Typography>
          <Typography
            style={{
              fontFamily: FontFamily.Medium,
              fontSize: isMobile ? 12 : 14,
              color: BaseColor.grey,
              textOverflow: "ellipsis",
              WebkitLineClamp: 1,
              overflow: "hidden",
              WebkitBoxOrient: "vertical",
              display: "-webkit-box",
              paddingRight: "10px",
              userSelect: "none",
            }}
          >
            {address}
          </Typography>
        </div>
      </div>
    </motion.div>
  );
};

export default CEventCard;
