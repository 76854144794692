import { makeStyles } from "@mui/styles";
import BaseColor from "../../Config/colors";
import { color, FontFamily } from "../../Config/theme";
import { isMobile, isTablet } from "react-device-detect";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    boxSizing: "border-box",
    backgroundColor: BaseColor.white,
  },
  root2: {
    overflowY: "auto",
  },
  pageLoader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  imageContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // "@media (max-width: 900px)": {
    //   display: "none",
    // },
  },
  image: {
    width: "100%",
    objectFit: "contain",
    height: "100%",
  },
  logoContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  logo: {
    width: 220,
  },
  titleContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "20px",
  },
  title: {
    textAlign: "center",
    fontFamily: FontFamily.GilbertQualifiDemo,
    color: BaseColor.primary,
  },
  descriptionContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "15px",
    textAlign: "center",
    position: "relative",
  },
  description: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    marginTop: "30px",
    paddingBottom: "20px",
  },
  button: {
    backgroundColor: BaseColor.primary,
    color: BaseColor.white,
    width: "45%",
    height: "53px",
  },
  scrollBar: {
    // overflowX: "scroll",
    "&::-webkit-scrollbar": {
      width: 3,
      height: 5,
    },

    /* Track */
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 5px transperent",
      borderRadius: 10,
    },

    /* Handle */
    "&::-webkit-scrollbar-thumb": {
      background: BaseColor.transparent,
      borderRadius: 10,
    },

    /* Handle on hover */
    "&::-webkit-scrollbar-thumb:hover": {
      boxShadow: "inset 0 0 5px grey",
    },
  },
  carImg: {
    height: 400,
    width: "85%",
    objectFit: "cover",
    borderRadius: 10,
    [theme.breakpoints.down("sm")]: {
      height: 250,
    },
  },
  circleContainer: {
    width: "52px",
    height: "52px",
    borderRadius: "50%",
    border: "1px solid #B4BDC3",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  socialImage: {
    height: "21px",
    width: "21px",
  },
  success: {
    height: isMobile ? "95px" : "168px",
    width: isMobile ? "156px" : "278px",
  },
}));

export default useStyles;
