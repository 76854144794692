import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CHeader from "../../Components/Header";
import BaseColor from "../../Config/colors";
import theme, { FontFamily } from "../../Config/theme";
import CInput from "../../Components/CInput";
import CButton from "../../Components/CButton";
import { toast } from "react-toastify";
import { Setting } from "../../Utils/Setting";
import { getApiData, getAPIProgressData } from "../../Utils/APIHelper";
import Files from "../../Config/Files";
import CIcon from "../../Components/CIcon";
import styles from "./styles";
import { isMobile, isTablet, isIOS } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

export default function ContactUs() {
  // media queries and styles
  const classes = styles();
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const lg = useMediaQuery(theme.breakpoints.down("lg"));

  // form state
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  // error text message
  const [fullNameErrorText, setFullNameErrorText] = useState("");
  const [emailErrorText, setEmailErrorText] = useState("");
  const [messageErrorText, setMessageErrorText] = useState("");

  // btn loader
  const [sendBtnLoader, setSendBtnLoader] = useState(false);

  // page loader
  const [pageLoader, setPageLoader] = useState(true);

  // contact details
  const [contactNo, setContactNo] = useState("");
  const [contactEmail, setContactEmail] = useState("");

  // validation
  const validation = () => {
    const onlyCharactersRegex = /^[a-zA-Z\s]+$/;

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    let isValid = true;
    if (fullName.trim() === "") {
      setFullNameErrorText("Full name is required");
      isValid = false;
    }
    if (
      !onlyCharactersRegex.test(String(fullName)) &&
      fullName?.trim() !== ""
    ) {
      setFullNameErrorText("Enter valid name");
      isValid = false;
    }
    if (fullName?.trim() !== "" && fullName?.length < 3) {
      setFullNameErrorText("Name should be at least 3 characters");
      isValid = false;
    }
    if (email.trim() === "") {
      setEmailErrorText("Email is required");
      isValid = false;
    }
    if (!emailRegex.test(String(email)) && email?.trim() !== "") {
      setEmailErrorText("Enter valid email");
      isValid = false;
    }
    if (message.trim() === "") {
      setMessageErrorText("Message is required");
      isValid = false;
    }
    if (message.trim() !== "" && message?.length < 10) {
      setMessageErrorText("Message should be at least 10 characters");
      isValid = false;
    }
    if (isValid) {
      allErrorFalse();
      AddContactUs();
    }
  };

  useEffect(() => {
    getContactUsDetails();
  }, []);

  // all error false
  const allErrorFalse = () => {
    setFullNameErrorText("");
    setEmailErrorText("");
    setMessageErrorText("");
  };

  // get contact details
  const getContactUsDetails = async () => {
    let endPoints = Setting.endpoints.chatMessage;
    try {
      const resp = await getApiData(endPoints, "GET", {}, true);
      if (resp?.success) {
        if (!_.isEmpty(resp?.data)) {
          const email = resp?.data?.find(
            (data) => data?.meta_key === "Contact Email"
          );

          const contactNo = resp?.data?.find(
            (data) => data?.meta_key === "Contact Phone"
          );

          setContactEmail(email?.meta_name);
          setContactNo(contactNo?.meta_name);
          setPageLoader(false);
        }
      } else {
        setPageLoader(false);
      }
    } catch (error) {
      toast(error.message, {
        type: "error",
      });
      setPageLoader(false);
    }
  };

  // create contact us
  const AddContactUs = async () => {
    setSendBtnLoader(true);
    let endPoints = Setting.endpoints.contactUs;
    const params = {
      "ContactUs[name]": fullName,
      "ContactUs[email]": email,
      "ContactUs[message]": message,
    };

    try {
      const resp = await getAPIProgressData(endPoints, "POST", params);
      if (resp?.success) {
        toast(resp?.message, {
          type: "success",
        });
        setSendBtnLoader(true);
        setEmail("");
        setFullName("");
        setMessage("");
      } else {
        toast(resp?.message, {
          type: "error",
        });
        setSendBtnLoader(false);
      }
      setSendBtnLoader(false);
    } catch (error) {
      toast(error.message, { type: "error" });
      setSendBtnLoader(false);
    }
  };

  // navigation
  const navigate = useNavigate();

  return (
    <Grid container style={{ height: "100vh", overflowY: "auto" }}>
      <CHeader
        title={"Contact us"}
        backButtonClick={() => {
          navigate(-1);
        }}
      />
      <Grid
        item
        xs={12}
        height={
          md && isIOS && isMobile ? "calc(100% + 90px)" : "calc(100% - 90px)"
        }
      >
        {pageLoader ? (
          <Grid
            container
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            style={{
              height: "85vh",
            }}
          >
            <CircularProgress size={40} color="primary" />
          </Grid>
        ) : (
          <Grid container>
            <Grid item xs={0.5} sm={0.5} md={1} lg={1}></Grid>
            <Grid
              item
              xs={11}
              sm={11}
              md={10}
              lg={6}
              paddingTop={!md && "20px"}
            >
              <Grid container>
                {!md && (
                  <Grid
                    item
                    xs={12}
                    mb={"20px"}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"flex-start"}
                  >
                    <CIcon
                      src={Files.svgIcons.BackArrowIcon}
                      size={30}
                      style={{
                        marginLeft: "-4px",
                        cursor: !isMobile && !isTablet && "pointer",
                      }}
                      onClick={() => {
                        navigate(-1);
                      }}
                    />
                    <Typography
                      sx={{
                        color: BaseColor.primary,
                        fontFamily: FontFamily.GilbertQualifiDemo,
                        fontSize: 22,
                        marginLeft: "10px",
                      }}
                    >
                      Contact Us
                    </Typography>
                  </Grid>
                )}

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Grid
                    container
                    display={"flex"}
                    justifyContent={"space-between"}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={5.8}
                      md={5.8}
                      mt={isMobile && "-15px"}
                    >
                      <CInput
                        value={fullName}
                        placeholder={"Full name"}
                        label={"Full name"}
                        errorMsg={fullNameErrorText}
                        onChange={(val) => {
                          setFullName(val);
                          setFullNameErrorText("");
                        }}
                        error={!_.isEmpty(fullNameErrorText)}
                        maxLength={30}
                      />
                    </Grid>
                    <Grid item xs={12} sm={5.8} md={5.8}>
                      <CInput
                        label={"Email"}
                        value={email}
                        placeholder={"Email"}
                        errorMsg={emailErrorText}
                        onChange={(val) => {
                          setEmail(val);
                          setEmailErrorText("");
                        }}
                        error={!_.isEmpty(emailErrorText)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} mb={"20px"}>
                      <CInput
                        multiline
                        rows={4}
                        label={"Message"}
                        value={message}
                        placeholder={"Message"}
                        errorMsg={messageErrorText}
                        onChange={(val) => {
                          setMessage(val);
                          setMessageErrorText("");
                        }}
                        error={!_.isEmpty(messageErrorText)}
                        maxLength={255}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {!md && (
                  <Grid item xs={12} sm={12} md={12}>
                    <Grid container>
                      <Grid item xs={12} sm={6} md={4} lg={3} mb={"10px"}>
                        <CButton
                          variant={"contained"}
                          loading={sendBtnLoader}
                          onClick={() => {
                            validation();
                          }}
                        >
                          Send
                        </CButton>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
            {!md && <Grid item xs={1}></Grid>}

            {lg && (
              <>
                <Grid item xs={0.5} sm={0.5}></Grid>
                <Grid item xs={0.5} sm={0.5}></Grid>
              </>
            )}

            <Grid
              item
              xs={11}
              sm={11}
              md={10}
              lg={3}
              marginTop={md || isMobile || isTablet ? "0px" : "30px"}
              className={classes.contactView}
              marginBottom={"20px"}
            >
              <div
                style={{
                  backgroundColor:
                    md || isMobile || isTablet
                      ? BaseColor.white
                      : BaseColor.offWhiteBg,
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  flexDirection: "column",
                  height: !md && !isMobile && !isTablet && "100%",
                  borderRadius: "15px",
                  width: "100%",
                }}
              >
                <Typography
                  style={{
                    fontFamily: FontFamily.GilbertQualifiDemo,
                    fontSize: 22,
                    marginTop: "20px",
                    marginBottom: "20px",
                    width: (md || isMobile || isTablet) && "100%",
                  }}
                >
                  Contact Details
                </Typography>
                <img
                  src={Files.svgIcons.newLogo}
                  alt="contact us"
                  draggable={false}
                  style={{
                    objectFit: "contain",
                    width: md || isMobile || isTablet ? "100%" : "90%",
                  }}
                />
                <div
                  style={{ width: md || isMobile || isTablet ? "100%" : "90%" }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      marginTop: md || isMobile || isTablet ? "20px" : "50px",
                      width: "100%",
                      paddingBottom: 10,
                    }}
                  >
                    <CIcon
                      src={Files.svgIcons.mail}
                      style={{ marginRight: "20px" }}
                    />
                    <Typography
                      style={{
                        fontFamily: FontFamily.Medium,
                        fontSize: sm ? 15 : 16,
                        textOverflow: "ellipsis",
                        wordBreak: "break-word",
                      }}
                    >
                      {contactEmail}
                    </Typography>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      marginTop: "20px",
                      width: "100%",
                      marginBottom:
                        md || isMobile || isTablet ? "20px" : "50px",
                    }}
                  >
                    <CIcon
                      src={Files.svgIcons.contact}
                      style={{ marginRight: "20px" }}
                    />
                    <Typography
                      style={{
                        fontFamily: FontFamily.Medium,
                        fontSize: sm ? 15 : 16,
                      }}
                    >
                      {contactNo}
                    </Typography>
                  </div>
                  {/* <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginTop: "20px",
                    marginBottom: md || isMobile || isTablet ? "20px" : "50px",
                    width: "100%",
                  }}
                >
                  <CIcon
                    src={Files.svgIcons.locationIcon}
                    style={{ marginRight: "20px" }}
                  />
                  <Typography
                    style={{
                      fontFamily: FontFamily.Medium,
                      fontSize: sm ? 15 : 16,
                    }}
                  >
                    1901 Thornridge Cir. Shiloh, Hawaii 81063
                  </Typography>
                </div> */}
                </div>
              </div>
              {(md || isMobile || isTablet) && (
                <Grid item xs={12} sm={12} md={12} mt={"10px"}>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={4} lg={3} mb={"10px"}>
                      <CButton
                        variant={"contained"}
                        loading={sendBtnLoader}
                        onClick={() => {
                          validation();
                        }}
                      >
                        Send
                      </CButton>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>

            <Grid item xs={0.5} sm={0.5} md={1} lg={1}></Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
