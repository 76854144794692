import {
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./styles";
import _, { isEmpty } from "lodash";
import theme, { FontFamily } from "../../Config/theme";
import BaseColor from "../../Config/colors";
import { Setting } from "../../Utils/Setting";
import { toast } from "react-toastify";
import { getAPIProgressData } from "../../Utils/APIHelper";
import CModal from "../../Components/CModal";
import { isMobile, isTablet } from "react-device-detect";
import CloseIcon from "@mui/icons-material/Close";
import { CiSearch } from "react-icons/ci";
import CButton from "../../Components/CButton";
import { IoCheckmarkOutline } from "react-icons/io5";
import { MdClose } from "react-icons/md";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import dayjs from "dayjs";
import "react-calendar/dist/Calendar.css";
import { useSelector } from "react-redux";
import CSelect from "../../CSelect";
import { socket } from "../../Context/ContextSocket";
import Files from "../../Config/Files";
import { useLocation, useNavigate } from "react-router-dom";

export default function ReservationModel({
  reservationModal,
  setReservationModal,
  editData,
  editData2,
  getReservationListData,
  selectedMembers,
  setSelectedMembers,
  conversation_id,
  handleOpenParticularchat = () => {},
  isHome = false,
}) {
  // navigation
  const navigate = useNavigate();

  // style and media queries
  const classes = styles();
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  //get data from redux
  const userData = useSelector((state) => state.auth.userData);
  const CTab = useSelector((state) => state.auth.cTab);

  // get location params
  const location = useLocation();

  const [showSearchInput, setShowSearchInput] = useState(true);
  const [showAddButton, setShowAddButton] = useState(false);

  // bookmark loader
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDateErr, setSelectedDateErr] = useState(false);
  const [selectedDateMsg, setSelectedDateMsg] = useState("");

  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedTimeErr, setSelectedTimeErr] = useState(false);
  const [selectedTimeMsg, setSelectedTimeMsg] = useState("");

  // club name state
  const [selectedClub, setSelectedClub] = useState(null);
  const [clubNameErr, setClubNameErr] = useState(false);
  const [clubNameMsg, setClubNameMsg] = useState("");

  // choose member name state
  const [memberNames, setMemberNames] = useState("");
  const [memberNamesErr, setMemberNamesErr] = useState(false);

  const [members, setMembers] = useState([]);
  const [membersErr, setMembersErr] = useState(false);
  const [membersMsg, setMembersMsg] = useState("");

  const [addGuestButtons, setAddGuestButtons] = useState(false);
  const [loader, setLoader] = useState(false);

  // create btn loader
  const [loaderBtnCreate, setLoaderBtnCreate] = useState(false);
  const [clubs, setClubs] = useState([]);

  useEffect(() => {
    getCommonData();
  }, []);

  useEffect(() => {
    if (!isEmpty(editData)) {
      setSelectedClub(editData?.reservationDetails?.club_name);
      setSelectedDate(dayjs(editData?.reservationDetails?.end_date));
      setSelectedTime(
        dayjs(
          `${editData?.reservationDetails?.end_date}T${editData?.reservationDetails?.end_time}`
        )
      );
      setAddGuestButtons(true);
    }
    if (!isEmpty(editData2)) {
      setSelectedClub(editData2?.club_name);
      setSelectedDate(dayjs(editData2?.end_date));
      setSelectedTime(dayjs(`${editData2?.end_date}T${editData2?.end_time}`));
      setAddGuestButtons(true);
    }
  }, [editData, editData2]);

  const getCommonData = async () => {
    let endPoints = Setting.endpoints.commonData;

    const params = {
      email: userData.email,
    };

    try {
      const resp = await getAPIProgressData(endPoints, "POST", params);
      if (resp?.success) {
        if (!_.isEmpty(resp?.data)) {
          setClubs(resp?.data?.club_data);
        }
      } else {
        toast.error(resp?.message, {
          position: "top-right",
        });
      }
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
      });
    }
  };

  // user list
  const getChooseMemberList = async (value, updatedSelectedMembers) => {
    if (isEmpty(value)) {
      return;
    }
    setLoader(true);
    const data = {
      fullname: value,
    };

    try {
      const resp = await getAPIProgressData(
        `${Setting.endpoints.userList}`,
        "POST",
        data,
        true
      );
      if (resp?.success) {
        if (!_.isEmpty(resp?.data) && _.isArray(resp?.data)) {
          const memberData = resp?.data;
          if (!isEmpty(selectedMembers)) {
            memberData.map((mem) => {
              updatedSelectedMembers.map((sltmem) => {
                if (mem.id == sltmem.id) {
                  mem.isSelected = true;
                }
              });
            });
            setMembers(memberData);
          } else {
            setMembers(memberData);
          }
        } else {
          setMembers([]);
        }
      } else {
        toast.error(resp?.message, {
          position: "top-right",
        });
        setMembers([]);
      }
      setLoader(false);
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
      });
      setLoader(false);
    }
  };

  // Function to handle clicking on a member
  const handleClick = (index) => {
    const updatedMembers = [...members];
    updatedMembers[index].isSelected = !updatedMembers[index].isSelected;
    setMembers(updatedMembers);
    const isAnySelected = updatedMembers.some((member) => member.isSelected);
    setShowAddButton(isAnySelected);
  };

  // Function to increment the quantity of a  member
  const handleIncrement = (index) => {
    const updatedMembers = [...selectedMembers];
    const unaccompaniedPlay =
      selectedClub?.unaccompanied_pay == 0 ? "no" : "yes";
    let maxGuests = unaccompaniedPlay == "yes" ? 3 : 2;

    if (updatedMembers[index].default_quantity < maxGuests) {
      updatedMembers[index] = {
        ...updatedMembers[index],
        default_quantity: updatedMembers[index].default_quantity + 1,
      };
      setSelectedMembers(updatedMembers);
    }
  };

  // Function to decrement the quantity of a member
  const handleDecrement = (index) => {
    const updatedMembers = [...selectedMembers];
    if (updatedMembers[index].default_quantity > 0) {
      updatedMembers[index] = {
        ...updatedMembers[index],
        default_quantity: updatedMembers[index].default_quantity - 1,
      };
      setSelectedMembers(updatedMembers);
    }
  };

  const handleClear = () => {
    setMemberNames("");
    setMembersErr(false);
    setMembersMsg("");
  };

  const handleRemoveSelectedMember = (idToRemove) => {
    // Filter out the item with the matching id from selectedMembers
    const updatedSelectedMembers = selectedMembers.filter(
      (member) => member.id !== idToRemove
    );

    // Find the removed member from selectedMembers
    const removedMember = selectedMembers.find(
      (member) => member.id === idToRemove
    );

    // If the removed member was found
    if (removedMember) {
      // Update its isSelected property to false
      removedMember.isSelected = false;

      // Clone the members array
      const updatedMembers = [...members];
      // Filter out the item with the matching id from members (if it exists)
      const filteredMembers = updatedMembers.filter(
        (member) => member.id !== idToRemove
      );

      // Add the removed member back to the members array
      updatedMembers.push(removedMember);

      // Update the state for both selectedMembers and members
      setSelectedMembers(updatedSelectedMembers);
      setMembers(filteredMembers);

      // If no selected members are left, show the search input
      if (isEmpty(updatedSelectedMembers)) {
        setShowSearchInput(true);
      }
    }
    getChooseMemberList(memberNames, updatedSelectedMembers);
  };

  const handleAddButtonClick = () => {
    setShowSearchInput(false);
    setMemberNames("");

    // Filter out the selected members from the members array
    const newSelectedMembers = members.filter((member) => member.isSelected);

    // Merge the newly selected members with the existing selected members
    const updatedSelectedMembers = [
      ...selectedMembers,
      ...newSelectedMembers.filter(
        (newMember) =>
          !selectedMembers.some(
            (existingMember) => existingMember.id === newMember.id
          )
      ),
    ];
    // Update the selected members state with the merged array
    setSelectedMembers(updatedSelectedMembers);

    // Remove selected members from the members array
    const newMembers = members.filter((member) => !member.isSelected);
    setMembers(newMembers);
  };
  // Function to handle time change
  const handleTimeChange = (newTime) => {
    setSelectedTime(newTime);
    setSelectedTimeErr(false);
    setSelectedTimeMsg("");
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setSelectedDateErr(false);
    setSelectedDateMsg("");
  };

  // Validation function
  const validateForm = () => {
    const anyMemberSelected = selectedMembers.some(
      (member) => member.isSelected
    );
    const currentDate = dayjs();
    const selectedDateTime = dayjs(selectedDate)
      .set("hour", selectedTime.hour())
      .set("minute", selectedTime.minute());

    let isValid = true;

    if (!selectedClub) {
      setClubNameErr(true);
      setClubNameMsg("Club Name is required");
      isValid = false;
    } else {
      setClubNameErr(false);
      setClubNameMsg("");
    }

    if (!selectedDate) {
      setSelectedDateErr(true);
      setSelectedDateMsg("Date is required");
      isValid = false;
    } else {
      setSelectedDateErr(false);
      setSelectedDateMsg("");
    }

    if (!selectedTime) {
      setSelectedTimeErr(true);
      setSelectedTimeMsg("Time is required");
      isValid = false;
    } else if (selectedDateTime.isBefore(currentDate)) {
      setSelectedTimeErr(true);
      setSelectedTimeMsg("You can't select a past time");
      isValid = false;
    } else {
      setSelectedTimeErr(false);
      setSelectedTimeMsg("");
    }

    // if (!memberNames) {
    //   setMemberNamesErr(true);
    //   setMemberNamesMsg("Member Names are required");
    //   isValid = false;
    // } else {
    //   setMemberNamesErr(false);
    //   setMemberNamesMsg("");
    // }

    if (!anyMemberSelected) {
      setMembersErr(true);
      setMembersMsg("Please select at least one member");
      isValid = false;
    } else {
      setMembersErr(false);
      setMembersMsg("");
    }

    return isValid;
  };

  // Function to handle create button click
  const handleCreateClick = () => {
    if (validateForm()) {
      // api call
      getCreateReservation();
    }
  };

  // create reservation api call
  const getCreateReservation = async () => {
    setLoaderBtnCreate(true);
    let endPoints = Setting.endpoints.createReservation;

    const toDate = !_.isNull(selectedDate)
      ? dayjs(selectedDate).format("YYYY-MM-DD")
      : "";
    const toTime = !_.isNull(selectedTime)
      ? dayjs(selectedTime).format("HH:mm:ss")
      : "";
    const memberData = selectedMembers.map((member) => ({
      member_id: member.id,
      guest_cnt: member.default_quantity,
    }));
    const memeberId = selectedMembers.map((member) => member.id);

    const memberName = selectedMembers
      .map((item) => {
        return `${item.nick_name}`;
      })
      .join(", ");

    const newmemeberId = [...memeberId, userData.id];
    const singlememberId = [...memeberId];

    const data = {
      "ReservationMaster[club_id]":
        selectedClub?.id ||
        editData?.reservationDetails?.club_id ||
        editData2?.club_id,
      "ReservationMaster[club_name]":
        selectedClub?.name ||
        editData?.reservationDetails?.club_name ||
        editData2?.club_name,
      "ReservationMaster[start_date]": toDate,
      "ReservationMaster[start_time]": toTime,
      "ReservationMaster[member_data]": JSON.stringify(memberData),
    };

    if (!isEmpty(editData)) {
      data.id = editData.reservation_id;
    }
    if (!isEmpty(editData2)) {
      data.id = editData2.id;
    }
    try {
      const resp = await getAPIProgressData(endPoints, "POST", data, true);
      if (resp?.success) {
        setReservationModal(false);
        toast(resp?.message, {
          type: "success",
        });
        if (getReservationListData) {
          getReservationListData();
        }

        setSelectedMembers([]);
        setSelectedClub(null);
        setSelectedDate(null);
        setSelectedTime(null);
        setShowSearchInput(true);
        setShowAddButton(false);

        const usersString = newmemeberId.join(", "); // Join the array elements with a comma and space
        if (isEmpty(editData) && isEmpty(editData2)) {
          if (newmemeberId.length > 2) {
            socket.connect();
            const requestData = {
              s_id: userData.id,
              r_id: newmemeberId,
              trip_id: selectedClub?.id || "",
              trip_type: "g",
              con_name: memberName + ", " + userData?.nick_name,
            };
            socket.emit("create_conversation", requestData, (response) => {
              const requestPoll2 = {
                file_type: "poll",
                date: dayjs(selectedDate).format("YYYY-MM-DD"),
                time: dayjs(selectedTime).format("HH:mm:ss"),
                s_id: userData?.id,
                c_id: response.data.conv_id,
                users_id: usersString,
                reservation_data: resp?.data,
              };
              if (response.status) {
                socket.connect();
                socket.emit("send_message", requestPoll2, (response) => {
                  setTimeout(() => {
                    navigate(`/chat?changeTab=${true}`);
                  }, 1000);
                  if (response.status === false) {
                  } else {
                    setTimeout(() => {
                      navigate(`/chat?changeTab=${true}`);
                    }, 1000);
                  }
                });
              } else {
              }
            });
          } else {
            socket.connect();
            const requestData = {
              s_id: userData.id,
              r_id: singlememberId,
              trip_id: selectedClub?.id || "",
              trip_type: "s",
              con_name: memberName + ", " + userData?.nick_name,
            };
            socket.emit("create_conversation", requestData, (response) => {
              const requestPoll2 = {
                file_type: "poll",
                date: dayjs(selectedDate).format("YYYY-MM-DD"),
                time: dayjs(selectedTime).format("HH:mm:ss"),
                s_id: userData?.id,
                c_id:
                  (CTab === "groups" && location.pathname === "/chat") || isHome
                    ? response?.data?.conv_id
                    : conversation_id,
                users_id: usersString,
                reservation_data: resp?.data,
              };
              if (!response.status) {
                socket.connect();
                socket.emit("send_message", requestPoll2, (response) => {
                  if (response?.status) {
                    handleOpenParticularchat();
                  }
                });
              } else {
                socket.emit("send_message", requestPoll2, (response) => {
                  if (response?.status) {
                    handleOpenParticularchat();
                  }
                });
              }
            });
          }
        } else {
          if (!_.isEmpty(editData)) {
            handleOpenParticularchat();
            window.location.reload();
          }
        }
      } else {
        toast(resp?.message, {
          type: "error",
        });
      }
      setLoaderBtnCreate(false);
    } catch (error) {
      toast(error.message, { type: "error" });
      setLoaderBtnCreate(false);
    }
  };

  const isTimeDisabled = (time) => {
    const currentDate = dayjs();
    if (selectedDate && dayjs(selectedDate).isSame(currentDate, "day")) {
      return dayjs(time).isBefore(currentDate);
    }
    return false;
  };

  return (
    <CModal
      visible={reservationModal.vis}
      onClose={() => {
        setReservationModal(false);
        setSelectedMembers([]);
        setSelectedClub(null);
        setSelectedDate(null);
        setSelectedTime(null);
        setShowSearchInput(true);
        setShowAddButton(false);
      }}
    >
      <Grid
        container
        style={{
          position: "relative",
          width: sm ? "90vw" : "500px",
          maxHeight: "97vh",
          overflowY: "auto",
          padding: sm ? "40px 20px" : "40px",
        }}
        className={classes.scrollBar}
      >
        <CloseIcon
          onClick={() => {
            setReservationModal(false);
            setSelectedMembers([]);
            setSelectedClub(null);
            setSelectedDate(null);
            setSelectedTime(null);
            setShowSearchInput(true);
            setShowAddButton(false);
            setClubNameErr(false);
            setSelectedDateErr(false);
            setSelectedTimeErr(false);
            setMembersErr(false);
            setMemberNames("");
          }}
          className={classes.closeContainer}
        />
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography
            style={{
              fontSize: "24px",
              fontFamily: FontFamily.GilbertQualifiDemo,
              color: BaseColor.textColor,
              textAlign: "center",
            }}
          >
            {(!isEmpty(editData) && isEmpty(editData2)) ||
            (isEmpty(editData) && !isEmpty(editData2))
              ? "Update Reservation "
              : "Create Reservation"}
          </Typography>
          {md && <Divider style={{ marginTop: "2vh" }} />}
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          width={"100%"}
          marginTop={!md && "20px"}
        >
          <Typography
            sx={{
              color: "black",
              fontFamily: FontFamily.SemiBold,
              fontSize: 16,
              marginTop: isMobile ? 2 : 1,
              marginBottom: "10px",
            }}
          >
            {"Club Name"}
            <span
              style={{
                color: BaseColor.errorRed,
                fontSize: "16px",
                fontFamily: FontFamily.Bold,
              }}
            >
              *
            </span>
          </Typography>

          <CSelect
            data={clubs}
            value={selectedClub || editData?.reservationDetails?.club_name}
            onSelect={(val) => {
              setSelectedClub(val);
              const initialMembers = selectedMembers?.map((member) => ({
                ...member,
                default_quantity: 0,
              }));
              setSelectedMembers(initialMembers);
            }}
            fullObj
            placeholder={"Select Club"}
            error={clubNameErr}
            errorMsg={clubNameMsg}
          />
        </Grid>
        <Grid
          container
          alignItems={"center"}
          justifyContent={"space-between"}
          marginTop={"12px"}
        >
          <Grid item xs={5.8} sm={5.8} md={5.8} lg={5.8}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Typography
                sx={{
                  color: "black",
                  fontFamily: FontFamily.SemiBold,
                  fontSize: 16,
                  marginTop: isMobile ? 2 : 1,
                  marginBottom: "10px",
                }}
              >
                {"Date"}
                <span
                  style={{
                    color: BaseColor.errorRed,
                    fontSize: "16px",
                    fontFamily: FontFamily.Bold,
                  }}
                >
                  *
                </span>
              </Typography>
              <DesktopDatePicker
                sx={{
                  width: "100%",
                  "& .MuiIconButton-root": {
                    color: `${BaseColor.grey} !important`,
                  },
                  "& .MuiOutlinedInput-input": { fontSize: sm && "14px" },
                }}
                inputFormat="dd-MM-yyyy"
                disablePast
                views={["year", "month", "day"]}
                value={selectedDate}
                onChange={handleDateChange}
                slotProps={{
                  textField: {
                    placeholder: editData?.reservationDetails?.end_date
                      ? editData?.reservationDetails?.end_date
                      : sm
                      ? "Date"
                      : "Select Date",
                    readOnly: true,
                  },
                }}
              />

              {selectedDateErr && (
                <div
                  style={{
                    fontSize: 14,
                    color: BaseColor.errorRed,
                    fontFamily: FontFamily.Medium,
                  }}
                >
                  {selectedDateMsg}
                </div>
              )}
            </LocalizationProvider>
          </Grid>

          <Grid item xs={5.8} sm={5.8} md={5.8} lg={5.8}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Typography
                sx={{
                  color: "black",
                  fontFamily: FontFamily.SemiBold,
                  fontSize: 16,
                  marginTop: isMobile ? 2 : 1,
                  marginBottom: "10px",
                }}
              >
                {"Time"}
                <span
                  style={{
                    color: BaseColor.errorRed,
                    fontSize: "16px",
                    fontFamily: FontFamily.Bold,
                  }}
                >
                  *
                </span>
              </Typography>
              <DesktopTimePicker
                sx={{
                  width: "100%",
                  "& .MuiIconButton-root": {
                    color: `${BaseColor.grey} !important`,
                  },
                  "& .MuiOutlinedInput-input": {
                    fontSize: sm && "14px",
                  },
                }}
                value={selectedTime}
                onChange={handleTimeChange}
                timeSteps={{ minutes: 1 }}
                shouldDisableTime={isTimeDisabled}
                slotProps={{
                  textField: {
                    placeholder: editData?.reservationDetails?.end_time
                      ? editData?.reservationDetails?.end_time
                      : sm
                      ? "Time"
                      : "Select Time",
                    readOnly: true,
                  },
                }}
              />
              {selectedTimeErr && (
                <div
                  style={{
                    fontSize: 14,
                    color: BaseColor.errorRed,
                    fontFamily: FontFamily.Medium,
                    position: "absolute",
                  }}
                >
                  {selectedTimeMsg}
                </div>
              )}
            </LocalizationProvider>
          </Grid>
        </Grid>

        <div
          style={{
            width: "100%",
            height:
              selectedMembers.length > 0 &&
              showSearchInput &&
              !isEmpty(memberNames) &&
              "fit-content",
          }}
        >
          {_.isEmpty(editData) && _.isEmpty(editData2) && (
            <Grid
              container
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
              marginTop={"15px"}
            >
              <Typography
                style={{
                  color: "black",
                  fontFamily: FontFamily.SemiBold,
                  fontSize: 16,
                  marginTop: isMobile ? 2 : 1,
                }}
              >
                Choose members
                <span
                  style={{
                    color: BaseColor.errorRed,
                    fontSize: "16px",
                    fontFamily: FontFamily.Bold,
                  }}
                >
                  *
                </span>
              </Typography>
              {!showSearchInput && (
                <IconButton
                  onClick={() => {
                    setShowSearchInput(true);
                  }}
                  aria-label="search"
                >
                  <CiSearch />
                </IconButton>
              )}
            </Grid>
          )}

          {showSearchInput && _.isEmpty(editData) && _.isEmpty(editData2) && (
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField
                placeholder="Search members"
                value={memberNames}
                onChange={(e) => {
                  setMemberNames(e.target.value);
                  getChooseMemberList(e.target.value, selectedMembers);
                }}
                sx={[
                  {
                    width: "100%",
                    backgroundColor: "white",
                    borderRadius: "50%",
                    marginTop: "10px",
                    height: "40px",
                  },
                ]}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      style={{
                        fontSize: 24,
                        color: BaseColor.blackColor,
                      }}
                    >
                      <CiSearch size={25} color={BaseColor.grey} />
                    </InputAdornment>
                  ),
                  endAdornment: !_.isEmpty(memberNames) && (
                    <InputAdornment
                      position={"start"}
                      style={{
                        fontSize: 24,
                        color: BaseColor.blackColor,
                      }}
                    >
                      {!isEmpty(memberNames) && (
                        <IconButton onClick={handleClear}>
                          <MdClose color={BaseColor.grey} size={20} />
                        </IconButton>
                      )}
                    </InputAdornment>
                  ),
                }}
                error={memberNamesErr}
              />
              {/* {memberNamesErr && (
                    <div
                      style={{
                        fontSize: 14,
                        color: BaseColor.errorRed,
                        fontFamily: FontFamily.Medium,
                        marginTop: 10,
                        marginLeft: 3,
                      }}
                    >
                      {memberNamesMsg}
                    </div>
                  )} */}
            </Grid>
          )}

          {!_.isEmpty(memberNames) && showSearchInput && (
            <>
              {!_.isEmpty(members) && (
                <Typography
                  style={{
                    color: "black",
                    fontFamily: FontFamily.SemiBold,
                    fontSize: 16,
                    marginTop: _.isEmpty(selectedMembers) ? 20 : 15,
                  }}
                >
                  Search Results
                </Typography>
              )}

              <Grid
                container
                columnGap={4}
                style={{
                  overflowX: "auto",
                  flexWrap: "nowrap",
                  height: "fit-content",
                  paddingBottom: "20px",
                }}
                className={"custom-scrollbar-vertical"}
                justifyContent={
                  loader
                    ? "center"
                    : _.isEmpty(members)
                    ? "center"
                    : "flex-start"
                }
                alignItems={
                  loader
                    ? "center"
                    : _.isEmpty(members)
                    ? "center"
                    : "flex-start"
                }
                display={"flex"}
              >
                {!_.isEmpty(memberNames) && !_.isEmpty(members) ? (
                  <>
                    {loader ? (
                      <CircularProgress size={20} color="primary" />
                    ) : (
                      <>
                        {members?.map((value, index) => (
                          <Grid key={index} item>
                            <div className={classes.memberContainer}>
                              <Grid
                                onClick={() => {
                                  if (value?.is_blocked) {
                                    toast(
                                      "You cannot select this user as you have been blocked by them.",
                                      {
                                        type: "info",
                                      }
                                    );
                                  } else {
                                    handleClick(index);
                                  }
                                }}
                              >
                                <div style={{ position: "relative" }}>
                                  <img
                                    src={
                                      value.profile_pic_url ||
                                      "https://golf.teamgroovy.com/img_assets/boy.jpeg"
                                    }
                                    alt="memberUserImage"
                                    style={{
                                      height: "65px",
                                      width: "65px",
                                      borderRadius: "50%",
                                    }}
                                    draggable={false}
                                  />
                                  <div
                                    style={{
                                      background: value?.isSelected
                                        ? "#042B4860"
                                        : "none",
                                      height: "65px",
                                      width: "65px",
                                      borderRadius: "50%",
                                      position: "absolute",
                                      bottom: "3px",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      display: "flex",
                                    }}
                                  >
                                    {value?.isSelected && (
                                      <IoCheckmarkOutline
                                        color={BaseColor.white}
                                        size={40}
                                      />
                                    )}
                                  </div>
                                </div>
                                <Typography
                                  style={{
                                    fontSize: "12px",
                                    fontFamily: FontFamily.Bold,
                                    color: BaseColor.textColor,
                                    textAlign: "center",
                                    wordBreak: "break-word",
                                  }}
                                >
                                  {value?.nick_name}
                                </Typography>
                              </Grid>
                            </div>
                          </Grid>
                        ))}
                      </>
                    )}
                  </>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "50px",
                    }}
                  >
                    <Typography>No data found</Typography>
                  </div>
                )}
              </Grid>

              {membersErr && (
                <div
                  style={{
                    fontSize: 14,
                    color: BaseColor.errorRed,
                    fontFamily: FontFamily.Medium,
                  }}
                >
                  {membersMsg}
                </div>
              )}

              {!isEmpty(members) && showAddButton && (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  marginTop={md ? "5px" : "15px"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  display={"flex"}
                  flexDirection={"column"}
                >
                  <CButton
                    variant="outlined"
                    btnStyle={{ width: "30%" }}
                    style={{
                      height: "34px",
                    }}
                    onClick={handleAddButtonClick}
                  >
                    Add
                  </CButton>
                </Grid>
              )}
            </>
          )}

          {selectedMembers?.length > 0 && (
            <Grid
              container
              columnGap={4}
              style={{
                overflowX: "auto",
                flexWrap: "nowrap",
                height: "fit-content",
                marginTop: md ? "20px" : "24px",
                paddingBottom: "10px",
              }}
              className={"custom-scrollbar-vertical"}
              justifyContent={"flex-start"}
              alignItems={"flex-start"}
              display={"flex"}
            >
              <>
                {selectedMembers.map((value, index) =>
                  value?.isSelected ? (
                    <Grid key={index} item>
                      <div className={classes.memberContainer}>
                        <Grid
                          onClick={() => {
                            handleRemoveSelectedMember(value.id);
                          }}
                        >
                          <div style={{ position: "relative" }}>
                            <img
                              src={
                                value.profile_pic_url ||
                                "https://golf.teamgroovy.com/img_assets/boy.jpeg"
                              }
                              alt="memberUserImage"
                              style={{
                                height: "65px",
                                width: "65px",
                                borderRadius: "50%",
                              }}
                              draggable={false}
                            />
                            <div
                              style={{
                                background: "#042B4860",
                                height: "65px",
                                width: "65px",
                                borderRadius: "50%",
                                position: "absolute",
                                bottom: "3px",
                                justifyContent: "center",
                                alignItems: "center",
                                display: "flex",
                              }}
                            >
                              <IoCheckmarkOutline
                                color={BaseColor.white}
                                size={40}
                              />
                            </div>
                          </div>
                          <Typography
                            style={{
                              fontSize: "12px",
                              fontFamily: FontFamily.Bold,
                              color: BaseColor.textColor,
                              textAlign: "center",
                            }}
                          >
                            {value.nick_name}
                          </Typography>
                        </Grid>
                      </div>
                      {addGuestButtons && (
                        <Grid
                          container
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginTop: "5px",
                          }}
                        >
                          <Grid
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            onClick={() => handleDecrement(index)}
                          >
                            <span
                              style={{
                                fontSize: "14px",
                                fontFamily: FontFamily.SemiBold,
                                color: BaseColor.textColor,
                                cursor: !isMobile && !isTablet && "pointer",
                                height: "20px",
                                width: "20px",
                                borderRadius: "5px",
                                border: "1px solid #222",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                userSelect: "none",
                              }}
                            >
                              -
                            </span>
                          </Grid>
                          <span
                            style={{
                              fontSize: "14px",
                              fontFamily: FontFamily.SemiBold,
                              color: BaseColor.textColor,
                              margin: "0px 2px",
                            }}
                          >
                            {value.default_quantity}
                          </span>
                          <Grid
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            onClick={() => handleIncrement(index)}
                          >
                            <span
                              style={{
                                fontSize: "14px",
                                fontFamily: FontFamily.SemiBold,
                                color: BaseColor.textColor,
                                cursor: !isMobile && !isTablet && "pointer",
                                height: "20px",
                                width: "20px",
                                borderRadius: "5px",
                                border: "1px solid #222",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                userSelect: "none",
                              }}
                            >
                              +
                            </span>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  ) : null
                )}
                <Grid item>
                  <div className={classes.memberContainer}>
                    <Grid
                      onClick={() => {
                        if (selectedClub) {
                          setAddGuestButtons(!addGuestButtons);
                        } else {
                          toast.error("Please select a club first.");
                        }
                      }}
                      style={{
                        cursor: !isMobile && !isTablet && "pointer",
                      }}
                    >
                      <div style={{ position: "relative" }}>
                        <img
                          src={Files.Images.addGuest}
                          alt="memberUserImage"
                          style={{
                            height: "65px",
                            width: "65px",
                          }}
                          draggable={false}
                        />
                      </div>
                      <Typography
                        style={{
                          fontSize: "12px",
                          fontFamily: FontFamily.Bold,
                          color: BaseColor.textColor,
                          textAlign: "center",
                        }}
                      >
                        Add Guest
                      </Typography>
                    </Grid>
                  </div>
                </Grid>
              </>
            </Grid>
          )}
        </div>
        <Grid item xs={12} sm={12} md={12} lg={12} marginTop={"50px"}>
          <CButton
            variant="contained"
            onClick={() => {
              handleCreateClick();
            }}
            disabled={
              _.isEmpty(selectedClub) ||
              _.isEmpty(selectedDate) ||
              _.isEmpty(selectedTime) ||
              !selectedMembers?.some((member) => member.isSelected)
                ? true
                : false
            }
            loading={loaderBtnCreate}
          >
            {(!isEmpty(editData) && isEmpty(editData2)) ||
            (isEmpty(editData) && !isEmpty(editData2))
              ? "Update"
              : "Create"}
          </CButton>
        </Grid>
      </Grid>
    </CModal>
  );
}
