import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CHeader from "../../Components/Header";
import theme, { FontFamily } from "../../Config/theme";
import { Setting } from "../../Utils/Setting";
import { getAPIProgressData } from "../../Utils/APIHelper";
import { toast } from "react-toastify";
import _ from "lodash";
import BaseColor from "../../Config/colors";
import { useNavigate } from "react-router-dom";
import { isMobile, isTablet } from "react-device-detect";
import CIcon from "../../Components/CIcon";
import Files from "../../Config/Files";
import { motion } from "framer-motion";

export default function AboutUs() {
  // navigation
  const navigate = useNavigate();

  // media queries
  const md = useMediaQuery(theme.breakpoints.down("md"));

  // loader
  const [loader, setLoader] = useState(false);

  // data
  const [aboutUsData, setAboutUsData] = useState();

  useEffect(() => {
    getAboutUsData();
  }, []);

  // get about us data api call
  const getAboutUsData = async () => {
    setLoader(true);
    let endPoints = `${Setting.endpoints.getCmsPages}`;

    const data = {
      slug: "about_us",
    };

    try {
      const resp = await getAPIProgressData(endPoints, "POST", data);
      if (resp?.success) {
        if (
          !_.isEmpty(resp?.data) &&
          !_.isNull(resp?.data) &&
          !_.isUndefined(resp?.data)
        ) {
          setLoader(false);
          setAboutUsData(resp?.data);
        }
      } else {
        toast(resp?.message, {
          type: "error",
        });
        setLoader(false);
      }
      setLoader(false);
    } catch (error) {
      toast(error.message, {
        type: "error",
      });
      setLoader(false);
    }
  };

  const appBody = (
    <div dangerouslySetInnerHTML={{ __html: aboutUsData?.app_body }} />
  );

  return (
    <Grid container style={{ height: "100vh" }}>
      <CHeader
        title={"About Us"}
        backButtonClick={() => {
          navigate(-1);
        }}
      />
      {loader ? (
        <Grid
          container
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          style={{
            height: "calc(100vh - 90px)",
          }}
        >
          <CircularProgress size={40} color="primary" />
        </Grid>
      ) : (
        <Grid
          container
          style={{ height: "calc(100vh - 90px)", overflowY: "auto" }}
        >
          <Grid item xs={0.5} sm={0.5} md={1} lg={1}></Grid>
          <Grid item xs={11} sm={11} md={10} lg={10} paddingTop={!md && "20px"}>
            <Grid container>
              <Grid item xs={12} mb={"20px"}>
                <Grid
                  container
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {!md && (
                    <Grid item xs={12} display={"flex"} alignItems={"center"}>
                      <CIcon
                        src={Files.svgIcons.BackArrowIcon}
                        size={30}
                        style={{
                          marginLeft: "-4px",
                          cursor: !isMobile && !isTablet && "pointer",
                        }}
                        onClick={() => {
                          navigate(-1);
                        }}
                      />
                      <Typography
                        sx={{
                          color: BaseColor.primary,
                          fontFamily: FontFamily.GilbertQualifiDemo,
                          fontSize: 22,
                          marginLeft: "10px",
                        }}
                      >
                        About Us
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 50 }}
                transition={{ duration: 0.3 }}
              >
                <Grid item xs={12} style={{ overflowX: "hidden" }}>
                  <Grid
                    container
                    style={{
                      paddingBottom: "20px",
                    }}
                  >
                    {appBody}
                  </Grid>
                </Grid>
              </motion.div>
            </Grid>
          </Grid>

          <Grid item xs={0.5} sm={0.5} md={1} lg={1}></Grid>
        </Grid>
      )}
    </Grid>
  );
}
