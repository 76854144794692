import { makeStyles } from "@mui/styles";
import BaseColor from "../Config/colors";
import { FontFamily } from "../Config/theme";

const styles = makeStyles({
  select: {
    "& .MuiSelect-icon": {
      color: `${BaseColor.grey} !important`,
      marginRight: "10px",
    },
    "& .MuiSelect-iconOpen": {
      color: `${BaseColor.blackColor} !important`,
    },
  },

  errorMsgContainer: {
    marginTop: 5,
    marginLeft: 2,
  },
  errorMsgText: {
    fontSize: 14,
    color: BaseColor.errorRed,
    fontFamily: FontFamily.Medium,
  },
});
export default styles;
