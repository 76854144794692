const BaseColor = {
  primary: "#042B48",
  disablePrimary: "#C3ACD0",
  popOverSeperate: "#F0F0F0",
  offWhiteBg: "#EDF1F2",
  grey: "#7B8B97",
  placeHolderColor: "#797979",
  inActiveStep: "#5E5D5D",
  greyBorder: "#D8E0E3",
  disableGreen: "#13745070",
  dotColor: "#484848",
  disableText: "#81A7C5",
  cursorColor: "#B4BDC3",
  textColor: "#222222",
  offWhite: "#F7EFE5",
  green: "#137450",
  whiteColor: "#FFFBF5",
  blackk: "#252B33",
  red: "#FF1010",
  errorRed: "#FE2A2A",
  transparent: "#ffffff00",
  white: "#ffffff",
  blackColor: "#000000",
  lightGray: "#616161",
  darkGray: "#333333",
};

export default BaseColor;
