import { makeStyles } from "@mui/styles";
import BaseColor from "../../Config/colors";
import { FontFamily } from "../../Config/theme";

const styles = makeStyles({
  root: {
    width: "100%",
  },
  eyeImage: {
    height: 19,
    width: 19,
  },
  errorMsgContainer: {
    marginTop: 6,
    marginLeft: 3,
  },
  errorMsgText: {
    fontSize: 14,
    color: BaseColor.errorRed,
    fontFamily: FontFamily.Medium,
  },
  headerCon: {
    display: "flex",
    justifyContent: "space-between",
  },
  image: {
    height: "18px",
    width: "18px",
    marginRight: "20px",
  },
});

export default styles;
