import React, { useEffect, useState } from "react";
import { Autocomplete, TextField, Typography } from "@mui/material";
import BaseColor from "../../Config/colors";
import { BsSearch } from "react-icons/bs";
import { Setting } from "../../Utils/Setting";
import { getAPIProgressData } from "../../Utils/APIHelper";
import { toast } from "react-toastify";
import _ from "lodash";
import { FontFamily } from "../../Config/theme";
import { isMobile } from "react-device-detect";

const CAutoComplete = (props) => {
  const {
    valueObj = {},
    handleSelect = () => {},
    handleFieldChange = () => {},
    index = null,
    homeClubNameErr = "",
    onChange = () => {},
    value = "",
    id = "",
    onCustomInputChange = () => {}, // Callback to send isCustomInput back
    isEdit = false,
    selectedClubs,
  } = props;

  const [searchResults, setSearchResults] = useState([]);
  const [inputVal, setInputVal] = useState("");
  const [inputValObj, setInputValObj] = useState(null);

  useEffect(() => {
    if (isEdit) {
      setInputVal(value);
      setInputValObj(valueObj);
    }
  }, [isEdit]);

  const fetchSearchResults = async (val) => {
    let endPoints = Setting.endpoints.clubSearch;

    const params = {
      search: val,
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", params);
      if (resp?.success) {
        setSearchResults(resp?.data);
        onCustomInputChange(false);
        return resp?.data;
      } else {
        setSearchResults([]);
        onCustomInputChange(true);
        return false;
      }
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
      });
      onCustomInputChange(false);
    }
  };

  return (
    <>
      <Autocomplete
        {...props}
        value={inputValObj}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) =>
          option.name ? `${option.name} - ${option.address}` : ""
        }
        getOptionDisabled={(option) =>
          selectedClubs.some(
            (selectedClub, idx) =>
              idx !== index && selectedClub?.id === option.id
          )
        }
        freeSolo
        options={searchResults}
        style={{
          height: "50px",
          marginTop: "10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        // onSelect={(value) => {
        //   if (!_.isEmpty(value)) {
        //     handleSelect(value, index);
        //   } else {
        //     onCustomInputChange(false);
        //   }
        // }}
        onChange={(_event, value, _reason) => {
          if (!_.isEmpty(value)) {
            setInputValObj(value);
            handleFieldChange(value, index);
            onCustomInputChange(false);
          } else {
            setInputVal("");
            setInputValObj(null);
            handleFieldChange(value, index);
            setSearchResults([]);
            onCustomInputChange(false);
          }
        }}
        renderOption={(props, option) => {
          return (
            <li
              {...props}
              style={{
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <div
                style={{
                  marginBottom: "4px",
                  fontSize: "16px",
                  fontWeight: "bold",
                  color: "black",
                }}
              >
                {option.name}
              </div>
              <div style={{ fontSize: "14px", color: "#A9A9A9" }}>
                {option.address}
              </div>
              <hr
                style={{
                  width: "100%",
                  borderTop: "1px solid white",
                }}
              />
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            id={id}
            style={{
              height: "50px",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                event.stopPropagation();
              }
            }}
            sx={[
              {
                input: {
                  color: BaseColor.textColor,
                  "&::placeholder": {
                    opacity: 1,
                    fontFamily: FontFamily.Medium,
                    fontSize: isMobile ? 14 : 16,
                    color: !_.isEmpty(homeClubNameErr)
                      ? BaseColor.errorRed
                      : BaseColor.grey,
                  },
                  "&.MuiAutocomplete-input": {
                    textOverflow: "clip !important",
                  },
                },
              },
            ]}
            placeholder="Name"
            value={inputVal}
            onChange={(val) => {
              setInputVal(val.target.value);
              onChange(val, index);
              if (val.target.value) {
                setTimeout(() => {
                  fetchSearchResults(val.target.value);
                }, 300);
              } else {
                setSearchResults([]);
                onCustomInputChange(false);
              }
            }}
            error={homeClubNameErr}
            InputProps={{
              ...params.InputProps,
              // type: "search",
              style: {
                padding: inputValObj ? "0px 25px 0px 10px" : "0px 10px",
                fontSize: "16px",
              },
              endAdornment: (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderLeft: "1px solid grey",
                    paddingLeft: "8px",
                  }}
                >
                  {inputValObj ? (
                    params.InputProps.endAdornment
                  ) : (
                    <BsSearch
                      style={{
                        fontSize: 20,
                        color: BaseColor.blackColor,
                        marginRight: 8,
                      }}
                    />
                  )}
                </div>
              ),
            }}
          />
        )}
      />

      {!_.isEmpty(homeClubNameErr) && (
        <Typography
          style={{
            fontSize: 14,
            color: BaseColor.errorRed,
            fontFamily: FontFamily.Medium,
            paddingTop: "5px",
          }}
        >
          {homeClubNameErr}
        </Typography>
      )}
    </>
  );
};

export default CAutoComplete;
