import { makeStyles } from "@mui/styles";

const styles = makeStyles((theme) => ({
  root: {
    cursor: "pointer",
  },
  notificationContainer: {
    position: "fixed",
    width: 350,
    zIndex: 100000,
    backgroundColor: "#ffffff",
    marginTop: -6,
    borderRadius: 5,
    boxShadow:
      "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
    [theme.breakpoints.down("xs")]: {
      width: "95%",
    },
    [theme.breakpoints.down(350)]: {
      width: "94%",
    },
  },
  topRight: {
    top: 15,
    right: 15,
    transition: "transform 0.6s ease-in-out",
    animation: "$toast-in-right 0.7s",
  },
  subDivForNotification: {
    display: "flex",
    padding: 9,
  },
  subFlexCon: {
    display: "flex",
    flexDirection: "column",
  },
  notificationImage: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    marginBottom: 5,
  },
  notiAppLogo: {
    width: "auto",
    height: 25,
  },
}));
export default styles;
