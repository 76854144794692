import React, { useState, useEffect } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import Login from "../Screens/Login";
import NewMember from "../Screens/NewMember";
import SplashScreen from "../Screens/SplashScreen";
import Intro from "../Screens/Intro";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import _ from "lodash";
import ContactUs from "../Screens/ContactUs";
import Faqs from "../Screens/FAQs";
import TermsAndConditions from "../Screens/Terms&Conditions";
import PrivacyPolicy from "../Screens/PrivacyPolicy";
import AboutUs from "../Screens/AboutUs";

function LoginStack() {
  // Redux
  const walkthrough = useSelector((state) => state.auth.walkthrough);
  const { userData } = useSelector((state) => state.auth);

  // loader
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, []);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (_.isEmpty(userData) && location.pathname === "/new-member") {
      if (isMobile) {
        navigate("/login");
      } else {
        navigate("/");
      }
    } else if (_.isEmpty(userData)) {
      navigate("/");
    }
  }, []);

  return (
    <Routes>
      {isMobile ? (
        <Route
          exact
          path="/"
          element={
            isLoading ? <SplashScreen /> : walkthrough ? <Intro /> : <Login />
          }
        />
      ) : null}

      {userData?.signup_pending && (
        <Route path={"/new-member"} element={<NewMember />} />
      )}
      <Route path={isMobile ? "/login" : "/"} element={<Login />} />

      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/faqs" element={<Faqs />} />
      <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/about-us" element={<AboutUs />} />
    </Routes>
  );
}

export default LoginStack;
