import { makeStyles } from "@mui/styles";
import { FontFamily } from "../../Config/theme";
import BaseColor from "../../Config/colors";
import { isMobile, isTablet } from "react-device-detect";

const styles = makeStyles({
  closeContainer: {
    position: "absolute",
    top: 20,
    right: 20,
    fontSize: 30,
    cursor: !isMobile && !isTablet && "pointer",
    color: BaseColor.textColor,
    zIndex: 1,
  },
  divider: {
    width: "100%",
    borderTop: "1px solid #B4BDC3",
    marginBottom: 16,
    marginTop: 10,
  },
  calendarText: {
    fontFamily: FontFamily.Medium,
    color: BaseColor.grey,
    fontSize: "16px",
  },
  calendarWithoutBorder: {
    border: "none",
  },
  customNavigationLabel: {
    color: BaseColor.grey,
    fontSize: "16px",
    fontFamily: FontFamily.Bold,
  },
  customNavigationLabelText: {
    fontSize: "24px",
    fontFamily: FontFamily.GilbertQualifiDemo,
    color: BaseColor.textColor,
  },
  customSelectedDate: {
    border: `2px solid ${BaseColor.primary} !important`,
    background: `${BaseColor.white} !important`,
    borderRadius: 8,
    color: BaseColor.blackColor,
    "&.react-calendar__tile--active:enabled:hover": {
      backgroundColor: BaseColor.primary,
    },
    "&.react-calendar__month-view__days__day--weekend:enabled": {
      color: `${BaseColor.blackColor}!important`,
    },
  },
  customWeekendDay: {
    fontSize: "14px",
  },
  customTile: {
    borderBottom: "2px solid #137450 !important",
    borderRadius: 8,
  },
  todayDate: {
    border: "2px solid #042B48 !important",
    background: BaseColor.white,
    borderRadius: 8,
  },
  scrollBar: {
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  mainScrollBar: {
    "&::-webkit-scrollbar": {
      width: 0,
      height: 5,
    },
  },
});

export default styles;
