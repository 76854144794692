import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CHeader from "../../Components/Header";
import _ from "lodash";
import { getAPIProgressData } from "../../Utils/APIHelper";
import { Setting } from "../../Utils/Setting";
import { toast } from "react-toastify";
import theme, { FontFamily } from "../../Config/theme";
import CIcon from "../../Components/CIcon";
import Files from "../../Config/Files";
import { useNavigate } from "react-router-dom";
import { isMobile, isTablet } from "react-device-detect";
import BaseColor from "../../Config/colors";
import CButton from "../../Components/CButton";
import { motion } from "framer-motion";
import styles from "./styles";

const SubscriptionPlans = () => {
  // loader
  const [loader, setLoader] = useState(true);

  // navigation
  const navigate = useNavigate();

  // styles and media queries
  const classes = styles();
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const xs = useMediaQuery(theme.breakpoints.down("xs"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  // listing of plan
  const [planList, setPlanList] = useState([]);

  useEffect(() => {
    getPlanList();
  }, []);

  // get plan list api call
  const getPlanList = async () => {
    let endPoints = Setting.endpoints.planDetails;
    try {
      const resp = await getAPIProgressData(endPoints, "POST", {});
      if (resp?.success) {
        if (!_.isEmpty(resp?.data)) {
          setPlanList(resp?.data);
        } else {
          setPlanList([]);
        }
        setLoader(false);
      } else {
        setLoader(false);
      }
    } catch (error) {
      toast(error.message, {
        type: "error",
      });
      setLoader(false);
    }
  };

  // plan card design component
  const PlanCard = ({ plan }) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={{
          backgroundColor: "white",
          borderRadius: "20px",
          padding: "20px",
          height: "260px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          boxShadow: isHovered
            ? `0 0 0 2px ${BaseColor.primary}`
            : "0 0 10px 2px rgb(103 65 136 / 10%)",
          transition: "all 0.8s ease",
        }}
      >
        <div>
          <Typography
            style={{
              fontFamily: FontFamily.Bold,
              color: isHovered ? BaseColor.primary : BaseColor.blackColor,
              textAlign: "center",
              fontSize: "18px",
              marginBottom: "10px",
              transition: "color 0.8s ease",
            }}
          >
            {plan?.name}
          </Typography>
          <Typography
            style={{
              fontFamily: FontFamily.Bold,
              color: BaseColor.primary,
              textAlign: "center",
              fontSize: "36px",
              marginBottom: "20px",
            }}
          >
            ${plan?.price}
            <span
              style={{
                fontFamily: FontFamily.Medium,
                color: BaseColor.darkGray,
                fontSize: "18px",
                marginLeft: "5px",
              }}
            >
              /{plan?.plan_duration}
            </span>
          </Typography>

          <Typography
            style={{
              textAlign: "center",
              fontSize: isMobile ? "13px" : "16px",
              marginBottom: "10px",
            }}
          >
            {plan?.description}
          </Typography>
        </div>
        <CButton
          style={{
            marginTop: "20px",
            backgroundColor: isHovered ? BaseColor.primary : "white",
            color: isHovered ? "white" : BaseColor.primary,
            border: `1px solid ${BaseColor.primary}`,
            transition: "all 0.3s ease",
            width: "90%",
          }}
          onClick={() => {
            window.location.replace(plan?.checkout_link);
          }}
        >
          Subscribe now
        </CButton>
      </div>
    );
  };

  return (
    <Grid container style={{ height: "100vh", width: "100vw" }}>
      <CHeader
        title={"Plans"}
        backButtonClick={() => {
          navigate(-1);
        }}
      />
      {loader ? (
        <Grid
          container
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          style={{
            height: "calc(100vh - 90px)",
          }}
        >
          <CircularProgress size={40} color="primary" />
        </Grid>
      ) : (
        <Grid
          container
          style={{
            height: "calc(100vh - 90px)",
            overflowY: "auto",
          }}
        >
          <Grid item xs={0.5} sm={0.5} md={1} lg={1}></Grid>
          <Grid item xs={11} sm={11} md={10} lg={10}>
            <Grid container>
              <Grid item xs={12}>
                <Grid container className={classes.mainTitleContainer}>
                  {!md && (
                    <Grid
                      item
                      xs={12}
                      style={{ display: "flex", marginTop: "10px" }}
                    >
                      <CIcon
                        src={Files.svgIcons.BackArrowIcon}
                        size={30}
                        style={{
                          marginLeft: "-4px",
                          cursor: !isMobile && !isTablet && "pointer",
                        }}
                        onClick={() => {
                          navigate(-1);
                        }}
                      />
                      <Typography className={classes.title}>Plans</Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Grid item xs={12} className={classes.planTitle}>
                    <Typography
                      style={{
                        color: BaseColor.primary,
                        fontFamily: FontFamily.GilbertQualifiDemo,
                        fontSize: 26,
                        marginTop: "10px",
                      }}
                    >
                      Choose your plan
                    </Typography>
                    <Typography
                      style={{
                        color: BaseColor.darkGray,
                        fontFamily: FontFamily.Medium,
                        fontSize: isMobile ? 12 : 16,
                        marginTop: "10px",
                        textAlign: "center",
                      }}
                    >
                      Choose the one that best suits your experience level and
                      start enjoying our full range of services!
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={xs || sm ? 0 : md ? 1.5 : 2}
              display={"flex"}
              justifyContent={"center"}
              rowSpacing={sm && 2}
              style={{
                marginTop: xs || sm ? "20px" : "10px",
                paddingBottom: sm ? "60px" : "20px",
                paddingRight: !sm && "15px",
              }}
            >
              {planList.map((plan) => (
                <Grid item xs={12} sm={6} md={4} key={plan.id}>
                  <motion.div
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 50 }}
                    transition={{ duration: 0.4 }}
                  >
                    <PlanCard plan={plan} />
                  </motion.div>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={0.5} sm={0.5} md={1} lg={1}></Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default SubscriptionPlans;
