import {
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { IoIosArrowRoundBack } from "react-icons/io";
import styles from "./styles";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import _ from "lodash";
import { toast } from "react-toastify";
import theme, { FontFamily } from "../../Config/theme";
import { Setting } from "../../Utils/Setting";
import { getAPIProgressData } from "../../Utils/APIHelper";
import BaseColor from "../../Config/colors";
import CEventCard from "../../Components/CEventsCard";
import CHeader from "../../Components/Header";

const MyEvents = () => {
  // media queries and styles
  const classes = styles();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const xs = useMediaQuery(theme.breakpoints.down("xs"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const isLaptopSize = useMediaQuery(
    "(min-width: 1024px) and (max-width: 1262px)"
  );

  // navigation
  const navigate = useNavigate();

  // page loader
  const [pageLoader, setPageLoader] = useState(true);

  // filter loader
  const [filterLoader, setFilterLoader] = useState(false);

  // event list
  const [eventList, setEventList] = useState([]);

  // url params
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    setHeaderData(selectedFilter);
    getEventList(selectedFilter, true, false);
  }, []);

  // set params to url
  const selectedFilter = searchParams.has("selected_tab")
    ? searchParams.get("selected_tab")
    : "my_event";
  const setHeaderData = (tab = "my_event") => {
    let params = {};
    if (!_.isEmpty(tab)) {
      params.selected_tab = tab;
    }
    setSearchParams(createSearchParams(params), { replace: true });
  };

  // event tab
  const [selectedTab, setSelectedTab] = useState(selectedFilter);

  // event list
  const getEventList = async (type, pageLoad, filterLoader) => {
    if (pageLoad) {
      setPageLoader(true);
    }
    if (filterLoader) {
      setFilterLoader(true);
    }
    let endPoints = Setting.endpoints.eventList;

    let data = {};

    if (type === "saved_event") {
      data.is_event_bookmark = 1;
    }

    try {
      const resp = await getAPIProgressData(endPoints, "POST", data, true);
      if (resp?.success) {
        if (!_.isEmpty(resp?.data) && _.isArray(resp?.data)) {
          setEventList(resp?.data);
        } else {
          setEventList([]);
        }
        if (pageLoad) {
          setPageLoader(false);
        }
        if (filterLoader) {
          setFilterLoader(false);
        }
      } else {
        toast(resp?.message, {
          type: "error",
        });
        if (pageLoad) {
          setPageLoader(false);
        }
        if (filterLoader) {
          setFilterLoader(false);
        }
        setEventList([]);
      }
    } catch (error) {
      toast(error.message, { type: "error" });
      if (pageLoad) {
        setPageLoader(false);
      }
      if (filterLoader) {
        setFilterLoader(false);
      }
    }
  };

  // add and remove bookmarks
  const addAndRemoveBookmark = async (eventId, bookmark) => {
    let endPoints = Setting.endpoints.addAndRemoveBookmark;

    const data = {
      "EventManagement[event_id]": eventId,
      "EventManagement[is_event_bookmark]": bookmark ? 1 : 0,
    };

    try {
      const resp = await getAPIProgressData(endPoints, "POST", data, true);
      if (resp?.success) {
        toast(resp?.message, {
          type: "success",
        });
        getEventList(selectedFilter, false, true);
      } else {
        toast(resp?.message, {
          type: "error",
        });
      }
    } catch (error) {
      toast(error.message, { type: "error" });
    }
  };

  return (
    <Grid container style={{ height: "100vh", overflowY: "auto" }}>
      <CHeader
        title={"My Events"}
        backButtonClick={() => {
          navigate(-1);
        }}
      />
      {pageLoader ? (
        <Grid
          container
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          style={{
            height:
              md || isTablet || isMobile
                ? "calc(100vh - 70px)"
                : "calc(100vh - 90px)",
          }}
        >
          <CircularProgress color="primary" size={40} />
        </Grid>
      ) : (
        <>
          <Grid item xs={0.5} sm={0.5} md={1} lg={1} />
          <Grid
            item
            xs={11}
            sm={11}
            md={10}
            lg={10}
            style={{ height: md ? "calc(100vh - 70px)" : "calc(100vh - 90px)" }}
          >
            {!md && (
              <Grid container mb={1} mt={"20px"}>
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <IoIosArrowRoundBack
                    onClick={() => {
                      navigate(-1);
                    }}
                    style={{
                      fontSize: 35,
                      color: BaseColor.blackColor,
                      cursor: "pointer",
                    }}
                  />

                  <Typography
                    style={{
                      fontSize: 25,
                      color: BaseColor.primary,
                      fontFamily: FontFamily.GilbertQualifiDemo,
                      alignItems: "center",
                      textAlign: "center",
                      alignSelf: "center",
                      flexGrow: 1,
                      marginLeft: "10px",
                    }}
                  >
                    My Events
                  </Typography>
                </div>
              </Grid>
            )}

            <Grid
              item
              xs={12}
              display={"flex"}
              alignItems={"center"}
              mt={"20px"}
            >
              <Grid
                container
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={5}
                  display={"fex"}
                  alignItems={"center"}
                >
                  <div className={classes.switchDiv}>
                    <div
                      style={{
                        backgroundColor:
                          selectedTab === "my_event"
                            ? BaseColor.primary
                            : BaseColor.offWhiteBg,
                        color:
                          selectedTab === "my_event"
                            ? BaseColor.white
                            : BaseColor.blackColor,
                        width: !md && "200px",
                        fontFamily: FontFamily.Medium,
                      }}
                      onClick={() => {
                        setSelectedTab("my_event");
                        setHeaderData("my_event");
                        getEventList("my_event", false, true);
                      }}
                      className={classes.switchTab}
                    >
                      My Events
                    </div>
                    <div
                      style={{
                        backgroundColor:
                          selectedTab === "saved_event"
                            ? BaseColor.primary
                            : BaseColor.offWhiteBg,
                        color:
                          selectedTab === "saved_event"
                            ? BaseColor.white
                            : BaseColor.blackColor,
                        width: !md && "200px",
                        fontFamily: FontFamily.Medium,
                      }}
                      onClick={() => {
                        setSelectedTab("saved_event");
                        setHeaderData("saved_event");
                        getEventList("saved_event", false, true);
                      }}
                      className={classes.switchTab}
                    >
                      Saved Events
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              mt={1}
              style={{
                flexDirection: isMobile || isTablet ? "column" : "row",
                flexWrap: "nowrap",
                paddingLeft: "2px",
                paddingRight: "5px",
                overflow: isLaptopSize ? "auto" : "hidden",
              }}
            >
              {filterLoader ? (
                <Grid
                  container
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "650px",
                  }}
                >
                  <CircularProgress color="primary" size={30} />
                </Grid>
              ) : (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Grid
                    container
                    spacing={xs || sm ? 0 : 1}
                    justifyContent={"flex-start"}
                    display={"flex"}
                    rowGap={2}
                    marginTop={xs || sm ? "20px" : "10px"}
                    paddingBottom={"20px"}
                  >
                    {_.isEmpty(eventList) ? (
                      <Grid
                        container
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: md || isMobile || isTablet ? "70vh" : "60vh",
                        }}
                      >
                        <Typography
                          style={{
                            fontFamily: FontFamily.SemiBold,
                            color: BaseColor.blackColor,
                            fontSize: 16,
                          }}
                        >
                          No Data
                        </Typography>
                      </Grid>
                    ) : (
                      eventList?.map((item) => {
                        return (
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={4}
                            lg={4}
                            xl={4}
                            paddingRight={!sm && "10px"}
                          >
                            <CEventCard
                              name={item.event_name}
                              address={item.location}
                              distance={item.distance}
                              image={
                                !_.isArray(item?.event_images)
                                  ? item.event_images
                                  : item?.event_images[0]
                              }
                              bookMarkClick={() => {
                                addAndRemoveBookmark(
                                  item?.event_id,
                                  item?.is_event_bookmark == "1" ? false : true
                                );
                              }}
                              isBookMark={
                                item?.is_event_bookmark == "1" ? true : false
                              }
                              onClick={() => {
                                navigate(`/event-details/${item?.event_id}`);
                              }}
                            />
                          </Grid>
                        );
                      })
                    )}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={0.5} sm={0.5} md={1} lg={1} />
        </>
      )}
    </Grid>
  );
};

export default MyEvents;
