import { Modal } from "@mui/material";
import React from "react";
import styles from "./styles";
import PropType from "prop-types";
import { motion } from "framer-motion";

const CModal = (props) => {
  const { visible, onClose = () => {}, children, style } = props;

  const classes = styles();

  return (
    <Modal open={visible} onClose={onClose} className={classes.modal}>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.3 }} // Duration in seconds
        className={classes.modalContainer}
        style={style}
      >
        {children}
      </motion.div>
    </Modal>
  );
};

CModal.propTypes = {
  visible: PropType.bool,
  onClose: PropType.func,
};

CModal.defaultProps = {
  visible: false,
  onClose: () => {},
};

export default CModal;
