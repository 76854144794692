import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "../Screens/Home";
import EventDetails from "../Screens/EventDetails";
import ClubDetails from "../Screens/ClubDetails";
import Map from "../Screens/Map";
import Chat from "../Screens/Chat/chat";
import UserProfile from "../Screens/UserProfile";
import Payment from "../Screens/Payment";
import { isMobile, isTablet } from "react-device-detect";
import ReservationsList from "../Screens/ReservationList";
import Events from "../Screens/Events";
import Notification from "../Screens/Notification";
import MyEvents from "../Screens/MyEvents";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import theme, { FontFamily } from "../Config/theme";
import ProfileMobile from "../Screens/Profile/Mobile/ProfileMainScreen";
import Profile from "../Screens/Profile/Web/ProfileMainScreen";
import ProfilePersonalInfoMobile from "../Screens/Profile/Mobile/PersonalInfo";
import GolfClubsMobile from "../Screens/Profile/Mobile/GolfClubs";
import GolfOtherDetailsMobile from "../Screens/Profile/Mobile/GolfOtherDetails";
import ContactUs from "../Screens/ContactUs";
import Faqs from "../Screens/FAQs";
import TermsAndConditions from "../Screens/Terms&Conditions";
import PrivacyPolicy from "../Screens/PrivacyPolicy";
import AboutUs from "../Screens/AboutUs";
import ReviewsMobile from "../Screens/Profile/Mobile/ReviewsMobile";
import { useSelector } from "react-redux";
import { store } from "../Redux/store/configureStore";
import authActions from "../Redux/reducers/auth/actions";
import NotificationPopup from "../Components/NotificationPopUp";
import { getAPIProgressData } from "../Utils/APIHelper";
import { Setting } from "../Utils/Setting";
import _ from "lodash";
import AddBankMobile from "../Screens/Payment/AddBankMobile";
import PastReservation from "../Screens/PastReservations/index";
import SubscriptionPlans from "../Screens/SubscriptionPlans/index";
import Subscription from "../Screens/Profile/Web/Subscription";

function HomeStack() {
  // styles and media queries
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  // Redux
  const uuid = useSelector((state) => state.auth.useruuid);
  const isTokenSent = useSelector((state) => state.auth.isTokenSent);
  const isAdmin = useSelector((state) => state.auth.isAdmin);
  const userData = useSelector((state) => state.auth.userData);

  // back to admin when user login as admin
  const backToAdmin = () => {
    store.dispatch(authActions.setAccessToken(""));
    store.dispatch(authActions.setUserData({}));
    store.dispatch(authActions.setLatLng({}));
    store.dispatch(authActions.setSelectedChatData({}));
    store.dispatch(authActions.setIsAdmin(false));
    window.location.replace("https://golf.teamgroovy.com/admin");
  };

  useEffect(() => {
    if (!_.isEmpty(uuid) && !isTokenSent) {
      sendFcmToken(uuid);
    }
  }, []);

  // this function for send token to server
  const sendFcmToken = async (token) => {
    const data = {
      token: token,
    };

    try {
      const resp = await getAPIProgressData(
        Setting.endpoints.userToken,
        "POST",
        data,
        true
      );
      if (resp?.success) {
        store.dispatch(authActions.setIsTokenSent(true));
      }
    } catch (err) {}
  };

  return (
    <>
      {isAdmin && (
        <Grid
          container
          style={{
            backgroundColor: "yellow",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontFamily: FontFamily.Medium,
            boxShadow: "0 0 10px 2px rgb(103	65	136 / 10%)",
            position: "sticky",
            zIndex: 1000,
            top: 0,
            marginBottom: "-10px",
          }}
        >
          <span style={{ fontSize: sm ? "12px" : "14px" }}>
            You are currently login with admin
          </span>
          <Typography
            style={{
              marginLeft: sm ? "20px" : "30px",
              fontSize: isMobile ? "12px" : "14px",
              cursor: !isMobile && !isTablet && "pointer",
              textDecoration: "underline",
            }}
            onClick={() => {
              backToAdmin();
            }}
          >
            Back to admin
          </Typography>
        </Grid>
      )}
      <Routes>
        <Route path="/home" element={<Home />} />
        <Route path="/" element={<Home />} />
        <Route path="*" element={<Navigate to={"/"} />} />
        <Route path="/reservation" element={<ReservationsList />} />
        <Route path="/event" element={<Events />} />
        <Route path="/club-details" element={<ClubDetails />} />
        <Route path="/map" element={<Map />} />
        <Route path="/chat" element={<Chat />} />
        <Route path="/notification" element={<Notification />} />
        <Route path="/user-profile" element={<UserProfile />} />
        <Route path="/event-details/:id" element={<EventDetails />} />
        <Route path="/my-events" element={<MyEvents />} />
        <Route path="/club-details/:id" element={<ClubDetails />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/add-bank" element={<AddBankMobile />} />
        <Route path="/past-reservations" element={<PastReservation />} />
        {userData?.subscription_detail?.subscription_expire_days ===
          "Expired" && (
          <Route path="/subscription-plans" element={<SubscriptionPlans />} />
        )}

        {md || isMobile || isTablet ? (
          <Route path="/profile" element={<ProfileMobile />} />
        ) : (
          <Route path="/profile" element={<Profile />} />
        )}

        {(md || isMobile || isTablet) && (
          <>
            <Route
              path="/profile-personal-details"
              element={<ProfilePersonalInfoMobile />}
            />
            <Route path="/profile-golf-club" element={<GolfClubsMobile />} />
            <Route
              path="/profile-golf-other-details"
              element={<GolfOtherDetailsMobile />}
            />
            <Route path="/review-details" element={<ReviewsMobile />} />
            <Route path="/subscription" element={<Subscription />} />
          </>
        )}
        {isMobile && <Route path="/notification" element={<Notification />} />}

        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/faqs" element={<Faqs />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/about-us" element={<AboutUs />} />
      </Routes>
      <NotificationPopup />
    </>
  );
}

export default HomeStack;
