import { makeStyles } from "@mui/styles";
import BaseColor from "../../Config/colors";
import { FontFamily } from "../../Config/theme";

const styles = makeStyles({
  mainTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    color: `${BaseColor.primary} !important`,
    fontFamily: `${FontFamily.GilbertQualifiDemo} !important`,
    fontSize: "22px !important",
    marginLeft: "10px !important",
  },
  planTitle: {
    display: "flex !important",
    marginTop: "10px !important",
    justifyContent: "center !important",
    alignItems: "center !important",
    flexDirection: "column !important",
  },
});

export default styles;
